import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import * as actions from './UserClassificationDetails.actions';
import UserClassificationDetailsComponent from './UserClassificationDetails.component';
import { getSortedDimensionAttributes } from '../DimensionClassifications.reducer';

const mapStateToProps = (state, ownProps) => {

  const userId = ownProps.match.params.userId;

  const styleClassificationsById = state.dimensionClassification
                                   && state.dimensionClassification.styleClassificationsByUserId
                                   ? state.dimensionClassification.styleClassificationsByUserId
                                   : {};

  const roomClassificationsById = state.dimensionClassification
                                  && state.dimensionClassification.roomClassificationsByUserId
                                    ? state.dimensionClassification.roomClassificationsByUserId
                                    : {};

  const styleDimensions = getSortedDimensionAttributes({
    classifications: styleClassificationsById
  });

  const roomDimensions = getSortedDimensionAttributes({ classifications: roomClassificationsById });

  return {
    loaders: state.app.loaders,
    userId,
    styleClassificationsById,
    roomClassificationsById,
    styleDimensions,
    roomDimensions
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({
      ...actions
    }, dispatch)
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserClassificationDetailsComponent)
);