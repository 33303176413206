import * as types from '../../../../../constants/ActionTypes';
import * as api from '../../../../../util/api';

export const getLists = ({ startIndex = 0, sortBy, sortOrder,
  keyword, type = 'curation', pinterest } = {}) => {
  return (dispatch, getState) => {
    // const userId = state.app.user.userId;
    dispatch({ type: types.INITIATE_LOADER, loader: 'listByOSMID' });
    api.getLists({ startIndex, sortBy, sortOrder, keyword, type, pinterest })
    .then(resp => {
      dispatch({ type: types.REMOVE_LOADER, loader: 'listByOSMID' });
      dispatch({ type: types.CLEAR_APP_META_ATTRIBUTES_SELECTION });
      const state = getState();
      dispatch({ type: types.REFRESH_CURATION_LISTS, resp, meta: state.app.meta });
    });
  };
};

export const getProductLists = ({ startIndex = 0, sortBy = '',
  sortOrder = '', keyword = '' } = {}) => {
  return (dispatch, getState) => {
    dispatch({ type: types.INITIATE_LOADER, loader: 'listByProduct' });
    api.getProductLists({ startIndex, sortBy, sortOrder, keyword })
      .then(resp => {
        const searchReport = {
          totalProducts: resp.searchReport.totalProducts,
          keyword: resp.searchReport.keyword,
          pageSize: resp.searchReport.pageSize,
          startIndex: resp.searchReport.startIndex,
          sortOrder: resp.searchReport.sortOrder,
          sortBy: resp.searchReport.sortBy,
          $visiblePages: 5
        };
        dispatch({ type: types.LOAD_MAINTENANCE_ITEMS, items: resp.productList, searchReport });
        dispatch({ type: types.REMOVE_LOADER, loader: 'listByProduct' });
      })
      .catch(e => {
        console.error(e);
        dispatch({ type: types.REMOVE_LOADER, loader: 'listByProduct' });
      });
  };
};
export const filterItems = ({ keyword }) => {
  return (dispatch) => {
    dispatch(getProductLists({ keyword }));
  };
};

export const resetUISettings = () => {
  return (dispatch) => {
    dispatch({ type: types.SET_ITEM_KEYWORD_FILTER, keyword: '' });
  };
};

export const setProductFilterKeyword = ({ keyword }) => {
  return { type: types.SET_ITEM_KEYWORD_FILTER, keyword };
};
