import React, { Component } from 'react';
import PropTypes from 'prop-types';
import HeaderContainer from '../../modules/Header';
import './style.scss';
import { Legend, Tooltip, CartesianGrid, YAxis, XAxis, Bar, BarChart } from 'recharts';
import { Link } from "react-router-dom";

const colors = ['#F96302', '#00AE4D', '#FFC20E', '#286FAD', '#CCCCCC', '#ED1C24', '#003417'];


class UserClassificationsComponent extends Component {


  componentDidMount() {
    const { actions } = this.props;
    actions.getUserClassificationDetails({});
    document.title = "User Classifications";
  }

  mapDimensionToBarChart(dimension) {
    const data = dimension.map(attr => {
      return {
        name: attr.name,
        [attr.name]: attr.weight
      };
    });
    return data;
  }
  render() {
    const {
      loaders,
      styleClassifications,
      roomClassifications,
      roomDimensions,
      styleDimensions,
    } = this.props;


    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ midAngle = 0, percent }) => {
      const cx = 200;
      const cy = 100;
      const outerRadius = 80;
      const innerRadius = 0;

      const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
      const x = cx + radius * Math.cos(-midAngle * RADIAN);
      const y = cy + radius * Math.sin(-midAngle * RADIAN);

      return (
        <text x={x}
          y={y}
          fill="white"
          textAnchor={x > cx ? 'start' : 'end'}
          dominantBaseline="central"
        >
          {`${(percent * 100).toFixed(0)}%`}
        </text>
      );
    };

    return (
      <div className="grid curationizer-main">
        <HeaderContainer />
        <div className="grid userClassifications">
          <div className="col__12-12">
          <div className="col__12-12 classifications-details">
              <div className="grid">
               <div className="col__12-12 classifications-type">
                   Classification By Room
                </div>
                {loaders['load-user-classification-details'] &&
                <div className="col__12-12 curationList-loader">
                  <div className="pre-loader">
                    <div className="segment-spinner" />
                  </div>
                </div>}
               {roomClassifications && roomDimensions && roomDimensions.length > 0
                && <div className="col__9-12">

                  <BarChart width={1000}
                    height={400}
                    data={this.mapDimensionToBarChart(roomDimensions)}
                    margin={{ top: 5, right: 30, left: 20, bottom: 10 }}
                    layout="vertical"
                    barSize={50}
                  >
                  <CartesianGrid strokeDasharray="3 3" />
                  <YAxis dataKey="name"
                    type="category"
                    style={{ fontSize: 10 }}
                    interval={0}
                    tick
                  />
                  <XAxis
                    type="number"
                    style={{ fontSize: 10 }}
                    interval={0}
                    tick
                    domain={[0, 100]}
                  />
                    <Tooltip />
                    {
                      roomDimensions.map((attr, index) => {
                        return (<Bar dataKey={attr.name}
                          fill={colors[index]}
                          key={index}
                        />);
                      })
                    }
                     </BarChart>
                </div>}

                {roomClassifications && roomDimensions && roomDimensions.length > 0
                && <div className="col__3-12">
                  <div className="col__12-12 classifications-header">
                    <div className="classifications-fields">
                      Classification Name
                    </div>

                    <div className="classifications-fields">
                      Classification %
                    </div>
                  </div>

                  <div className="col__12-12 classification-rows">
                    <div className="curationListBody grid">
                      <div className="col__12-12">
                        {roomClassifications && roomDimensions && roomDimensions.length > 0
                        && roomDimensions.map((classification, index) => {
                          return (
                            <div className={`grid ${index % 2 === 0 ?
                              'curationList__body' : ''}`}
                              key={classification.id}
                            >
                              <div className="classification-cells">
                                <h5>
                                  <Link
                                    to={`/userClassifications/dimensions/` +
                                    `${roomClassifications.id}/${classification.id}`}
                                  >
                                    {classification.name}
                                  </Link>
                                </h5>
                              </div>
                              <div className="classification-cells">
                                {classification.weight}
                              </div>
                            </div>
                          );
                        })

                        }
                      </div>
                    </div>
                  </div>
                </div>}
                <div className="col__12-12 classifications-type">
                  Classification By Style
                </div>
                {styleClassifications && styleDimensions && styleDimensions.length > 0
                && <div className="col__9-12">

                  <BarChart width={1000}
                    height={600}
                    data={this.mapDimensionToBarChart(styleDimensions)}
                    margin={{ top: 5, right: 30, left: 20, bottom: 10 }}
                    barSize={60}
                    layout="vertical"
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <YAxis dataKey="name"
                      type="category"
                      style={{ fontSize: 10 }}
                      interval={0}
                      tick
                    />
                    <XAxis
                      type="number"
                      style={{ fontSize: 10 }}
                      interval={0}
                      tick
                      domain={[0, 100]}
                    />
                    <Tooltip />
                    {
                      styleDimensions.map((attr, index) => {
                        return (<Bar dataKey={attr.name}
                          fill={colors[index]}
                          key={index}
                        />);
                      })
                    }
                  </BarChart>
                </div>}
                {styleClassifications && styleDimensions && styleDimensions.length > 0
                && <div className="col__3-12">
                  <div className="col__12-12 classifications-header">
                    <div className="classifications-fields">
                      Classification Name
                    </div>

                    <div className="classifications-fields">
                      Classification %
                    </div>
                  </div>

                  <div className="col__12-12 classification-rows">
                    <div className="curationListBody grid">
                      <div className="col__12-12">
                        {styleClassifications && styleDimensions && styleDimensions.length > 0
                        && styleDimensions.map((classification, index) => {
                          return (
                            <div className={`grid ${index % 2 === 0 ?
                              'curationList__body' : ''}`}
                              key={classification.id}
                            >
                              <div className="classification-cells">
                                <h5>
                                  <Link
                                    to={`/userClassifications/dimensions/` +
                                    `${styleClassifications.id}/${classification.id}`}
                                  >
                                    {classification.name}
                                  </Link>
                                </h5>
                              </div>
                              <div className="classification-cells">
                                {classification.weight}
                              </div>
                            </div>
                          );
                        })

                        }
                      </div>
                    </div>
                  </div>
                </div>}

              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

UserClassificationsComponent.propTypes = {
  loaders: PropTypes.object,
  styleClassifications: PropTypes.object,
  actions: PropTypes.object,
  roomClassifications: PropTypes.object,
  roomDimensions: PropTypes.array,
  styleDimensions: PropTypes.array
};

export default UserClassificationsComponent;
