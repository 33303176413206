import * as types from '../../../../../constants/ActionTypes';
import * as api from '../../../../../util/api';

export function resetMerchandisingAttributes() {
  return (dispatch, getState) => {
    const state = getState();
    const dimensions = state.app.meta.dimensions.map(dimension => {
      return {
        id: dimension.id,
        name: dimension.name,
        attributes: dimension.attributes.map(attr => {
          return {
            ...attr,
            weight: 0
          };
        })
      };
    });
    dispatch({ type: types.RESET_MERCHANDISING_ATTRIBUTES, dimensions });
  };
}

export function getProductAttributes({ itemId }) {
  return (dispatch, getState) => {
    dispatch({
      type: types.INITIATE_LOADER,
      loader: 'load_item_details'
    });
    dispatch(resetMerchandisingAttributes());
    api.getProductAttributes({ itemId })
      .then(resp => {
        dispatch({
          type: types.LOAD_MERCHANDISING_ATTRIBUTES,
          meta: (resp && resp.merchandisingAttributes ? resp.merchandisingAttributes : {})
        });
        dispatch({
          type: types.LOAD_PRODUCT_INFO,
          item: (resp.item && resp.item.length > 0 ? resp.item[0] : {})
        });
        dispatch({
          type: types.REMOVE_LOADER,
          loader: 'load_item_details'
        });
      })
      .catch(e => {
        console.error(e);
        dispatch({ type: types.REMOVE_LOADER, loader: 'load_item_details' });
      });
  };
}
