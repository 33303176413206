import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import * as actions from './MaintenanceDetails.actions';
import component from './MaintenanceDetails.component';

const mapStateToProps = (state, ownProps) => {
  const itemId = ownProps.match.params.id;
  return {
    loaders: state.app.loaders,
    item: {
      itemId
    }
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    actions: bindActionCreators({
      ...actions,
    }, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(component));
