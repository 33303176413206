import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

class GoogleAnalytics extends Component {
  componentDidMount() {
    const { actions, location } = this.props;
    actions.sendPageview({ pathname: location.pathname });
  }
  componentDidUpdate(oldProps) {
    const { location, actions } = this.props;
    if (location.pathname !== oldProps.location.pathname) {
      actions.sendPageview({ pathname: location.pathname });
    }
  }
  render() {
    const { children } = this.props;
    return (
      <Fragment>
      { children }
      </Fragment>
    );
  }
}

GoogleAnalytics.propTypes = {
  children: PropTypes.object,
  actions: PropTypes.object,
  location: PropTypes.object
};

export default GoogleAnalytics;
