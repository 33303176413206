
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
// import '../versions.style.scss';

import { GCS_IMAGE_BUCKET_LIFESTYLE } from '../../../../../../../../constants/environment';
import { imageErrorHandler } from '../../../../../../../../util/index';
import { deserializeHotspotItems }
  from '../../../../../../../overlay/Hotspots/CurationHotSpotReducer';

class LifestyleDiff extends Component {

  constructor(props) {
    super(props);
    this.imageRef = React.createRef();
    this.state = {
      boxes: []
    };
  }

  setBox(box) {
    let canvas = {
      width: 300,
      height: 165
    };

    const img = this.imageRef.current;
    if (img) {
      canvas = {
        width: img.width,
        height: img.height
      };
    }

    if (!box) {
      this.setState({ boxes: [] });
    } else {
      let b = deserializeHotspotItems({ items: [box], canvas });
      this.setState({ boxes: b });
    }
  }

  render() {
    const { image, imageBoundBox = [] } = this.props;
    const { boxes } = this.state;
    const imageBoundBoxDiffs = imageBoundBox.filter(n => {
      return (n.diff && n.item.guid === image.guid);
    });
    imageBoundBoxDiffs.sort((a, b) => {
      const itemA = a.item || a || { itemId: 0 };
      const itemB = a.item || b || { itemId: 0 };
      return a.itemId > b.itemId ? 1 : -1;
    });
    return (
      <li className="diff-images__row">
        <div>
        {image.diff &&
          <div><img
            ref={this.imageRef}
            className={image.type === 'A'
              ? 'diff-images__new-item'
              : 'diff-images__deleted-item'
            }
            alt=""
            onError={function err(e) {
              imageErrorHandler(e);
            }}
            width="300"
            src={(GCS_IMAGE_BUCKET_LIFESTYLE + image.item.guid)}
          />
          </div>
        }
        {!image.diff &&
          <div className="diff-hotspots__preview-wrapper">
            <div className="diff-hotspots__preview-svg-wrapper">
            {!!boxes.length && <svg>
              {boxes
              .map((box, n) => {
                return (
                  <g
                    key={n}
                    transform={`translate(${box.coords.x},${box.coords.y})`}
                    x={box.coords.x}
                    y={box.coords.y}
                  >

                    <circle
                      cx={box.dotCoord.x}
                      cy={box.dotCoord.y}
                      r={4}
                      fill="#F96302"
                      strokeWidth="1"
                      stroke="white"
                    />
                </g>);
              })}
            </svg>
            }
            <img
              ref={this.imageRef}
              width="300"
              alt=""
              onError={imageErrorHandler}
              src={(GCS_IMAGE_BUCKET_LIFESTYLE + image.guid)}
            />
            </div>
            {!!imageBoundBoxDiffs.length && <div className="diff-images__hotspot">
              <div className="diff-images__hotspot-header">
                Hotspot Changes
              </div>
              <ul>
              {imageBoundBoxDiffs
                .map((box, n) => {
                  return (<li key={n}
                    onMouseEnter={(e) => {
                      this.setBox(box.item);
                    }}
                    onMouseLeave={(e) => {
                      this.setBox();
                    }}
                    className={`diff-images__hotspot-item ${box.type === 'A'
                      ? 'diff-images__hotspot-item--new'
                      : 'diff-images__hotspot-item--deleted'
                    }`}
                  >
                    <span className="diff-images__hotspot-item-change-type">
                      {box.type === 'A' ? 'Added' : 'Deleted'}
                    </span>
                    <span
                      className="diff-images__hotspot-item-itemId"
                    >ItemId: {box.item.itemId}</span>
                  </li>);
                })
              }
              </ul>
            </div>
            }
          </div>
        }
        </div>
      </li>
    );
  }
}

LifestyleDiff.propTypes = {
  image: PropTypes.object,
  imageBoundBox: PropTypes.array
};

export default LifestyleDiff;
