import * as types from '../../../../constants/ActionTypes';


const initialState = {
  skuClassification: [],
  userClassification: [],
  userClassificationChanged: [],
  skuClassificationChanged: [],
  errorMessage: '',
  infoMessage: ''
};

export default function app(state = initialState, action) {
  switch (action.type) {
    case types.SET_CLASSIFICATIONS:
      return {
        ...state,
        skuClassification: action.sku,
        userClassification: action.user,
        userClassificationChanged: action.user,
        skuClassificationChanged: action.sku,
        errorMessage: '',
      };
    case types.SET_USER_CLASSIFICATION_WEIGHT:
      return {
        ...state,
        userClassificationChanged: state.userClassificationChanged.map(userClassification => {
          if (userClassification.id === action.id) {
            return {
              ...userClassification,
              weight: action.weight
            };
          }
          return userClassification;
        })
      };
    case types.SET_SKU_CLASSIFICATION_WEIGHT:
      return {
        ...state,
        skuClassificationChanged: state.skuClassificationChanged.map(skuClassification => {
          if (skuClassification.id === action.id) {
            return {
              ...skuClassification,
              weight: action.weight
            };
          }
          return skuClassification;
        })
      };
    case types.SET_SKU_CLASSIFICATION_ERROR_MSG:
      return {
        ...state,
        errorMessage: action.errorMessage
      };
    case types.SET_CLASSIFICATION_INFO_MSG:
      return {
        ...state,
        infoMessage: action.infoMessage
      };
    default:
      return state;
  }
}