import React, { Component } from 'react';
import PropTypes from 'prop-types';

class NewCollection extends Component {
  render() {
    const {
      loaders,
      actions
    } = this.props;
    return (
      <div className="col__2-12">
          <a
            className={
              'bttn-outline bttn-outline--primary bttn--inline ' +
              (loaders.listByUser ? 'disabled' : '')
            }
            href="#"
            onClick={e => {
              e.preventDefault();
              if (!loaders.listByUser) {
                actions.createNewListAndRouteToIt({ type: 'collection' });
              }
            }}
          >
            <span className="bttn__content">New Collection</span>
          </a>
        </div>
    );
  }
}

NewCollection.propTypes = {
  loaders: PropTypes.object,
  actions: PropTypes.object
};

export default NewCollection;