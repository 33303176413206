import * as types from '../../../../../../constants/ActionTypes';
import defaultProduct from '../../../../../../assets/images/default-product.jpg';


const initialState = {
  imageBoundBox: [],
  items: [],
  merchandisingAttributes: {},
  itemByItem: false,
  activeItemId: 0
};

const updateImageSrc = ({ data, size = '400' }) => {
  let path = (data.info && Object.keys(data.info).length)
    ? data.info.imageUrl
    : defaultProduct;
  return path.replace(/<SIZE>/g, size);
};


export const getItemsImageBoundBox = ({ state }) => {
  let imageBoundBox = [];
  if (state.vision.imageBoundBox && state.vision.imageBoundBox.length > 0) {
    imageBoundBox = state.vision.imageBoundBox.filter(
                                                      hotspot => hotspot.itemIds
                                                      && hotspot.itemIds.length > 0
                                                      );
  }
  return imageBoundBox;
};

export default function app(state = initialState, action) {
  switch (action.type) {
    case types.SET_VISION_IMAGE_ATTRIBUTES:
      return {
        ...state,
        imageBoundBox: action.imageBoundBox,
        items: action.items,
        merchandisingAttributes: action.merchandisingAttributes
      };
    case types.REMOVE_VISION_IMAGE:
      return {
        ...state,
        imageBoundBox: [],
        items: [],
        merchandisingAttributes: {},
        itemByItem: false,
        activeItemId: 0
      };
    case types.RESET_VISION_UI_SETTINGS:
      return {
        ...state,
        imageBoundBox: [],
        items: [],
        merchandisingAttributes: {},
        itemByItem: false,
        activeItemId: 0
      };
    case types.LOAD_HOTSPOT_IMAGES:
      return {
        ...state,
        hotspotItems: action.hotspotItems,
        itemByItem: false,
        activeItemId: 0
      };
    case types.RESET_HOTSPOT_IMAGES:
      return {
        ...state,
        hotspotItems: []
      };
    case types.SET_UNSET_ITEM_BY_ITEM:
      return {
        ...state,
        itemByItem: action.itemByItem
      };
    case types.SET_VISION_ITEM_ACTIVE:
      return {
        ...state,
        activeItemId: action.activeItemId
      };
    default:
      return state;
  }
}