import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReviewItemDragSource from './ReviewItem/ReviewItemDragSource';
import ReviewItemDropzoneComponent from './ReviewItemDropzoneComponent';
import ReviewItemDetails from '../ReviewItemDetails';
import { calculateHeight } from '../../../../../../util';
import './style.scss';

function getNoOfColumns({ ui, type }) {
  if (type === 'collection') return 'col__6-12';

  const columns = ui.ReviewList.NoOfColumns;
  switch (columns) {
    case 2:
      return 'col__1-2';
    case 3:
      return 'col__1-3';
    case 4:
      return 'col__1-4';
    case 5:
      return 'col__1-5';
    case 6:
      return 'col__1-6';
    default:
      return 'col__1-3';
  }
}

class ReviewItem extends Component {
  render() {
    const {
      list,
      item = {},
      ui,
      actions,
      index,
      multiItemPreview
    } = this.props;
    return (
    <div
      style={{
        height: calculateHeight({
          item,
          columns: ui.ReviewList.NoOfColumns,
          editMode: ui.curateList.editMode
        })
      }}
      className={`${getNoOfColumns({ ui, type: list.type })} open-item__container
        ${(item._selected && !ui.curateList.editMode) ? 'open-item__container--selected' : ''}`}
    >
      <ReviewItemDropzoneComponent
        list={list}
        item={item}
        index={index}
        actions={actions}
        multiItemPreview={multiItemPreview}
      >
        <ReviewItemDragSource
          list={list}
          item={item}
          index={index}
          multiItemPreview={multiItemPreview}
        />
        {!ui.curateList.editMode && item.type !== 'collection' &&
        <ReviewItemDetails index={index} item={item} list={list} />
        }
      </ReviewItemDropzoneComponent>
    </div>
    );
  }
}

ReviewItem.propTypes = {
  list: PropTypes.object,
  ui: PropTypes.object,
  item: PropTypes.object,
  actions: PropTypes.shape({
    selectItem: PropTypes.func.isRequired
  }),
  multiItemPreview: PropTypes.object,
  index: PropTypes.number
};


export default ReviewItem;
