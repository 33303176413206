import * as types from '../../../../../constants/ActionTypes';


const initialState = {
  users: [],
  styleClassificationsByUserId: {},
  roomClassificationsByUserId: {},
};

export const getSortedDimensionAttributes = ({ classifications }) => {
  let classificationAttributes = [];
  if (classifications
      && classifications.attributes
      && classifications.attributes.length > 0) {
    classificationAttributes =
      classifications.attributes.sort((a, b) => (a.weight < b.weight ? 1 : -1));
  }
  return classificationAttributes;
};

export const getDimensionName = ({ user }) => {
  let dimensionName = '';
  if (user && user.dimensions && user.dimensions.length > 0) {
    dimensionName = user.dimensions[0].name;
  }
  return dimensionName;
};

export const getAttributeName = ({ user }) => {
  let attributeName = '';
  if (user
    && user.dimensions
    && user.dimensions.length > 0
    && user.dimensions[0].attributes
    && user.dimensions[0].attributes.length > 0) {
    attributeName = user.dimensions[0].attributes[0].name;
  }
  return attributeName;
};

export const getAttributeWeight = ({ user }) => {
  let attributeWeight = 0.0000;
  if (user
    && user.dimensions
    && user.dimensions.length > 0
    && user.dimensions[0].attributes
    && user.dimensions[0].attributes.length > 0) {
    attributeWeight = user.dimensions[0].attributes[0].weight;
  }
  return attributeWeight;
};

export default function app(state = initialState, action) {
  switch (action.type) {
    case types.SET_DIMENSION_CLASSIFICATION_BY_USER:
      return {
        ...state,
        users: action.users.map(user => {
          return {
            userId: user.userId,
            dimensionName: getDimensionName({ user }),
            attributeName: getAttributeName({ user }),
            attributeWeight: getAttributeWeight({ user })
          };
        }),
        searchReport: action.searchReport
      };
    case types.SET_USER_CLASSIFICATION_DETAILS_BY_ID:
      return {
        ...state,
        styleClassificationsByUserId: action.styleClassificationsByUserId,
        roomClassificationsByUserId: action.roomClassificationsByUserId
      };
    default:
      return state;
  }
}