import React, { Component } from 'react';
import PropTypes from 'prop-types';
import HeaderContainer from '../../modules/Header';
import Accordion from '../../modules/Accordion';
import ReviewItemImage from './modules/ReviewItemImage';
import ReviewItemContainer from './modules/ReviewItemContainer';
import CheckboxGroup from '../../modules/CheckboxGroup';
import InlineMessage from '../../modules/InlineMessage/component';
import Versions from './modules/Versions';
import CollectionsLists from './modules/CollectionsList';
import CurateListHeader from './components/CurateListHeader.component';
import ReviewItemImageWrapper from './components/ReviewItemImageWrapper.component';
import ReviewItemAttributesWrapper from './components/ReviewItemAttributesWrapper.component';
import ReviewListDetails from './components/ReviewListDetails.component';
import Overlay from 'thd-overlay';
// big dep (recharts)
// import AnalyticsContainer from '../../modules/Analytics';
import TagsContainer from '../../modules/Tag';
import XDate from 'xdate';
import { formatDate } from '../../../../util';
import './style.scss';

const DAVINCI_NAME_ERR = 1021;
const DAVINCI_DESCRIPTION_ERR = 1022;
const DELETE_CURATION_ERR = 1026;
const UPLOAD_IMAGE_ERR = 400;

class ReviewList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false
    };
  }
  componentDidMount() {
    const { actions, listType } = this.props;
    actions.onload()
      .then(() => {
        this.setState({ isLoaded: true });
      });

    let title = '';
    if (listType === 'curation') {
      title = 'Curation Details';
    } else if (listType === 'collection') {
      title = 'Collection Details';
    }
    document.title = title;
  }

  componentDidUpdate(oldProps) {
    const { actions, location } = this.props;
    if (location.pathname !== oldProps.location.pathname) {
      actions.onload()
        .then(() => {
          this.setState({ isLoaded: true });
        });

    }
  }

  displayWarningModal() {
    const myInlineContent = document.createElement('div');
    myInlineContent.innerHTML = `<div class="grid">
      <div class="col__12-12">My Inline Content</div>
    </div>`;
    const exampleOverlay = new Overlay({
      width: 1000,
      height: 660,
      speed: '500ms',
      channel: 'desktop',
      header: true,
      title: 'My Inline Overlay Title',
      htmlNode: myInlineContent
    });
    exampleOverlay.open();
  }

  isLoaded() {
    const { loaders } = this.props;
    return this.state.isLoaded && !loaders['render-review-list'];
  }

  render() {
    const {
      list,
      actions,
      ui,
      errors,
      selectedOpen,
      loaders,
      listType,
      spaceCollectionURL,
      isEnablePinterest,
      collections
    } = this.props;
    return (
      <div className="grid curationizer-main">
    <HeaderContainer />
    <div className="col__12-12 curate-list">
      {this.isLoaded() && list.$canEdit && list.status === 'edit' &&
        <div className="alert alert--warning reminder">
          <span className="alert__message">
            <span className="alert__title">Reminder:</span> changes saved, but not published.</span>
        </div>
      }
      {this.isLoaded() && list && list.meta && <div className="grid flush">

        <div className="col__9-12 curate-list__sidebar">
          {errors.length > 0 &&
            <div className="grid flush">
              {errors.filter(error => {
                return (error.errorCode !== DELETE_CURATION_ERR
                  && error.errorCode !== DAVINCI_DESCRIPTION_ERR
                  && error.errorCode !== DAVINCI_NAME_ERR
                  && error.errorCode !== UPLOAD_IMAGE_ERR);
              }).map((err, i) => {
                return (<InlineMessage type="danger" key={i}
                  message={err.description}
                />);
              })}
            </div>
          }
          {errors.length > 0 &&
            <div className="grid flush">
              {errors.filter(error => {
                return error.errorCode === DAVINCI_NAME_ERR;
              }).map((err, i) => {
                return (<InlineMessage type="danger" key={i}
                  message={err.description}
                />);
              })}
            </div>
          }
          { list.type === 'collection' &&
            <div className="spacecollection__url--wrapper">
              <span className="collection__url--label"> {list.type} URL:</span>
              <span className="collection__input--wrapper">
                <p
                  className="collection__url-input"
                  onClick={(e) => {
                    document.execCommand('copy');
                  }}
                ><span>{spaceCollectionURL}</span></p>
              </span>
            </div>
          }
          <CurateListHeader
            list={list}
            actions={actions}
            errors={errors}
            DAVINCI_DESCRIPTION_ERR={DAVINCI_DESCRIPTION_ERR}
          />
          <div className="curation-list-result">
            <div className="row-header-wrapper">
              <div className="row-header">
                <div className="row-header__title">
                  <span className="row-header__text text-caps">
                    {list.type} Items ({list.items.length})
                  </span>
                </div>
                <div className="item_list__header--link review-item__container">
                  {list.$canEdit && list.type === 'curation' &&
                    <span className="drop-down__title drop-down__title--select">
                      <select
                        className="drop-down__select"
                        name="sortByAjax"
                        id="sortByAjax"
                        value={('' + list.sortOrder) || false}
                        onChange={(e) => {
                          const value = e.target.value;
                          actions.setCurationSort({ curation: list, value });
                        }}
                      >
                        <option value="1">
                          Manual Sort
                        </option>
                        <option value="random">
                          Shuffle
                        </option>
                        <option value="2">
                          Price High to Low
                        </option>
                        <option value="3">
                          Price Low to High
                        </option>
                        <option value="4">
                          Recommended
                        </option>
                      </select>
                    </span>
                  }
                  {list.type !== 'collection' &&
                    <span className="drop-down__title drop-down__title--select
                      ReviewList__column--View"
                    >
                    <select
                      className="drop-down__select"
                      name="sortByAjax"
                      id="sortByAjax"
                      value={ui.ReviewList.NoOfColumns}
                      onChange={(e) => {
                        const value = e.target.value;
                        actions.setReviewListColumnView({ value });
                      }}
                    >
                      <option value="2">
                        2 Column View
                      </option>
                      <option value="3">
                        3 Column View
                      </option>
                      <option value="4">
                        4 Column View
                      </option>
                      <option value="5">
                        5 Column View
                      </option>
                      <option value="6">
                        6 Column View
                      </option>
                    </select>
                  </span>
                  }
                  {list.$canEdit && <a className={`row-header__link reviewList__edit
                    ${ui.curateList.editMode ? 'curationList__Sort--Header' : ''}`} href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      actions.toggleCurateListEditMode();
                    }}
                  >
                    {!ui.curateList.editMode &&
                      <span>Remove</span>
                    }
                    {ui.curateList.editMode &&
                      <span>Cancel</span>
                    }
                  </a>}
                  {list.$canEdit &&
                    <a className="curationList__duplicate--list" href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        let url = `/davinci/overlay/index.html#/${list.type}/duplicate/${list.id}`;
                        window.THD_OVERLAYS.duplicateList.open(url);
                      }}
                    >Duplicate List</a>
                  }
                </div>
              </div>
            </div>
            {loaders['review-sorting'] && <div>
              <div className="pre-loader">
                <div className="segment-spinner"></div>
              </div>
            </div>
            }
            {!loaders['review-sorting'] && <div
              className={`grid ${selectedOpen ? 'review-items--selected' : ''}`}
            >
              {list.items.map((item, i) => {
                return (
                  <ReviewItemContainer
                    key={i}
                    index={i}
                    itemId={item.id || item.itemId}
                    list={list}
                    item={item}
                  />
                );
              })}
            </div>
            }
          </div>
        </div>
        <div className="col__3-12 curate-list__sidebar">
          {list.$canEdit && <div className="reviewList-slider reviewList__back">
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  actions.resetSettings({ listId: list.id });
                  if (ui.curateList.editMode) {
                    actions.toggleCurateListEditMode();
                  }
                  if (listType === 'curation') {
                    actions.navigate(`/curation/curate/${list.id}`);
                  } else {
                    actions.navigate(`/collection/curate/${list.id}`);
                  }
                }}
              >
                <h2>
                  Discover More {list.type === 'collection' ? 'Curations' : 'Products'}
                </h2>
              </a>
          </div>
          }
          <ReviewItemImageWrapper list={list} errors={errors} UPLOAD_IMAGE_ERR={UPLOAD_IMAGE_ERR} />

          <ReviewItemAttributesWrapper list={list} actions={actions} />
          <div className="review-item-details-wrapper">
            <div className="review-item-box-title">
              <span className="review-item-box-title__text">Publish</span>
            </div>
            <ReviewListDetails text="Status" list={list} />
            <ReviewListDetails text={list.type === 'collection' ? 'Collection ID' :
              'Curation ID'} list={list}
            />
            <ReviewListDetails text="Curator" list={list} />
            <ReviewListDetails text="Date created" list={list} />
            <div className="reviewList-details">
              <div className="checkbox-btn ">
                <input
                  type="checkbox"
                  checked={list.hideOnCLP === true ? 'checked' : '' }
                  ref={el => {
                    if (el) {
                      el.removeAttribute('checked');
                    }
                  }}
                  onChange={(e) => {
                    actions.toggleHideCLPCheckbox({ list });
                  }}
                  className="checkbox-btn__input"
                  name="clpStatus"
                  id="clpStatus"
                />
                <label className="checkbox-btn__label" htmlFor="clpStatus">
                  Hide on CLP
                </label>
              </div>
            </div>
            <div className="reviewList-details">
              <div className="checkbox-btn ">
                <input
                  type="checkbox"
                  checked={list.pinterestSTLStatus ? 'checked' : '' }
                  disabled={!isEnablePinterest}
                  ref={el => {
                    if (el) {
                      if (list.pinterestSTLStatus) {
                        el.setAttribute('checked', 'checked');
                      } else {
                        el.removeAttribute('checked');
                      }
                    }
                  }}
                  onChange={(e) => {
                    actions.togglePinterestCheckbox({ list });
                  }}
                  className="checkbox-btn__input"
                  name="pinterest_published"
                  id="pinterest_published"
                />
                <label className="checkbox-btn__label" htmlFor="pinterest_published">
                  Send to Pinterest
                </label>
            </div>
            </div>
            {list.$canEdit && <div className="grid">
              {errors.length > 0 &&
                <div className="grid flush">
                  {errors.filter(error => {
                    return error.errorCode === DELETE_CURATION_ERR;
                  }).map((err, i) => {
                    return (<InlineMessage type="danger" key={i}
                      message={err.description}
                    />);
                  })}
                </div>
              }
              {!!list.$publishErrors.length &&
              <div className="col__12-12 reviewList__status">
                {list.$publishErrors.map((err, i) => {
                  // return <div className="curation-flash" key={i}>{err.message}</div>;
                  return (<div className="alert alert--warning warning" key={i}>
                  <span className="alert__message">
                    <span className="alert__title">Warning:</span> {err.message}</span>
                  </div>);
                })}
                </div>
              }
              <div className="col__6-12">
              {list.type === 'collection' && <a
                href="#"
                className="bttn-outline bttn-outline--primary bttn--inline collection-preview"
                onClick={(e) => {
                  e.preventDefault();
                  actions.previewCollection({ list });
                }}
              >
                  <span className="bttn__content">
                      <span>
                        Preview
                      </span>
                  </span>
                </a>
              }
              </div>
              <div className="col__6-12">
                <a
                  href="#"
                  className={`bttn bttn--primary ${((!list.$canPublish) ? 'disabled' : '')}`}
                  onClick={(e) => {
                    e.preventDefault();
                    if (list.$canPublish) {
                      actions.updateCurationStatus({ curation: list, newStatus: 'publish' });
                    }
                  }}
                >
                  <span className="bttn__content">
                    {loaders['curation-status'] &&
                      <span className="segment-spinner segment-spinner--bttn"></span>}
                    {!loaders['curation-status'] &&
                      <span>
                        Publish
                      </span>
                    }
                  </span>
                </a>
              </div>
            </div>}
            {list.$canEdit && list.status === 'active' &&
              <div className="reviewList__status reviewList__status--links">
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      if (list.$canPublish) {
                        actions.unpublish({ curationId: list.id, type: list.type });
                      }
                    }}
                    className="u__default-link"
                    href="#"
                  >
                    Deactivate {list.type}
                  </a>
              </div>
            }
          </div>

            <div className="review-item-details-wrapper review-item-create-wrapper">
              {loaders['curation-collection-copy'] &&
              <div className="review-item-details-wrapper review-item-create-wrapper">
                <div className="pre-loader create-collection-loader">
                  <div className="segment-spinner" />
                </div>
              </div>}

              {!loaders['curation-collection-copy'] && list.$canEdit &&
              <div className="reviewList__status reviewList__status--links reviewList__create">
                  {list.type === 'curation' && <a
                    className={
                      (!list.$canPublish || loaders['curation-collection-copy']) ? 'disabled' : ''
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      if (list.$canEdit) {
                        if (collections && collections.length === 0) {
                          actions.createCollectionFromCuration({ listId: list.id })
                          .then(resp => {
                            actions.navigate(`/collection/review/${resp.id}`);
                          });
                        }
                                            
                        if (collections && collections.length > 0) {
                          let url = `/davinci/overlay/index.html#/${list.type}/warning/${list.id}`;
                          window.THD_OVERLAYS.warningMessage.open(url);
                        }
                      }
                    }}
                    className="u__default-link"
                    href="#"
                  >
                    Create Collection{loaders['curation-collection-copy'] ? '...' : ''}

                  </a>
                  }
                  {list.type === 'curation' && <span>&nbsp;|&nbsp;</span>}
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      actions.deleteCuration({ curationId: list.id, type: list.type });
                    }}
                    className="u__default-link"
                    href="#"
                  >
                  <span className="delete">Delete {list.type}</span>
                </a>
              </div>
            }
          </div>
        </div>
      </div>
      }
      {this.isLoaded() && list && list.id && <CollectionsLists listId={list.id} />}
      {this.isLoaded() && list && list.id && <Versions listId={list.id} />}
      {!this.isLoaded() &&
        <div className="pre-loader">
          <div className="segment-spinner"></div>
        </div>
      }
    </div>
  </div>
    );
  }
}

ReviewList.propTypes = {
  list: PropTypes.object,
  ui: PropTypes.object,
  errors: PropTypes.array,
  actions: PropTypes.shape({}),
  selectedOpen: PropTypes.bool.isRequired,
  loaders: PropTypes.object,
  listType: PropTypes.string,
  spaceCollectionURL: PropTypes.string,
  isEnablePinterest: PropTypes.bool,
  location: PropTypes.object,
  collections: PropTypes.array
};


export default ReviewList;
