import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from './actions';
import * as types from '../../../../../../constants/ActionTypes';
import component from './component';
import { withRouter } from 'react-router-dom';
import { clearError } from '../../../AppActions';

const mapStateToProps = (state, ownProps) => {
  let list = actions.getList({
    currentPage: state.ui.pagination.selectedCurationpage,
    list: ownProps.list,
    totalListPerPage: state.ui.pagination.totalListPerPage
  });

  const searchReport = state.item.results && state.item.results.searchReport
    ? state.item.results.searchReport
    : {};

  list.$count = (ownProps.list.items || []).length;

  return {
    list,
    itemIds: (ownProps.list.items || []).map(item => (item.id || item.itemId)),
    searchReport,
    curationListView: state.ui.curationList.viewType,
    multiItemPreview: ownProps.multiItemPreview || {},
    ui: state.ui || {},
    loaders: state.app.loaders,
    hasSearchResults: !!(state.item.results && state.item.results.searchReport)
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {

  return {
    actions: bindActionCreators({
      ...actions, clearError,
      handlePageChanged: (newPage) => {
        return () => {
          dispatch(actions.handlePageChanged({ newPage }));
        };
      },

      saveCuration: ({ list }) => {
        return () => {
          dispatch({ type: types.INITIATE_LOADER, loader: 'item-update' });
          dispatch(actions.saveCuration({ list }))
            .then(resp => {
              dispatch({ type: types.REMOVE_LOADER, loader: 'item-update' });
              const type = (list.type || 'curation').toLowerCase();
              let path = `/${type}/review/${resp.id}`;
              // temp (new curations/collections)
              // should go to default to default meta
              if (list.isTemp) {
                if (list.type === 'curation') {
                  path += '/default';
                }
                ownProps.history.replace(`/${type}/curate/${resp.id}`);
              }

              ownProps.history.push(path);
            });
        };
      }
    }, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(component));
