import * as types from '../../../../constants/ActionTypes';
import { createDefaultProduct } from '../../../../util';
import { generateMultiItemPreview } from '../CurateList/actions';
import { ENV } from '../../../../constants/environment';
import defaultProduct from '../../../../assets/images/default-product.jpg';
const initialState = {
  lists: [],
  errorModel: []
};
const DIMENSIONS = {
  STYLE: 100,
  ROOM: 200,
  COLOR: 300,
  COLOR_FAMILY: 400
};
const defaultSliderValues = (type, selectedDimensions = []) => {
  return type.map((dimension, i) => {
    return {
      ...dimension,
      weight: i === 0 ? 100 : 0,
      isDimension: true,
      attributes: dimension.attributes.map(attr => {
        let myDimension = selectedDimensions.find(dim => dim.id === dimension.id);
        if (!!myDimension && !!myDimension.attributes) {
          const selectedAttr = myDimension.attributes.find(a => a.id === attr.id);
          if (!!selectedAttr) {
            return {
              ...attr,
              weight: selectedAttr.weight
            };
          }
        }
        return {
          ...attr,
          weight: 0
        };
      })
    };
  }).sort((a, b) => a.position > b.position);
};

const defaultRefinementValues = (type = [], selectedRefinements = []) => {
  return type.map((refinement, i) => {
    return {
      ...refinement,
      weight: i === 100 ? 100 : 0,
      _lockAll: false,
      attributes: refinement.attributes
        .map((attr, ind) => {
          let myRefinement = selectedRefinements.find(ref => ref.id === refinement.id);
          if (!!myRefinement && !!myRefinement.attributes) {
            const selectedAttr = myRefinement.attributes.find(a => a.id === attr.id);
            if (!!selectedAttr) {
              return {
                name: selectedAttr.name,
                id: selectedAttr.id,
                weight: selectedAttr.value === 100 ? 100 : 0,
                value: selectedAttr.value,
                _locked: selectedAttr.value === 100
              };
            }
          }
          return {
            name: attr.name,
            id: attr.id,
            weight: attr.value === 100 ? 100 : 0,
            value: attr.value,
            _locked: false
          };
        })
        .sort((a, b) => {
          if (refinement.name !== 'Price Point') {
            return b.value - a.value;
          }
          return 0;
        })
    };
  });
};

function shuffle(array = []) {
  let i = 0;
  let j = 0;
  let temp = null;
  const res = [...array];

  for (i = res.length - 1; i > 0; i -= 1) {
    j = Math.floor(Math.random() * (i + 1));
    temp = res[i];
    res[i] = res[j];
    res[j] = temp;
  }

  return res;
}

const defaultTagValues = (type, selectedTags = []) => {
  return type.map((tag, i) => {
    return {
      ...tag,
      attributes: tag.attributes.map(attr => {
        let myTags = selectedTags.find(selectedTag => selectedTag.id === tag.id);
        if (!!myTags && !!myTags.attributes) {
          const selectedAttr = myTags.attributes.find(a => a.id === attr.id);
          if (!!selectedAttr) {
            return {
              ...attr,
              selected: true
            };
          }
        }
        return {
          ...attr,
          selected: false
        };
      })
    };
  });
};
const updateImageSrc = ({ data, size = '400' }) => {
  let path = (data.info && Object.keys(data.info).length)
    ? data.info.imageUrl
    : defaultProduct;
  return path.replace(/<SIZE>/g, size);
};
const fixTypes = (curation) => {
  const c = {
    ...curation
  };
  if (c.settings) {
    c.settings.dimensions = (c.settings.dimensions || []).map(dim => {
      return {
        ...dim,
        type: 'dimension'
      };
    });
    c.settings.refinements = (c.settings.refinements || []).map(dim => {
      return {
        ...dim,
        type: 'refinement'
      };
    });
    c.settings.tags = (c.settings.tags || []).map(dim => {
      return {
        ...dim,
        type: 'tag'
      };
    });
  }
  return c;
};

const defaultMetaData = (appMeta = {}, selectedMeta = {}) => {
  const meta = {
    tags: selectedMeta.tags || []
  };
  meta.tags = (appMeta.tags || []).map(tag => {
    return {
      ...tag,
      attributes: tag.attributes.map(attr => {
        let myTags = (selectedMeta.tags || []).find(t => t.id === tag.id);
        if (!!myTags && !!myTags.attributes) {
          const selectedAttr = myTags.attributes.find(a => a.id === attr.id);
          if (!!selectedAttr) {
            return {
              ...attr,
              selected: true
            };
          }
        }
        return {
          ...attr,
          selected: false
        };
      })
    };
  });
  meta.dimensions = appMeta.dimensions.map(dim => {
    return {
      ...dim,
      attributes: dim.attributes.map(attr => {
        let myDimension = (selectedMeta.dimensions || []).find(d => d.id === dim.id);
        if (!!myDimension && !!myDimension.attributes) {
          const selectedAttr = myDimension.attributes.find(a => a.id === attr.id);
          if (!!selectedAttr) {
            return {
              ...attr,
              value: true
            };
          }
        }
        return {
          ...attr
        };
      })
    };
  });
  return meta;
};

const updateSliderWeight = ({ selectedValue, slider, list }) => {
  if (slider.isLocked) return slider.weight;
  const totalUnlockedSliders = list.settings.dimensions.reduce((cur, next) => {
    return cur + (next.isLocked ? 0 : 1);
  }, 0) - 3;// should be 1 but two sliders are hidden
  const totalSliderValue = list.settings.dimensions.reduce((cur, next) => {
    return cur + next.weight;
  }, 0);
  const totalLockedValues = list.settings.dimensions.reduce((cur, next) => {
    return cur + (next.isLocked ? next.weight : 0);
  }, 0);
  const diff = Math.floor(totalSliderValue - 100);
  // distribute values to unlocked sliders evenly
  const ret = Math.floor((100 - selectedValue - totalLockedValues) / totalUnlockedSliders);
  if (ret < 0) return 0;
  return ret;
};

const limitSlider = ({ value, list, slider, complete = false }) => {
  const totalLockedValues = list.settings.dimensions.reduce((cur, next) => {
    return cur + (next.isLocked ? next.weight : 0);
  }, 0);
  if (value > (100 - totalLockedValues)) {
    return 100 - totalLockedValues;
  }
  return value;
};

const shouldRestrictSelection = ({ list, action, attributes, attribute }) => {
  if (list.type !== 'collection') return false;
  if (action.dimensionId === DIMENSIONS.ROOM && action.value) return true;
  const mark = attributes.filter(a => a.value).length > 1;
  if (action.dimensionId === DIMENSIONS.STYLE && mark && action.value) return true;
  return false;
};

export default function app(state = initialState, action) {
  switch (action.type) {
    case types.DELETE_CURAION:
      return {
        ...state,
        lists: state.lists.filter(list => {
          return list.id !== action.curationId;
        })
      };
    case types.REFRESH_CURATION_LISTS:
      return {
        ...state,
        searchReport: action.resp.searchReport,
        lists: action.resp.lists.map(list => {
          const c = {
            totalChildren: 0,
            items: [],
            settings: {
              dimensions: defaultSliderValues(action.meta.dimensions),
              refinements: defaultRefinementValues(action.meta.refinements),
              filters: defaultRefinementValues(action.meta.filters),
              tags: defaultTagValues(action.meta.tags)
            },
            ...list,
            meta: defaultMetaData(action.meta, list.meta),
            ui: {
              nameEdit: false
            }
          };
          return fixTypes(c);
        })
      };
    case types.SET_MULTI_LIST_DATA:
      return {
        ...state,
        lists: state.lists.map(list => {
          let selectedList = action.response.lists.find(l => l.id === list.id);
          if (!!selectedList) {
            const c = {
              totalChildren: 0,
              items: [],
              settings: {
                dimensions: defaultSliderValues(action.meta.dimensions),
                refinements: defaultRefinementValues(action.meta.refinements),
                filters: defaultRefinementValues(action.meta.filters),
                tags: defaultTagValues(action.meta.tags)
              },
              ...selectedList,
              meta: defaultMetaData(action.meta, selectedList.meta),
              ui: {
                nameEdit: false
              },
              _selected: true
            };
            return fixTypes(c);
          }
          return list;
        })
      };
    case types.SET_LIST_DATA_WITH_NAME_EDIT:
      if (!action.response) return state;
      return {
        ...state,
        lists: state.lists.map(list => {
          if (list.id === action.id) {
            return {
              ...action.response,
              ui: {
                nameEdit: false
              },
              meta: defaultMetaData(action.meta, action.response.meta)
            };
          }
          return list;
        })
      };
    case types.TOGGLE_LIST_NAME_EDIT_MODE:
      return {
        ...state,
        lists: state.lists.map(list => {
          if (list.id === action.listId) {
            return {
              ...list,
              ui: {
                nameEdit: !list.ui.nameEdit
              }
            };
          }
          return list;
        })
      };
    case types.TOGGLE_SELECTED_RESULTS_ITEM_SELECTION:
      return {
        ...state,
        lists: state.lists.map(list => {
          if (list.id === action.curationId) {
            return {
              ...list,
              items: list.items.map(item => {
                if (item.itemId === action.selectedItemId) {
                  return {
                    ...item,
                    results: (item.results || []).map(resultItem => {
                      if (resultItem.itemId === action.itemId) {
                        return {
                          ...resultItem,
                          _selected: !resultItem._selected
                        };
                      }
                      return resultItem;
                    })
                  };
                }
                return item;
              })
            };
          }
          return list;
        })
      };
    case types.TOGGLE_RESULTS_ITEM_SELECTION:
      return {
        ...state,
        lists: state.lists.map(list => {
          if (list.id === action.listId) {
            return {
              ...list,
              items: list.items.map(item => {
                if ((item.id || item.itemId) === action.itemId) {
                  return {
                    ...item,
                    _selected: !item._selected
                  };
                }
                return item;
              }).map(item => {
                // item.results is the items search result based on similar products
                // need to handle selecting those items as well
                if (item._selected) {
                  return {
                    ...item,
                    results: {
                      ...item.results,
                      items: item.results && (item.results.items || []).map(resultItem => {
                        if ((resultItem.id || resultItem.itemId) === action.itemId) {
                          return {
                            ...resultItem,
                            _selected: !resultItem._selected
                          };
                        }
                        return resultItem;
                      })
                    }
                  };
                }
                return item;
              })
            };
          }
          return list;
        })
      };
    // @TODO refactor into single case (this and one below)
    case types.UPDATE_CURATION_NAME:
      return {
        ...state,
        lists: state.lists.map(list => {
          if (list.id === action.curationId) {
            return {
              ...list,
              name: action.name
            };
          }
          return list;
        })
      };
      case types.UPDATE_INTERNAL_NAME:
        return {
          ...state,
          lists: state.lists.map(list => {
            if (list.id === action.curationId) {
              return {
                ...list,
                internalRoomName: action.internalRoomName
              };
            }
            return list;
          })
        };
    case types.UPDATE_COLLECTION_SUBTYPE:
      return {
        ...state,
        lists: state.lists.map(list => {
          if (list.id === action.listId) {
            return {
              ...list,
              collectionSubType: action.collectionSubType
            };
          }
          return list;
        })
      };
    case types.UPDATE_CURATION_STATUS:
      return {
        ...state,
        lists: state.lists.map(list => {
          if (list.id === action.id) {
            return {
              ...list,
              status: action.status
            };
          }
          return list;
        })
      };
    case types.UPDATE_MULTI_LIST_STATUS:
      return {
        ...state,
        lists: state.lists.map(list => {
          let selectedList = action.lists.find(l => l.id === list.id);
          if (!!selectedList) {
            return {
              ...list,
              status: selectedList.status
            };
          }
          return list;
        })
      };
    case types.TOGGLE_PINTEREST_PUBLISHED:
      return {
        ...state,
        lists: state.lists.map(list => {
          if (list.id === action.listId) {
            return {
              ...list,
              pinterestSTLStatus: !list.pinterestSTLStatus
            };
          }
          return list;
        })
      };
      case types.TOGGLE_HIDE_CLP_PUBLISHED:
        return {
          ...state,
          lists: state.lists.map(list => {
            if (list.id === action.listId) {
              return {
                ...list,
                hideOnCLP: !list.hideOnCLP
              };
            }
            return list;
          })
        };
    case types.UPDATE_CURATION_DESCRIPTION:
      return {
        ...state,
        lists: state.lists.map(list => {
          if (list.id === action.curationId) {
            return {
              ...list,
              description: action.description
            };
          }
          return list;
        })
      };
    case types.TOGGLE_SLIDER_LOCK:
      return {
        ...state,
        lists: state.lists.map(list => {
          if (list.id === action.listId) {
            return {
              ...list,
              settings: {
                ...list.settings,
                [action.sliderType + 's']: list.settings[action.sliderType + 's'].map(slider => {
                  if (slider.id === action.sliderId) {
                    return {
                      ...slider,
                      isLocked: !slider.isLocked
                    };
                  }
                  return slider;
                })
              }
            };
          }
          return list;
        })
      };
    case types.SET_CURATION_SORT:
      return {
        ...state,
        lists: state.lists.map(list => {
          if (list.id === action.curationId) {
            return {
              ...list,
              items: action.random
                ? shuffle(list.items)
                : list.items,
              sortOrder: action.value,
            };
          }
          return list;
        })
      };
    case types.SET_SLIDER_VALUE:
      return {
        ...state,
        lists: state.lists.map(list => {
          if (list.id === action.listId) {
            return {
              ...list,
              settings: {
                ...list.settings,
                [action.sliderType + 's']: list.settings[action.sliderType + 's'].map(slider => {
                  if (slider.id === action.sliderId) {
                    return {
                      ...slider,
                      weight: limitSlider({
                        value: parseInt(action.value, 10),
                        list,
                        slider,
                        complete: action.complete
                      })
                    };
                  }
                  return {
                    ...slider,
                    weight: updateSliderWeight({
                      selectedValue: parseInt(action.value, 10),
                      slider,
                      list
                    })
                  };

                })
              }
            };
          }
          return list;
        })
      };
    case types.SET_SLIDER_ATTRIBUTE_VALUE:
      return {
        ...state,
        lists: state.lists.map(list => {
          if (list.id === action.listId) {
            return {
              ...list,
              settings: {
                ...list.settings,
                [action.sliderType + 's']: list.settings[action.sliderType + 's'].map(slider => {
                  if (slider.id === action.sliderId) {
                    return {
                      ...slider,
                      attributes: slider.attributes.map(attribute => {
                        if (attribute.id === action.attributeId) {
                          const weight = parseInt(action.value, 10);
                          return {
                            ...attribute,
                            weight,
                            _locked: weight === 0
                          };
                        }
                        return attribute;
                      })
                    };
                  }
                  return slider;
                })
              }
            };
          }
          return list;
        })
      };
    case types.SET_REFINEMENT_ATTRIBUTE_VALUE:
      return {
        ...state,
        lists: state.lists.map(list => {
          if (list.id === action.listId) {
            return {
              ...list,
              settings: {
                ...list.settings,
                [action.sliderType + 's']: list.settings[action.sliderType + 's'].map(slider => {
                  if (slider.id === action.sliderId) {
                    return {
                      ...slider,
                      attributes: slider.attributes.map(attribute => {
                        if (attribute.id === action.attributeId) {
                          return {
                            ...attribute,
                            weight: parseInt(action.value, 10),
                            _locked: parseInt(action.value, 10) === 100
                          };
                        }
                        return attribute;
                      })
                    };
                  }
                  return slider;
                })
              }
            };
          }
          return list;
        })
      };
    case types.SET_ALL_ATTRIBUTE_VALUES_OF_REFINEMENT:
      return {
        ...state,
        lists: state.lists.map(list => {
          return {
            ...list,
            settings: {
              ...list.settings,
              [action.sliderType + 's']: list.settings[action.sliderType + 's'].map(slider => {
                if (slider.id === action.sliderId) {
                  return {
                    ...slider,
                    _lockAll: parseInt(action.value, 10) === 100,
                    attributes: slider.attributes.map(attribute => {
                      return {
                        ...attribute,
                        weight: parseInt(action.value, 10),
                        _locked: parseInt(action.value, 10) === 100
                      };
                    })
                  };
                }
                return slider;
              })
            }
          };
        })
      };
    case types.CREATE_CURATION_LIST_FROM_TEMPLATE:
      return {
        ...state,
        lists: [...state.lists, {
          id: action.resp.id,
          name: action.resp.name || '',
          type: action.listType || 'curation',
          isTemp: action.resp.isTemp,
          createdAt: new Date().getTime(),
          settings: {
            dimensions: defaultSliderValues(action.meta.dimensions),
            refinements: defaultRefinementValues(action.meta.refinements),
            tags: defaultTagValues(action.meta.tags),
            filters: defaultRefinementValues(action.meta.filters)
          },
          meta: defaultMetaData(action.meta),
          items: []
        }]
      };
    case types.ADD_LIST:
      return {
        ...state,
        lists: [...state.lists, {
          ...action.curation,
          settings: {
            dimensions: defaultSliderValues(action.meta.dimensions),
            refinements: defaultRefinementValues(action.meta.refinements),
            tags: defaultTagValues(action.meta.tags),
            filters: defaultRefinementValues(action.meta.filters)
          },
          meta: defaultMetaData(action.meta),
        }]
      };
    case types.RESET_UI_SETTINGS:
      return {
        ...state,
        lists: state.lists.map(list => {
          if (list.id === action.id) {
            const settings = list.settings || action.meta;
            return {
              ...list,
              settings: {
                dimensions: defaultSliderValues(settings.dimensions),
                refinements: defaultRefinementValues(settings.refinements),
                filters: defaultRefinementValues(settings.filters),
                tags: defaultTagValues(settings.tags)
              }
            };
          }
          return list;
        })
      };
    case types.ADD_EMPTY_IMAGE:
      return {
        ...state,
        lists: state.lists.map(list => {
          if (list.id === action.listId) {
            return {
              ...list,
              images: [...(list.images || []), {
                guid: ''
              }]
            };
          }
          return list;
        })
      };
    case types.UPDATE_IMAGE_POSITION:
      return {
        ...state,
        lists: state.lists.map(list => {
          if (list.id === action.listId) {
            return {
              ...list,
              images: ((images) => {
                let img = images.splice(action.currentPosition, 1)[0];
                images.splice(action.newPosition, 0, img);
                return images;
              })((list.images || []).slice())
            };
          }
          return list;
        })
      };
    case types.UPDATE_LIST_IMAGE:
      return {
        ...state,
        lists: state.lists.map(list => {
          if (list.id === action.listId) {
            return {
              ...list,
              images: ((images) => {
                if (!action.remove) {
                  images.splice(action.index, action.insert ? 0 : 1, {
                    ...action.image
                  });
                } else if (action.remove) {
                  images.splice(action.index, 1);
                }
                return images;
              })(list.images.slice()),
              imageBoundBox: ((images) => {
                if (action.remove) {
                  return images.filter(bbox => bbox.guid !== action.image.guid);
                }
                return images;
              })(list.imageBoundBox.slice())
            };
          }
          return list;
        })
      };
    case types.COPY_CURATION_TO_COLLECTION:
      return {
        ...state,
        lists: state.lists.map(list => {
          if ((list.id || list.itemId) === action.listId) {
            return {
              ...list,
              name: action.curation.name,
              description: (action.curation.description || '').substring(0, 800),
              meta: defaultMetaData(action.meta, action.curation.meta),
              images: action.curation.images || [],
              imageBoundBox: action.curation.imageBoundBox || [],
              settings: action.curation.settings,
              pinterestSTLStatus: action.curation.pinterestSTLStatus
            };
          }
          return list;
        })
      };
    case types.SET_LIST_DATA_WITHOUT_ITEMS:
      if (!action.response) return state;
      return {
        ...state,
        lists: state.lists.map(list => {
          if (list.id === action.id) {
            return {
              ...action.response,
              items: list.items,
              versions: list.versions,
              searchReport: list.searchReport,
              meta: defaultMetaData(action.meta, action.response.meta)
            };
          }
          return list;
        })
      };
    case types.SET_LIST_DATA_WITHOUT_META:
      if (!action.response) return state;
      return {
        ...state,
        lists: state.lists.map(list => {
          if (list.id === action.id) {
            return {
              ...action.response,
              items: createItems(action.response.items),
              meta: list.meta
            };
          }
          return list;
        })
      };
    case types.SET_LIST_DATA:
      if (!action.response) return state;
      return {
        ...state,
        lists: ((lists) => {
          const template = {
            ...action.response,
            items: createItems(action.response.items),
            meta: defaultMetaData(action.meta, action.response.meta)
          };
          if (lists.findIndex(l => l.id === parseInt(action.id, 10)) > -1) {
            return lists.map(curation => {
              if (curation.id === parseInt(action.id, 10)) {
                const c = {
                  ...curation,
                  ...template
                };
                return fixTypes(c);
              }
              return curation;
            });
          }
          return [...lists, fixTypes({
            ...action.response,
            ...template
          })];
        })(state.lists)
      };
    case types.SET_LIST_DATA_FOR_CURATION:
      return {
        ...state,
        lists: state.lists.map(list => {
          if (list.id === action.listId) {
            return {
              // collection
              ...list,
              // the curations
              items: (list.items || []).map(item => {
                if ((item.id || item.itemId) === action.itemId) {
                  return {
                    id: item.id,
                    _selected: item._selected,
                    ...action.response,
                    items: action.response.items.map(sku => {
                      return {
                        ...sku,
                        storeSku: {
                          pricing: {},
                          ...sku.storeSku
                        },
                        $imageSrc: updateImageSrc({ data: sku }),
                      };
                    })
                  };
                }
                return {
                  ...item,
                  _selected: false
                };
              })
            };
          }
          return list;
        })
      };
    case types.TOGGLE_VERSION_ROW:
      return {
        ...state,
        lists: state.lists.map(list => {
          if (list.id === action.listId) {
            return {
              ...list,
              versions: (list.versions || []).map(version => {
                if (version.listVersion === action.listVersion) {
                  return {
                    ...version,
                    _open: !version._open
                  };
                }
                return version;
              })
            };
          }
          return list;
        })
      };
    case types.CLOSE_ALL_VERSION_ROWS:
      return {
        ...state,
        lists: state.lists.map(list => {
          if (list.id === action.listId) {
            return {
              ...list,
              versions: (list.versions || []).map(version => {
                return {
                  ...version,
                  _open: false
                };
              })
            };
          }
          return list;
        })
      };
    case types.LIST_COLLECTIONS_FROM_CURATION:
      return {
        ...state,
        collections: action.collections
      };
    case types.LOAD_DIFF_FOR_VERSION:
      return {
        ...state,
        lists: state.lists.map(list => {
          if (list.id === action.listId) {
            return {
              ...list,
              versions: list.versions.map(version => {
                if (version.listVersion === action.versionNumber) {
                  return {
                    ...version,
                    createdAt: version.current ? list.updatedAt : version.createdAt,
                    diff: action.diff,
                    compareToVersion: action.old,
                    version: action.current,
                    state: action.state
                  };
                }
                return version;
              })
            };
          }
          return list;
        })
      };
    case types.UPDATE_VERSIONS_FOR_LIST:
      return {
        ...state,
        lists: state.lists.map(list => {
          if (list.id === action.listId) {
            return {
              ...list,
              versions: action.payload.map(version => {
                const oldVersion = (list.versions || []).find(v => v.listVersion === version.listVersion) || {};
                return {
                  ...oldVersion,
                  ...version
                };
              }),
              searchReport: action.searchReport
            };
          }
          return list;
        })
      };
    case types.LOAD_VERSION_FOR_LIST:
      return {
        ...state,
        lists: state.lists.map(list => {
          if (list.id === action.listId) {
            return {
              ...list,
              versions: list.versions.map(version => {
                if (version.id === action.versionId) {
                  return {
                    ...version,
                    details: { ...action.payload }
                  };
                }
                return version;
              })
            };
          }
          return list;
        })
      };
    case types.SET_REVIEW_LIST_STATUS:
      return {
        ...state,
        lists: state.lists.map(list => {
          if (list.id === action.listId) {
            return {
              ...list,
              status: action.status
            };
          }
          return list;
        })
      };
    case types.UPDATE_CURATION_METADATA:
      return {
        ...state,
        lists: state.lists.map(list => {
          if (list.id === action.curationId) {
            return {
              ...list,
              meta: {
                ...list.meta,
                dimensions: list.meta.dimensions.map(dimension => {
                  if (action.dimensionId === dimension.id) {
                    return {
                      ...dimension,
                      attributes: (() => {
                        let curSelected = dimension
                          .attributes
                          .filter(attr => attr.value).map((attr, i) => attr.id);
                        let attrs = dimension.attributes.map(attr => {
                          if (attr.id === action.attributeId) {
                            return {
                              ...attr,
                              value: action.value
                            };
                          }
                          // collections are only allowed to have one value
                          if (shouldRestrictSelection({
                            list,
                            action,
                            attributes: dimension.attributes,
                            attribute: attr
                          })) {
                            return {
                              ...attr,
                              value: false
                            };
                          }
                          return attr;
                        });
                        if (list.type === 'collection' &&
                          action.dimensionId === 100 &&
                          curSelected.length === 2 &&
                          curSelected[1] !== action.attributeId) {
                          attrs.find(attr => attr.id === curSelected[1]).value = true;
                        }
                        return attrs;
                      })()
                    };
                  }
                  return dimension;
                })
              }
            };
          }
          return list;
        })
      };
    case types.UPDATE_SELECTED_LISTS_METADATA:
      return {
        ...state,
        lists: state.lists.map(list => {
          if (list._selected) {
            return {
              ...list,
              meta: {
                ...list.meta,
                dimensions: list.meta.dimensions.map(dimension => {
                  if (action.dimensionId === dimension.id) {
                    return {
                      ...dimension,
                      attributes: (() => {
                        let curSelected = dimension
                          .attributes
                          .filter(attr => attr.value).map((attr, i) => attr.id);
                        let attrs = dimension.attributes.map(attr => {
                          if (attr.id === action.attributeId) {
                            return {
                              ...attr,
                              value: action.value
                            };
                          }
                          // collections are only allowed to have one value
                          if (shouldRestrictSelection({
                            list,
                            action,
                            attributes: dimension.attributes,
                            attribute: attr
                          })) {
                            return {
                              ...attr,
                              value: false
                            };
                          }
                          return attr;
                        });
                        if (list.type === 'collection' &&
                          action.dimensionId === 100 &&
                          curSelected.length === 2 &&
                          curSelected[1] !== action.attributeId) {
                          attrs.find(attr => attr.id === curSelected[1]).value = true;
                        }
                        return attrs;
                      })()
                    };
                  }
                  return dimension;
                })
              }
            };
          }
          return list;
        })
      };
    case types.REPLACE_ITEMS_IN_SET:
      return {
        ...state,
        lists: state.lists.map(list => {
          if (list.id === action.listId) {
            return {
              ...list,
              items: action.items
            };
          }
          return list;
        })
      };
    case types.ADD_ITEMS_TO_SET:
      return {
        ...state,
        lists: state.lists.map(list => {
          if (list.id === action.listId) {
            return {
              ...list,
              items: ((items) => {
                const unique = [];
                items.splice(action.index || 0, 0, ...action.items.map(item => {
                  return {
                    ...item,
                    _selected: false,
                    _saved: false
                  };
                }));
                return items.filter(item => {
                  if (unique.indexOf(item.itemId) === -1) {
                    unique.push(item.itemId);
                    return true;
                  }
                  return false;
                }).map(item => {
                  return {
                    ...item,
                    $inCuration: true,
                    results: {
                      ...item.results,
                      items: ((item.results || {}).items || []).map(result => {
                        return {
                          ...result,
                          _selected: false
                        };
                      })
                    }
                  };
                });
              })(list.items.slice())
            };
          }
          return list;
        })
      };
    case types.ADD_ITEM_TO_SET:
      return {
        ...state,
        lists: state.lists.map(list => {
          let key;
          let type = action.item.type === 'curation' ? 'curation' : 'item';
          if (type === 'curation') {
            key = 'curations';
          } else {
            key = 'skus';
          }
          if (list.id === action.listId) {
            return {
              ...list,
              items: ((items) => {
                let idKey = key === 'curations' ? 'id' : 'itemId';
                const existsIndex = items.findIndex(i => i[idKey] === action.item[idKey]);
                if (existsIndex > -1) return items;
                if (typeof action.index === 'undefined') {
                  return [...items.map(item => {
                    if (item._selected && item.results) {
                      return {
                        ...item,
                        results: {
                          ...item.results,
                          items: (item.results.items || []).map(itemResult => {
                            if (itemResult.itemId === action.item.itemId) {
                              return {
                                ...itemResult,
                                _selected: false,
                                $inCuration: true
                              };
                            }
                            return itemResult;
                          })
                        }
                      };
                    }
                    return item;
                  }), {
                    ...action.item,
                    _selected: false,
                    $inCuration: true,
                    _saved: false
                  }];
                }
                items.splice(action.index, 0,
                  { ...action.item, _selected: false, $inCuration: true, _saved: false });
                return items;
              })(list.items.slice())
            };
          }
          return list;
        })
      };
    case types.REMOVE_ITEMS_FROM_SET:
      return {
        ...state,
        lists: state.lists.map(list => {
          if (list.id === action.curationId) {
            return {
              ...list,
              items: list.items.filter(item => {
                const index = action.items.findIndex(i => i.itemId === item.itemId);
                return index === -1;
              })
            };
          }
          return list;
        })
      };
    case types.REMOVE_ITEM_FROM_SET:
      return {
        ...state,
        lists: state.lists.map(list => {
          if (list.id === action.listId) {
            return {
              ...list,
              status: typeof action.status !== 'undefined' ? action.status : list.status,
              items: list
                .items
              .filter(item => (item.itemId || item.id) !== (action.item.itemId || action.item.id)),
            };
          }
          return list;
        })
      };
    case types.UPDATE_ITEM_POSITION:
      return {
        ...state,
        lists: state.lists.map(list => {
          if (list.id === action.listId) {
            return {
              ...list,
              items: ((items) => {
                const currentIndex = items.findIndex(i => (i.itemId || i.id) === (action.itemId));
                const item = items[currentIndex];
                items.splice(currentIndex, 1);
                items.splice(action.index, null, item);
                return items;
              })(list.items.slice()).map((item, i) => {
                return { ...item, _index: i };
              })
            };
          }
          return list;
        })
      };
    case types.SELECT_REVIEW_ITEM:
      return {
        ...state,
        lists: state.lists.map(list => {
          if (list.id === action.listId) {
            // if the item type is item then the `skus` object needs to be updated
            return {
              ...list,
              items: list.items.map((item, i) => {
                if (i === action.index) {
                  return {
                    ...item,
                    _selected: true
                  };
                }
                return {
                  ...item,
                  _selected: false
                };
              })
            };
          }
          return list;
        })
      };
    case types.SET_RESULTS_FOR_ITEM:
      return {
        ...state,
        lists: state.lists.map(list => {
          if (list.id === action.listId) {
            return {
              ...list,
              items: (list.items || []).map(item => {
                if (item.itemId === action.itemId) {
                  return {
                    ...item,
                    results: ((results) => {
                      const ret = {
                        ...results
                      };
                      ret.items = (ret.items || []).map(sku => {
                        return {
                          ...sku,
                          $imageSrc: updateImageSrc({ data: sku })
                        };
                      });
                      ret.searchReport.dimensionForSort =
                        (ret.searchReport.dimensionForSort || []).map(dim => {
                          return {
                            ...dim,
                            $selected: !!(ret.searchReport.dimensionSortedBy || [])
                              .find(d => d.id === dim.id)
                          };
                        });
                      return ret;
                    })({ ...action.results })
                  };
                }
                return item;
              })
            };
          }
          return list;
        })
      };
    case types.SET_DISCOVER_ITEM_BY:
      return {
        ...state,
        lists: state.lists.map(list => {
          if (list.id === action.listId) {
            return {
              ...list,
              items: (list.items || []).map(item => {
                if (item.itemId === action.itemId) {
                  return {
                    ...item,
                    results: {
                      ...item.results,
                      searchReport: {
                        ...item.results.searchReport,
                        dimensionForSort: (item.results.searchReport.dimensionForSort || [])
                        .map(dim => {
                          if (dim.id === action.dimensionId) {
                            return {
                              ...dim,
                              $selected: action.value
                            };
                          }
                          return dim;
                        })
                      }
                    }
                  };
                }
                return item;
              })
            };
          }
          return list;
        })
      };
    case types.REMOVE_RESULTS_ITEM:
      return {
        ...state,
        lists: state.lists.map(list => {
          if (list.id === action.curationId) {
            return {
              ...list,
              items: list.items.map(item => {
                if (item.itemId === action.resultId) {
                  return {
                    ...item,
                    results: item.results.filter(i => i.itemId !== action.itemId)
                  };
                }
                return item;
              })
            };
          }
          return list;
        })
      };
    case types.UPDATE_MULTI_LISTS_TAGS_VALUE:
      return {
        ...state,
        lists: state.lists.map(list => {
          if (list._selected) {
            return {
              ...list,
              meta: {
                ...list.meta,
                tags: list.meta.tags.map(tag => {
                  return {
                    ...tag,
                    attributes: tag.attributes.map(attr => {
                      if (tag.id === action.categoryId && attr.id === action.attributeId) {
                        return {
                          ...attr,
                          selected: action.tagAttrSelected
                        };
                      }
                      return attr;
                    })
                  };
                })
              }
            };
          }
          return list;
        })
      };
    case types.SET_CURATION_SEARCH_TAGS_VALUE:
      return {
        ...state,
        lists: state.lists.map(list => {
          if (list.id === action.listId) {
            if (action.pageType === 'curation') {
              return {
                ...list,
                settings: {
                  ...list.settings,
                  tags: list.settings.tags.map(tag => {
                    return {
                      ...tag,
                      attributes: tag.attributes.map(attr => {
                        if (tag.id === action.categoryId && attr.id === action.attributeId) {
                          return {
                            ...attr,
                            selected: action.tagAttrSelected
                          };
                        }
                        return attr;
                      })
                    };
                  })
                }
              };
            }
            return {
              ...list,
              meta: {
                ...list.meta,
                tags: list.meta.tags.map(tag => {
                  return {
                    ...tag,
                    attributes: tag.attributes.map(attr => {
                      if (tag.id === action.categoryId && attr.id === action.attributeId) {
                        return {
                          ...attr,
                          selected: action.tagAttrSelected
                        };
                      }
                      return attr;
                    })
                  };
                })
              }
            };
          }
          return list;
        })
      };
    case types.REMOVE_CURATION_SEARCH_TAGS_VALUE:
      return {
        ...state,
        lists: state.lists.map(list => {
          if (list.id === action.listId) {
            if (action.pageType === 'curation') {
              return {
                ...list,
                settings: {
                  ...list.settings,
                  tags: list.settings.tags.map(tag => {
                    return {
                      ...tag,
                      attributes: tag.attributes.map(attr => {
                        if (tag.id === action.categoryId && attr.id === action.attributeId) {
                          return {
                            ...attr,
                            selected: false
                          };
                        }
                        return attr;
                      })
                    };
                  })
                }
              };
            }
            return {
              ...list,
              meta: {
                ...list.meta,
                tags: list.meta.tags.map(tag => {
                  return {
                    ...tag,
                    attributes: tag.attributes.map(attr => {
                      if (tag.id === action.categoryId && attr.id === action.attributeId) {
                        return {
                          ...attr,
                          selected: false
                        };
                      }
                      return attr;
                    })
                  };
                })
              }
            };
          }
          return list;
        })
      };
    case types.UPDATE_DIMENSION_WEIGHTS:
      return {
        ...state,
        lists: state.lists.map(list => {
          if (list.id === parseInt(action.listId, 10)) {
            return {
              ...list,
              dimensionWeights: action.resp.dimension
            };
          }
          return list;
        })
      };
    case types.SET_LIST_ANALYTICS:
      return {
        ...state,
        lists: state.lists.map(list => {
          if (list.id === action.listId) {
            return {
              ...list,
              meta: {
                ...list.meta,
                analytics: action.results
              }
            };
          }
          return list;
        })
      };
    case types.TOGGLE_LIST_SELECTION:
      return {
        ...state,
        lists: state.lists.map(list => {
          if (list.id === action.listId) {
            return {
              ...list,
              _selected: !list._selected
            };
          }
          return list;
        })
      };
    case types.TOGGLE_MULTI_LIST_ATTRIBUTE_EDIT_MODE:
      return {
        ...state,
        lists: state.lists.map(list => {
          if (list.id === action.listId) {
            return {
              ...list,
              meta: {
                ...list.meta,
                dimensions: list.meta.dimensions.map(dimension => {
                  return {
                    ...dimension,
                    attributes: dimension.attributes.map(attr => {
                      if (dimension.id === action.dimensionId && attr.id === action.attributeId) {
                        return {
                          ...attr,
                          multiListEdit: !attr.multiListEdit
                        };
                      }
                      return attr;
                    })
                  };
                })
              }
            };
          }
          return list;
        })
      };
    case types.CLEAR_MULTI_LIST_ATTRIBUTE_EDIT_MODE:
      return {
        ...state,
        lists: state.lists.map(list => {
          return {
            ...list,
            meta: {
              ...list.meta,
              dimensions: list.meta.dimensions.map(dimension => {
                return {
                  ...dimension,
                  attributes: dimension.attributes.map(attr => {
                    return {
                      ...attr,
                      multiListEdit: false
                    };
                  })
                };
              })
            }
          };
        })
      };
    case types.SET_ITEM_ANALYTICS:
      return {
        ...state,
        lists: state.lists.map(list => {
          if (list.id === action.listId) {
            return {
              ...list,
              items: list.items.map((item, i) => {
                if (i === action.index) {
                  return {
                    ...item,
                    analytics: action.results
                  };
                }
                return {
                  ...item,
                  analytics: { itemAnalytics: [] }
                };
              })
            };
          }
          return list;
        })
      };
    case types.ADD_CURATION_ERROR:
      return {
        ...state,
        errorModel: [
          ...action.response,
        ]
      };
    case types.GENERIC_ERROR_HANDLER_CLEAR:
      return {
        ...state,
        errorModel: []
      };
    default:
      return state;
  }
}

export const getListById = ({ listId, state = [] }) => {
  return state.lists.find(i => i.id === listId);
};

export const getsearchTaglist = ({ state, listId, type, inputTagValue, isTagInputFocus = false }) => {
  let selectedTagCategory = state.ui.tag.selectedTagCategory;
  // let tags = state.app.meta.tags;
  let tags = [];
  let searchTags = [];
  let selectedTags = [];
  let selectedList = state.curationList.lists.find(list => {
    return (list.id === listId);
  });
  if (type === 'multiEdit') {
    tags = state.app.meta.tags;
  } else if (type === 'curation') {
    tags = selectedList.settings.tags;
  } else {
    tags = selectedList.meta.tags;
  }
  const re = new RegExp(inputTagValue, 'i');
  tags.map(tag => {
    if (selectedTagCategory === '0') {
      tag.attributes.map(attribute => {
        if (re.test(attribute.name)) {
          searchTags.push({
            categoryId: tag.id,
            categoryName: tag.name,
            attributeId: attribute.id,
            attributeName: attribute.name,
            selected: attribute.selected
          });
        }
        return attribute;
      });
    }
    if (tag.id === selectedTagCategory) {
      tag.attributes.map(attribute => {
        if (re.test(attribute.name)) {
          searchTags.push({
            categoryId: tag.id,
            categoryName: tag.name,
            attributeId: attribute.id,
            attributeName: attribute.name,
            selected: attribute.selected
          });
        }
        return attribute;
      });
    }
    return tag;
  });
  return searchTags;
};

export const getAppliedTags = ({ state, listId, type }) => {
  let appliedTags = [];
  let tags = [];
  let selectedList = state.curationList.lists.find(list => {
    return (list.id === parseInt(listId, 10));
  });
  if (type === 'multiEdit') {
    tags = state.app.meta.tags;
  } else if (selectedList) {
    if (type === 'curation') {
      tags = selectedList.settings ? selectedList.settings.tags : [];
    } else {
      tags = selectedList.meta.tags;
    }
  }
  tags.map(tag => {
    tag.attributes.map(attribute => {
      if (attribute.selected) {
        appliedTags.push({
          categoryId: tag.id,
          categoryName: tag.name,
          attributeId: attribute.id,
          attributeName: attribute.name,
          selected: attribute.selected
        });
      }
      return attribute;
    });
    return tag;
  });
  return appliedTags;
};

export const serializeAttributes = ({ curation, startIndex = 0, pageSize = 24 }) => {
  const ret = {
    startIndex,
    pageSize
  };
  ret.dimensions = (curation.settings.dimensions || [])
    .map(dim => {
      return {
        ...dim,
        attributes: dim.attributes.map(attr => {
          return {
            id: attr.id,
            name: attr.name,
            weight: attr.weight
          };
        }).filter(attr => attr.weight > 0)
      };
    })
    .filter(dim => dim.attributes.length);
  ret.refinments = (curation.settings.refinements || []).map(ref => {
    const attributes = ref.attributes.filter(attr => {
      return attr.weight === 100;
    }).map(attr => {
      return {
        id: attr.id,
        name: attr.name,
        weight: attr.weight
      };
    });
    if (!attributes.length) return false;
    return {
      id: ref.id,
      attributes
    };
  }).filter(ref => !!ref);

  ret.filters = (curation.settings.filters || []).map(ref => {
    const attributes = ref.attributes.filter(attr => {
      return attr.weight === 100;
    }).map(attr => {
      return {
        id: attr.id,
        name: attr.name,
        weight: attr.weight
      };
    });
    if (!attributes.length) return false;
    return {
      id: ref.id,
      attributes
    };
  }).filter(ref => !!ref);

  ret.tags = (curation.settings.tags || []).map(tag => {
    const attributes = tag.attributes.filter(attr => {
      return !!attr.selected;
    }).map(attr => {
      return {
        id: attr.id
      };
    });
    if (!attributes.length) return false;
    return {
      id: tag.id,
      attributes
    };
  }).filter(tag => !!tag);
  return ret;
};

export const serializeMeta = ({ list }) => {
  let hideOnCLP = false;
  if (list.hideOnCLP === true) {
    hideOnCLP = true;
  }
  const ret = {
    name: !!list.name ? list.name.replace(/([\\"])/g, '\\$1') : list.name,
    internalRoomName:!!list.internalRoomName ? list.internalRoomName.replace(/([\\"])/g, '\\$1') : list.internalRoomName,
    description: !!list.description ?
    list.description.replace(/([\\"])/g, '\\$1') : list.description,
    images: (list.images || []).map((image, i) => {
      return {
        guid: image.guid,
        position: i,
        height: image.height || 0,
        width: image.width || 0
      };
    }),
    pinterestSTLStatus: list.pinterestSTLStatus || 0,
    imageBoundBox: list.imageBoundBox || [],
    hideOnCLP,
    meta: {
      dimensions: list.meta.dimensions.map(dim => {
        return {
          id: dim.id,
          attributes: dim.attributes
          .filter(attr => {
            if (typeof attr.value === 'undefined') return true;
            return !!attr.value;
          }).map(attr => {
            return {
              id: attr.id
            };
          })
        };
      }),
      tags: (list.meta.tags || []).map(tag => {
        const attributes = tag.attributes.filter(attr => {
          if (typeof attr.selected === 'undefined') return true;
          return !!attr.selected;
        }).map(attr => {
          return {
            id: attr.id
          };
        });
        if (!attributes.length) return false;
        return {
          id: tag.id,
          attributes
        };
      }).filter(tag => !!tag)
    },
    sortOrder: list.sortOrder,
    collectionSubType: list.collectionSubType,
    listid: list.id
  };
  return ret;
};

const getDimensionWeight = ({ list, dimensionId, attributeId }) => {
  const dimension = (list.dimensionWeights || []).find(d => d.id === dimensionId);
  if (!dimension) return '0.00';
  const attr = dimension.attributes.find(atr => atr.id === attributeId);
  if (!attr) return '0.00';
  return attr.weight.toFixed(2);
};

export const injectDimensionWeight = (_list) => {
  let list = {
    ..._list
  };
  if (!list.meta) return list;
  list.meta.dimensions = list.meta.dimensions.map(dimension => {
    return {
      ...dimension,
      attributes: dimension.attributes.map(attr => {
        return {
          ...attr,
          $weight: getDimensionWeight({ list, dimensionId: dimension.id, attributeId: attr.id })
        };
      })
    };
  });
  return list;
};

export const getListMetaErrors = ({ list, state }) => {
  const errors = [];
  if (!list.$canEdit) {
    errors.push({
      message: 'You do not have permission to publish'
    });
  }
  if (list.name === '') {
    errors.push({ message: 'Name is required' });
  }
  if (list.type === 'curation') return errors;
  const totalValidImages = (list.images || []).find(image => image.guid !== '');
  // Look for image related errors from errorModel.
  const imageError = (state.curationList.errorModel || []).filter(error => {
    return error.errorCode === 400;
  });
  if (!totalValidImages ||
    imageError.length > 0
  ) {
    errors.push({
      message: 'You must provide an image to publish'
    });
  }
  const styleDimension = list.meta.dimensions.find(d => d.id === DIMENSIONS.STYLE);
  const selectedStyleDimensions = styleDimension.attributes.filter(attr => attr.value);
  if (selectedStyleDimensions.length > 2) {
    errors.push({
      dimensionId: DIMENSIONS.STYLE,
      message: 'Only two styles may be selected'
    });
  }
  const roomDimensions = list.meta.dimensions.find(d => d.id === DIMENSIONS.ROOM);
  const selectedRooms = roomDimensions.attributes.filter(attr => attr.value);
  if (selectedRooms.length > 1) {
    errors.push({
      dimensionId: DIMENSIONS.STYLE,
      message: 'Only one room may be selected'
    });
  }
  if (selectedRooms.length === 0) {
    errors.push({
      dimensionId: DIMENSIONS.STYLE,
      message: 'At least one room must be selected'
    });
  }
  if (list.items.length === 0) {
    errors.push({
      message: 'No Active Curations to publish'
    });
  }
  const inactiveCurations = (list.items || []).find(curation => curation.status !== 'active');
  if (inactiveCurations) {
    errors.push({
      message: 'All Curations should be active to publish'
    });
  }
  return errors;
};

export const sliceItemsForCollection = ({ item }) => {
  if (!item.type) return item;
  const items = item.items || [];
  const itemSize = items.length;
  const $items = items.slice(0, 12);

  const leftOverCount = itemSize - $items.length;
  if (leftOverCount > 0) {
    // update $image of 12th item
    const image = generateMultiItemPreview({ totalItems: leftOverCount, size: 'small' });
    $items[11].$imageSrc = image.src;
  }
  return {
    ...item,
    $items
  };
};

const createItems = (items = []) => {
  return items.map(item => {
    if (item.type === 'curation' || Object.keys(item).length === 2) {
      return {
        type: 'curation',
        items: [],
        ...createDefaultProduct(),
        ...item
      };
    }
    return {
      ...item,
      storeSku: {
        pricing: {},
        ...item.storeSku
      },
      $imageSrc: updateImageSrc({ data: item }),
    };
  })
  .filter(sku => {
    return sku.info && Object.keys(sku.info).length >= 1;
  });
};

export const getSpaceCollectionHostName = () => {
  let hostName = 'https://hd-qa74.homedepotdev.com/collection/';
  if (ENV === 'prod') {
    hostName = 'https://www.homedepot.com/collection/';
  }
  return hostName;
};

export const getSpaceCollectionURL = ({ list, preview = false }) => {
  const hostName = getSpaceCollectionHostName();
  let id = `id-${list.id}`;
  let url = '';
  let collectionName = (list.name || '').replace(/[^A-Z0-9]+/ig, '-').toLowerCase() + '/';
  if (list.meta && list.meta.dimensions) {
    let attribute = list.meta.dimensions
      .find(dim => dim.id === 200).attributes
      .find(attr => attr.value);
    let roomName = 'ROOM/';
    if (attribute) {
      roomName = attribute.name.replace(/[^A-Z0-9]+/ig, '-').toLowerCase() + '/';
    }
    let previewMode = '';
    if (preview) {
      previewMode = '?preview=true';
    }
    url = hostName + roomName + collectionName + id + previewMode;
    return url;
  }
  return url;
};

export const reOrderDimensions = ({ dimensions }) => {
  if (typeof dimensions !== 'object') return dimensions;
  // If room in the 1st position, dont shit.
  if (dimensions[0].id === 200) { return dimensions;}
  let reOrderDims = [];
  reOrderDims.push(dimensions[1]);
  reOrderDims.push(dimensions[0]);
  reOrderDims.push(dimensions[2]);
  reOrderDims.push(dimensions[3]);
  // const firstElement = reOrderDims.shift();
  // Move the Rooms first in the dimensions array.
  // reOrderDims.splice(1, 0, firstElement);
  return reOrderDims;
};
