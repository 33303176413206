import * as types from '../../../constants/ActionTypes';
import { guid } from '../../../util';

const initialState = {
  tag: {
    selectedTagCategory: '0',
    inputTagValue: '',
    showCategoryDropDown: false,
    isTagInputFocus: false
  },
  collectionList: {
    viewType: 'grid',
    sortName: '',
    sortType: 'asc', // ascending (or) descending
    filterKeyword: ''
  },
  curationList: {
    viewType: 'grid',
    sortName: '',
    sortType: 'asc', // ascending (or) descending
    filterKeyword: ''
  },
  pagination: {
    selectedCurationpage: 1,
    totalResultsPerPage: 24,
    totalListPerPage: 24
  },
  name: {
    editMode: false,
    value: ''
  },
  internalRoomName: {
    editMode: false,
    value: ''
  },
  collectionSubType: {
    editMode: false,
    value: 'default'
  },
  description: {
    editMode: false
  },
  curateList: {
    editMode: false
  },
  ReviewList: {
    NoOfColumns: 4
  },
  menu: {
    activeTab: 'curations'
  },
  curationStatus: {
    active: false
  },
  pinterestFilter: {
    yes: false,
    no: false
  },
  defaultCurationList: false,
  showMaintenanceSimilarItems: false

};

export default function app(state = initialState, action) {
  switch (action.type) {
    case types.SET_SEARCH_KEYWORD:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          keyword: action.keyword
        }
      };
    case types.SET_CURATION_TAGS_CATEGORY:
      return {
        ...state,
        tag: {
          ...state.tag,
          selectedTagCategory: action.category
        }
      };
    case types.SET_IS_TAG_INPUT_FIELD_FOCUS_IN:
      return {
        ...state,
        tag: {
          ...state.tag,
          isTagInputFocus: action.isTagInputFocus
        }
      };
    case types.SET_CURATION_INPUT_TAG_VALUE:
      return {
        ...state,
        tag: {
          ...state.tag,
          inputTagValue: action.value
        }
      };
    case types.SHOW_HIDE_TAG_CATEGORY_DROPDOWN:
      return {
        ...state,
        tag: {
          ...state.tag,
          showCategoryDropDown: action.value
        }
      };
    case types.SET_CURATION_KEYWORD_FILTER:
      return {
        ...state,
        curationList: {
          ...state.curationList,
          filterKeyword: action.keyword
        }
      };
    case types.SET_PRODUCT_KEYWORD_FILTER:
      return {
        ...state,
        productList: {
          ...state.productList,
          filterKeyword: action.keyword
        }
      };
    case types.CHANGE_CURATION_LIST_VIEW:
      return {
        ...state,
        curationList: {
          ...state.curationList,
          viewType: action.viewType
        }
      };
    case types.SET_CURATION_SELECTED_PAGINATION_VALUE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          selectedCurationpage: action.value
        }
      };
    case types.TOGGLE_NAME_EDIT_MODE:
      return {
        ...state,
        name: {
          ...state.name,
          editMode: !state.name.editMode
        }
      };
    case types.TOGGLE_COLLECTION_SUBTYPE_EDIT_MODE:
      return {
        ...state,
        collectionSubType: {
          ...state.name,
          editMode: !state.collectionSubType.editMode
        }
      };
    case types.SAVE_CURATION_NAME:
      return {
        ...state,
        name: {
          ...state.name,
          value: action.name
        }
      };
      case types.SAVE_INTERNAL_NAME:
        return {
          ...state,
          internalRoomName: {
            ...state.name,
            value: action.name
          }
        };
    case types.TOGGLE_DESCRIPTION_EDIT_MODE:
      return {
        ...state,
        description: {
          ...state.name,
          editMode: !state.description.editMode
        }
      };
    case types.RESET_SORT_TYPE:
      return {
        ...state,
        curationList: {
          ...state.curationList,
          sortName: '',
          sortType: 'desc'
        }
      };
    case types.SET_SORT_TYPE:
      return {
        ...state,
        curationList: {
          ...state.curationList,
          sortName: action.sortName,
          sortType: action.sortType
        }
      };
    case types.TOGGLE_CURATELIST_EDIT_MODE:
      return {
        ...state,
        curateList: {
          ...state.curateList,
          editMode: !state.curateList.editMode
        }
      };
    case types.TOGGLE_CURATELIST_BULK_MODE:
      return {
        ...state,
        curateList: {
          ...state.curateList,
          bulkMode: typeof action.bulkMode !== 'undefined'
          ? action.bulkMode
          : !state.curateList.bulkMode
        }
      };
    case types.SET_REVIEWLIST_COLUMN_VIEW:
      return {
        ...state,
        ReviewList: {
          ...state.ReviewList,
          NoOfColumns: parseInt(action.value, 10)
        }

      };
    case types.SET_ERROR_MESSAGE:
      return {
        ...state,
        error: {
          code: action.code || 500,
          message: action.message
        }
      };
    case types.CLEAR_ERROR_MESSAGE:
      return {
        ...state,
        error: null
      };
    case types.REVIEW_PREVIEW_IMAGE:
      return {
        ...state,
        reviewPreviewImage: action.picture
      };
    case types.RESET_UI_SETTINGS:
      return initialState;
    case types.SET_ACTIVE_MENU_TAB:
      return {
        ...state,
        menu: {
          ...state.menu,
          activeTab: action.activeTab
        }
      };
    case types.SET_UI_LIST_VIEW:
      return {
        ...state,
        curationList: {
          ...state.curationList,
          viewMode: action.uiViewMode
        }
      };
    case types.SET_CURATION_STATUS:
      return {
        ...state,
        curationStatus: {
          ...state.name,
          active: action.status
        }
      };
    case types.RESET_PINTEREST_STATUS:
      return {
        ...state,
        pinterestFilter: {
          yes: false,
          no: false
        }
      };
    case types.SET_PINTEREST_STATUS:
      return {
        ...state,
        pinterestFilter: {
          yes: action.value,
          no: !action.value
        }
      };
    case types.SET_DEFAULT_CURATION_LIST:
      return {
        ...state,
        defaultCurationList: action.defaultCurationList
      };
    case types.TOGGLE_MAINTENANCE_SIMILAR_ITEMS:
      return {
        ...state,
        showMaintenanceSimilarItems: action.showSimilarItems
      };
    case types.RESET_SIMILAR_ITEMS_SLIDER:
      return {
        ...state,
        showMaintenanceSimilarItems: false
      };
    default:
      return state;
  }
}
