import React, { Component } from 'react';
import PropTypes from 'prop-types';

class NewCuration extends Component {
  render() {
    const {
      loaders,
      actions
    } = this.props;
    return (
      <div className="col__2-12">
          <a
            className={
              'bttn-outline bttn-outline--primary bttn--inline ' +
              (loaders.listByUser ? 'disabled' : '')
            }
            href="#"
            onClick={e => {
              e.preventDefault();
              if (!loaders.listByUser) {
                actions.createNewListAndRouteToIt({ type: 'curation' });
              }
            }}
          >
            <span className="bttn__content">New Curation</span>
          </a>
        </div>
    );
  }
}

NewCuration.propTypes = {
  loaders: PropTypes.object,
  actions: PropTypes.shape({
    createNewListAndRouteToIt: PropTypes.func.isRequired
  })
};

export default NewCuration;