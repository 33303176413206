import React, { Component } from 'react';
import PropTypes from 'prop-types';
import HeaderContainer from '../../modules/Header';
import SearchContainer from '../../modules/Search';
import { fetchListClassication } from '../ReviewList/actions';
import SliderContainer from '../../modules/Slider';
import ItemResultsDropComponent from './ItemResultsDropComponent';
import ItemSetDropComponent from './ItemSetDropComponent';
import TagsContainer from '../../modules/Tag';
import VisionComponent from './modules/Vision';
import Accordion from '../../modules/Accordion';
import CheckboxGroup from '../../modules/CheckboxGroup';
// import { listenerCount } from 'cluster';

class CurateItem extends Component {
  componentDidMount() {
    const { match } = this.props;
    const { actions } = this.props;
    let id = parseInt(match.params.id, 10);
    if (isNaN(id)) {
      id = match.params.id;
    }
    actions.resetUISettings({ id });
    const listType = match.params.listType || 'curation';
    if (listType === 'collection') {
      actions.fetchCurationListsForCollection();
    }
    if (match.params.id.length < 13) {
      fetchListClassication({
        curationId: id,
        type: listType
      });
    }
    let title = '';
    if (listType === 'curation') {
      title = 'Curation';
    } else if (listType === 'collection') {
      title = 'Collection';
    }
    document.title = `New ${title}`;
  }
  render() {
    const {
      actions,
      loaders,
      results,
      listType,
      searchReport,
      itemView,
      list,
      multiItemPreview,
      generateMultiItemPreview,
      ui
    } = this.props;
    return (
    <div className="grid curationizer-main">
      <HeaderContainer />
      <SearchContainer listType={listType} list={list} />
      {loaders['vision-image-upload'] &&
      <div className="pre-loader vision-spinner">
        <div className="segment-spinner"></div>
      </div>
      }
      <div className="col__12-12 curate-list">
        <div className="grid flush">
          {listType === 'curation' &&
          <div className="col__3-12 curate-list__sidebar">
            <VisionComponent listId={list.id} />
            {list.settings && (list.settings.dimensions || [])
              .filter(dimension => dimension.id !== 300 && dimension.id !== 400)
              .map((slider, i) =>
              <SliderContainer key={i} listId={list.id} slider={slider} />)}
            {list.settings && (list.settings.filters || []).map((slider, i) =>
              <SliderContainer key={i} listId={list.id} slider={slider} />)}
          {<TagsContainer listId={list.id} type="curation" listType={list.type} />}
          </div>
          }
          {listType !== 'curation' &&
          <div className="col__3-12 curate-list__sidebar">
          {list.settings && (list.settings.dimensions || []).map((dimension, i) => {
            return (<Accordion key={dimension.id} id={`ac-${dimension.id}`} title={dimension.name} >
              <CheckboxGroup
                id={`collection-dim-${dimension.id}`}
                elements={dimension.attributes}
                onChange={(value, element) => {
                  actions.updateAttributeWeight({
                    list,
                    dimension,
                    attributeId: element.id,
                    weight: value ? 100 : 0
                  });
                  actions.fetchCurationList({
                    id: list.id
                  });
                }}
              />
            </Accordion>);
          })}
          {<TagsContainer listId={list.id} type="curation" listType={list.type} />}
          </div>
          }
          <div className="col__9-12">
            <div className={'grid dnd-container flush '
              + (list.type === 'collection' ? 'collection-list' : 'curation-list')}
            >
              <ItemResultsDropComponent
                results={results} list={list} searchReport={searchReport} loaders={loaders}
                generateMultiItemPreview={generateMultiItemPreview}
                actions={actions} itemView={itemView} ui={ui}
              />
              <ItemSetDropComponent
                results={results} list={list} actions={actions} loaders={loaders}
                generateMultiItemPreview={generateMultiItemPreview}
              />
            </div>
          </div>
        </div>
      </div>
    </div>);
  }
}

CurateItem.propTypes = {
  actions: PropTypes.object,
  children: PropTypes.object,
  params: PropTypes.object,
  results: PropTypes.object,
  list: PropTypes.object,
  itemView: PropTypes.number,
  multiItemPreview: PropTypes.object,
  listType: PropTypes.string,
  generateMultiItemPreview: PropTypes.func,
  searchReport: PropTypes.object,
  loaders: PropTypes.object,
  ui: PropTypes.object,
  match: PropTypes.object
};

export default CurateItem;
