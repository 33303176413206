import { NativeTypes } from 'react-dnd-html5-backend';
import { DropTarget } from 'react-dnd';
import FileDropZone from './component';
import { bindActionCreators } from 'redux';
import * as actions from './actions';
import { connect } from 'react-redux';

const fileTarget = {
  drop(props, monitor) {
    const files = monitor.getItem().files;
    props.actions.handleFileSelect(files, { listId: props.list.id });
  }
};

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    actions: bindActionCreators({
      ...actions
    }, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(
  DropTarget(NativeTypes.FILE, fileTarget, (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
  }))(FileDropZone)
);
