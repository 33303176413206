import * as types from '../../../../constants/ActionTypes';


const initialState = {
  userClassificationDimensions: [],
  styleClassifications: {},
  roomClassifications: {},
  colorClassifications: {},
  colorFamilyClassifications: {}
};

export const getSortedDimensionAttributes = ({ classifications }) => {
  let classificationAttributes = [];
  if (classifications
    && classifications.attributes
    && classifications.attributes.length > 0) {
    classificationAttributes =
      classifications.attributes.sort((a, b) => (a.weight < b.weight ? 1 : -1));
  }
  return classificationAttributes;
};

export default function app(state = initialState, action) {
  switch (action.type) {
    case types.SET_USER_CLASSIFICATION_DETAILS:
      return {
        ...state,
        userClassificationDimensions: action.userClassificationDimensions,
        styleClassifications: action.styleClassifications,
        roomClassifications: action.roomClassifications,
        colorClassifications: action.colorClassifications,
        colorFamilyClassifications: action.colorFamilyClassifications
      };
    case types.RESET_USER_CLASSIFICATION_DETAILS:
      return {
        ...state,
        userClassificationDimensions: [],
        styleClassifications: {},
        roomClassifications: {},
        colorClassifications: {},
        colorFamilyClassifications: {}
      };
    default:
      return state;
  }
}