import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ItemDragSource from './modules/Item/ItemDragSource';
import { DropTarget, DragDropContext, DragDropContextProvider } from 'react-dnd';
import ReviewItemDetails from '../ReviewList/modules/ReviewItemDetails';
import { calculateHeight } from '../../../../util';
import Pager from 'react-pager';
import Discovery from './modules/Discovery';
import InlineMessage from '../../modules/InlineMessage/component';

class ItemResults extends Component {
  removeHeight() {
    document.querySelector('.curation-list-result').style.removeProperty('height');
  }
  render() {
    const {
      results,
      actions,
      itemView,
      list,
      searchReport,
      loaders,
      multiItemPreview,
      ui
    } = this.props;
    return (
  <div className="curation-list-result" style={{
    height: list.type !== 'collection' ? '99vh' : ''
  }}
  >
    <div className="row-header-wrapper">
      {searchReport && (
        <div className="row-header">
          <div className="row-header__title">
            {searchReport.resultType !== 'sliders' && (
              <div>
                {!!searchReport.totalProducts && (
                  <span className="row-header__text">
                    Search (
                    {searchReport.startIndex / 24 * 24 + 1}
                    -
                    {Math.min(
                      searchReport.startIndex + searchReport.pageSize,
                      searchReport.totalProducts
                    )}
                    &nbsp; of {searchReport.totalProducts}
                    )
                  </span>
                )}
                {searchReport.totalProducts === 0 && (
                  <span className="row-header__text">No results</span>
                )}
              </div>
            )}
            {searchReport.resultType === 'sliders' && (
              <span className="row-header__text">Item Results ({results.length})</span>
            )}
          </div>
          {searchReport.totalProducts === 0 && (
            <Discovery list={list} />
          )}
          {searchReport.totalProducts > 0 && (
            <div className="item_list__header--link">
                <button
                  className="bttn-outline bttn--link"
                  id="toggle-view"
                  onClick={e => {
                    e.preventDefault();
                    const view = itemView === 4 ? 6 : 4;
                    actions.toggleItemResultView({ view });
                  }}
                >
                  <span className="bttn__content">Change View</span>
                </button>
                {results.searchReport.resultType === 'upload' &&
                <button
                  className="bttn-outline bttn--link"
                  onClick={e => {
                    e.preventDefault();
                    let items = results.items;
                    actions.addAllSelectedToSet({ listId: list.id, items });
                  }}
                >
                  <span className="bttn__content">Add All</span>
                </button>}
                <button
                  className="bttn-outline bttn--link"
                  onClick={e => {
                    e.preventDefault();
                    let items = results.items;
                    actions.clearResultsSet({ listId: list.id });
                  }}
                >
                  <span className="bttn__content">Clear All</span>
                </button>
              </div>
            )}
        </div>
      )}
    </div>
    {loaders.itemResult && (
      <div className="pre-loader">
        <div className="segment-spinner" />
        {this.removeHeight()}
      </div>
    )}
    {!loaders.itemResult &&
      !searchReport && list.type !== 'collection' && (
        <div className="grid">
          <div className="col__12-12">
            <Discovery list={list} />
          </div>
        </div>
      )}
    <div className="grid">
      {!loaders.itemResult &&
        results.$items.map((item, index) => {
          const className = [itemView === 4 ? 'col__3-12' : 'col__2-12'];
          if (item.type === 'curation') {
            className.push('open-item__container');
            if (item._selected) {
              className.push('results-item--selected');
            }
          }
          return (
            <div
              style={{
                height:
                  list.type === 'collection'
                    ? calculateHeight({ item, columns: itemView, type: list.type })
                    : 'auto'
              }}
              key={'results-' + (item.itemId || item.id)}
              className={className.join(' ')}
            >
              <ItemDragSource
                item={item}
                results={results}
                list={list}
                type="results"
                multiItemPreview={multiItemPreview}
                size={itemView === 4 ? 'large' : 'small'}
              />
              <ReviewItemDetails index={index} item={item} list={list} type="results" />
            </div>
          );
        })}
    </div>
    <div className="pagination-wrapper">
      {!loaders.itemResult &&
        searchReport &&
        searchReport.totalProducts > 24 &&
        searchReport.searchInterface !== 'xlsx' && (
          <Pager
            total={searchReport.totalProducts / 24}
            current={Math.ceil(searchReport.startIndex / 24)}
            visiblePages={searchReport.$visiblePages}
            titles={{ first: '<<', last: '>>' }}
            className="hd-pagination__wrapper"
            onPageChanged={actions.handlePageChanged}
          />
        )}
    </div>
  </div>);
  }
}

ItemResults.propTypes = {
  results: PropTypes.object,
  list: PropTypes.object,
  actions: PropTypes.object,
  itemView: PropTypes.number,
  multiItemPreview: PropTypes.object,
  searchReport: PropTypes.object,
  loaders: PropTypes.object,
  ui: PropTypes.object
};

const spec = {
  canDrop(props, monitor) {
    const item = monitor.getItem().item;
    const results = props.results;
    // console.log('canDrop');
    // dont allow dropping of item thats already in result set
    // const inSet = !!results.find(result => result.itemId === item.itemId);
    // can only drop if the item is in curation
    return typeof item.$inCuration === 'undefined' || item.$inCuration;
  },
  drop(props, monitor) {
    const item = monitor.getItem().item;
    const listId = props.list.id;
    if (item._selected) {
      props.actions.removeAllSelectedFromSet({ list: props.list });
    } else {
      props.actions.removeItemFromSet({ item, listId, type: props.list.type });
    }
  }
};

const collect = (connect, monitor) => {
  const info = {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop()
  };
  return info;
};


class ItemResultsDropComponent extends Component {
  render() {
    const { connectDropTarget, isOver, canDrop, results, list } = this.props;
    const className = ['col__8-12 curate-list__sidebar'];
    const totalItems = ((results && results.items) || []).filter(item => item._selected).length;
    const multiItemPreview = this.props.generateMultiItemPreview({
      totalItems,
      size: this.props.itemView === 4 ? 'large' : 'small'
    });
    if (isOver && canDrop) {
      className.push('curation--list--droppable');
    }
    return connectDropTarget(
      <div className={className.join(' ')}>
        <ItemResults {...this.props} multiItemPreview={multiItemPreview} />
      </div>
    );
  }
}

ItemResultsDropComponent.propTypes = {
  position: PropTypes.objectOf(PropTypes.number),
  isOver: PropTypes.bool,
  connectDropTarget: PropTypes.func,
  movePiece: PropTypes.func,
  canDrop: PropTypes.bool,
  children: PropTypes.object,
  results: PropTypes.object,
  list: PropTypes.object,
  itemView: PropTypes.number,
  itemPagination: PropTypes.object,
  actions: PropTypes.object,
  generateMultiItemPreview: PropTypes.func,
  searchReport: PropTypes.object,
  loaders: PropTypes.object
};

export default DropTarget('DRAG_SOURCE', spec, collect)(ItemResultsDropComponent); // eslint-disable-line
