import { selectItem } from '../ReviewItemContainer/actions';
import * as types from '../../../../../../constants/ActionTypes';
import * as api from '../../../../../../util/api';

export function closeSelection({ list, item }) {
  return selectItem({ list, index: -1, item });
}

export function setDiscoverItemBy({
  dimensionId, dimensionName, itemId, listId, startIndex = 0, value }) {
  return (dispatch, getState) => {
    // set the discover item by.
    if (!!dimensionId) {
      dispatch({ type: types.SET_DISCOVER_ITEM_BY, itemId, listId, dimensionId, value });
    }
    const state = getState();
    let sortByDimensions = [];
    const selectedList = state.curationList.lists.find(list => list.id === listId);
    const selectedItem = selectedList.items.find(item => (item.itemId || item.id) === itemId);
    sortByDimensions = selectedItem.results.searchReport.dimensionForSort.filter(dim => {
      return dim.$selected;
    }).map(d => {
      return {
        id: d.id,
        name: d.name,
      };
    });

    dispatch({
      type: types.INITIATE_LOADER,
      loader: 'item-details'
    });
    api.getItemsByItem({
      itemId,
      sortByDimensions,
      startIndex
    })
    .then(resp => {
      dispatch({
        type: types.REMOVE_LOADER,
        loader: 'item-details'
      });
      dispatch({ type: types.SET_RESULTS_FOR_ITEM, results: resp, itemId, listId });
    });
  };
}

export function handlePageChanged({ newPage, itemId, listId }) {
  return (dispatch, getState) => {
    const state = getState();
    const dimensionId = state.ui.ReviewList.discoverItemBy || 'all';
    const startIndex = (Math.ceil(newPage) * 24);
    dispatch(setDiscoverItemBy({ dimensionId, itemId, listId, startIndex }));
  };
}
