import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import * as actions from './UserClassifications.actions';
import UserClassificationsComponent from './UserClassfications.component';
import { getSortedDimensionAttributes } from './UserClassifications.reducer';


const mapStateToProps = (state) => {

  const styleClassifications = state.userClassification
                              && state.userClassification.styleClassifications
                               ? state.userClassification.styleClassifications
                               : {};

  const roomClassifications = state.userClassification
                              && state.userClassification.roomClassifications
                              ? state.userClassification.roomClassifications
                              : {};

  const styleDimensions = getSortedDimensionAttributes({
    classifications: styleClassifications
  });

  const roomDimensions = getSortedDimensionAttributes({ classifications: roomClassifications });

  return {
    loaders: state.app.loaders,
    styleClassifications,
    roomClassifications,
    styleDimensions,
    roomDimensions
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    actions: bindActionCreators({
      ...actions
    }, dispatch)
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserClassificationsComponent)
);