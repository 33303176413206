import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './style.scss';

class Tag extends Component {
  render() {
    const {
      listId,
      tags,
      type,
      actions,
      tagSearchList,
      inputTagValue,
      disabled,
      isTagCategoryListToShow,
      isSearchTagsToShow,
      appliedTags,
      selectedCategoryName,
      listType
    } = this.props;
    return (
      <div className="accordion__wrapper">
      <div className="accordion__item">
        <input
          className="accordion__input"
          id="ac-tags"
          name="accordion-2"
          type="checkbox"
        />
        <label className="accordion__label" htmlFor="ac-tags">
          <div className="accordion__trigger"><div className="accordion__plus"></div></div>
            <div className="slider-text-wrapper accordion__text-wrapper">
              <div className={(type !== 'review' && listType !== 'collection'
                && type !== 'multiEdit') ?
                'slider-text-wrapper__text' : 'slider-text-wrapper__title'}
              >
                Tags
              </div>
              <div className="slider-text-wrapper__subtitle">
                {/* This bit just concats all of tags into a string to be displayed in grey
                  next to the Tag label
                  */}
                {(() => {
                  let appliedValues = appliedTags.map(tag => {
                    return tag.categoryName + ':' + tag.attributeName;
                  });
                  if (appliedValues.length) {
                    return '[' + appliedValues.join(', ') + ']';
                  }
                  return '';
                })()}
              </div>
            </div>
        </label>
        <div className="accordion__content-wrapper tag-parent__wrapper">
          <div className="accordion__content">
            {!disabled && <div className="tag__content-wrapper">
              <a className="bttn bttn--primary bttn--inline bttn--primary__shadow">
                <span className="bttn__content bttn-dropdown__arrow"
                  onClick={(e) => {
                    e.preventDefault();
                    actions.toggleTagCategoryListDropDown();
                  }}
                >
                  {selectedCategoryName}
                </span>
              </a>
              <div className="tag_search--component">
                <input className="form-input__field tag_input--field"
                  type="text"
                  placeholder={'Search & Select Tags'}
                  value={inputTagValue}
                  onChange={(e) => {
                    const value = e.target.value;
                    actions.setInputTagValue({ value });
                  }}
                  onFocus={(e) => {
                    actions.setIsTagInputFocus({ isTagInputFocus: true });
                  }}
                />
                {isSearchTagsToShow &&
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      actions.setIsTagInputFocus({ isTagInputFocus: false });
                      actions.setInputTagValue({ value: '' });
                    }}
                    className="close-Tag--Search"
                  >
                    <span className="bttn__content"></span>
                  </a>
                }
              </div>
            </div>}
            <div className={isTagCategoryListToShow ? 'show-tagFilter__wrapper'
              : 'hide-tagFilter__wrapper'}
            >
              <ul className="drop-down__list tag-category__list--test">
                <li className="drop-down__item"
                  onClick={(e) => {
                    const value = '0';
                    actions.setTagCategory({ value, listId });
                  }}
                >
                  <span className="drop-down__hover-effect">
                    All
                  </span>
                </li>
                {/* These are the main categories*/}
                {(tags || []).map((tag, l) => {
                  return (
                    <li key={l} className="drop-down__item"
                      onClick={(e) => {
                        const value = tag.id;
                        actions.setTagCategory({ value, listId });
                      }}
                    >
                      <span className="drop-down__hover-effect">
                        {tag.name}
                      </span>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className={isSearchTagsToShow ?
              'show-tagFilter__wrapper' :
              'hide-tagFilter__wrapper' }
            >
              {/* These are the checkboxes */}
              <ul className="drop-down__list">
                {(tagSearchList || []).map((tag, i) => {
                  return (
                    <li key={tag.attributeId + tag.attributeName} className="drop-down__item">
                    <span className="drop-down__hover-effect">
                      <div className="checkbox-btn">
                        <input className="checkbox-btn__input" type="checkbox"
                          value={tag.categoryName}
                          id={`rc-${i}`}
                          name={`rc-${i}`}
                          checked = {tag.selected}
                          readOnly
                        />
                        <label className="checkbox-btn__label" htmlFor={`rc-${i}`}
                          onClick={(e) => {
                            const categoryId = tag.categoryId;
                            const attributeId = tag.attributeId;
                            if (type === 'multiEdit') {
                              actions.addRemoveTagValueForMultiEdit({ categoryId,
                                attributeId,
                                type,
                                tagAttrSelected: tag.selected });
                            } else {
                              actions.addRemoveTagValue({ categoryId,
                                attributeId,
                                listId,
                                type,
                                tagAttrSelected: tag.selected });
                            }
                          }}
                        >{tag.categoryName}: {tag.attributeName}</label>
                      </div>
                    </span>
                  </li>
                  );
                })}
              </ul>
            </div>
            {/* These are the popup bubbles */}
            <div className="appliedTag">
              {appliedTags.map((tag, j) =>
                <span key={j} className="top-refinement-cntr">
                  {!disabled &&
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      const categoryId = tag.categoryId;
                      const attributeId = tag.attributeId;
                      if (type === 'multiEdit') {
                        actions.addRemoveTagValueForMultiEdit({ categoryId,
                          attributeId,
                          type,
                          tagAttrSelected: tag.selected });
                      } else {
                        actions.addRemoveTagValue({ categoryId,
                          attributeId,
                          listId,
                          type,
                          tagAttrSelected: true });
                      }
                    }}
                    className="refinements bttn-outline bttn--filter bttn--inline"
                  >
                    <span className="bttn__content">{tag.categoryName}: { tag.attributeName }</span>
                  </a>
                  }
                  {disabled &&
                    <span className="disable-refinements refinements
                      bttn-outline bttn--filter bttn--inline"
                    >
                      <span className="bttn__content">
                        {tag.categoryName}: { tag.attributeName }
                      </span>
                    </span>
                  }
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>);
  }
}

Tag.propTypes = {
  listId: PropTypes.number,
  type: PropTypes.string,
  tags: PropTypes.array,
  tagSearchList: PropTypes.array,
  actions: PropTypes.object,
  inputTagValue: PropTypes.string,
  isSearchTagsToShow: PropTypes.bool,
  isTagCategoryListToShow: PropTypes.bool,
  appliedTags: PropTypes.array,
  selectedCategoryName: PropTypes.string,
  disabled: PropTypes.bool,
  listType: PropTypes.string
};

export default Tag;
