import * as types from '../../../../../../constants/ActionTypes';
import { API_HOST } from '../../../../../../constants/environment';
import results from './mock';
import * as api from '../../../../../../util/api';
import itemAnalytics from '../../../mock/itemAnalytics';


export function toggleSelectedReviewDiscoveryItem({ selectedItemId, itemId, curationId }) {
  return { type: types.TOGGLE_SELECTED_RESULTS_ITEM_SELECTION, selectedItemId, itemId, curationId };
}

export function selectItem({ item, index, list }) {
  return (dispatch, getState) => {
    const state = getState();
    const dim = state.app.meta.dimensions[0];

    const itemType = list.type === 'collection'
      ? 'curation'
      : 'item';

    const id = item.id || item.itemId;
    if (item.pageType === 'results') {
      dispatch({ type: types.SELECT_CURATION_ITEM, id: index });
    } else {
      dispatch({ type: types.SELECT_REVIEW_ITEM, id, index, listId: list.id, itemType });
    }
    // @todo make api request

    // dispatch({
    //   type: types.SET_ITEM_ANALYTICS, results: itemAnalytics, itemId, index, listId: list.id
    // });

    dispatch({
      type: types.INITIATE_LOADER,
      loader: 'item-details'
    });

    if (list.type === 'curation' && index !== -1) {

      api.getItemsByItem({ itemId: item.itemId })
        .then(resp => {
          dispatch({
            type: types.SET_RESULTS_FOR_ITEM,
            results: resp,
            itemId: item.itemId,
            listId: list.id,
            itemType: 'item'
          });
          dispatch({
            type: types.REMOVE_LOADER,
            loader: 'item-details'
          });
        })
        .catch(e => {
          console.log(e);
          dispatch({
            type: types.REMOVE_LOADER,
            loader: 'item-details'
          });
        });
    } else if (index !== -1) {
      let _id = (item.id || item.itemId);
      api.getListDetailsById({ listId: _id, type: item.type })
        .then(response => {
          dispatch({
            type: types.SET_LIST_DATA_FOR_CURATION,
            listId: list.id || list.itemId,
            response,
            meta: state.app.meta,
            itemId: item.id || item.itemId
          });
          // dispatch({ type: types.SELECT_REVIEW_ITEM, id, index, listId: list.id, itemType });
          dispatch({
            type: types.REMOVE_LOADER,
            loader: 'item-details'
          });
        }).catch(err => {
          console.log(err);
          dispatch({
            type: types.REMOVE_LOADER,
            loader: 'item-details'
          });
        });
    }
  };
}

export function removeResultItemFromItem({ itemId, curationId }) {
  return (dispatch, getState) => {
    const state = getState();
    const curation = state.curationList.lists.find(list => list.id === curationId);
    const openItem = curation.skus.find(item => item._selected);
    dispatch({ type: types.REMOVE_RESULTS_ITEM, itemId, curationId, resultId: openItem.itemId });
  };
}
