import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import './collectionslist.style.scss';
import RowHeader from '../../../../modules/RowHeader';
import { formatDate } from '../../../../../../util';

class CollectionsListComponent extends Component {

  componentDidMount() {
    const { list, actions } = this.props;
    const listId = list.id;
    actions.getCollectionsLists({ listId });
  }

  render() {
    const { collections, list } = this.props;
    if (!collections || collections.length === 0) return null;
    if (list.type !== 'curation') return null;
    return (
    <div className="collectionslist">
      <RowHeader title="Associated Collections" />
      <div className="grid collectionlist__table">
        <div className="col__12-12 collectionslist__row collectionslist__row--title">
            <span>ID #</span>
            <span>Name</span>
            <span>Created At</span>
            <span>Updated At</span>
            <span>Status</span>
            <span>Curations</span>
          </div>
        { (collections || []).map((collection, i) => {
          return (
            <Fragment key={i}>
              <div className="col__12-12 collectionslist__row-wrapper">
                <div className=" grid">
                  <div
                    className="col__11-12 collectionslist__row"
                  >
                    <span>{collection.collectionId}</span>
                    <span className="span__word-break">{collection.collectionName}</span>
                    <span>{formatDate(Number(collection.createdAt), 'MM/dd/yyyy hh:mm:ssTT')}</span>
                    <span>{formatDate(Number(collection.updatedAt), 'MM/dd/yyyy hh:mm:ssTT')}</span>
                    {collection.status === 0 && <span>{'edit'}</span>}
                    {collection.status === 1 && <span>{'active'}</span>}
                    {collection.status === 2 && <span>{'inactive'}</span>}
                    <span>{collection.curationCount}</span>
                  </div>
              </div>
            </div>
            </Fragment>
          );
        })}
      </div>
    </div>
    );
  }
}

CollectionsListComponent.propTypes = {
  list: PropTypes.object,
  actions: PropTypes.object,
  collections: PropTypes.array
};

export default CollectionsListComponent;