import * as types from '../../../../../../../constants/ActionTypes';
import * as api from '../../../../../../../util/api';
import { serializeRemoveItem, getSelectedCurationCount } from '../../MaintenanceDetails.reducer';
import { getJoinArrayElements } from "../../../../../../../util";

export function toggleSimilarItems({ showSimilarItems }) {
  return { type: types.TOGGLE_MAINTENANCE_SIMILAR_ITEMS, showSimilarItems: !showSimilarItems };
}

export function loadSimilarItems({ itemId }) {
  return (dispatch, getState) => {
    dispatch({
      type: types.INITIATE_LOADER,
      loader: 'similar-items'
    });
    api.getItemsByItem({ itemId })
    .then(resp => {
      dispatch({
        type: types.SET_SIMILAR_ITEMS_FOR_ITEM,
        results: resp
      });
      dispatch({
        type: types.REMOVE_LOADER,
        loader: 'similar-items'
      });
    })
    .catch(e => {
      console.log(e);
      dispatch({
        type: types.REMOVE_LOADER,
        loader: 'similar-items'
      });
    });
  };
}

export const searchForSimilarItems = ({ keyword }) => {
  return (dispatch) => {
    dispatch({ type: types.CLEAR_ERROR_MESSAGE });
    dispatch({ type: types.INITIATE_LOADER, loader: 'similar-items' });
    if (window.gtag) {
      window.gtag('event', 'search', {
        event_label: keyword,
        select_content: 'curation'
      });
    }
    api.getItemsByKeyword({ keyword })
      .then(res => {
        dispatch({ type: types.REMOVE_LOADER, loader: 'similar-items' });
        dispatch({
          type: types.SET_SIMILAR_ITEMS_FOR_ITEM,
          results: res
        });

      })
      .catch(e => {
        console.log(e);
        dispatch({
          type: types.REMOVE_LOADER,
          loader: 'similar-items'
        });
      });
  };
};

export const replaceItem = ({ itemId }) => {
  return (dispatch, getState) => {

    const state = getState();
    const curationCount = getSelectedCurationCount({ state });
    const answer = confirm(`You are about to replace this item from ${curationCount} curation(s), are you sure?`); // eslint-disable-line

    if (answer) {
      const userAction = 'replace';

      const req = serializeRemoveItem({ state, itemId, userAction });

      dispatch({ type: types.INITIATE_LOADER, loader: 'replace-item' });
      dispatch({ type: types.RESET_SIMILAR_ITEMS_SLIDER });
      dispatch({ type: types.SET_WINDOW_SCROLL_TOP, windowScroll: true });

      api.updateProductInCuration(
        {
          itemId,
          curationIds: req.curationIds,
          allCurations: req.allCurations,
          userAction: req.userAction,
          replaceItemId: req.replaceItemId
        })
        .then(res => {

          const searchReport = {
            pageSize: res.searchReport.pageSize,
            startIndex: res.searchReport.startIndex,
            totalCurations: res.searchReport.totalCurations,
            totalCollections: res.searchReport.totalCollections,
            $visiblePages: 5
          };
          dispatch({
            type: types.LOAD_PRODUCT_CURATIONS,
            curations: res.curations,
            searchReport,
            status: res.status ? res.status : {}
          });

          const publishedCurations = res.status
                                    && res.status.activeModeCurationsUpdatedAndPublished
                                      ? res.status.activeModeCurationsUpdatedAndPublished
                                      : [];

          const editModeNonPublishedCurations = res.status
                                              && res.status.editModeCurationsUpdatedAndNotPublished
                                                ? res.status.editModeCurationsUpdatedAndNotPublished
                                                : [];

          const inactiveModeNonPublishedCurations = res.status
                                          && res.status.inActiveModeCurationsUpdatedAndNotPublished
                                            ? res.status.inActiveModeCurationsUpdatedAndNotPublished
                                            : [];

          const infoMessage = `Product ${itemId} has been replaced ` +
            `from ${curationCount} curation(s). ` +
            `${publishedCurations.length} curation(s) were republished.` +
            ` ${editModeNonPublishedCurations.length} curation(s) remain in edit status, and` +
           ` ${inactiveModeNonPublishedCurations.length} curation(s) remain in inactive status.`;


          dispatch({ type: types.SET_PRODUCT_CURATION_INFO_MESSAGE, infoMessage });
          dispatch({ type: types.REMOVE_LOADER, loader: 'replace-item' });
        })
        .catch(e => {
          console.log(e);
          dispatch({
            type: types.REMOVE_LOADER,
            loader: 'replace-item'
          });
        });
    }
    return '';
  };
};

export function toggleSimilarItemSelection({ isSelected, itemId }) {
  return { type: types.TOGGLE_SIMILAR_ITEM_SELELCTION, itemId, isSelected };
}
export function resetSimilarItemsSlider() {
  return { type: types.RESET_SIMILAR_ITEMS_SLIDER };
}