import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const deriveValueFromElement = ({ element }) => {
  if (element.weight === 100) return true;
  if (element.weight === 0) return false;
  if (typeof element.value !== 'undefined') return element.value;
  return '';
};

class CheckboxGroup extends Component {
  render() {
    const {
      id,
      elements,
      onChange,
      disabled = false,
      showWeight = false
    } = this.props;
    return (<form className={`checkbox-${id}`}>
      {elements.map((el, i) => {
        return (
          <div className="checkbox-btn refinements__checkbox" key={i}>
            <input
              disabled={disabled ? 'disabled' : ''}
              className="checkbox-btn__input"
              type="checkbox"
              id={`checkbox-${id}-${el.id}-${i}`}
              name={`checkbox-${id}-${el.id}-checkbox-${i}`}
              checked={deriveValueFromElement({ element: el })}
              onChange={(e) => {}}
              ref={(element) => {
                if (disabled && element) {
                  element.setAttribute('disabled', 'disabled');
                }
                if (el.value && element) {
                  element.setAttribute('checked', 'checked');
                }
                if (typeof el.value !== 'undefined' && !el.value && element) {
                  element.removeAttribute('checked', 'checked');
                }
              }}
            />
            <label
              className="checkbox-btn__label" htmlFor={`checkbox-${id}-${el.id}-checkbox-${i}`}
              onClick={(e) => {
                const input = e.currentTarget.parentNode.querySelector('.checkbox-btn__input');
                if (!disabled) {
                  onChange(!input.checked, el);
                }
              }}
            >{el.name}
            {showWeight &&
              <span className="checkbox__dimension-weight">{el.$weight || '0.00'}%</span>
            }
            </label>
          </div>);
      })}
</form>);
  }
}

CheckboxGroup.propTypes = {
  id: PropTypes.any,
  elements: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.any,
    name: PropTypes.string,
    value: PropTypes.any
  })),
  showWeight: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func
};

export default CheckboxGroup;
