import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import './style.scss';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const data = [
  { name: 'Page A', uv: 4000, pv: 2400, amt: 2400 },
  { name: 'Page B', uv: 3000, pv: 1398, amt: 2210 },
  { name: 'Page C', uv: 2000, pv: 9800, amt: 2290 },
  { name: 'Page D', uv: 2780, pv: 3908, amt: 2000 },
  { name: 'Page E', uv: 1890, pv: 4800, amt: 2181 },
  { name: 'Page F', uv: 2390, pv: 3800, amt: 2500 },
  { name: 'Page G', uv: 3490, pv: 4300, amt: 2100 },
];

const COLORS = [
  '#f96302',
  '#c14c00'
];
class CustomizedLabel extends Component {
  render() {
    const { text } = this.props;
    return (<span className="smallerLabel">
      {text}
    </span>);
  }
}
CustomizedLabel.propTypes = {
  text: PropTypes.string
};
class ProductDetailsComponent extends Component {

  componentDidMount() {
    //
    const { actions, item } = this.props;
    if (item.itemId !== undefined) {
      actions.getProductAttributes({ itemId: item.itemId });
    }
  }

  mapDimensionToChart(dimension) {
    const d = dimension.attributes.map(attr => {
      return {
        name: attr.name,
        [dimension.name]: attr.weight
      };
    });
    // d.sort((a, b) => (a[dimension.name] < b[dimension.name] ? 1 : -1));
    return d;
  }

  render() {
    const {
      attributes
    } = this.props;
    return (
      <div className="grid merchadisingAttributes">
        {attributes && attributes.map((dimension, index) => {
          return (<div key={index} className="col__12-12">
              <BarChart width={1000} height={300}
                data={this.mapDimensionToChart(dimension)}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                layout="vertical"
              >
              <CartesianGrid strokeDasharray="3 3" />
              <YAxis dataKey="name" type="category" style={{ fontSize: 10 }} interval={0} tick />
              <XAxis type="number" style={{ fontSize: 10 }} interval={0} tick domain={[0, 100]} />
              <Tooltip />
              <Legend />
              <Bar dataKey={dimension.name} fill={COLORS[index]} />
              </BarChart>
            </div>);
        })}
      </div>
    );
  }
}

ProductDetailsComponent.propTypes = {
  // loaders: PropTypes.object,
  actions: PropTypes.object,
  item: PropTypes.object,
  itemId: PropTypes.string,
  attributes: PropTypes.array
};

export default ProductDetailsComponent;