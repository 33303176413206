/* global VERSION, PACKAGE_JSON_VERSION, COMMITHASH */
import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import persistState from 'redux-localstorage';
import reducers from './reducers';
import { APP_CONTEXT, GA_ID } from '../../constants/environment';
import * as util
  from '../../util';
import { Switch, Route, HashRouter, BrowserRouter, hashHistory } from 'react-router-dom';
import './index.scss';
import '../../assets/font-awesome-4.7.0/scss/font-awesome.scss';
import Promise from 'es6-promise';
import AppContainer from './App/AppContainer';
import CurationList from './App/CurationList';
import CurateList from './App/CurateList';
import MaintenanceListView from './App/Maintenance/List';
import ClassificationsView from './App/Classifications';
import UserClassificationsView from './App/UserClassifications';
import DimensionClassificationsView
  from './App/UserClassifications/Modules/DimensionClassifications';
import UserClassificationDetailsByIdView
  from './App/UserClassifications/Modules/UserClassificationDetails';
import MaintenanceDetailsContainer from './App/Maintenance/Details';
import ReviewList from './App/ReviewList';
import GoogleAnalytics from './modules/GoogleAnalytics';
import Overlay from 'thd-overlay';
Promise.polyfill();
window.THD_OVERLAYS = null;
const middleware = [thunk];
const history = new BrowserRouter().history;

if (util.storageAvailable('localStorage') && (
  window.localStorage.getItem('THD_CURATIONIZER') === null ||
  window.localStorage.getItem('THD_CURATIONIZER') !== VERSION
)) {
  window.localStorage.setItem(APP_CONTEXT, null);
  window.localStorage.setItem('THD_CURATIONIZER', VERSION);
}

if (/#\/token_type/.test(window.location.hash)) {
  util.processAuthToken(window.location.hash);
}

const store = createStore(
  reducers,
  compose(
    applyMiddleware(...middleware),
    persistState(null, {
      key: APP_CONTEXT
    }),
    (window.devToolsExtension /* && process.env.NODE_ENV !== 'production' */)
      ? window.devToolsExtension()
      : f => f
  )
);

window[`__${APP_CONTEXT}__`] = {
  store,
  version: VERSION// eslint-disable-line
};

history.listen(function (location) {

});

window.onerror = (err) => {
  window.gtag('event', 'exception', {
    description: err.message,
    fatal: true   // set to true if the error is fatal
  });
};
if (!window.THD_OVERLAYS) {
  window.THD_OVERLAYS = {};
  window.THD_OVERLAYS.duplicateList = new Overlay({
    width: 450,
    height: 300,
    speed: '500ms',
    channel: 'desktop'
  });
  window.THD_OVERLAYS.warningMessage = new Overlay({
    width: 650,
    height: 300,
    speed: '500ms',
    channel: 'desktop'
  });

  window.THD_OVERLAYS.hotspots = new Overlay({
    width: window.innerWidth - 50,
    height: window.innerHeight - 50,
    speed: '500ms',
    channel: 'desktop'
  });

}


    // const hash = location.hash;
    // if (!/access_token/.test(hash) && hash) {
    //   const path = hash.substring(1, hash.length);
    //   window.gtag('config', GA_ID, {
    //     page_title: path,
    //     page_path: path
    //   });
    // }

ReactDOM.render(
  <Provider store={store}>
    <HashRouter history={hashHistory}>
      <GoogleAnalytics>
        <Switch>
          <Route exact path="/" component={AppContainer} />
           {/* <Route path="/token_type*" component={AppContainer} /> */}
          <Route path="?code*" component={AppContainer} />
          <Route path="/products" exact component={MaintenanceListView} />
          <Route path="/classifications" exact component={ClassificationsView} />
          <Route path="/userClassifications" exact component={UserClassificationsView} />
          <Route path="/userClassifications/dimensions/:id/:attributeId/"
            exact
            component={DimensionClassificationsView}
          />
          <Route path="/userClassifications/dimensions/:page/:id/:attributeId/"
            exact
            component={DimensionClassificationsView}
          />
          <Route path="/userClassifications/details/:userId"
            exact
            component={UserClassificationDetailsByIdView}
          />
          <Route path="/products/:page" exact component={MaintenanceListView} />
          <Route path="/products/:page/:keyword" exact component={MaintenanceListView} />
          <Route
            path="/products/:page/:sortBy/:sortOrder"
            exact
            component={MaintenanceListView}
          />
          <Route
            path="/products/:page/:keyword/:sortBy/:sortOrder"
            component={MaintenanceListView}
          />
          <Route path="/product/details/:id" exact component={MaintenanceDetailsContainer} />
          <Route path="/:listType/curate/:id" onEnter={util.requireAuth} component={CurateList} />
          <Route path="/:listType/review/:id" onEnter={util.requireAuth} component={ReviewList} />
          <Route path="/:listType/review/:id/default" onEnter={util.requireAuth}
            component={ReviewList}
          />
          <Route path="/:listType" exact component={CurationList} />
          <Route path="/:listType/:viewMode?/:page" exact component={CurationList} />
          <Route path="/:listType/:viewMode?/:page/:keyword" exact component={CurationList} />
          <Route
            path="/:listType/:viewMode?/:page/:sortBy/:sortOrder"
            exact
            component={CurationList}
          />
          <Route
            path="/:listType/:viewMode?/:page/:keyword/:sortBy/:sortOrder"
            component={CurationList}
          />
          </Switch>
      </GoogleAnalytics>
    </HashRouter>
  </Provider>,
  document.getElementById('root')
);
