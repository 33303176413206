import * as types from '../../../../../../constants/ActionTypes';
import * as api from '../../../../../../util/api';

export const getUserClassificationDetailsById = ({ userId }) => {
  return (dispatch) => {
    dispatch({
      type: types.INITIATE_LOADER,
      loader: 'load-user-classification-details-by-id'
    });

    return api
      .getUserClassificationDetailsById({ userId })
      .then(response => {
        dispatch({
          type: types.SET_USER_CLASSIFICATION_DETAILS_BY_ID,
          styleClassificationsByUserId: response.dimensions
                                  && response.dimensions.length > 0
                                  && response.dimensions.find(dimensions => dimensions.id === 100),
          roomClassificationsByUserId: response.dimensions
                                  && response.dimensions.length > 0
                                  && response.dimensions.find(dimensions => dimensions.id === 200)
        });

        dispatch({ type: types.REMOVE_LOADER, loader: 'load-user-classification-details-by-id' });
      })
      .catch(e => {
        console.error(e);
        dispatch({ type: types.REMOVE_LOADER, loader: 'load-user-classification-details-by-id' });
      });
  };

};