import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import * as appActions from './AppActions';
import { getAuthUrl, processAuthCode, processAuthToken } from '../../../util';
import * as types from '../../../constants/ActionTypes';
import App from './AppComponent';
import './AppStyle.scss';

const mapStateToProps = (state, ownProps) => {
  const authUrl = '';
  return {
    results: state.item.results || {},
    list: state.item.list || [],
    authUrl: getAuthUrl(),
    error: state.ui.error && state.ui.error.message,
    loaders: state.app.loaders
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  let code = '';
  if (window.location.search.includes('code=')) {
    code = new URLSearchParams(window.location.search).get('code');
    dispatch(appActions.getloggedinUserToken(code)).then(tokenresp => {
    // appActions.getloggedinUserToken(code).then(tokenresp => {
      // console.log(tokenresp);
      if (tokenresp && tokenresp.access_token !== '') {
        const path = processAuthCode(tokenresp, false);
        dispatch({ type: types.INITIATE_LOADER, loader: 'get-user' });
        dispatch(appActions.getUserDetails()).then(resp => {
          dispatch({ type: types.REMOVE_LOADER, loader: 'get-user' });
          if (resp && resp.id) {
            dispatch(appActions.clearError());
            dispatch(appActions.fetchMetaData()).then(res => {
              dispatch({ type: types.SET_ACTIVE_MENU_TAB, activeTab: 'curations' });
              // ownProps.history.push('/curations');
              const curationPath = `${window.location.origin}${window.location.pathname}#curations`;
              window.location = curationPath;

            });
          } else {
            dispatch({ type: types.SET_ERROR_MESSAGE, message: 'Error authenticating user' });
            ownProps.history.push('/');
            setTimeout(() => {
              dispatch(appActions.logoutUser());
            }, 3000);
          }
        })
        .catch(e => {
          dispatch({ type: types.REMOVE_LOADER, loader: 'get-user' });
        });
      }
    });
    // });
  }
  // @todo
  // if signed in navigate to list
  dispatch((d, getState) => {
    const state = getState();
    if (state.app.loggedIn) {
      setTimeout(() => {
        ownProps.history.push('/list');
      }, 200);
    }
  });
  return {
    navigate: () => {
      dispatch(appActions.loginUser());
      // @todo loader
      setTimeout(() => {
        ownProps.history.push('/list');
      }, 500);
    },
    actions: bindActionCreators({
      ...appActions,
    }, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
