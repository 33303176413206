import * as types from '../../../../../../../constants/ActionTypes';
import * as util from '../../../../../../../util';
import * as api from '../../../../../../../util/api';

export function getProductAttributes({ itemId }) {
  return (dispatch, getState) => {
    dispatch({ type: types.INITIATE_LOADER, loader: 'listByMerchandisingAttribute' });
    api.getProductAttributes({ itemId })
      .then(resp => {
        dispatch({ type: types.LOAD_MERCHANDISING_ATTRIBUTES,
          loader: 'listByMerchandisingAttribute' });
        dispatch({ type: types.REMOVE_LOADER, loader: 'listByMerchandisingAttribute' });
      });
  };
}