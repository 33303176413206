module.exports = {
  results: [
    {
      itemId: '0000006',
      imageSrc: '//www.homedepot.com/hdus/en_US/DTCCOMNEW/fetch/collections/staticImages/' +
      'patio_collection_Alveranda/17PATIO-207189496-ALVERANDA-SILO.jpg',
      title: 'product 06 title',
      modelNumber: '123456',
      price: '12.20'
    },
    {
      itemId: '0000007',
      imageSrc: '//www.homedepot.com/hdus/en_US/DTCCOMNEW/fetch/collections/staticImages/' +
      'patio_collection_Alveranda/17PATIO-207189496-ALVERANDA-SILO.jpg',
      title: 'product 07 title',
      modelNumber: 'MODEL234',
      price: '12.20'
    },
    {
      itemId: '0000008',
      imageSrc: '//www.homedepot.com/hdus/en_US/DTCCOMNEW/fetch/collections/staticImages/' +
      'patio_collection_Alveranda/17PATIO-207189496-ALVERANDA-SILO.jpg',
      title: 'product 08 title',
      modelNumber: 'MODEL2324',
      price: '12.20'
    },
    {
      itemId: '0000009',
      imageSrc: '//www.homedepot.com/hdus/en_US/DTCCOMNEW/fetch/collections/staticImages/' +
      'patio_collection_Alveranda/17PATIO-207189496-ALVERANDA-SILO.jpg',
      title: 'product 09 title',
      modelNumber: 'MODEL2568',
      price: '12.20'
    },
    {
      itemId: '0000010',
      imageSrc: '//www.homedepot.com/hdus/en_US/DTCCOMNEW/fetch/collections/staticImages/' +
      'patio_collection_Alveranda/17PATIO-207189496-ALVERANDA-SILO.jpg',
      title: 'product 10 title',
      modelNumber: 'MODEL231098',
      price: '12.20'
    }
  ]
};
