import * as types from '../../../../constants/ActionTypes';
import * as api from '../../../../util/api';
import { addItemToSet } from '../CurateList/actions';
import * as util from '../../../../util';
import { getSpaceCollectionURL } from '../CurationList/reducer';
import { loadVersionsFor } from './modules/Versions/versions.actions';

export const updateItemPosition = ({ itemId, index, listId }) => {
  return (dispatch, getState) => {
    dispatch({ type: types.UPDATE_ITEM_POSITION, itemId, index, listId });
    const state = getState();
    const list = state.curationList.lists.find(c => (c.itemId || c.id) === listId);
    api.saveList({ list })
      .then(response => {
        // we dont want to update collection list data because this reponse does
        // response is slow and list data is incomplete in the collection review page
        // because it does not contain the entire curation
        dispatch({ type: types.SET_LIST_DATA_WITHOUT_ITEMS, id: list.id,
          response, meta: state.app.meta });
      });
  };
};

let ONCE_LISTENER;
export const appMainWindowListener = (dispatch) => {
  if (ONCE_LISTENER) return;
  ONCE_LISTENER = true;
  util.addEvent(window, 'message', message => {
    let data = message.data;
    if (typeof data === 'string') {
      try {
        data = JSON.parse(data);
      } catch (e) {
        // console.log(e);
        return;
      }
    }
    const event = data.event;
    // {type: types.VALUE, ...}
    if (event && event.type === 'redux_event') {
      dispatch({ ...event.message });
    }
  });
};

export const copyPrimaryCurationToCollection = ({ listId }) => {
  return (dispatch, getState) => {
    return new Promise(resolve => {
      let state = getState();
      const list = state.curationList.lists.find(l => l.id === listId);
      if (list.type !== 'collection') return resolve();
      if (!list.items || list.items.length === 0) return resolve();
      const curationToCopy = list.items[0];
      api.getListDetailsById({ listId: curationToCopy.id || curationToCopy.itemId }).then(resp => {
        dispatch({
          type: types.COPY_CURATION_TO_COLLECTION,
          curation: resp,
          listId,
          meta: state.app.meta
        });
        state = getState();
        const item = state.curationList.lists.find(l => l.id === listId);
        item.hideOnCLP = curationToCopy.hideOnCLP;
        api
          .updateListMeta({ list: item, type: item.type })
          .then(response => {
            dispatch({ type: types.SET_LIST_DATA_WITHOUT_ITEMS, id: item.id,
              response, meta: state.app.meta });
            dispatch({ type: types.SAVE_CURATION_NAME, name: response.name });
            dispatch({ type: types.SAVE_INTERNAL_NAME, internalRoomName: response.internalRoomName });
            resolve();
          })
          .catch(e => {
            console.log(e);
            resolve();
          });
      });
    });
  };
};

export const createCollectionFromCuration = ({ listId }) => {
  return (dispatch, getState) => {
    dispatch({ type: types.INITIATE_LOADER, loader: 'curation-collection-copy' });
    let state = getState();
    let meta = state.app.meta;
    const list = state.curationList.lists.find(l => l.id === listId);
    return new Promise(resolve => {
      api.createList({ type: 'collection' }).then(resp => {
        dispatch({
          type: types.CREATE_CURATION_LIST_FROM_TEMPLATE,
          meta,
          resp,
          listType: 'collection'
        });
        dispatch({ type: types.SET_LIST_DATA, id: resp.id, response: resp, meta });
        state = getState();
        let collection = state.curationList.lists.find(l => l.id === resp.id);
        const curation = state.curationList.lists.find(l => l.id === list.id);
        dispatch(
          addItemToSet({
            item: curation,
            listId: collection.id,
            index: 0
          })
        );
        state = getState();
        collection = state.curationList.lists.find(l => l.id === resp.id);
        api.saveList({ list: collection })
        .then(respon => {
          dispatch({
            type: types.SET_LIST_DATA_WITHOUT_ITEMS,
            id: collection.id,
            response: respon,
            meta: state.app.meta
          });
          dispatch({ type: types.SAVE_CURATION_NAME, name: respon.name });
          dispatch({ type: types.SAVE_INTERNAL_NAME, internalRoomName: respon.internalRoomName });
          dispatch({ type: types.SET_ACTIVE_MENU_TAB, activeTab: 'collections' });
          collection = state.curationList.lists.find(l => l.id === resp.id);
          dispatch(copyPrimaryCurationToCollection({ listId: collection.id }))
            .then(e => {
              dispatch({ type: types.REMOVE_LOADER, loader: 'curation-collection-copy' });
              resolve(collection);
            })
            .catch(e => {
              console.log(e);
              resolve();
            });
        });
      });
    });
  };
};

export const updateCurationMetaData = ({ value, dimensionId, attributeId, curationId }) => {
  return (dispatch, getState) => {
    dispatch({ type: types.UPDATE_CURATION_METADATA, dimensionId, attributeId, value, curationId });
    const state = getState();
    const meta = state.app.meta;
    const list = state.curationList.lists.find(l => l.id === curationId);
    api.updateListMeta({ list, type: list.type }).then(response => {
      if (response.errorCode) {
        dispatch({ type: types.ADD_CURATION_ERROR, response: [response] });
      } else {
        dispatch({
          type: types.SET_REVIEW_LIST_STATUS,
          listId: list.id,
          status: response.status
        });
        dispatch({ type: types.GENERIC_ERROR_HANDLER_CLEAR });
      }
    });
  };
};

export const togglePinterestCheckbox = ({ list }) => {
  return (dispatch, getState) => {
    dispatch({ type: types.TOGGLE_PINTEREST_PUBLISHED, listId: list.id });
    api
      .updateListMeta({
        list: {
          ...list,
          pinterestSTLStatus: !list.pinterestSTLStatus
        },
        type: list.type
      })
      .then(response => {
        const state = getState();
        if (response.errorCode) {
          dispatch({ type: types.ADD_CURATION_ERROR, response: [response] });
        } else {
          dispatch({ type: types.SET_LIST_DATA_WITHOUT_ITEMS, id: list.id,
            response, meta: state.app.meta });
          dispatch({ type: types.GENERIC_ERROR_HANDLER_CLEAR });
        }
      });
  };
};
export const toggleHideCLPCheckbox = ({ list }) => {
  return (dispatch, getState) => {
    dispatch({ type: types.TOGGLE_HIDE_CLP_PUBLISHED, listId: list.id });
    api
      .updateListMeta({
        list: {
          ...list,
          hideOnCLP: !list.hideOnCLP
        },
        type: list.type
      })
      .then(response => {
        const state = getState();
        if (response.errorCode) {
          dispatch({ type: types.ADD_CURATION_ERROR, response: [response] });
        } else {
          dispatch({ type: types.SET_LIST_DATA_WITHOUT_ITEMS, id: list.id,
            response, meta: state.app.meta });
          dispatch({ type: types.GENERIC_ERROR_HANDLER_CLEAR });
        }
      });
  };
};
export const toggleNameEditMode = () => {
  return { type: types.TOGGLE_NAME_EDIT_MODE };
};

export const toggleSubTypeEditMode = () => {
  return { type: types.TOGGLE_COLLECTION_SUBTYPE_EDIT_MODE };
};

export const saveCurationName = ({ name }) => {
  return { type: types.SAVE_CURATION_NAME, name };
};

export const saveinternalRoomName = ({ internalRoomName }) => {
  return { type: types.SAVE_INTERNAL_NAME, internalRoomName };
};

export const setCurationStatus = ({ status }) => {
  let isActive = status === 'active';
  return { type: types.SET_CURATION_STATUS, status: isActive };
};

export const updateCollectionSubType = ({ collectionSubType, listId }) => {
  return (dispatch, getState) => {
    dispatch({ type: types.UPDATE_COLLECTION_SUBTYPE, collectionSubType, listId });
    const state = getState();
    const list = state.curationList.lists.find(l => l.id === listId);
    api
      .updateListMeta({
        curationId: listId,
        list,
        type: list.type
      })
      .then(response => {
        if (response.errorCode) {
          dispatch({ type: types.ADD_CURATION_ERROR, response: [response] });
        } else {
          dispatch({ type: types.SET_LIST_DATA, id: list.id, response, meta: state.app.meta });
          dispatch({ type: types.GENERIC_ERROR_HANDLER_CLEAR });
        }
      });
  };
};

export const toggleDescriptionEditMode = () => {
  return { type: types.TOGGLE_DESCRIPTION_EDIT_MODE };
};

export const updateCurationName = ({ name, curationId }) => {
  return (dispatch, getState) => {
    dispatch({ type: types.UPDATE_CURATION_NAME, name, curationId });
    const state = getState();
    const list = state.curationList.lists.find(l => l.id === curationId);
    api
      .updateListMeta({
        curationId,
        list,
        type: list.type
      })
      .then(response => {
        if (response.errorCode) {
          dispatch({ type: types.ADD_CURATION_ERROR, response: [response] });
        } else {
          dispatch({ type: types.SET_LIST_DATA_WITHOUT_ITEMS, id: list.id,
            response, meta: state.app.meta });
          dispatch({ type: types.SAVE_CURATION_NAME, name });
          dispatch({ type: types.GENERIC_ERROR_HANDLER_CLEAR });
        }
      });
  };
};

export const updateInternalRoomName = ({ internalRoomName, curationId }) => {
  return (dispatch, getState) => {
    dispatch({ type: types.UPDATE_INTERNAL_NAME, internalRoomName, curationId });
    const state = getState();
    const list = state.curationList.lists.find(l => l.id === curationId);
    api
      .updateListMeta({
        curationId,
        list,
        type: list.type
      })
      .then(response => {
        if (response.errorCode) {
          dispatch({ type: types.ADD_CURATION_ERROR, response: [response] });
        } else {
          dispatch({ type: types.SET_LIST_DATA_WITHOUT_ITEMS, id: list.id,
            response, meta: state.app.meta });
          dispatch({ type: types.SAVE_INTERNAL_NAME, internalRoomName });
          dispatch({ type: types.GENERIC_ERROR_HANDLER_CLEAR });
        }
      });
  };
};

export const updateCurationDescription = ({ description, curationId }) => {
  return (dispatch, getState) => {
    dispatch({ type: types.UPDATE_CURATION_DESCRIPTION, description, curationId });
    const state = getState();
    const list = state.curationList.lists.find(l => l.id === curationId);
    api
      .updateListMeta({
        curationId,
        list,
        type: list.type
      })
      .then(response => {
        if (response.errorCode) {
          dispatch({ type: types.ADD_CURATION_ERROR, response: [response] });
        } else {
          dispatch({ type: types.SET_LIST_DATA_WITHOUT_ITEMS, id: list.id,
            response, meta: state.app.meta });
          dispatch({ type: types.GENERIC_ERROR_HANDLER_CLEAR });
          dispatch(toggleDescriptionEditMode());
        }
      });
  };
};

export const deleteCuration = ({ curationId, type }) => {
  return dispatch => {
    return api.deleteCuration({ curationId, type }).then(resp => {
      if (resp.errorCode) {
        dispatch({ type: types.ADD_CURATION_ERROR, response: [resp] });
      } else {
        dispatch({ type: types.DELETE_CURATION, curationId });
        dispatch({ type: types.GENERIC_ERROR_HANDLER_CLEAR });
      }
      return resp;
    });
  };
};

export const setCurationSort = ({ curation, value }) => {
  return (dispatch, getState) => {
    let v = value;
    let random = false;
    if (v === 'random') {
      v = '1';
      random = true;
    }
    dispatch({ type: types.SET_CURATION_SORT, curationId: curation.id, value: v, random });
    const state = getState();
    const updatedList = state.curationList.lists.find(l => l.id === curation.id);
    const meta = state.app.meta;
    dispatch({ type: types.INITIATE_LOADER, loader: 'review-sorting' });
    if (random) {
      return api.saveList({ list: updatedList })
        .then(response => {
          // we dont want to update collection list data because this reponse does
          // response is slow and list data is incomplete in the collection review page
          // because it does not contain the entire curation
          dispatch({ type: types.SET_LIST_DATA_WITHOUT_ITEMS, id: updatedList.id,
            response, meta: state.app.meta });
          dispatch({ type: types.REMOVE_LOADER, loader: 'review-sorting' });
        })
        .catch(e => {
          console.log(e);
          dispatch({ type: types.REMOVE_LOADER, loader: 'review-sorting' });
        });
    }
    return api
      .updateListMeta({
        curationId: curation.id,
        list: updatedList,
        type: updatedList.type
      })
      .then(response => {
        dispatch({ type: types.REMOVE_LOADER, loader: 'review-sorting' });
        dispatch({ type: types.SET_LIST_DATA, id: curation.id, response, meta });
      })
      .catch(err => {
        dispatch({ type: types.REMOVE_LOADER, loader: 'review-sorting' });
        console.log(err);
      });
  };
};

export const setReviewListColumnView = ({ value }) => {
  return { type: types.SET_REVIEWLIST_COLUMN_VIEW, value };
};

export const previewCollection = ({ list }) => {
  return () => {
    const previewURL = getSpaceCollectionURL({ list, preview: true });
    window.open(previewURL);
  };
};

export const resetSettings = ({ listId }) => {
  return (dispatch, getState) => {
    // Reset Tag UI Settings.
    dispatch({ type: types.SET_CURATION_TAGS_CATEGORY, category: '0', listId });
    dispatch({ type: types.SHOW_HIDE_TAG_CATEGORY_DROPDOWN, value: false });
    dispatch({ type: types.SET_IS_TAG_INPUT_FIELD_FOCUS_IN, isTagInputFocus: false });
  };
};

export const fetchCurationClassificationValues = ({ listId }) => {
  return (dispatch, getState) => {
    return api.getClassificationForList({ listId }).then(resp => {
      dispatch({ type: types.UPDATE_DIMENSION_WEIGHTS, resp, listId });
      return resp;
    });
  };
};

export const fetchCurationsForCollection = ({ listId }) => {
  return (dispatch, getState) => {
    const state = getState();
    const meta = state.app.meta;
    const list = state.curationList.lists.find(l => l.id === parseInt(listId, 10));
    dispatch({
      type: types.INITIATE_LOADER,
      loader: 'item-details'
    });
    let promises = list.items.map(curation => {
      return api.getListDetailsById({ listId: curation.id, type: curation.type })
        .then(response => {
          dispatch({
            type: types.SET_LIST_DATA_FOR_CURATION,
            listId: list.id || list.itemId,
            response,
            meta,
            itemId: curation.id || curation.itemId
          });
        }).catch(err => {
          console.log(err);
          dispatch({
            type: types.REMOVE_LOADER,
            loader: 'item-details'
          });
        });
    });
    return Promise.all(promises)
      .then(resp => {
        dispatch({
          type: types.REMOVE_LOADER,
          loader: 'item-details'
        });
        return resp;
      })
      .catch(err => {
        dispatch({
          type: types.REMOVE_LOADER,
          loader: 'item-details'
        });
        return err;
      });
  };
};

export const defaultClassicationValues = ({ listId }) => {
  return (dispatch, getState) => {
    let state = getState();
    const id = parseInt(listId, 10);
    let list = state.curationList.lists.find(l => l.id === id);
    (list.dimensionWeights || []).forEach(dim => {
      dim.attributes.forEach(attr => {
        if (attr.weight >= 25) {
          dispatch({
            type: types.UPDATE_CURATION_METADATA,
            dimensionId: dim.id,
            attributeId: attr.id,
            value: attr.weight,
            curationId: id
          });
        }
      });
    });
    state = getState();
    list = state.curationList.lists.find(l => l.id === id);
    api.updateListMeta({ list, type: list.type });
  };
};

export const fetchListClassication = ({ curationId, type = 'curation' }) => {
  return (dispatch, getState) => {
    const state = getState();
    const meta = state.app.meta;
    dispatch({ type: types.INITIATE_LOADER, loader: 'render-review-list' });
    return api.getListDetailsById({ listId: curationId, type }).then(response => {
      dispatch({ type: types.REMOVE_LOADER, loader: 'render-review-list' });
      if (response && !response.errorCode) {
        dispatch({ type: types.SET_LIST_DATA, id: curationId, response, meta });
        dispatch({ type: types.SAVE_CURATION_NAME, name: response.name });
        dispatch({ type: types.SAVE_INTERNAL_NAME, internalRoomName: response.internalRoomName });
        dispatch(setCurationStatus({ status: response.status }));
      }
      return response;
    });
  };
};

export const unpublish = ({ curationId, type }) => {
  return dispatch => {
    dispatch({ type: types.INITIATE_LOADER, loader: 'curation-unpublish-status' });
    api.deactivateCuration({ curationId, type }).then(resp => {
      dispatch({ type: types.REMOVE_LOADER, loader: 'curation-unpublish-status' });
      if (resp && !resp.errorCode) {
        dispatch({ type: types.UPDATE_CURATION_STATUS, status: resp.status, id: resp.id });
      }
    });
  };
};

export const updateCurationStatus = ({ curation, newStatus = 'publish' }) => {
  const status = curation.status;
  return (dispatch, getState) => {
    if (newStatus === 'publish') {
      dispatch({ type: types.INITIATE_LOADER, loader: 'curation-status' });
      api.publishCuration({ curationId: curation.id, type: curation.type })
        .then(resp => {
          dispatch({ type: types.REMOVE_LOADER, loader: 'curation-status' });
          dispatch({ type: types.UPDATE_CURATION_STATUS, status: resp.status, id: resp.id });
          dispatch(setCurationStatus({ status: resp.status }));
          // fetch versions
          dispatch(loadVersionsFor({ listId: curation.id }));
        });
    } else if (status === 'deactivate') {
      dispatch({ type: types.INITIATE_LOADER, loader: 'curation-status' });
      api.deactivateCuration({ curationId: curation.id }).then(resp => {
        dispatch({ type: types.REMOVE_LOADER, loader: 'curation-status' });
        if (resp && !resp.errorCode) {
          dispatch({ type: types.UPDATE_CURATION_STATUS, status: resp.status, id: resp.id });
        }
      });
    }
  };
};
