import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Accordion from '../../../modules/Accordion';
import CheckboxGroup from '../../../modules/CheckboxGroup';
import TagsContainer from '../../../modules/Tag';

class ReviewItemAttributesWrapper extends Component {
  render() {
    const {
      list,
      actions
    } = this.props;
    return (
      <div className="review-item-attributes-wrapper">
            <div className="review-item-box-title">
              <span className="review-item-box-title__text text-caps">{list.type} Attributes</span>
            </div>
            {list.meta.dimensions.map(dimension => {
              return (<Accordion key={dimension.id}
                id={`ac-${dimension.id}`} title={dimension.name}
                subtitle={(() => {
                  let selectedValues = dimension.attributes.filter(attr => {
                    return attr.value;
                  }).map(att => {
                    return att.name;
                  });
                  if (selectedValues.length) {
                    return '[' + selectedValues.join(', ') + ']';
                  }
                  return '';
                })()
                }
              >
              <CheckboxGroup
                id={`review-${dimension.id}`}
                elements={dimension.attributes}
                disabled={!list.$canEdit}
                showWeight={list.type === 'curation'}
                onChange={(value, element) => {
                  actions.updateCurationMetaData({
                    value, curationId: list.id, attributeId: element.id, dimensionId: dimension.id
                  });
                }}
              />
            </Accordion>);
            })}
            <TagsContainer listId={list.id} disabled={!list.$canEdit}
              type="review" listType={list.type}
            />
          </div>
    );
  }
}

ReviewItemAttributesWrapper.propTypes = {
  list: PropTypes.object,
  actions: PropTypes.shape({})
};

export default ReviewItemAttributesWrapper;