export default {
  lists: [{
      "productId": "11111111",
      "itemId": "100497596",
      "productUrl": "/p/KitchenAid-Artisan-5-Qt-Pink-Stand-Mixer-KSM150PSPK/100497596",
      "availabilityType": "Online",
      "info": {
        "brandName": "KitchenAid",
        "productLabel": "Artisan 5 Qt. Pink Stand Mixer",
        "modelNumber": "KSM150PSPK",
        "productType": "MERCHANDISE",
        "imageId": "71aa55c7-06b7-4fb3-9ef7-263d10788102",
        "totalNumberOfOptions": 10,
        "imageUrl": "https://images.homedepot-static.com/productImages/71aa55c7-06b7-4fb3-9ef7-263d10788102/svn/pink-kitchenaid-stand-mixers-ksm150pspk-64_<SIZE>.jpg",
        "swatches": [
          {
            "label": "Pink",
            "value": "58789337-fd5c-49f7-90dc-0ed53cfdc24e",
            "url": "/p/KitchenAid-Artisan-5-Qt-Pink-Stand-Mixer-KSM150PSPK/100497596"
          },
          {
            "label": "Blue Willow",
            "value": "d18f56d4-e3e6-44ae-a8fc-6664b1d2841c",
            "url": "/p/KitchenAid-Artisan-5-Qt-Blue-Willow-Stand-Mixer-KSM150PSBW/100497588"
          },
          {
            "label": "Caviar",
            "value": "fef7fdd0-8d80-442a-9a2f-7b836dbf94b3",
            "url": "/p/KitchenAid-Artisan-5-Qt-Caviar-Stand-Mixer-KSM150PSCV/100497589"
          },
          {
            "label": "Tangerine",
            "value": "e295852d-a80b-49ea-92f2-4bcfe13f7747",
            "url": "/p/KitchenAid-Artisan-5-Qt-Tangerine-Stand-Mixer-KSM150PSTG/100497600"
          },
          {
            "label": "Gloss Cinnamon",
            "value": "6b6de810-a120-4789-a8cc-dd58cb229c37",
            "url": "/p/KitchenAid-Artisan-5-Qt-Cinnamon-Gloss-Stand-Mixer-KSM150PSGC/203685750"
          }
        ]
      },
      "storeSku": {
        "fulfillmentOptions": {
          "fulfillable": true,
          "buyOnlineShipToStore": {
            "eligibilityStatus": true,
            "checkStoreLink": false
          },
          "shipToHome": {
            "eligibilityStatus": true
          },
          "storemessage": "BOSS",
          "storeInventory": "NA",
          "backOrder": false
        },
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 349.99,
          "specialPrice": 279.99,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 70,
          "percentageOff": 20
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": true,
        "freeShippingMessage": "Free"
      },
      "ratingsReviews": {
        "averageRating": "4.8719",
        "totalReviews": "20486"
      }
    },
    {
      "productId": "100656272",
      "itemId": "100656272",
      "productUrl": "/p/Nearly-Natural-20-in-Hydrangea-Wreath-4642-BL/100656272",
      "availabilityType": "Online",
      "info": {
        "brandName": "Nearly Natural",
        "productLabel": "20 in. Hydrangea Wreath",
        "modelNumber": "4642-BL",
        "productType": "MERCHANDISE",
        "imageId": "a20603ae-d398-41e7-97bc-e03f148664fd",
        "imageUrl": "https://images.homedepot-static.com/productImages/a20603ae-d398-41e7-97bc-e03f148664fd/svn/blue-nearly-natural-decorative-wreaths-4642-bl-64_<SIZE>.jpg"
      },
      "storeSku": {
        "fulfillmentOptions": {
          "fulfillable": true,
          "buyOnlineShipToStore": {
            "eligibilityStatus": true,
            "checkStoreLink": false
          },
          "shipToHome": {
            "eligibilityStatus": true
          },
          "storemessage": "BOSS",
          "storeInventory": "NA",
          "backOrder": false
        },
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 33.6,
          "specialPrice": 33.6,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": true,
        "freeShippingMessage": "Free"
      },
      "ratingsReviews": {
        "averageRating": "2.5",
        "totalReviews": "4"
      }
    },
    {
      "productId": "202047085",
      "itemId": "202047085",
      "productUrl": "/p/World-Imports-Berkley-Square-5-Light-Weathered-Bronze-Chandelier-WI8102562/202047085",
      "availabilityType": "Online",
      "info": {
        "brandName": "World Imports",
        "productLabel": "Berkley Square 5-Light Weathered Bronze Chandelier",
        "modelNumber": "WI8102562",
        "productType": "MERCHANDISE",
        "imageId": "ed3e52ea-0335-4be2-8363-81350d5a91e9",
        "discontinuedItem": true,
        "imageUrl": "https://images.homedepot-static.com/productImages/ed3e52ea-0335-4be2-8363-81350d5a91e9/svn/weathered-bronze-world-imports-chandeliers-wi8102562-64_<SIZE>.jpg"
      },
      "storeSku": {
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 124.99,
          "specialPrice": 124.99,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": false,
        "freeShippingThreshold": "$45",
        "freeShippingMessage": "Free"
      },
      "ratingsReviews": {
        "averageRating": "4.4483",
        "totalReviews": "29"
      }
    },
    {
      "productId": "202202527",
      "itemId": "202202527",
      "productUrl": "/p/Elegant-Home-Fashions-Wilshire-20-in-W-x-24-in-H-x-7-in-D-Bathroom-Storage-Wall-Cabinet-in-White-HD17046/202202527",
      "availabilityType": "Online",
      "info": {
        "brandName": "Elegant Home Fashions",
        "productLabel": "Wilshire 20 in. W x 24 in. H x 7 in. D Bathroom Storage Wall Cabinet in White",
        "modelNumber": "HD17046",
        "productType": "MERCHANDISE",
        "imageId": "38a430e7-e620-4acf-ae89-0038f7977784",
        "imageUrl": "https://images.homedepot-static.com/productImages/38a430e7-e620-4acf-ae89-0038f7977784/svn/white-elegant-home-fashions-bathroom-wall-cabinets-hd17046-64_<SIZE>.jpg"
      },
      "storeSku": {
        "fulfillmentOptions": {
          "fulfillable": false,
          "outofstockOnline": true,
          "storeInventory": "NA",
          "backOrder": false
        },
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 52.24,
          "specialPrice": 52.24,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": true,
        "freeShippingMessage": "Free"
      },
      "ratingsReviews": {
        "averageRating": "3.3548",
        "totalReviews": "62"
      }
    },
    {
      "productId": "202523608",
      "itemId": "202523608",
      "productUrl": "/p/MSI-Mint-Green-Subway-12-in-x-12-in-x-8-mm-Glass-Mesh-Mounted-Mosaic-Tile-GLSST-MG8MM/202523608",
      "availabilityType": "Online",
      "info": {
        "brandName": "MSI",
        "productLabel": "Mint Green Subway 12 in. x 12 in. x 8 mm Glass Mesh-Mounted Mosaic Tile",
        "modelNumber": "GLSST-MG8MM",
        "productType": "MERCHANDISE",
        "imageId": "da507b54-43bd-4e21-a256-52cca30bc0b1",
        "storeSkuNumber": "475037",
        "imageUrl": "https://images.homedepot-static.com/productImages/da507b54-43bd-4e21-a256-52cca30bc0b1/svn/green-msi-mosaic-tile-glsst-mg8mm-64_<SIZE>.jpg"
      },
      "storeSku": {
        "fulfillmentOptions": {
          "fulfillable": true,
          "buyOnlineShipToStore": {
            "eligibilityStatus": true,
            "checkStoreLink": false
          },
          "shipToHome": {
            "eligibilityStatus": true
          },
          "storemessage": "BOSS",
          "storeInventory": "NA",
          "backOrder": false
        },
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 8.45,
          "specialPrice": 8.45,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": false,
        "freeShippingThreshold": "$45",
        "freeShippingMessage": "FREE with $45 Order"
      },
      "ratingsReviews": {
        "averageRating": "4.5294",
        "totalReviews": "17"
      }
    },
    {
      "productId": "203089757",
      "itemId": "203089757",
      "productUrl": "/p/Talista-9-Light-Rustic-Sienna-Bronze-Chandelier-with-Shaded-Umber-Glass-CLI-FRT2154-09-41/203089757",
      "availabilityType": "Online",
      "info": {
        "brandName": "Talista",
        "productLabel": "9-Light Rustic Sienna Bronze Chandelier with Shaded Umber Glass",
        "modelNumber": "CLI-FRT2154-09-41",
        "productType": "MERCHANDISE",
        "imageId": "3e1b3bb3-8bb8-4239-8e0c-cd23f0834122",
        "imageUrl": "https://images.homedepot-static.com/productImages/3e1b3bb3-8bb8-4239-8e0c-cd23f0834122/svn/rustic-sienna-finish-talista-chandeliers-cli-frt2154-09-41-64_<SIZE>.jpg"
      },
      "storeSku": {
        "fulfillmentOptions": {
          "fulfillable": false,
          "outofstockOnline": true,
          "storeInventory": "NA",
          "backOrder": false
        },
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 334.99,
          "specialPrice": 334.99,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": false,
        "itemLevelFreeShip": false
      },
      "ratingsReviews": {
        "averageRating": "0.000000",
        "totalReviews": "0"
      }
    },
    {
      "productId": "11111111",
      "itemId": "203171283",
      "productUrl": "/p/Kraftware-Fishnet-Oval-Placemat-in-Mist-Green-Set-of-12-38236/203171283",
      "availabilityType": "Online",
      "info": {
        "brandName": "Kraftware",
        "productLabel": "Fishnet Oval Placemat in Mist Green (Set of 12)",
        "modelNumber": "38236",
        "productType": "MERCHANDISE",
        "imageId": "ab2c52c0-a2ec-4b94-8876-163767446f13",
        "totalNumberOfOptions": 9,
        "imageUrl": "https://images.homedepot-static.com/productImages/ab2c52c0-a2ec-4b94-8876-163767446f13/svn/greens-kraftware-placemats-38236-64_<SIZE>.jpg",
        "swatches": [
          {
            "label": "Greens",
            "value": "42574813-ac8a-4113-a8f6-38fa2a34fea3",
            "url": "/p/Kraftware-Fishnet-Oval-Placemat-in-Mist-Green-Set-of-12-38236/203171283"
          },
          {
            "label": "Reds / Pinks",
            "value": "9c4def72-aacb-4269-97e8-fc0d9593a99f",
            "url": "/p/Kraftware-Fishnet-Oval-Placemat-in-Brick-Set-of-12-38436/203171246"
          },
          {
            "label": "Blacks",
            "value": "4f1d8e36-c6de-4ee7-a1a6-9d9bfac26b1e",
            "url": "/p/Kraftware-Fishnet-Oval-Placemat-in-Black-Set-of-12-31136/203171236"
          },
          {
            "label": "Purples / Lavenders",
            "value": "6a05d323-bd48-46a4-9508-9d0007482f0b",
            "url": "/p/Kraftware-Fishnet-Oval-Placemat-in-Purple-Set-of-12-52736/203171348"
          },
          {
            "label": "Oranges/Peaches",
            "value": "f2c8f8cb-8632-4cc7-95f7-40a2dd1e23ed",
            "url": "/p/Kraftware-Fishnet-Oval-Placemat-in-Spice-Orange-Set-of-12-14836/203171358"
          }
        ]
      },
      "storeSku": {
        "fulfillmentOptions": {
          "fulfillable": true,
          "buyOnlineShipToStore": {
            "eligibilityStatus": true,
            "checkStoreLink": false
          },
          "shipToHome": {
            "eligibilityStatus": true
          },
          "storemessage": "BOSS",
          "storeInventory": "NA",
          "backOrder": false
        },
        "pricing": {
          "uom": "set",
          "unitsPerCase": "0.0",
          "originalPrice": 27.97,
          "specialPrice": 27.97,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": false,
        "freeShippingThreshold": "$45",
        "freeShippingMessage": "FREE with $45 Order"
      },
      "ratingsReviews": {
        "averageRating": "4.0",
        "totalReviews": "4"
      }
    },
    {
      "productId": "11111111",
      "itemId": "203087095",
      "productUrl": "/p/Kraftware-14-in-Round-Serving-Tray-in-White-10330/203087095",
      "availabilityType": "Online",
      "info": {
        "brandName": "Kraftware",
        "productLabel": "14 in. Round Serving Tray in White",
        "modelNumber": "10330",
        "productType": "MERCHANDISE",
        "imageId": "44c83227-7f6b-48bf-aa2e-726c92056575",
        "totalNumberOfOptions": 6,
        "imageUrl": "https://images.homedepot-static.com/productImages/44c83227-7f6b-48bf-aa2e-726c92056575/svn/white-kraftware-serving-trays-10330-64_<SIZE>.jpg",
        "swatches": [
          {
            "label": "White",
            "value": "5f35a25c-fb06-439f-9a25-d624c9909b87",
            "url": "/p/Kraftware-14-in-Round-Serving-Tray-in-White-10330/203087095"
          },
          {
            "label": "Blue",
            "value": "c7467632-c9d7-4314-9a48-85a17749e50e",
            "url": "/p/Kraftware-14-in-Round-Serving-Tray-in-Process-Blue-12230/203087111"
          },
          {
            "label": "Lemon",
            "value": "da4903d8-16b1-4e24-a887-960a79153073",
            "url": "/p/Kraftware-14-in-Round-Serving-Tray-in-Lemon-13930/203087116"
          },
          {
            "label": "Orange",
            "value": "66f31a27-149d-4de4-8fc4-672d8ea13aaa",
            "url": "/p/Kraftware-14-in-Round-Serving-Tray-in-Spicy-Orange-14830/203087136"
          },
          {
            "label": "Ivory",
            "value": "4660882f-e74b-4bf1-83cc-8d164e6224d0",
            "url": "/p/Kraftware-14-in-Round-Serving-Tray-in-Ivory-11330/203087105"
          }
        ]
      },
      "storeSku": {
        "fulfillmentOptions": {
          "fulfillable": true,
          "buyOnlineShipToStore": {
            "eligibilityStatus": true,
            "checkStoreLink": false
          },
          "shipToHome": {
            "eligibilityStatus": true
          },
          "storemessage": "BOSS",
          "storeInventory": "NA",
          "backOrder": false
        },
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 19.82,
          "specialPrice": 19.82,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": false,
        "freeShippingThreshold": "$45",
        "freeShippingMessage": "FREE with $45 Order"
      },
      "ratingsReviews": {
        "averageRating": "1.0",
        "totalReviews": "2"
      }
    },
    {
      "productId": "203089941",
      "itemId": "203089941",
      "productUrl": "/p/Talista-9-Light-Chestnut-Copper-Chandelier-with-Shaded-Umber-Glass-CLI-FRT2393-09-17/203089941",
      "availabilityType": "Online",
      "info": {
        "brandName": "Talista",
        "productLabel": "9-Light Chestnut Copper Chandelier with Shaded Umber Glass",
        "modelNumber": "CLI-FRT2393-09-17",
        "productType": "MERCHANDISE",
        "imageId": "2a1ce035-bdca-4380-9376-a13a0da33064",
        "imageUrl": "https://images.homedepot-static.com/productImages/2a1ce035-bdca-4380-9376-a13a0da33064/svn/chestnut-talista-chandeliers-cli-frt2393-09-17-64_<SIZE>.jpg"
      },
      "storeSku": {
        "fulfillmentOptions": {
          "fulfillable": false,
          "outofstockOnline": true,
          "storeInventory": "NA",
          "backOrder": false
        },
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 249.99,
          "specialPrice": 249.99,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": false,
        "itemLevelFreeShip": false
      },
      "ratingsReviews": {
        "averageRating": "0.000000",
        "totalReviews": "0"
      }
    },
    {
      "productId": "203118247",
      "itemId": "203118247",
      "productUrl": "/p/OSPdesigns-Country-Cottage-Tan-End-Table-CC04/203118247",
      "availabilityType": "Online",
      "info": {
        "brandName": "OSPdesigns",
        "productLabel": "Country Cottage Tan End Table",
        "modelNumber": "CC04",
        "productType": "MERCHANDISE",
        "imageId": "f39e1726-ef1c-40e7-ad30-71aa17f78722",
        "imageUrl": "https://images.homedepot-static.com/productImages/f39e1726-ef1c-40e7-ad30-71aa17f78722/svn/tan-ospdesigns-end-tables-cc04-64_<SIZE>.jpg"
      },
      "storeSku": {
        "fulfillmentOptions": {
          "fulfillable": false,
          "outofstockOnline": true,
          "storeInventory": "NA",
          "backOrder": false
        },
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 53.57,
          "specialPrice": 53.57,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": true,
        "freeShippingMessage": "Free"
      },
      "ratingsReviews": {
        "averageRating": "5.0",
        "totalReviews": "1"
      }
    },
    {
      "productId": "203201468",
      "itemId": "203201468",
      "productUrl": "/p/Martha-Stewart-Living-36-in-H-Rhododendron-Leaf-Cart-With-Pull-Out-Trays-0795110600/203201468",
      "availabilityType": "Online",
      "info": {
        "brandName": "Martha Stewart Living",
        "productLabel": "36 in. H Rhododendron Leaf Cart With Pull Out Trays",
        "modelNumber": "0795110600",
        "productType": "MERCHANDISE",
        "imageId": "644b7e6f-a2bd-401d-96b8-54e46aabd5cc",
        "discontinuedItem": true,
        "imageUrl": "https://images.homedepot-static.com/productImages/644b7e6f-a2bd-401d-96b8-54e46aabd5cc/svn/rhododendron-leaf-martha-stewart-living-craft-storage-0795110600-64_<SIZE>.jpg"
      },
      "storeSku": {
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 309,
          "specialPrice": 309,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": false,
        "freeShippingThreshold": "$45",
        "freeShippingMessage": "Free"
      },
      "ratingsReviews": {
        "averageRating": "3.6667",
        "totalReviews": "3"
      }
    },
    {
      "productId": "203251331",
      "itemId": "203251331",
      "productUrl": "/p/Trademark-Fine-Art-35-in-x-35-in-Cupcake-Canvas-Art-WL027-C3535GG/203251331",
      "availabilityType": "Online",
      "info": {
        "brandName": "Trademark Fine Art",
        "productLabel": "35 in. x 35 in. Cupcake Canvas Art",
        "modelNumber": "WL027-C3535GG",
        "productType": "MERCHANDISE",
        "imageId": "45e158ad-06be-4c3c-9c57-c4ca969054d8",
        "imageUrl": "https://images.homedepot-static.com/productImages/45e158ad-06be-4c3c-9c57-c4ca969054d8/svn/multi-trademark-fine-art-canvas-art-wl027-c3535gg-64_<SIZE>.jpg"
      },
      "storeSku": {
        "fulfillmentOptions": {
          "fulfillable": true,
          "buyOnlineShipToStore": {
            "eligibilityStatus": true,
            "checkStoreLink": false
          },
          "shipToHome": {
            "eligibilityStatus": true
          },
          "storemessage": "BOSS",
          "storeInventory": "NA",
          "backOrder": false
        },
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 110.08,
          "specialPrice": 110.08,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": true,
        "freeShippingMessage": "Free"
      },
      "ratingsReviews": {
        "averageRating": "0.000000",
        "totalReviews": "0"
      }
    },
    {
      "productId": "203447807",
      "itemId": "203447807",
      "productUrl": "/p/MSI-Greecian-White-12-in-x-12-in-Polished-Beveled-Marble-Mesh-Mounted-Mosaic-Floor-and-Wall-Tile-GRE-2X4PB/203447807",
      "availabilityType": "Shared",
      "info": {
        "brandName": "MSI",
        "productLabel": "Greecian White 12 in. x 12 in. Polished Beveled Marble Mesh-Mounted Mosaic Floor and Wall Tile",
        "modelNumber": "GRE-2X4PB",
        "productType": "MERCHANDISE",
        "imageId": "1a8773be-3461-40b5-8679-fd64680ecc2b",
        "storeSkuNumber": "370612",
        "imageUrl": "https://images.homedepot-static.com/productImages/1a8773be-3461-40b5-8679-fd64680ecc2b/svn/white-msi-mosaic-tile-gre-2x4pb-64_<SIZE>.jpg"
      },
      "storeSku": {
        "fulfillmentOptions": {
          "fulfillable": true,
          "buyOnlinePickupInStore": {
            "eligibilityStatus": true,
            "checkStoreLink": false
          },
          "shipToHome": {
            "eligibilityStatus": true
          },
          "deliverFromStore": {
            "eligibilityStatus": true
          },
          "storemessage": "BOPIS",
          "checkinventory": true,
          "storeInventory": "In Stock",
          "storeStatus": 100,
          "deliveryMessage": {
            "sameDayDeliveryMessage": "Today"
          },
          "backOrder": false
        },
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 10.97,
          "specialPrice": 10.97,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        },
        "inventory": [
          {
            "store": "121",
            "sellableQty": 66,
            "qty": 69
          }
        ]
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": false,
        "freeShippingThreshold": "$45",
        "freeShippingMessage": "FREE with $45 Order"
      },
      "ratingsReviews": {
        "averageRating": "4.757",
        "totalReviews": "107"
      }
    },
    {
      "productId": "11111111",
      "itemId": "203529921",
      "productUrl": "/p/Dolle-12-in-x-12-in-x-3-4-in-Lite-Corner-Shelf-in-White-55404/203529921",
      "availabilityType": "Shared",
      "info": {
        "brandName": "Dolle",
        "productLabel": "12 in. x 12 in. x 3/4 in. Lite Corner Shelf in White",
        "modelNumber": "55404",
        "productType": "MERCHANDISE",
        "imageId": "57635da1-3667-494c-b15d-7eb3c69496a2",
        "storeSkuNumber": "251362",
        "totalNumberOfOptions": 4,
        "imageUrl": "https://images.homedepot-static.com/productImages/57635da1-3667-494c-b15d-7eb3c69496a2/svn/white-dolle-decorative-shelving-accessories-55404-64_<SIZE>.jpg",
        "swatches": [
          {
            "label": "White",
            "value": "581530d3-99b9-4748-9dd8-96c15f07edc3",
            "url": "/p/Dolle-12-in-x-12-in-x-3-4-in-Lite-Corner-Shelf-in-White-55404/203529921"
          },
          {
            "label": "Espresso",
            "value": "a2a13191-91f4-40f9-90c7-c19b8fb3cef0",
            "url": "/p/Dolle-12-in-x-12-in-x-3-4-in-Lite-Corner-Shelf-in-Espresso-55442/203530025"
          },
          {
            "label": "Cherry",
            "value": "b7cc227b-30a3-46a5-b2d2-f5b539c2a412",
            "url": "/p/Dolle-12-in-x-12-in-x-3-4-in-Lite-Corner-Shelf-in-Cherry-55435/203529923"
          },
          {
            "label": "Wood",
            "value": "d82a4aff-0e9f-4f30-9e5d-35500667df4b",
            "url": "/p/Dolle-12-in-x-12-in-x-3-4-in-Driftwood-Lite-Corner-Shelf-55473/204640367"
          }
        ]
      },
      "storeSku": {
        "fulfillmentOptions": {
          "fulfillable": true,
          "shipToHome": {
            "eligibilityStatus": true
          },
          "deliverFromStore": {
            "eligibilityStatus": true
          },
          "storeInventory": "NA",
          "storeStatus": 500,
          "deliveryMessage": {
            "sameDayDeliveryMessage": "Today"
          },
          "backOrder": false
        },
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 15.98,
          "specialPrice": 15.98,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        },
        "inventory": [
          {
            "store": "121",
            "sellableQty": 1,
            "qty": 2
          }
        ]
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": false,
        "freeShippingThreshold": "$45",
        "freeShippingMessage": "FREE with $45 Order"
      },
      "ratingsReviews": {
        "averageRating": "4.75",
        "totalReviews": "4"
      }
    },
    {
      "productId": "11111111",
      "itemId": "203131080",
      "productUrl": "/p/Home-Styles-Americana-White-Kitchen-Island-With-Seating-5002-948/203131080",
      "availabilityType": "Online",
      "info": {
        "brandName": "Home Styles",
        "productLabel": "Americana White Kitchen Island With Seating",
        "modelNumber": "5002-948",
        "productType": "MERCHANDISE",
        "imageId": "6876b606-06c9-40f5-9bfe-fc7985f56a84",
        "totalNumberOfOptions": 3,
        "collectionName": "Americana Collection in White and Oak",
        "collectionURL": "/collection/Decor/Americana-Collection-in-White-and-Oak/Family-305121957?omsid=203131080",
        "imageUrl": "https://images.homedepot-static.com/productImages/6876b606-06c9-40f5-9bfe-fc7985f56a84/svn/white-home-styles-kitchen-islands-5002-948-64_<SIZE>.jpg",
        "swatches": [
          {
            "label": "White",
            "value": "d4b0fdb0-d7c4-471b-98f2-a0e100be3c49",
            "url": "/p/Home-Styles-Americana-White-Kitchen-Island-With-Seating-5002-948/203131080"
          },
          {
            "label": "Wood",
            "value": "16424dfa-70e9-457a-9b6c-07464d7d0952",
            "url": "/p/Home-Styles-Americana-Distressed-Cottage-Oak-Kitchen-Island-With-Seating-5004-948/203131079"
          },
          {
            "label": "Black",
            "value": "d5abaeb6-1b29-44d7-9bdc-132dfd1d15ca",
            "url": "/p/Home-Styles-Americana-Black-Kitchen-Island-With-Seating-5003-948/203131078"
          }
        ],
        "assetCount": "4",
        "collectionType": "Integrated Brand Template"
      },
      "storeSku": {
        "fulfillmentOptions": {
          "fulfillable": true,
          "buyOnlineShipToStore": {
            "eligibilityStatus": true,
            "checkStoreLink": false
          },
          "shipToHome": {
            "eligibilityStatus": true
          },
          "storemessage": "BOSS",
          "storeInventory": "NA",
          "backOrder": false
        },
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 965,
          "specialPrice": 965,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": true,
        "freeShippingMessage": "Free"
      },
      "ratingsReviews": {
        "averageRating": "4.2552",
        "totalReviews": "145"
      }
    },
    {
      "productId": "203611742",
      "itemId": "203611742",
      "productUrl": "/p/Home-Styles-Monarch-White-Kitchen-Island-With-Drop-Leaf-5020-94/203611742",
      "availabilityType": "Online",
      "info": {
        "brandName": "Home Styles",
        "productLabel": "Monarch White Kitchen Island With Drop Leaf",
        "modelNumber": "5020-94",
        "productType": "MERCHANDISE",
        "imageId": "e4b021ce-b0c7-4b13-984c-86540f8d1fb0",
        "collectionName": "Monarch Collection in White Oak",
        "collectionURL": "/collection/Decor/Monarch-Collection-in-White-Oak/Family-305122051?omsid=203611742",
        "imageUrl": "https://images.homedepot-static.com/productImages/e4b021ce-b0c7-4b13-984c-86540f8d1fb0/svn/white-home-styles-kitchen-islands-5020-94-64_<SIZE>.jpg",
        "assetCount": "3",
        "collectionType": "Integrated Brand Template"
      },
      "storeSku": {
        "fulfillmentOptions": {
          "fulfillable": true,
          "buyOnlineShipToStore": {
            "eligibilityStatus": true,
            "checkStoreLink": false
          },
          "shipToHome": {
            "eligibilityStatus": true
          },
          "storemessage": "BOSS",
          "storeInventory": "NA",
          "backOrder": false
        },
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 710.7,
          "specialPrice": 710.7,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": true,
        "freeShippingMessage": "Free"
      },
      "ratingsReviews": {
        "averageRating": "4.5417",
        "totalReviews": "24"
      }
    },
    {
      "productId": "204030737",
      "itemId": "204030737",
      "productUrl": "/p/Lipper-International-9-75-in-x-15-5-in-x-9-5-in-Bamboo-Bread-Box-with-Window-Door-8847/204030737",
      "availabilityType": "Online",
      "info": {
        "brandName": "Lipper International",
        "productLabel": "9.75 in. x 15.5 in. x 9.5 in. Bamboo Bread Box with Window Door",
        "modelNumber": "8847",
        "productType": "MERCHANDISE",
        "imageId": "54f575b2-9b3b-46f4-bcc2-5fb505fff210",
        "imageUrl": "https://images.homedepot-static.com/productImages/54f575b2-9b3b-46f4-bcc2-5fb505fff210/svn/brown-lipper-international-bread-boxes-8847-64_<SIZE>.jpg"
      },
      "storeSku": {
        "fulfillmentOptions": {
          "fulfillable": true,
          "buyOnlineShipToStore": {
            "eligibilityStatus": true,
            "checkStoreLink": false
          },
          "shipToHome": {
            "eligibilityStatus": true
          },
          "storemessage": "BOSS",
          "storeInventory": "NA",
          "backOrder": false
        },
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 30.68,
          "specialPrice": 30.68,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": false,
        "freeShippingThreshold": "$45",
        "freeShippingMessage": "FREE with $45 Order"
      },
      "ratingsReviews": {
        "averageRating": "4.1875",
        "totalReviews": "16"
      }
    },
    {
      "productId": "204151480",
      "itemId": "204151480",
      "productUrl": "/p/KitchenAid-Professional-600-Series-6-Qt-Pink-Stand-Mixer-KP26M1XPK/204151480",
      "availabilityType": "Online",
      "info": {
        "brandName": "KitchenAid",
        "productLabel": "Professional 600 Series 6 Qt. Pink Stand Mixer",
        "modelNumber": "KP26M1XPK",
        "productType": "MERCHANDISE",
        "imageId": "0ea0cf68-4a63-4c59-a499-498786ebc408",
        "discontinuedItem": true,
        "imageUrl": "https://images.homedepot-static.com/productImages/0ea0cf68-4a63-4c59-a499-498786ebc408/svn/pink-kitchenaid-stand-mixers-kp26m1xpk-64_<SIZE>.jpg"
      },
      "storeSku": {
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 449.99,
          "specialPrice": 449.99,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": true,
        "freeShippingMessage": "Free"
      },
      "ratingsReviews": {
        "averageRating": "4.6989",
        "totalReviews": "4616"
      }
    },
    {
      "productId": "204326522",
      "itemId": "204326522",
      "productUrl": "/p/Carolina-Cottage-Vera-Ivory-Wood-Dining-Chair-575-AP/204326522",
      "availabilityType": "Online",
      "info": {
        "brandName": "Carolina Cottage",
        "productLabel": "Vera Ivory Wood Dining Chair",
        "modelNumber": "575-AP",
        "productType": "MERCHANDISE",
        "imageId": "099a002e-7f07-482f-a9c3-77dc1bddf5ec",
        "discontinuedItem": true,
        "imageUrl": "https://images.homedepot-static.com/productImages/099a002e-7f07-482f-a9c3-77dc1bddf5ec/svn/ivory-carolina-cottage-dining-chairs-575-ap-64_<SIZE>.jpg"
      },
      "storeSku": {
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 90.51,
          "specialPrice": 90.51,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": true,
        "freeShippingMessage": "Free"
      },
      "ratingsReviews": {
        "averageRating": "5.0",
        "totalReviews": "2"
      }
    },
    {
      "productId": "204469510",
      "itemId": "204469510",
      "productUrl": "/p/Filament-Design-Xavier-2-Light-Pink-Sconce-CLI-BIAW40/204469510",
      "availabilityType": "Online",
      "info": {
        "brandName": "Filament Design",
        "productLabel": "Xavier 2-Light Pink Sconce",
        "modelNumber": "CLI-BIAW40",
        "productType": "MERCHANDISE",
        "imageId": "45b9cdce-d013-4d22-8bbd-20fa1217fa24",
        "discontinuedItem": true,
        "imageUrl": "https://images.homedepot-static.com/productImages/45b9cdce-d013-4d22-8bbd-20fa1217fa24/svn/pink-filament-design-sconces-cli-biaw40-64_<SIZE>.jpg"
      },
      "storeSku": {
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 225,
          "specialPrice": 225,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": true,
        "freeShippingMessage": "Free"
      },
      "ratingsReviews": {
        "averageRating": "0.000000",
        "totalReviews": "0"
      }
    },
    {
      "productId": "204495482",
      "itemId": "204495482",
      "productUrl": "/p/Filament-Design-Prospect-30-in-x-36-25-in-Antique-White-Distressed-Chest-04259/204495482",
      "availabilityType": "Online",
      "info": {
        "brandName": "Filament Design",
        "productLabel": "Prospect 30 in. x 36.25 in. Antique White Distressed Chest",
        "modelNumber": "04259",
        "productType": "MERCHANDISE",
        "imageId": "674bca8c-efd8-4e3d-9677-bcea6b97a2ad",
        "discontinuedItem": true,
        "imageUrl": "https://images.homedepot-static.com/productImages/674bca8c-efd8-4e3d-9677-bcea6b97a2ad/svn/whites-filament-design-office-storage-cabinets-04259-64_<SIZE>.jpg"
      },
      "storeSku": {
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 835,
          "specialPrice": 835,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": false,
        "itemLevelFreeShip": false
      },
      "ratingsReviews": {
        "averageRating": "0.000000",
        "totalReviews": "0"
      }
    },
    {
      "productId": "204499347",
      "itemId": "204499347",
      "productUrl": "/p/Sunbeam-Heritage-4-6-Qt-Offset-Bowl-Pink-Stand-Mixer-FPSBSM210P/204499347",
      "availabilityType": "Online",
      "info": {
        "brandName": "Sunbeam",
        "productLabel": "Heritage 4.6 Qt Offset Bowl Pink Stand Mixer",
        "modelNumber": "FPSBSM210P",
        "productType": "MERCHANDISE",
        "imageId": "d7b32ae0-6e73-4c5a-8522-90e8b8d06a48",
        "discontinuedItem": true,
        "imageUrl": "https://images.homedepot-static.com/productImages/d7b32ae0-6e73-4c5a-8522-90e8b8d06a48/svn/pink-sunbeam-stand-mixers-fpsbsm210p-64_<SIZE>.jpg"
      },
      "storeSku": {
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 129.5,
          "specialPrice": 129.5,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": true,
        "freeShippingMessage": "Free"
      },
      "ratingsReviews": {
        "averageRating": "3.6941",
        "totalReviews": "170"
      }
    },
    {
      "productId": "204640295",
      "itemId": "204640295",
      "productUrl": "/p/Dolle-31-1-2-in-x-12-in-x-3-4-in-Driftwood-Lite-Shelf-55350/204640295",
      "availabilityType": "Shared",
      "info": {
        "brandName": "Dolle",
        "productLabel": "31-1/2 in. x 12 in. x 3/4 in. Driftwood Lite Shelf",
        "modelNumber": "55350",
        "productType": "MERCHANDISE",
        "imageId": "64630c04-2c15-4154-b4f0-6ddb7b4f3787",
        "storeSkuNumber": "1000032955",
        "imageUrl": "https://images.homedepot-static.com/productImages/64630c04-2c15-4154-b4f0-6ddb7b4f3787/svn/driftwood-dolle-bathroom-shelves-55350-64_<SIZE>.jpg"
      },
      "storeSku": {
        "fulfillmentOptions": {
          "fulfillable": true,
          "shipToHome": {
            "eligibilityStatus": true
          },
          "deliverFromStore": {
            "eligibilityStatus": true
          },
          "storeInventory": "NA",
          "storeStatus": 500,
          "deliveryMessage": {
            "sameDayDeliveryMessage": "Today"
          },
          "backOrder": false
        },
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 13.98,
          "specialPrice": 13.98,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        },
        "inventory": [
          {
            "store": "121",
            "sellableQty": 6,
            "qty": 7
          }
        ]
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": false,
        "freeShippingThreshold": "$45",
        "freeShippingMessage": "FREE with $45 Order"
      },
      "ratingsReviews": {
        "averageRating": "3.0",
        "totalReviews": "2"
      }
    },
    {
      "productId": "204688252",
      "itemId": "204688252",
      "productUrl": "/p/Nearly-Natural-Blooming-Roses-with-Vase-in-Light-Pink-1328-LP/204688252",
      "availabilityType": "Online",
      "info": {
        "brandName": "Nearly Natural",
        "productLabel": "Blooming Roses with Vase in Light Pink",
        "modelNumber": "1328-LP",
        "productType": "MERCHANDISE",
        "imageId": "a0943cf9-7bf0-4428-85f5-c4d89e3b2e44",
        "imageUrl": "https://images.homedepot-static.com/productImages/a0943cf9-7bf0-4428-85f5-c4d89e3b2e44/svn/nearly-natural-artificial-flowers-1328-lp-64_<SIZE>.jpg"
      },
      "storeSku": {
        "fulfillmentOptions": {
          "fulfillable": true,
          "buyOnlineShipToStore": {
            "eligibilityStatus": true,
            "checkStoreLink": false
          },
          "shipToHome": {
            "eligibilityStatus": true
          },
          "storemessage": "BOSS",
          "storeInventory": "NA",
          "backOrder": false
        },
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 45.26,
          "specialPrice": 45.26,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": true,
        "freeShippingMessage": "Free"
      },
      "ratingsReviews": {
        "averageRating": "0.000000",
        "totalReviews": "0"
      }
    },
    {
      "productId": "204729156",
      "itemId": "204729156",
      "productUrl": "/p/KOHLER-Artifacts-Ceramic-Tray-in-White-K-98629-NA/204729156",
      "availabilityType": "Online",
      "info": {
        "brandName": "KOHLER",
        "productLabel": "Artifacts Ceramic Tray in White",
        "modelNumber": "K-98629-NA",
        "productType": "MERCHANDISE",
        "imageId": "16694249-7ee5-497c-b733-4940614afec0",
        "imageUrl": "https://images.homedepot-static.com/productImages/16694249-7ee5-497c-b733-4940614afec0/svn/white-kohler-soap-dishes-k-98629-na-64_<SIZE>.jpg"
      },
      "storeSku": {
        "fulfillmentOptions": {
          "fulfillable": true,
          "buyOnlineShipToStore": {
            "eligibilityStatus": true,
            "checkStoreLink": false
          },
          "shipToHome": {
            "eligibilityStatus": true
          },
          "storemessage": "BOSS",
          "storeInventory": "NA",
          "backOrder": false
        },
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 31.54,
          "specialPrice": 31.54,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": false,
        "freeShippingThreshold": "$45",
        "freeShippingMessage": "FREE with $45 Order"
      },
      "ratingsReviews": {
        "averageRating": "5.0",
        "totalReviews": "1"
      }
    },
    {
      "productId": "204757710",
      "itemId": "204757710",
      "productUrl": "/p/International-Concepts-Unfinished-Wood-X-Back-Dining-Chair-Set-of-2-C-14P/204757710",
      "availabilityType": "Online",
      "info": {
        "brandName": "International Concepts",
        "productLabel": "Unfinished Wood X-Back Dining Chair (Set of 2)",
        "modelNumber": "C-14P",
        "productType": "MERCHANDISE",
        "imageId": "80170129-9675-4ef7-95cc-fe70643a1f3e",
        "imageUrl": "https://images.homedepot-static.com/productImages/80170129-9675-4ef7-95cc-fe70643a1f3e/svn/unfinished-international-concepts-dining-chairs-c-14p-64_<SIZE>.jpg"
      },
      "storeSku": {
        "fulfillmentOptions": {
          "fulfillable": true,
          "buyOnlineShipToStore": {
            "eligibilityStatus": true,
            "checkStoreLink": false
          },
          "shipToHome": {
            "eligibilityStatus": true
          },
          "storemessage": "BOSS",
          "storeInventory": "NA",
          "backOrder": false
        },
        "pricing": {
          "uom": "pair",
          "unitsPerCase": "0.0",
          "originalPrice": 186.99,
          "specialPrice": 186.99,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": true,
        "freeShippingMessage": "Free"
      },
      "ratingsReviews": {
        "averageRating": "0.000000",
        "totalReviews": "0"
      }
    },
    {
      "productId": "204851567",
      "itemId": "204851567",
      "productUrl": "/p/Yosemite-Home-Decor-16-in-Circular-Iron-Wall-Clock-in-Distressed-White-Frame-CLKA1B1068/204851567",
      "availabilityType": "Online",
      "info": {
        "brandName": "Yosemite Home Decor",
        "productLabel": "16 in. Circular Iron Wall Clock in Distressed White Frame",
        "modelNumber": "CLKA1B1068",
        "productType": "MERCHANDISE",
        "imageId": "05dc3f12-bce5-40a4-99c7-7de9bde6e646",
        "imageUrl": "https://images.homedepot-static.com/productImages/05dc3f12-bce5-40a4-99c7-7de9bde6e646/svn/whites-yosemite-home-decor-wall-clocks-clka1b1068-64_<SIZE>.jpg"
      },
      "storeSku": {
        "fulfillmentOptions": {
          "fulfillable": false,
          "outofstockOnline": true,
          "storeInventory": "NA",
          "backOrder": false
        },
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 41.95,
          "specialPrice": 41.95,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": false,
        "freeShippingThreshold": "$45",
        "freeShippingMessage": "FREE with $45 Order"
      },
      "ratingsReviews": {
        "averageRating": "3.5",
        "totalReviews": "2"
      }
    },
    {
      "productId": "205029610",
      "itemId": "205029610",
      "productUrl": "/p/Kids-White-Wood-Armless-Chair-1626900410/205029610",
      "availabilityType": "Online",
      "info": {
        "productLabel": "Kids White Wood Armless Chair",
        "modelNumber": "1626900410",
        "productType": "MERCHANDISE",
        "imageId": "8214646d-40ba-49c3-8d79-05fb7e67f3a6",
        "discontinuedItem": true,
        "imageUrl": "https://images.homedepot-static.com/productImages/8214646d-40ba-49c3-8d79-05fb7e67f3a6/svn/white-kids-desks-chairs-1626900410-64_<SIZE>.jpg"
      },
      "storeSku": {
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 69,
          "specialPrice": 69,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": true,
        "freeShippingMessage": "Free"
      },
      "ratingsReviews": {
        "averageRating": "0.000000",
        "totalReviews": "0"
      }
    },
    {
      "productId": "11111111",
      "itemId": "205144093",
      "productUrl": "/p/Rachael-Ray-Cucina-Dinnerware-16-Piece-Stoneware-Dinnerware-Set-in-Almond-Cream-55094/205144093",
      "availabilityType": "Online",
      "info": {
        "brandName": "Rachael Ray",
        "productLabel": "Cucina Dinnerware 16-Piece Stoneware Dinnerware Set in Almond Cream",
        "modelNumber": "55094",
        "productType": "MERCHANDISE",
        "imageId": "47f85265-ad03-41d5-9eca-eea457be4fab",
        "totalNumberOfOptions": 5,
        "imageUrl": "https://images.homedepot-static.com/productImages/47f85265-ad03-41d5-9eca-eea457be4fab/svn/beige-rachael-ray-dinnerware-sets-55094-64_<SIZE>.jpg",
        "swatches": [
          {
            "label": "Beige",
            "value": "f84e7bbf-5e1c-4e99-93cc-be489d73e502",
            "url": "/p/Rachael-Ray-Cucina-Dinnerware-16-Piece-Stoneware-Dinnerware-Set-in-Almond-Cream-55094/205144093"
          },
          {
            "label": "Orange",
            "value": "83c0f236-aa65-497a-a045-e85bb14555b6",
            "url": "/p/Rachael-Ray-Cucina-Dinnerware-16-Piece-Stoneware-Dinnerware-Set-in-Pumpkin-Orange-55095/205144094"
          },
          {
            "label": "Purple",
            "value": "d3259f97-bf43-41b8-a06e-f2710d5a6ba0",
            "url": "/p/Rachael-Ray-Cucina-Dinnerware-16-Piece-Stoneware-Dinnerware-Set-in-Lavender-Purple-51502/206315881"
          },
          {
            "label": "Red",
            "value": "a8530876-ce59-4892-8deb-1f5bb727e65c",
            "url": "/p/Rachael-Ray-Cucina-Dinnerware-16-Piece-Stoneware-Dinnerware-Set-in-Cranberry-Red-55096/205144095"
          }
        ]
      },
      "storeSku": {
        "fulfillmentOptions": {
          "fulfillable": true,
          "buyOnlineShipToStore": {
            "eligibilityStatus": true,
            "checkStoreLink": false
          },
          "shipToHome": {
            "eligibilityStatus": true
          },
          "storemessage": "BOSS",
          "storeInventory": "NA",
          "backOrder": false
        },
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 69.97,
          "specialPrice": 69.97,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": false,
        "freeShippingThreshold": "$45",
        "freeShippingMessage": "Free"
      },
      "ratingsReviews": {
        "averageRating": "4.2273",
        "totalReviews": "22"
      }
    },
    {
      "productId": "205201206",
      "itemId": "205201206",
      "productUrl": "/p/Anchor-Hocking-1-gal-Heritage-Hill-Jar-69349T/205201206",
      "availabilityType": "Online",
      "info": {
        "brandName": "Anchor Hocking",
        "productLabel": "1 gal. Heritage Hill Jar",
        "modelNumber": "69349T",
        "productType": "MERCHANDISE",
        "imageId": "a67d67fc-711c-475c-8307-e2d6fb90ef14",
        "imageUrl": "https://images.homedepot-static.com/productImages/a67d67fc-711c-475c-8307-e2d6fb90ef14/svn/anchor-hocking-food-storage-containers-69349t-64_<SIZE>.jpg"
      },
      "storeSku": {
        "fulfillmentOptions": {
          "fulfillable": true,
          "buyOnlineShipToStore": {
            "eligibilityStatus": true,
            "checkStoreLink": false
          },
          "shipToHome": {
            "eligibilityStatus": true
          },
          "storemessage": "BOSS",
          "storeInventory": "NA",
          "backOrder": false
        },
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 10.99,
          "specialPrice": 10.99,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": false,
        "freeShippingThreshold": "$45",
        "freeShippingMessage": "FREE with $45 Order"
      },
      "ratingsReviews": {
        "averageRating": "1.0",
        "totalReviews": "1"
      }
    },
    {
      "productId": "205311642",
      "itemId": "205311642",
      "productUrl": "/p/Designers-Fountain-Bella-Maison-5-Light-Natural-Iron-Distressed-Ash-Chandelier-85385-DA/205311642",
      "availabilityType": "Online",
      "info": {
        "brandName": "Designers Fountain",
        "productLabel": "Bella Maison 5-Light Natural Iron Distressed Ash Chandelier",
        "modelNumber": "85385-DA",
        "productType": "MERCHANDISE",
        "imageId": "3c91a8c1-f956-4c6e-bc7d-984f610ea767",
        "discontinuedItem": true,
        "imageUrl": "https://images.homedepot-static.com/productImages/3c91a8c1-f956-4c6e-bc7d-984f610ea767/svn/natural-iron-designers-fountain-chandeliers-85385-da-64_<SIZE>.jpg"
      },
      "storeSku": {
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 337.9,
          "specialPrice": 337.9,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": true,
        "freeShippingMessage": "Free"
      },
      "ratingsReviews": {
        "averageRating": "0.000000",
        "totalReviews": "0"
      }
    },
    {
      "productId": "205450183",
      "itemId": "205450183",
      "productUrl": "/p/Cake-Boss-Serveware-Metal-Cream-Cake-Carrier-with-Icing-59733/205450183",
      "availabilityType": "Online",
      "info": {
        "brandName": "Cake Boss",
        "productLabel": "Serveware Metal Cream Cake Carrier with Icing",
        "modelNumber": "59733",
        "productType": "MERCHANDISE",
        "imageId": "98796f22-efdf-475a-99f4-d153a0bb6b57",
        "imageUrl": "https://images.homedepot-static.com/productImages/98796f22-efdf-475a-99f4-d153a0bb6b57/svn/cake-boss-bakeware-carriers-59733-64_<SIZE>.jpg"
      },
      "storeSku": {
        "fulfillmentOptions": {
          "fulfillable": true,
          "buyOnlineShipToStore": {
            "eligibilityStatus": true,
            "checkStoreLink": false
          },
          "shipToHome": {
            "eligibilityStatus": true
          },
          "storemessage": "BOSS",
          "storeInventory": "NA",
          "backOrder": false
        },
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 39.99,
          "specialPrice": 39.99,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": false,
        "freeShippingThreshold": "$45",
        "freeShippingMessage": "FREE with $45 Order"
      },
      "ratingsReviews": {
        "averageRating": "5.0",
        "totalReviews": "3"
      }
    },
    {
      "productId": "205450588",
      "itemId": "205450588",
      "productUrl": "/p/BonJour-Dinnerware-Meadow-Rooster-Stoneware-10-in-Round-Serving-Bowl-50180/205450588",
      "availabilityType": "Online",
      "info": {
        "brandName": "BonJour",
        "productLabel": "Dinnerware Meadow Rooster Stoneware 10 in. Round Serving Bowl",
        "modelNumber": "50180",
        "productType": "MERCHANDISE",
        "imageId": "e36afc35-b11d-437d-9ff5-104784846176",
        "imageUrl": "https://images.homedepot-static.com/productImages/e36afc35-b11d-437d-9ff5-104784846176/svn/white-bonjour-serving-bowls-50180-64_<SIZE>.jpg"
      },
      "storeSku": {
        "fulfillmentOptions": {
          "fulfillable": true,
          "buyOnlineShipToStore": {
            "eligibilityStatus": true,
            "checkStoreLink": false
          },
          "shipToHome": {
            "eligibilityStatus": true
          },
          "storemessage": "BOSS",
          "storeInventory": "NA",
          "backOrder": false
        },
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 19.99,
          "specialPrice": 19.99,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": false,
        "freeShippingThreshold": "$45",
        "freeShippingMessage": "FREE with $45 Order"
      },
      "ratingsReviews": {
        "averageRating": "0.000000",
        "totalReviews": "0"
      }
    },
    {
      "productId": "205486841",
      "itemId": "205486841",
      "productUrl": "/p/Tribest-Mason-Jar-Personal-Blender-PB-350-A/205486841",
      "availabilityType": "Online",
      "info": {
        "brandName": "Tribest",
        "productLabel": "Mason Jar Personal Blender",
        "modelNumber": "PB-350-A",
        "productType": "MERCHANDISE",
        "imageId": "96607bf1-67a4-4ea4-82a2-429474231d48",
        "imageUrl": "https://images.homedepot-static.com/productImages/96607bf1-67a4-4ea4-82a2-429474231d48/svn/cream-tribest-countertop-blenders-pb-350-a-64_<SIZE>.jpg"
      },
      "storeSku": {
        "fulfillmentOptions": {
          "fulfillable": true,
          "buyOnlineShipToStore": {
            "eligibilityStatus": true,
            "checkStoreLink": false
          },
          "shipToHome": {
            "eligibilityStatus": true
          },
          "storemessage": "BOSS",
          "storeInventory": "NA",
          "backOrder": false
        },
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 87.95,
          "specialPrice": 87.95,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": true,
        "freeShippingMessage": "Free"
      },
      "ratingsReviews": {
        "averageRating": "5.0",
        "totalReviews": "11"
      }
    },
    {
      "productId": "205532153",
      "itemId": "205532153",
      "productUrl": "/p/Safavieh-Kathleen-27-in-Square-Clock-in-Distressed-Antique-White-AMH4068A/205532153",
      "availabilityType": "Online",
      "info": {
        "brandName": "Safavieh",
        "productLabel": "Kathleen 27 in. Square Clock in Distressed Antique White",
        "modelNumber": "AMH4068A",
        "productType": "MERCHANDISE",
        "imageId": "46eb9a91-59e2-40bb-87d3-0a0d1732c1bb",
        "imageUrl": "https://images.homedepot-static.com/productImages/46eb9a91-59e2-40bb-87d3-0a0d1732c1bb/svn/beige-cream-safavieh-wall-clocks-amh4068a-64_<SIZE>.jpg"
      },
      "storeSku": {
        "fulfillmentOptions": {
          "fulfillable": true,
          "buyOnlineShipToStore": {
            "eligibilityStatus": true,
            "checkStoreLink": false
          },
          "shipToHome": {
            "eligibilityStatus": true
          },
          "storemessage": "BOSS",
          "storeInventory": "NA",
          "backOrder": false
        },
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 93.53,
          "specialPrice": 93.53,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": true,
        "freeShippingMessage": "Free"
      },
      "ratingsReviews": {
        "averageRating": "0.000000",
        "totalReviews": "0"
      }
    },
    {
      "productId": "205706250",
      "itemId": "205706250",
      "productUrl": "/p/York-Wallcoverings-American-Classics-Distressed-Damask-Wallpaper-AM8768/205706250",
      "availabilityType": "Online",
      "info": {
        "brandName": "York Wallcoverings",
        "productLabel": "American Classics Distressed Damask Wallpaper",
        "modelNumber": "AM8768",
        "productType": "MERCHANDISE",
        "imageId": "26ca0586-3180-4193-879a-797a4af7976a",
        "discontinuedItem": true,
        "imageUrl": "https://images.homedepot-static.com/productImages/26ca0586-3180-4193-879a-797a4af7976a/svn/york-wallcoverings-wallpaper-am8768-64_<SIZE>.jpg"
      },
      "storeSku": {
        "pricing": {
          "uom": "each",
          "unitsPerCase": "56.00",
          "caseUnitUom": "sq. ft.",
          "originalPrice": 89.98,
          "specialPrice": 89.98,
          "alternatePriceDisplay": true,
          "lowerPrice": false,
          "unitOriginalPrice": "1.61",
          "unitSpecialPrice": "1.61",
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": true,
        "freeShippingMessage": "Free"
      },
      "ratingsReviews": {
        "averageRating": "0.000000",
        "totalReviews": "0"
      }
    },
    {
      "productId": "205829385",
      "itemId": "205829385",
      "productUrl": "/p/Nearly-Natural-Blooming-Hydrangea-with-Vase-1356-CR/205829385",
      "availabilityType": "Online",
      "info": {
        "brandName": "Nearly Natural",
        "productLabel": "Blooming Hydrangea with Vase",
        "modelNumber": "1356-CR",
        "productType": "MERCHANDISE",
        "imageId": "f385d103-adec-42ef-80ab-40afae68c706",
        "collectionName": "Blooming Hydrangea Collection in White",
        "collectionURL": "/collection/Decor/Blooming-Hydrangea-Collection-in-White/Family-305644079?omsid=205829385",
        "imageUrl": "https://images.homedepot-static.com/productImages/f385d103-adec-42ef-80ab-40afae68c706/svn/nearly-natural-artificial-flowers-1356-cr-64_<SIZE>.jpg",
        "assetCount": "2",
        "collectionType": "Integrated Brand Template"
      },
      "storeSku": {
        "fulfillmentOptions": {
          "fulfillable": false,
          "outofstockOnline": true,
          "storeInventory": "NA",
          "backOrder": false
        },
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 42.09,
          "specialPrice": 42.09,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": false,
        "freeShippingThreshold": "$45",
        "freeShippingMessage": "FREE with $45 Order"
      },
      "ratingsReviews": {
        "averageRating": "0.000000",
        "totalReviews": "0"
      }
    },
    {
      "productId": "11111111",
      "itemId": "206165702",
      "productUrl": "/p/Kaleen-Helena-Mint-2-ft-x-3-ft-Area-Rug-3207-88-23/206165702",
      "availabilityType": "Online",
      "info": {
        "brandName": "Kaleen",
        "productLabel": "Helena Mint 2 ft. x 3 ft. Area Rug",
        "modelNumber": "3207-88 23",
        "productType": "MERCHANDISE",
        "imageId": "7983e90f-5785-4e3c-98b0-f8bf981c736f",
        "totalNumberOfOptions": 21,
        "imageUrl": "https://images.homedepot-static.com/productImages/7983e90f-5785-4e3c-98b0-f8bf981c736f/svn/mint-kaleen-area-rugs-3207-88-23-64_<SIZE>.jpg",
        "swatches": [
          {
            "label": "Mint",
            "value": "1fff1f45-590b-42c0-906c-c30abf1573f1",
            "url": "/p/Kaleen-Helena-Mint-2-ft-x-3-ft-Area-Rug-3207-88-23/206165702"
          },
          {
            "label": "Light Brown",
            "value": "9c36046b-f148-4f87-98d9-04c6ef4c8e8b",
            "url": "/p/Kaleen-Helena-Light-Brown-2-ft-x-3-ft-Area-Rug-3207-82-23/206165696"
          },
          {
            "label": "Graphite",
            "value": "30b600dd-318a-4969-941d-bb37e355b807",
            "url": "/p/Kaleen-Helena-Graphite-2-ft-x-3-ft-Area-Rug-3207-68-23/206165690"
          },
          {
            "label": "Lt. Brown",
            "value": "9c36046b-f148-4f87-98d9-04c6ef4c8e8b",
            "url": "/p/Kaleen-Helena-Light-Brown-3-ft-x-8-ft-Runner-Rug-3207-82-268/206345222"
          }
        ]
      },
      "storeSku": {
        "fulfillmentOptions": {
          "fulfillable": false,
          "outofstockOnline": true,
          "storeInventory": "NA",
          "backOrder": false
        },
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 63.59,
          "specialPrice": 63.59,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0,
          "pricingMessage": "see final price in cart",
          "mapAboveOriginalPrice": true
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": true,
        "freeShippingMessage": "Free"
      },
      "ratingsReviews": {
        "averageRating": "2.0",
        "totalReviews": "2"
      }
    },
    {
      "productId": "11111111",
      "itemId": "206165759",
      "productUrl": "/p/Kaleen-Helena-Mint-8-ft-x-10-ft-Area-Rug-3212-88-810/206165759",
      "availabilityType": "Online",
      "info": {
        "brandName": "Kaleen",
        "productLabel": "Helena Mint 8 ft. x 10 ft. Area Rug",
        "modelNumber": "3212-88 810",
        "productType": "MERCHANDISE",
        "imageId": "fca19894-e3fd-4f08-9483-ab5e43cc9b8f",
        "totalNumberOfOptions": 21,
        "imageUrl": "https://images.homedepot-static.com/productImages/fca19894-e3fd-4f08-9483-ab5e43cc9b8f/svn/mint-kaleen-area-rugs-3212-88-810-64_<SIZE>.jpg",
        "swatches": [
          {
            "label": "Mint",
            "value": "f271311e-9a35-430f-bd85-1c48c3b48958",
            "url": "/p/Kaleen-Helena-Mint-8-ft-x-10-ft-Area-Rug-3212-88-810/206165759"
          },
          {
            "label": "Blue",
            "value": "f3c8a962-80d3-4387-afea-2c231f458e34",
            "url": "/p/Kaleen-Helena-Blue-3-ft-x-8-ft-Runner-Rug-3212-17-268/206345230"
          },
          {
            "label": "Sage",
            "value": "5faa6974-dbf0-4325-b98d-764efa818bfa",
            "url": "/p/Kaleen-Helena-Sage-3-ft-x-8-ft-Runner-Rug-3212-59-268/206345231"
          }
        ]
      },
      "storeSku": {
        "fulfillmentOptions": {
          "fulfillable": true,
          "buyOnlineShipToStore": {
            "eligibilityStatus": true,
            "checkStoreLink": false
          },
          "shipToHome": {
            "eligibilityStatus": true
          },
          "storemessage": "BOSS",
          "storeInventory": "NA",
          "backOrder": false
        },
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 667.65,
          "specialPrice": 667.65,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0,
          "pricingMessage": "see final price in cart",
          "mapAboveOriginalPrice": true
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": true,
        "freeShippingMessage": "Free"
      },
      "ratingsReviews": {
        "averageRating": "4.6667",
        "totalReviews": "3"
      }
    },
    {
      "productId": "206267892",
      "itemId": "206267892",
      "productUrl": "/p/Hampton-Bay-Galvanized-Metal-Serving-Caddy-14028/206267892",
      "availabilityType": "Online",
      "info": {
        "brandName": "Hampton Bay",
        "productLabel": "Galvanized Metal Serving Caddy",
        "modelNumber": "14028",
        "productType": "MERCHANDISE",
        "imageId": "d1e280ef-ffdc-4b00-b516-37c97767a702",
        "storeSkuNumber": "1001503900",
        "imageUrl": "https://images.homedepot-static.com/productImages/d1e280ef-ffdc-4b00-b516-37c97767a702/svn/hampton-bay-serving-carts-14028-64_<SIZE>.jpg"
      },
      "storeSku": {
        "fulfillmentOptions": {
          "fulfillable": false,
          "outofstockOnline": true,
          "storeInventory": "NA",
          "backOrder": false
        },
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 12.98,
          "specialPrice": 12.98,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": true,
        "freeShippingMessage": "Free"
      },
      "ratingsReviews": {
        "averageRating": "5.0",
        "totalReviews": "3"
      }
    },
    {
      "productId": "206315622",
      "itemId": "206315622",
      "productUrl": "/p/Artistic-Weavers-Volos-Mint-8-ft-x-10-ft-Indoor-Area-Rug-S00151022636/206315622",
      "availabilityType": "Online",
      "info": {
        "brandName": "Artistic Weavers",
        "productLabel": "Volos Mint 8 ft. x 10 ft. Indoor Area Rug",
        "modelNumber": "S00151022636",
        "productType": "MERCHANDISE",
        "imageId": "2797c859-2d98-4e47-83aa-e423be193595",
        "discontinuedItem": true,
        "imageUrl": "https://images.homedepot-static.com/productImages/2797c859-2d98-4e47-83aa-e423be193595/svn/mint-artistic-weavers-area-rugs-s00151022636-64_<SIZE>.jpg"
      },
      "storeSku": {
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 577.26,
          "specialPrice": 577.26,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": true,
        "freeShippingMessage": "Free"
      },
      "ratingsReviews": {
        "averageRating": "5.0",
        "totalReviews": "1"
      }
    },
    {
      "productId": "11111111",
      "itemId": "206345466",
      "productUrl": "/p/Kaleen-Helena-Mint-3-ft-x-8-ft-Runner-Rug-3212-88-268/206345466",
      "availabilityType": "Online",
      "info": {
        "brandName": "Kaleen",
        "productLabel": "Helena Mint 3 ft. x 8 ft. Runner Rug",
        "modelNumber": "3212-88 268",
        "productType": "MERCHANDISE",
        "imageId": "e625015a-bead-4751-ac5f-15aa44d29cb0",
        "totalNumberOfOptions": 21,
        "imageUrl": "https://images.homedepot-static.com/productImages/e625015a-bead-4751-ac5f-15aa44d29cb0/svn/mint-kaleen-area-rugs-3212-88-268-64_<SIZE>.jpg",
        "swatches": [
          {
            "label": "Mint",
            "value": "e9336892-8676-44f6-ace4-8613249cc4a9",
            "url": "/p/Kaleen-Helena-Mint-3-ft-x-8-ft-Runner-Rug-3212-88-268/206345466"
          },
          {
            "label": "Blue",
            "value": "f3c8a962-80d3-4387-afea-2c231f458e34",
            "url": "/p/Kaleen-Helena-Blue-3-ft-x-8-ft-Runner-Rug-3212-17-268/206345230"
          },
          {
            "label": "Sage",
            "value": "5faa6974-dbf0-4325-b98d-764efa818bfa",
            "url": "/p/Kaleen-Helena-Sage-3-ft-x-8-ft-Runner-Rug-3212-59-268/206345231"
          }
        ]
      },
      "storeSku": {
        "fulfillmentOptions": {
          "fulfillable": true,
          "buyOnlineShipToStore": {
            "eligibilityStatus": true,
            "checkStoreLink": false
          },
          "shipToHome": {
            "eligibilityStatus": true
          },
          "storemessage": "BOSS",
          "storeInventory": "NA",
          "backOrder": false
        },
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 186.92,
          "specialPrice": 186.92,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0,
          "pricingMessage": "see final price in cart",
          "mapAboveOriginalPrice": true
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": true,
        "freeShippingMessage": "Free"
      },
      "ratingsReviews": {
        "averageRating": "4.6667",
        "totalReviews": "3"
      }
    },
    {
      "productId": "206430205",
      "itemId": "206430205",
      "productUrl": "/p/Nostalgia-Electrics-Retro-Cake-Pop-and-Donut-Hole-Maker-RCPM900/206430205",
      "availabilityType": "Online",
      "info": {
        "brandName": "Nostalgia Electrics",
        "productLabel": "Retro Cake Pop and Donut Hole Maker",
        "modelNumber": "RCPM900",
        "productType": "MERCHANDISE",
        "imageId": "034de8a8-d6f5-4330-bc32-805825fff8af",
        "discontinuedItem": true,
        "imageUrl": "https://images.homedepot-static.com/productImages/034de8a8-d6f5-4330-bc32-805825fff8af/svn/red-nostalgia-electrics-specialty-dessert-makers-rcpm900-64_<SIZE>.jpg"
      },
      "storeSku": {
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 26.24,
          "specialPrice": 26.24,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": false,
        "freeShippingThreshold": "$45",
        "freeShippingMessage": "FREE with $45 Order"
      },
      "ratingsReviews": {
        "averageRating": "5.0",
        "totalReviews": "2"
      }
    },
    {
      "productId": "206598499",
      "itemId": "206598499",
      "productUrl": "/p/SINKOLOGY-Wright-Copper-Kitchen-Sink-Bottom-Grid-Heavy-Duty-Vinyl-Coated-in-Antique-Brown-SG001-33/206598499",
      "availabilityType": "Online",
      "info": {
        "brandName": "SINKOLOGY",
        "productLabel": "Wright Copper Kitchen Sink Bottom Grid Heavy Duty Vinyl Coated in Antique Brown",
        "modelNumber": "SG001-33",
        "productType": "MERCHANDISE",
        "imageId": "0279446b-f168-494e-b996-72a7d51f9225",
        "imageUrl": "https://images.homedepot-static.com/productImages/0279446b-f168-494e-b996-72a7d51f9225/svn/sinkology-kitchen-sink-organizers-sg001-33-64_<SIZE>.jpg"
      },
      "storeSku": {
        "fulfillmentOptions": {
          "fulfillable": true,
          "buyOnlineShipToStore": {
            "eligibilityStatus": true,
            "checkStoreLink": false
          },
          "shipToHome": {
            "eligibilityStatus": true
          },
          "storemessage": "BOSS",
          "storeInventory": "NA",
          "backOrder": false
        },
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 99.49,
          "specialPrice": 99.49,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": true,
        "freeShippingMessage": "Free"
      },
      "ratingsReviews": {
        "averageRating": "4.6857",
        "totalReviews": "35"
      }
    },
    {
      "productId": "11111111",
      "itemId": "206868821",
      "productUrl": "/p/Safavieh-Hooper-Blue-White-Patio-Dining-Chair-2-Pack-FOX5209A-SET2/206868821",
      "availabilityType": "Online",
      "info": {
        "brandName": "Safavieh",
        "productLabel": "Hooper Blue & White Patio Dining Chair (2-Pack)",
        "modelNumber": "FOX5209A-SET2",
        "productType": "MERCHANDISE",
        "imageId": "0b2d0517-e7f9-4991-b42c-2721becee259",
        "totalNumberOfOptions": 4,
        "imageUrl": "https://images.homedepot-static.com/productImages/0b2d0517-e7f9-4991-b42c-2721becee259/svn/safavieh-outdoor-dining-chairs-fox5209a-set2-64_<SIZE>.jpg",
        "swatches": [
          {
            "label": "Blue/White",
            "value": "1e8a240f-d548-4402-81c9-7140f44c4480",
            "url": "/p/Safavieh-Hooper-Blue-White-Patio-Dining-Chair-2-Pack-FOX5209A-SET2/206868821"
          },
          {
            "label": "Black/White",
            "value": "3f93d242-c80d-4dd9-8099-2decdac041dc",
            "url": "/p/Safavieh-Hooper-Black-Patio-Dining-Chair-2-Pack-FOX5209E-SET2/207004903"
          },
          {
            "label": "Yellow/White",
            "value": "2931f922-a52b-491b-8042-dd1bcc25ab8f",
            "url": "/p/Safavieh-Hooper-Yellow-Patio-Dining-Chair-2-Pack-FOX5209D-SET2/207004902"
          },
          {
            "label": "Gray/White",
            "value": "0fe2a94d-bc38-4462-aeca-6386b51a9408",
            "url": "/p/Safavieh-Hooper-Grey-White-Patio-Dining-Chair-2-Pack-FOX5209B-SET2/206868823"
          }
        ]
      },
      "storeSku": {
        "fulfillmentOptions": {
          "fulfillable": false,
          "outofstockOnline": true,
          "storeInventory": "NA",
          "backOrder": false
        },
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 257.54,
          "specialPrice": 257.54,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": true,
        "freeShippingMessage": "Free"
      },
      "ratingsReviews": {
        "averageRating": "5.0",
        "totalReviews": "3"
      }
    },
    {
      "productId": "11111111",
      "itemId": "206868825",
      "productUrl": "/p/Safavieh-Salcha-Blue-and-White-Outdoor-Dining-Chair-Pack-of-2-FOX5210A-SET2/206868825",
      "availabilityType": "Online",
      "info": {
        "brandName": "Safavieh",
        "productLabel": "Salcha Blue and White Outdoor Dining Chair (Pack of 2)",
        "modelNumber": "FOX5210A-SET2",
        "productType": "MERCHANDISE",
        "imageId": "41f9ec7f-5df5-46ec-9804-e38dc3607b97",
        "totalNumberOfOptions": 4,
        "imageUrl": "https://images.homedepot-static.com/productImages/41f9ec7f-5df5-46ec-9804-e38dc3607b97/svn/safavieh-outdoor-dining-chairs-fox5210a-set2-64_<SIZE>.jpg",
        "swatches": [
          {
            "label": "Blue/White",
            "value": "247eecbc-02ff-43b3-9dfe-2ad7b8b80ed8",
            "url": "/p/Safavieh-Salcha-Blue-and-White-Outdoor-Dining-Chair-Pack-of-2-FOX5210A-SET2/206868825"
          },
          {
            "label": "Black/White",
            "value": "35f48967-4f5e-4f29-9360-f8114537fad9",
            "url": "/p/Safavieh-Salcha-Black-Patio-Dining-Chair-2-Pack-FOX5210E-SET2/207016947"
          },
          {
            "label": "Yellow/White",
            "value": "10c6ddb3-bcdb-4494-a6a3-d82678364f33",
            "url": "/p/Safavieh-Salcha-Yellow-Patio-Dining-Chair-2-Pack-FOX5210D-SET2/207016946"
          },
          {
            "label": "Gray/White",
            "value": "df973514-a288-471b-b392-caf1a3613e6b",
            "url": "/p/Safavieh-Salcha-Grey-White-Patio-Dining-Chair-2-Pack-FOX5210B-SET2/206868827"
          }
        ]
      },
      "storeSku": {
        "fulfillmentOptions": {
          "fulfillable": true,
          "buyOnlineShipToStore": {
            "eligibilityStatus": true,
            "checkStoreLink": false
          },
          "shipToHome": {
            "eligibilityStatus": true
          },
          "storemessage": "BOSS",
          "storeInventory": "NA",
          "backOrder": false
        },
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 204.96,
          "specialPrice": 204.96,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": true,
        "freeShippingMessage": "Free"
      },
      "ratingsReviews": {
        "averageRating": "4.6667",
        "totalReviews": "3"
      }
    },
    {
      "productId": "206874092",
      "itemId": "206874092",
      "productUrl": "/p/Pulaski-Furniture-Country-White-Storage-Cabinet-DS-2542-850/206874092",
      "availabilityType": "Online",
      "info": {
        "brandName": "Pulaski Furniture",
        "productLabel": "Country White Storage Cabinet",
        "modelNumber": "DS-2542-850",
        "productType": "MERCHANDISE",
        "imageId": "f10a70ad-5428-4515-9de4-a12c12b904e9",
        "imageUrl": "https://images.homedepot-static.com/productImages/f10a70ad-5428-4515-9de4-a12c12b904e9/svn/white-pulaski-furniture-office-storage-cabinets-ds-2542-850-64_<SIZE>.jpg"
      },
      "storeSku": {
        "fulfillmentOptions": {
          "fulfillable": true,
          "buyOnlineShipToStore": {
            "eligibilityStatus": true,
            "checkStoreLink": false
          },
          "shipToHome": {
            "eligibilityStatus": true
          },
          "storemessage": "BOSS",
          "storeInventory": "NA",
          "backOrder": false
        },
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 227.99,
          "specialPrice": 227.99,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": false,
        "itemLevelFreeShip": false
      },
      "ratingsReviews": {
        "averageRating": "0.000000",
        "totalReviews": "0"
      }
    },
    {
      "productId": "206923695",
      "itemId": "206923695",
      "productUrl": "/p/Sencor-2-Slice-Mint-Green-Toaster-STS31GR-NAA1/206923695",
      "availabilityType": "Online",
      "info": {
        "brandName": "Sencor",
        "productLabel": "2-Slice Mint Green Toaster",
        "modelNumber": "STS31GR-NAA1",
        "productType": "MERCHANDISE",
        "imageId": "331cbcf7-bf3e-4bfc-9dad-158af3165930",
        "imageUrl": "https://images.homedepot-static.com/productImages/331cbcf7-bf3e-4bfc-9dad-158af3165930/svn/pastel-green-sencor-toasters-sts31gr-naa1-64_<SIZE>.jpg"
      },
      "storeSku": {
        "fulfillmentOptions": {
          "fulfillable": true,
          "buyOnlineShipToStore": {
            "eligibilityStatus": true,
            "checkStoreLink": false
          },
          "shipToHome": {
            "eligibilityStatus": true
          },
          "storemessage": "BOSS",
          "storeInventory": "NA",
          "backOrder": false
        },
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 48.49,
          "specialPrice": 48.49,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": true,
        "freeShippingMessage": "Free"
      },
      "ratingsReviews": {
        "averageRating": "4.439",
        "totalReviews": "41"
      }
    },
    {
      "productId": "11111111",
      "itemId": "206985762",
      "productUrl": "/p/Tadpoles-5-Light-Pink-Sapphire-Chandelier-cch5pl004/206985762",
      "availabilityType": "Online",
      "info": {
        "brandName": "Tadpoles",
        "productLabel": "5-Light Pink Sapphire Chandelier",
        "modelNumber": "cch5pl004",
        "productType": "MERCHANDISE",
        "imageId": "f71ed0b7-dd13-40e2-8df6-dc5b2724cb1d",
        "totalNumberOfOptions": 3,
        "imageUrl": "https://images.homedepot-static.com/productImages/f71ed0b7-dd13-40e2-8df6-dc5b2724cb1d/svn/pink-painted-tadpoles-chandeliers-cch5pl004-64_<SIZE>.jpg",
        "swatches": [
          {
            "label": "Pink/Painted",
            "value": "1a755f04-789a-4ac9-a54f-d7dd432b9abb",
            "url": "/p/Tadpoles-5-Light-Pink-Sapphire-Chandelier-cch5pl004/206985762"
          },
          {
            "label": "White",
            "value": "b6e6ed99-f99a-4ecc-8437-b2a4568fa7b0",
            "url": "/p/Tadpoles-5-Light-White-Diamond-Chandelier-CCH5PL010/206985764"
          },
          {
            "label": "Black",
            "value": "eb0ea906-ec53-446d-9d8c-4d4ec14c8f05",
            "url": "/p/Tadpoles-5-Light-Black-Onyx-Chandelier-CCH5PL020/206985780"
          }
        ]
      },
      "storeSku": {
        "fulfillmentOptions": {
          "fulfillable": true,
          "buyOnlineShipToStore": {
            "eligibilityStatus": true,
            "checkStoreLink": false
          },
          "shipToHome": {
            "eligibilityStatus": true
          },
          "storemessage": "BOSS",
          "storeInventory": "NA",
          "backOrder": false
        },
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 84.12,
          "specialPrice": 84.12,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": false,
        "freeShippingThreshold": "$45",
        "freeShippingMessage": "Free"
      },
      "ratingsReviews": {
        "averageRating": "2.875",
        "totalReviews": "16"
      }
    },
    {
      "productId": "206995649",
      "itemId": "206995649",
      "productUrl": "/p/HomeSullivan-Margot-Antique-White-Wood-Dining-Chair-Set-of-2-405123S2PC/206995649",
      "availabilityType": "Online",
      "info": {
        "brandName": "HomeSullivan",
        "productLabel": "Margot Antique White Wood Dining Chair (Set of 2)",
        "modelNumber": "405123S2PC",
        "productType": "MERCHANDISE",
        "imageId": "84082d89-cc9a-4476-8f27-86027d5dc1ec",
        "imageUrl": "https://images.homedepot-static.com/productImages/84082d89-cc9a-4476-8f27-86027d5dc1ec/svn/antique-white-homesullivan-dining-chairs-405123s2pc-64_<SIZE>.jpg"
      },
      "storeSku": {
        "fulfillmentOptions": {
          "fulfillable": true,
          "buyOnlineShipToStore": {
            "eligibilityStatus": true,
            "checkStoreLink": false
          },
          "shipToHome": {
            "eligibilityStatus": true
          },
          "storemessage": "BOSS",
          "storeInventory": "NA",
          "backOrder": false
        },
        "pricing": {
          "uom": "pair",
          "unitsPerCase": "0.0",
          "originalPrice": 379.79,
          "specialPrice": 379.79,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": true,
        "freeShippingMessage": "Free"
      },
      "ratingsReviews": {
        "averageRating": "0.000000",
        "totalReviews": "0"
      }
    },
    {
      "productId": "207000343",
      "itemId": "207000343",
      "productUrl": "/p/Titan-Lighting-Kelsey-1-Light-Oil-Rubbed-Bronze-Pendant-with-Clear-Glass-TN-75192/207000343",
      "availabilityType": "Online",
      "info": {
        "brandName": "Titan Lighting",
        "productLabel": "Kelsey 1-Light Oil Rubbed Bronze Pendant with Clear Glass",
        "modelNumber": "TN-75192",
        "productType": "MERCHANDISE",
        "imageId": "74a65a94-763d-4849-965b-2315f4e3f964",
        "imageUrl": "https://images.homedepot-static.com/productImages/74a65a94-763d-4849-965b-2315f4e3f964/svn/oil-rubbed-bronze-titan-lighting-pendant-lights-tn-75192-64_<SIZE>.jpg"
      },
      "storeSku": {
        "fulfillmentOptions": {
          "fulfillable": false,
          "outofstockOnline": true,
          "storeInventory": "NA",
          "backOrder": false
        },
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 145.5,
          "specialPrice": 145.5,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": true,
        "freeShippingMessage": "Free"
      },
      "ratingsReviews": {
        "averageRating": "0.000000",
        "totalReviews": "0"
      }
    },
    {
      "productId": "207015443",
      "itemId": "207015443",
      "productUrl": "/p/Titan-Lighting-Circeo-3-Light-Light-Pink-Chandelier-TN-75667/207015443",
      "availabilityType": "Online",
      "info": {
        "brandName": "Titan Lighting",
        "productLabel": "Circeo 3-Light Light Pink Chandelier",
        "modelNumber": "TN-75667",
        "productType": "MERCHANDISE",
        "imageId": "1974b3e5-f29e-48ea-b7b8-542f28b7b1cb",
        "imageUrl": "https://images.homedepot-static.com/productImages/1974b3e5-f29e-48ea-b7b8-542f28b7b1cb/svn/light-pink-titan-lighting-chandeliers-tn-75667-64_<SIZE>.jpg"
      },
      "storeSku": {
        "fulfillmentOptions": {
          "fulfillable": true,
          "buyOnlineShipToStore": {
            "eligibilityStatus": true,
            "checkStoreLink": false
          },
          "shipToHome": {
            "eligibilityStatus": true
          },
          "storemessage": "BOSS",
          "storeInventory": "NA",
          "backOrder": false
        },
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 164.9,
          "specialPrice": 164.9,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": true,
        "freeShippingMessage": "Free"
      },
      "ratingsReviews": {
        "averageRating": "0.000000",
        "totalReviews": "0"
      }
    },
    {
      "productId": "11111111",
      "itemId": "207016947",
      "productUrl": "/p/Safavieh-Salcha-Black-Patio-Dining-Chair-2-Pack-FOX5210E-SET2/207016947",
      "availabilityType": "Online",
      "info": {
        "brandName": "Safavieh",
        "productLabel": "Salcha Black Patio Dining Chair (2-Pack)",
        "modelNumber": "FOX5210E-SET2",
        "productType": "MERCHANDISE",
        "imageId": "6e2c3f57-3356-4d35-a393-fc8de8dec495",
        "totalNumberOfOptions": 4,
        "imageUrl": "https://images.homedepot-static.com/productImages/6e2c3f57-3356-4d35-a393-fc8de8dec495/svn/safavieh-outdoor-dining-chairs-fox5210e-set2-64_<SIZE>.jpg",
        "swatches": [
          {
            "label": "Black/White",
            "value": "35f48967-4f5e-4f29-9360-f8114537fad9",
            "url": "/p/Safavieh-Salcha-Black-Patio-Dining-Chair-2-Pack-FOX5210E-SET2/207016947"
          },
          {
            "label": "Blue/White",
            "value": "247eecbc-02ff-43b3-9dfe-2ad7b8b80ed8",
            "url": "/p/Safavieh-Salcha-Blue-and-White-Outdoor-Dining-Chair-Pack-of-2-FOX5210A-SET2/206868825"
          },
          {
            "label": "Yellow/White",
            "value": "10c6ddb3-bcdb-4494-a6a3-d82678364f33",
            "url": "/p/Safavieh-Salcha-Yellow-Patio-Dining-Chair-2-Pack-FOX5210D-SET2/207016946"
          },
          {
            "label": "Gray/White",
            "value": "df973514-a288-471b-b392-caf1a3613e6b",
            "url": "/p/Safavieh-Salcha-Grey-White-Patio-Dining-Chair-2-Pack-FOX5210B-SET2/206868827"
          }
        ]
      },
      "storeSku": {
        "fulfillmentOptions": {
          "fulfillable": true,
          "buyOnlineShipToStore": {
            "eligibilityStatus": true,
            "checkStoreLink": false
          },
          "shipToHome": {
            "eligibilityStatus": true
          },
          "storemessage": "BOSS",
          "storeInventory": "NA",
          "backOrder": false
        },
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 249.93,
          "specialPrice": 249.93,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": true,
        "freeShippingMessage": "Free"
      },
      "ratingsReviews": {
        "averageRating": "4.6667",
        "totalReviews": "3"
      }
    },
    {
      "productId": "207132021",
      "itemId": "207132021",
      "productUrl": "/p/28-in-Mint-Green-Ceramic-Table-Lamp-W-MR8908MINT-GREEN/207132021",
      "availabilityType": "Online",
      "info": {
        "productLabel": "28 in. Mint Green Ceramic Table Lamp",
        "modelNumber": "W-MR8908MINT GREEN",
        "productType": "MERCHANDISE",
        "imageId": "e7f24f94-7dbe-4210-8722-19536250696f",
        "imageUrl": "https://images.homedepot-static.com/productImages/e7f24f94-7dbe-4210-8722-19536250696f/svn/mint-green-brushed-steel-accents-table-lamps-w-mr8908mint-green-64_<SIZE>.jpg"
      },
      "storeSku": {
        "fulfillmentOptions": {
          "fulfillable": true,
          "buyOnlineShipToStore": {
            "eligibilityStatus": true,
            "checkStoreLink": false
          },
          "shipToHome": {
            "eligibilityStatus": true
          },
          "storemessage": "BOSS",
          "storeInventory": "NA",
          "backOrder": false
        },
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 118.49,
          "specialPrice": 118.49,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": false,
        "freeShippingThreshold": "$45",
        "freeShippingMessage": "Free"
      },
      "ratingsReviews": {
        "averageRating": "0.000000",
        "totalReviews": "0"
      }
    },
    {
      "productId": "207159189",
      "itemId": "207159189",
      "productUrl": "/p/TAG-Small-Square-Pedestal-Cake-Plate-TAG43606/207159189",
      "availabilityType": "Online",
      "info": {
        "brandName": "TAG",
        "productLabel": "Small Square Pedestal Cake Plate",
        "modelNumber": "TAG43606",
        "productType": "MERCHANDISE",
        "imageId": "d6730682-320c-4e81-9fc5-923b5b04d798",
        "discontinuedItem": true,
        "imageUrl": "https://images.homedepot-static.com/productImages/d6730682-320c-4e81-9fc5-923b5b04d798/svn/tag-cake-tiered-stands-tag43606-64_<SIZE>.jpg"
      },
      "storeSku": {
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 28.23,
          "specialPrice": 28.23,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": false,
        "freeShippingThreshold": "$45",
        "freeShippingMessage": "FREE with $45 Order"
      },
      "ratingsReviews": {
        "averageRating": "0.000000",
        "totalReviews": "0"
      }
    },
    {
      "productId": "207208756",
      "itemId": "207208756",
      "productUrl": "/p/Merola-Tile-Hudson-Tangier-Mint-Green-12-3-8-in-x-12-1-2-in-x-5-mm-Porcelain-Mosaic-Tile-FKOLTR32/207208756",
      "availabilityType": "Online",
      "info": {
        "brandName": "Merola Tile",
        "productLabel": "Hudson Tangier Mint Green 12-3/8 in. x 12-1/2 in. x 5 mm Porcelain Mosaic Tile",
        "modelNumber": "FKOLTR32",
        "productType": "MERCHANDISE",
        "imageId": "04d3ff28-6525-48f1-83d3-310e856cde4d",
        "imageUrl": "https://images.homedepot-static.com/productImages/04d3ff28-6525-48f1-83d3-310e856cde4d/svn/mint-green-high-sheen-merola-tile-mosaic-tile-fkoltr32-64_<SIZE>.jpg"
      },
      "storeSku": {
        "fulfillmentOptions": {
          "fulfillable": true,
          "buyOnlineShipToStore": {
            "eligibilityStatus": true,
            "checkStoreLink": false
          },
          "shipToHome": {
            "eligibilityStatus": true
          },
          "storemessage": "BOSS",
          "storeInventory": "NA",
          "backOrder": false
        },
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 13.98,
          "specialPrice": 13.98,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": false,
        "freeShippingThreshold": "$45",
        "freeShippingMessage": "FREE with $45 Order"
      },
      "ratingsReviews": {
        "averageRating": "4.2222",
        "totalReviews": "9"
      }
    },
    {
      "productId": "300032464",
      "itemId": "300032464",
      "productUrl": "/p/Stonebriar-Collection-7-in-x-5-in-Blue-Wood-Tray-with-3-Glass-Bottles-SB-5371A/300032464",
      "availabilityType": "Online",
      "info": {
        "brandName": "Stonebriar Collection",
        "productLabel": "7 in. x 5 in. Blue Wood Tray with 3 Glass Bottles",
        "modelNumber": "SB-5371A",
        "productType": "MERCHANDISE",
        "imageId": "ac32ebf7-0166-403d-b92c-0dc9ff29034f",
        "imageUrl": "https://images.homedepot-static.com/productImages/ac32ebf7-0166-403d-b92c-0dc9ff29034f/svn/blue-stonebriar-collection-decorative-bottles-sb-5371a-64_<SIZE>.jpg"
      },
      "storeSku": {
        "fulfillmentOptions": {
          "fulfillable": true,
          "buyOnlineShipToStore": {
            "eligibilityStatus": true,
            "checkStoreLink": false
          },
          "shipToHome": {
            "eligibilityStatus": true
          },
          "storemessage": "BOSS",
          "storeInventory": "NA",
          "backOrder": false
        },
        "pricing": {
          "uom": "set",
          "unitsPerCase": "0.0",
          "originalPrice": 17.5,
          "specialPrice": 17.5,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": false,
        "freeShippingThreshold": "$45",
        "freeShippingMessage": "FREE with $45 Order"
      },
      "ratingsReviews": {
        "averageRating": "0.000000",
        "totalReviews": "0"
      }
    },
    {
      "productId": "300061408",
      "itemId": "300061408",
      "productUrl": "/p/Corningware-Etch-4-Piece-Beige-Bakeware-Set-1096868/300061408",
      "availabilityType": "Online",
      "info": {
        "brandName": "Corningware",
        "productLabel": "Etch 4-Piece Beige Bakeware Set",
        "modelNumber": "1096868",
        "productType": "MERCHANDISE",
        "imageId": "742258f8-11b8-4fb7-93e0-32f314177968",
        "discontinuedItem": true,
        "imageUrl": "https://images.homedepot-static.com/productImages/742258f8-11b8-4fb7-93e0-32f314177968/svn/beige-corningware-bakeware-sets-1096868-64_<SIZE>.jpg"
      },
      "storeSku": {
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 36.97,
          "specialPrice": 36.97,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": false,
        "freeShippingThreshold": "$45",
        "freeShippingMessage": "FREE with $45 Order"
      },
      "ratingsReviews": {
        "averageRating": "5.0",
        "totalReviews": "3"
      }
    },
    {
      "productId": "11111111",
      "itemId": "300080764",
      "productUrl": "/p/Brewster-Bentley-Mint-Damask-Wallpaper-Sample-ARB67572SAM/300080764",
      "availabilityType": "Online",
      "info": {
        "brandName": "Brewster",
        "productLabel": "Bentley Mint Damask Wallpaper Sample",
        "modelNumber": "ARB67572SAM",
        "productType": "MERCHANDISE",
        "imageId": "41832593-f8cc-4daa-b506-bda1e124b6fe",
        "secondaryImageId": "c617abef-cd76-4102-9323-a637d8b72756",
        "totalNumberOfOptions": 4,
        "imageUrl": "https://images.homedepot-static.com/productImages/41832593-f8cc-4daa-b506-bda1e124b6fe/svn/brewster-wallpaper-arb67572sam-64_<SIZE>.jpg",
        "secondaryimageUrl": "https://images.homedepot-static.com/productImages/c617abef-cd76-4102-9323-a637d8b72756/svn/brewster-wallpaper-arb67572sam-e4_<SIZE>.jpg",
        "swatches": [
          {
            "label": "Mint",
            "value": "8712bb16-d040-41cd-ae48-290886e79f36",
            "url": "/p/Brewster-Bentley-Mint-Damask-Wallpaper-Sample-ARB67572SAM/300080764"
          },
          {
            "label": "Grey",
            "value": "f3e1b648-58a3-4718-b039-b7ae07aa5785",
            "url": "/p/Brewster-Bentley-Grey-Damask-Wallpaper-Sample-ARB67574SAM/300080758"
          },
          {
            "label": "Cream",
            "value": "62e40a62-ad10-4bcf-865a-9771f0f3d70b",
            "url": "/p/Brewster-Bentley-Cream-Damask-Wallpaper-Sample-ARB67573SAM/300080765"
          },
          {
            "label": "Beige",
            "value": "1de9da99-bafa-443b-9dfd-8843fa7de63f",
            "url": "/p/Brewster-Bentley-Beige-Damask-Wallpaper-Sample-ARB67576SAM/300080759"
          }
        ]
      },
      "storeSku": {
        "fulfillmentOptions": {
          "fulfillable": true,
          "buyOnlineShipToStore": {
            "eligibilityStatus": true,
            "checkStoreLink": false
          },
          "shipToHome": {
            "eligibilityStatus": true
          },
          "storemessage": "BOSS",
          "storeInventory": "NA",
          "backOrder": false
        },
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 2.88,
          "specialPrice": 2.88,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": true,
        "freeShippingMessage": "Free"
      },
      "ratingsReviews": {
        "averageRating": "0.000000",
        "totalReviews": "0"
      }
    },
    {
      "productId": "300090189",
      "itemId": "300090189",
      "productUrl": "/p/Titan-Lighting-Ceramic-Artichoke-Candle-Holder-TN-891322/300090189",
      "availabilityType": "Online",
      "info": {
        "brandName": "Titan Lighting",
        "productLabel": "Ceramic Artichoke Candle Holder",
        "modelNumber": "TN-891322",
        "productType": "MERCHANDISE",
        "imageId": "0522584d-0802-492b-84f6-9b03752cf40c",
        "discontinuedItem": true,
        "imageUrl": "https://images.homedepot-static.com/productImages/0522584d-0802-492b-84f6-9b03752cf40c/svn/whites-titan-lighting-candle-holders-tn-891322-64_<SIZE>.jpg"
      },
      "storeSku": {
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 69.97,
          "specialPrice": 69.97,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": false,
        "freeShippingThreshold": "$45",
        "freeShippingMessage": "Free"
      },
      "ratingsReviews": {
        "averageRating": "0.000000",
        "totalReviews": "0"
      }
    },
    {
      "productId": "11111111",
      "itemId": "300095740",
      "productUrl": "/p/Well-Woven-Kings-Court-Kitchen-Bakery-Blue-2-ft-x-7-ft-Kitchen-Runner-Rug-66762/300095740",
      "availabilityType": "Online",
      "info": {
        "brandName": "Well Woven",
        "productLabel": "Kings Court Kitchen Bakery Blue 2 ft. x 7 ft. Kitchen Runner Rug",
        "modelNumber": "66762",
        "productType": "MERCHANDISE",
        "imageId": "3aa070bc-c6eb-4e27-ba31-85c72d16658f",
        "totalNumberOfOptions": 7,
        "imageUrl": "https://images.homedepot-static.com/productImages/3aa070bc-c6eb-4e27-ba31-85c72d16658f/svn/blue-well-woven-area-rugs-66762-64_<SIZE>.jpg",
        "swatches": [
          {
            "label": "Blue",
            "value": "c0e938ae-3a35-4580-994a-ef0b191eb7c1",
            "url": "/p/Well-Woven-Kings-Court-Kitchen-Bakery-Blue-2-ft-x-7-ft-Kitchen-Runner-Rug-66762/300095740"
          },
          {
            "label": "Green",
            "value": "f7ca437e-c2f2-4fde-8173-51b1405bcc20",
            "url": "/p/Well-Woven-Kings-Court-Kitchen-Bakery-Green-4-ft-x-4-ft-Round-Kitchen-Area-Rug-66754R/300095744"
          },
          {
            "label": "Beige",
            "value": "4f148846-ba4e-4e55-9550-8e96b0134171",
            "url": "/p/Well-Woven-Kings-Court-Kitchen-Bakery-Beige-2-ft-x-5-ft-Kitchen-Area-Rug-6672-2S/300095738"
          }
        ]
      },
      "storeSku": {
        "fulfillmentOptions": {
          "fulfillable": false,
          "outofstockOnline": true,
          "storeInventory": "NA",
          "backOrder": false
        },
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 35,
          "specialPrice": 35,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": false,
        "freeShippingThreshold": "$45",
        "freeShippingMessage": "FREE with $45 Order"
      },
      "ratingsReviews": {
        "averageRating": "3.0",
        "totalReviews": "1"
      }
    },
    {
      "productId": "300146778",
      "itemId": "300146778",
      "productUrl": "/p/Polder-18-Jar-Spice-Rack-5429-05RM/300146778",
      "availabilityType": "Online",
      "info": {
        "brandName": "Polder",
        "productLabel": "18-Jar Spice Rack",
        "modelNumber": "5429-05RM",
        "productType": "MERCHANDISE",
        "imageId": "b1eda20a-7393-4dec-a5b7-53ba93afc6f5",
        "imageUrl": "https://images.homedepot-static.com/productImages/b1eda20a-7393-4dec-a5b7-53ba93afc6f5/svn/polder-spice-racks-jars-5429-05rm-64_<SIZE>.jpg"
      },
      "storeSku": {
        "fulfillmentOptions": {
          "fulfillable": true,
          "buyOnlineShipToStore": {
            "eligibilityStatus": true,
            "checkStoreLink": false
          },
          "shipToHome": {
            "eligibilityStatus": true
          },
          "storemessage": "BOSS",
          "storeInventory": "NA",
          "backOrder": false
        },
        "pricing": {
          "uom": "box",
          "unitsPerCase": "0.0",
          "originalPrice": 35.9,
          "specialPrice": 35.9,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": false,
        "freeShippingThreshold": "$45",
        "freeShippingMessage": "FREE with $45 Order"
      },
      "ratingsReviews": {
        "averageRating": "4.0",
        "totalReviews": "2"
      }
    },
    {
      "productId": "11111111",
      "itemId": "300187797",
      "productUrl": "/p/Home-Decorators-Collection-Quentin-White-Modular-Bar-9468500400/300187797",
      "availabilityType": "Online",
      "info": {
        "brandName": "Home Decorators Collection",
        "productLabel": "Quentin White Modular Bar",
        "modelNumber": "9468500400",
        "productType": "MERCHANDISE",
        "imageId": "646b024d-2a16-4be5-acdb-339c513daa7d",
        "totalNumberOfOptions": 3,
        "collectionName": "Quentin Collection in White",
        "collectionURL": "/collection/Decor/Quentin-Collection-in-White/Family-306521798?omsid=300187797",
        "imageUrl": "https://images.homedepot-static.com/productImages/646b024d-2a16-4be5-acdb-339c513daa7d/svn/white-home-decorators-collection-bars-bar-sets-9468500400-64_<SIZE>.jpg",
        "swatches": [
          {
            "label": "White",
            "value": "2c431447-81c2-44ee-81bd-b2352f8da733",
            "url": "/p/Home-Decorators-Collection-Quentin-White-Modular-Bar-9468500400/300187797"
          },
          {
            "label": "Brown",
            "value": "32c268b2-5b50-445a-a5e3-eb6ea85e80a4",
            "url": "/p/Home-Decorators-Collection-Quentin-Brown-Modular-Bar-9468500820/300187891"
          },
          {
            "label": "Black",
            "value": "4ab52203-a2b1-465b-82da-00ee675df2c0",
            "url": "/p/Home-Decorators-Collection-Quentin-Black-Modular-Bar-9468500200/300187876"
          }
        ],
        "assetCount": "2",
        "collectionType": "Integrated Brand Template"
      },
      "storeSku": {
        "fulfillmentOptions": {
          "fulfillable": true,
          "buyOnlineShipToStore": {
            "eligibilityStatus": true,
            "checkStoreLink": false
          },
          "shipToHome": {
            "eligibilityStatus": true
          },
          "storemessage": "BOSS",
          "storeInventory": "NA",
          "backOrder": false
        },
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 218.49,
          "specialPrice": 218.49,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": false,
        "freeShippingThreshold": "$45",
        "freeShippingMessage": "Free"
      },
      "ratingsReviews": {
        "averageRating": "4.0",
        "totalReviews": "1"
      }
    },
    {
      "productId": "300214062",
      "itemId": "300214062",
      "productUrl": "/p/42-in-Distressed-White-Brennen-Candle-Holder-9654910410/300214062",
      "availabilityType": "Online",
      "info": {
        "productLabel": "42 in. Distressed White Brennen Candle Holder",
        "modelNumber": "9654910410",
        "productType": "MERCHANDISE",
        "imageId": "e83c3726-ab55-476b-840d-92ffaeb5132c",
        "discontinuedItem": true,
        "imageUrl": "https://images.homedepot-static.com/productImages/e83c3726-ab55-476b-840d-92ffaeb5132c/svn/white-candle-holders-9654910410-64_<SIZE>.jpg"
      },
      "storeSku": {
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 70.41,
          "specialPrice": 70.41,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": false,
        "freeShippingThreshold": "$45",
        "freeShippingMessage": "Free"
      },
      "ratingsReviews": {
        "averageRating": "1.0",
        "totalReviews": "1"
      }
    },
    {
      "productId": "300224472",
      "itemId": "300224472",
      "productUrl": "/p/Titan-Lighting-Possum-Belly-Grey-and-White-Kitchen-Island-with-Drawer-TN-891636/300224472",
      "availabilityType": "Online",
      "info": {
        "brandName": "Titan Lighting",
        "productLabel": "Possum Belly Grey and White Kitchen Island with Drawer",
        "modelNumber": "TN-891636",
        "productType": "MERCHANDISE",
        "imageId": "867e6d05-5bd1-4072-b99d-1007d4b232f6",
        "imageUrl": "https://images.homedepot-static.com/productImages/867e6d05-5bd1-4072-b99d-1007d4b232f6/svn/heritage-gray-manor-porch-rail-titan-lighting-kitchen-islands-tn-891636-64_<SIZE>.jpg"
      },
      "storeSku": {
        "fulfillmentOptions": {
          "fulfillable": true,
          "buyOnlineShipToStore": {
            "eligibilityStatus": true,
            "checkStoreLink": false
          },
          "shipToHome": {
            "eligibilityStatus": true
          },
          "storemessage": "BOSS",
          "storeInventory": "NA",
          "backOrder": false
        },
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 2300,
          "specialPrice": 2300,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": false,
        "itemLevelFreeShip": false
      },
      "ratingsReviews": {
        "averageRating": "0.000000",
        "totalReviews": "0"
      }
    },
    {
      "productId": "300340995",
      "itemId": "300340995",
      "productUrl": "/p/Lone-Elm-Studios-23-5-in-x-27-in-Galvanized-Metal-A-Frame-House-Shaped-Wall-Shelf-92664/300340995",
      "availabilityType": "Online",
      "info": {
        "brandName": "Lone Elm Studios",
        "productLabel": "23.5 in. x 27 in. Galvanized Metal A-Frame House Shaped Wall Shelf",
        "modelNumber": "92664",
        "productType": "MERCHANDISE",
        "imageId": "2a5912e3-cef9-428c-9ff1-93be641c100a",
        "discontinuedItem": true,
        "imageUrl": "https://images.homedepot-static.com/productImages/2a5912e3-cef9-428c-9ff1-93be641c100a/svn/lone-elm-studios-decorative-shelving-accessories-92664-64_<SIZE>.jpg"
      },
      "storeSku": {
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 103.94,
          "specialPrice": 103.94,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": false,
        "freeShippingThreshold": "$45",
        "freeShippingMessage": "Free"
      },
      "ratingsReviews": {
        "averageRating": "0.000000",
        "totalReviews": "0"
      }
    },
    {
      "productId": "300375859",
      "itemId": "300375859",
      "productUrl": "/p/Cooks-Standard-36-in-Wooden-Wall-Mounted-Pot-Rack-NC-00267/300375859",
      "availabilityType": "Online",
      "info": {
        "brandName": "Cooks Standard",
        "productLabel": "36 in. Wooden Wall Mounted Pot Rack",
        "modelNumber": "NC-00267",
        "productType": "MERCHANDISE",
        "imageId": "8900ad24-55ee-49f3-8be4-15383cd24b92",
        "imageUrl": "https://images.homedepot-static.com/productImages/8900ad24-55ee-49f3-8be4-15383cd24b92/svn/wood-cooks-standard-pot-racks-nc-00267-64_<SIZE>.jpg"
      },
      "storeSku": {
        "fulfillmentOptions": {
          "fulfillable": true,
          "buyOnlineShipToStore": {
            "eligibilityStatus": true,
            "checkStoreLink": false
          },
          "shipToHome": {
            "eligibilityStatus": true
          },
          "storemessage": "BOSS",
          "storeInventory": "NA",
          "backOrder": false
        },
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 53.44,
          "specialPrice": 53.44,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": false,
        "freeShippingThreshold": "$45",
        "freeShippingMessage": "Free"
      },
      "ratingsReviews": {
        "averageRating": "4.7273",
        "totalReviews": "33"
      }
    },
    {
      "productId": "11111111",
      "itemId": "300495096",
      "productUrl": "/p/Art-Style-Design-Living-Knitted-Kitchen-Towel-Cactus-Green-98-398/300495096",
      "availabilityType": "Online",
      "info": {
        "brandName": "Art Style Design Living",
        "productLabel": "Knitted Kitchen Towel, Cactus Green",
        "modelNumber": "98-398",
        "productType": "MERCHANDISE",
        "imageId": "b03bfb75-c4a4-4f97-a138-86da566bb8df",
        "totalNumberOfOptions": 3,
        "imageUrl": "https://images.homedepot-static.com/productImages/b03bfb75-c4a4-4f97-a138-86da566bb8df/svn/greens-art-style-design-living-kitchen-towels-98-398-64_<SIZE>.jpg",
        "swatches": [
          {
            "label": "Greens",
            "value": "ae6a26e5-fd64-4e57-a4ac-6588183f8603",
            "url": "/p/Art-Style-Design-Living-Knitted-Kitchen-Towel-Cactus-Green-98-398/300495096"
          },
          {
            "label": "Grays",
            "value": "c86e3870-ac5c-4a65-a4e9-b770df87b97f",
            "url": "/p/Art-Style-Design-Living-Knitted-Kitchen-Towel-Stone-Grey-98-443/300495094"
          },
          {
            "label": "Yellows / Golds",
            "value": "6c8c7e77-9d1f-4612-b6ac-60c8122db3c1",
            "url": "/p/Art-Style-Design-Living-Knitted-Kitchen-Towel-Mustard-Yellow-98-397/300495089"
          }
        ]
      },
      "storeSku": {
        "fulfillmentOptions": {
          "fulfillable": true,
          "buyOnlineShipToStore": {
            "eligibilityStatus": true,
            "checkStoreLink": false
          },
          "shipToHome": {
            "eligibilityStatus": true
          },
          "storemessage": "BOSS",
          "storeInventory": "NA",
          "backOrder": false
        },
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 13.53,
          "specialPrice": 13.53,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": false,
        "freeShippingThreshold": "$45",
        "freeShippingMessage": "FREE with $45 Order"
      },
      "ratingsReviews": {
        "averageRating": "0.000000",
        "totalReviews": "0"
      }
    },
    {
      "productId": "300526052",
      "itemId": "300526052",
      "productUrl": "/p/Cangshan-W-Series-6-Piece-Knife-Set-59960/300526052",
      "availabilityType": "Online",
      "info": {
        "brandName": "Cangshan",
        "productLabel": "W Series 6-Piece Knife Set",
        "modelNumber": "59960",
        "productType": "MERCHANDISE",
        "imageId": "69d09cf0-e027-4d06-bfb2-66b669d83934",
        "imageUrl": "https://images.homedepot-static.com/productImages/69d09cf0-e027-4d06-bfb2-66b669d83934/svn/cangshan-knife-sets-59960-64_<SIZE>.jpg"
      },
      "storeSku": {
        "fulfillmentOptions": {
          "fulfillable": true,
          "buyOnlineShipToStore": {
            "eligibilityStatus": true,
            "checkStoreLink": false
          },
          "shipToHome": {
            "eligibilityStatus": true
          },
          "storemessage": "BOSS",
          "storeInventory": "NA",
          "backOrder": false
        },
        "pricing": {
          "uom": "set",
          "unitsPerCase": "0.0",
          "originalPrice": 169.99,
          "specialPrice": 169.99,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": false,
        "freeShippingThreshold": "$45",
        "freeShippingMessage": "Free"
      },
      "ratingsReviews": {
        "averageRating": "5.0",
        "totalReviews": "5"
      }
    },
    {
      "productId": "300641397",
      "itemId": "300641397",
      "productUrl": "/p/Eva-Solo-33oz-Plastic-Jug-with-Vacuum-Glass-Insert-in-Matte-White-502802/300641397",
      "availabilityType": "Online",
      "info": {
        "brandName": "Eva Solo",
        "productLabel": "33oz Plastic Jug with Vacuum Glass Insert in Matte White",
        "modelNumber": "502802",
        "productType": "MERCHANDISE",
        "imageId": "1e8aa7bb-e4cd-444c-8d16-04b6a8e4f895",
        "discontinuedItem": true,
        "imageUrl": "https://images.homedepot-static.com/productImages/1e8aa7bb-e4cd-444c-8d16-04b6a8e4f895/svn/eva-solo-travel-to-go-containers-502802-64_<SIZE>.jpg"
      },
      "storeSku": {
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 76.18,
          "specialPrice": 76.18,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": false,
        "freeShippingThreshold": "$45",
        "freeShippingMessage": "Free"
      },
      "ratingsReviews": {
        "averageRating": "0.000000",
        "totalReviews": "0"
      }
    },
    {
      "productId": "11111111",
      "itemId": "300655549",
      "productUrl": "/p/Flash-Furniture-24-in-Distressed-White-Bar-Stool-ET353424WH/300655549",
      "availabilityType": "Online",
      "info": {
        "brandName": "Flash Furniture",
        "productLabel": "24 in. Distressed White Bar Stool",
        "modelNumber": "ET353424WH",
        "productType": "MERCHANDISE",
        "imageId": "4b440149-ef8c-4070-91fd-83edad1c78ce",
        "totalNumberOfOptions": 8,
        "imageUrl": "https://images.homedepot-static.com/productImages/4b440149-ef8c-4070-91fd-83edad1c78ce/svn/white-flash-furniture-bar-stools-et353424wh-64_<SIZE>.jpg",
        "swatches": [
          {
            "label": "White",
            "value": "b125a330-79f4-48bf-8f6a-0ac886dd48c7",
            "url": "/p/Flash-Furniture-24-in-Distressed-White-Bar-Stool-ET353424WH/300655549"
          },
          {
            "label": "Red",
            "value": "7893a309-0b68-4b35-b658-b01d4b579312",
            "url": "/p/Flash-Furniture-24-in-Distressed-Red-Bar-Stool-ET353424RD/300655545"
          },
          {
            "label": "Black",
            "value": "e503f30b-d30e-4e03-8209-b63747d3b871",
            "url": "/p/Flash-Furniture-24-in-Distressed-Black-Bar-Stool-ET353424BK/300655544"
          },
          {
            "label": "Green",
            "value": "bb07bcec-07e1-4078-bb1e-523850592885",
            "url": "/p/Flash-Furniture-24-in-Distressed-Green-Bar-Stool-ET353424GN/300655546"
          },
          {
            "label": "Blue",
            "value": "d86d98be-b81c-4601-96c6-bddf2fed5544",
            "url": "/p/Flash-Furniture-24-in-Distressed-Blue-Bar-Stool-ET353424DB/300655548"
          }
        ]
      },
      "storeSku": {
        "fulfillmentOptions": {
          "fulfillable": true,
          "buyOnlineShipToStore": {
            "eligibilityStatus": true,
            "checkStoreLink": false
          },
          "shipToHome": {
            "eligibilityStatus": true
          },
          "storemessage": "BOSS",
          "storeInventory": "NA",
          "backOrder": false
        },
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 66.71,
          "specialPrice": 66.71,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": false,
        "freeShippingThreshold": "$45",
        "freeShippingMessage": "Free"
      },
      "ratingsReviews": {
        "averageRating": "4.8182",
        "totalReviews": "11"
      }
    },
    {
      "productId": "11111111",
      "itemId": "300655577",
      "productUrl": "/p/Flash-Furniture-24-in-Mint-Bar-Stool-ETBT350324MINT/300655577",
      "availabilityType": "Online",
      "info": {
        "brandName": "Flash Furniture",
        "productLabel": "24 in. Mint Bar Stool",
        "modelNumber": "ETBT350324MINT",
        "productType": "MERCHANDISE",
        "imageId": "d50d5af7-ac05-4eba-beca-72b4e8e95605",
        "totalNumberOfOptions": 4,
        "imageUrl": "https://images.homedepot-static.com/productImages/d50d5af7-ac05-4eba-beca-72b4e8e95605/svn/mint-flash-furniture-bar-stools-etbt350324mint-64_<SIZE>.jpg",
        "swatches": [
          {
            "label": "Mint",
            "value": "f0fee395-01d8-4e92-87ce-f1fb15161eb9",
            "url": "/p/Flash-Furniture-24-in-Mint-Bar-Stool-ETBT350324MINT/300655577"
          },
          {
            "label": "Blue",
            "value": "3884cb0b-ed1a-45c5-8245-65d0f7cdc06a",
            "url": "/p/Flash-Furniture-24-in-Crystal-Blue-Bar-Stool-ETBT350324CB/300655574"
          },
          {
            "label": "Copper",
            "value": "4cd61ffd-5f50-4b88-b5b2-f11637579502",
            "url": "/p/Flash-Furniture-24-in-Copper-Bar-Stool-ETBT350324POC/300655575"
          },
          {
            "label": "Purple",
            "value": "94601232-f351-45f5-a2b0-9f17453527bb",
            "url": "/p/Flash-Furniture-24-in-Purple-Bar-Stool-ETBT350324PUR/300655576"
          }
        ]
      },
      "storeSku": {
        "fulfillmentOptions": {
          "fulfillable": true,
          "buyOnlineShipToStore": {
            "eligibilityStatus": true,
            "checkStoreLink": false
          },
          "shipToHome": {
            "eligibilityStatus": true
          },
          "storemessage": "BOSS",
          "storeInventory": "NA",
          "backOrder": false
        },
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 37.41,
          "specialPrice": 37.41,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": false,
        "freeShippingThreshold": "$45",
        "freeShippingMessage": "FREE with $45 Order"
      },
      "ratingsReviews": {
        "averageRating": "5.0",
        "totalReviews": "1"
      }
    },
    {
      "productId": "11111111",
      "itemId": "300695982",
      "productUrl": "/p/Kenneth-James-Shipwreck-White-Wood-Wallpaper-Sample-PS41010SAM/300695982",
      "availabilityType": "Online",
      "info": {
        "brandName": "Kenneth James",
        "productLabel": "Shipwreck White Wood Wallpaper Sample",
        "modelNumber": "PS41010SAM",
        "productType": "MERCHANDISE",
        "imageId": "d6b4c97c-e9f9-42a1-a933-bea2f8400e6f",
        "totalNumberOfOptions": 4,
        "imageUrl": "https://images.homedepot-static.com/productImages/d6b4c97c-e9f9-42a1-a933-bea2f8400e6f/svn/kenneth-james-wallpaper-ps41010sam-64_<SIZE>.jpg",
        "swatches": [
          {
            "label": "White",
            "value": "f52ba6b6-b9ac-4fbd-8f1e-ed13ab446072",
            "url": "/p/Kenneth-James-Shipwreck-White-Wood-Wallpaper-Sample-PS41010SAM/300695982"
          },
          {
            "label": "Light Grey",
            "value": "a2c842b1-08f5-401d-b3a4-6c88054033c8",
            "url": "/p/Kenneth-James-Shipwreck-Light-Grey-Wood-Wallpaper-Sample-PS41004SAM/300695962"
          },
          {
            "label": "Aquamarine",
            "value": "d7152e16-c3c3-4371-8c61-393be9245bbd",
            "url": "/p/Kenneth-James-Shipwreck-Aquamarine-Wood-Wallpaper-Sample-PS41002SAM/300695961"
          },
          {
            "label": "Grey",
            "value": "90fe75e7-ba63-4073-9ad2-998cd3afa949",
            "url": "/p/Kenneth-James-Shipwreck-Grey-Wood-Wallpaper-Sample-PS41008SAM/300695980"
          }
        ]
      },
      "storeSku": {
        "fulfillmentOptions": {
          "fulfillable": true,
          "buyOnlineShipToStore": {
            "eligibilityStatus": true,
            "checkStoreLink": false
          },
          "shipToHome": {
            "eligibilityStatus": true
          },
          "storemessage": "BOSS",
          "storeInventory": "NA",
          "backOrder": false
        },
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 3.02,
          "specialPrice": 3.02,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": true,
        "freeShippingMessage": "Free"
      },
      "ratingsReviews": {
        "averageRating": "0.000000",
        "totalReviews": "0"
      }
    },
    {
      "productId": "300696235",
      "itemId": "300696235",
      "productUrl": "/p/Titan-Lighting-8-in-x-16-in-Earthenware-Decorative-Watering-Jug-in-Rustic-Persian-Blue-TN-892194/300696235",
      "availabilityType": "Online",
      "info": {
        "brandName": "Titan Lighting",
        "productLabel": "8 in. x 16 in. Earthenware Decorative Watering Jug in Rustic Persian Blue",
        "modelNumber": "TN-892194",
        "productType": "MERCHANDISE",
        "imageId": "88e2f0d2-f424-434c-b8d4-f524d5c1909c",
        "discontinuedItem": true,
        "imageUrl": "https://images.homedepot-static.com/productImages/88e2f0d2-f424-434c-b8d4-f524d5c1909c/svn/persian-blue-titan-lighting-decorative-bottles-tn-892194-64_<SIZE>.jpg"
      },
      "storeSku": {
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 67.5,
          "specialPrice": 67.5,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": false,
        "freeShippingThreshold": "$45",
        "freeShippingMessage": "Free"
      },
      "ratingsReviews": {
        "averageRating": "0.000000",
        "totalReviews": "0"
      }
    },
    {
      "productId": "300733747",
      "itemId": "300733747",
      "productUrl": "/p/Larissa-White-Bath-Tray-9855760410/300733747",
      "availabilityType": "Online",
      "info": {
        "productLabel": "Larissa White Bath Tray",
        "modelNumber": "9855760410",
        "productType": "MERCHANDISE",
        "imageId": "2df70d3c-5c77-4bd8-bdb4-3d3e6939d21b",
        "discontinuedItem": true,
        "imageUrl": "https://images.homedepot-static.com/productImages/2df70d3c-5c77-4bd8-bdb4-3d3e6939d21b/svn/white-decorative-trays-9855760410-64_<SIZE>.jpg"
      },
      "storeSku": {
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 44.97,
          "specialPrice": 44.97,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": false,
        "freeShippingThreshold": "$45",
        "freeShippingMessage": "FREE with $45 Order"
      },
      "ratingsReviews": {
        "averageRating": "0.000000",
        "totalReviews": "0"
      }
    },
    {
      "productId": "300865947",
      "itemId": "300865947",
      "productUrl": "/p/Artland-Garden-Terrace-3-Gal-Glass-Bev-Dispenser-with-Chiller-Infuser-Water-Hyacinth-Stand-and-6-Glass-Mason-Jar-Mugs-15-oz-10403SA/300865947",
      "availabilityType": "Online",
      "info": {
        "brandName": "Artland",
        "productLabel": "Garden Terrace 3 Gal. Glass Bev Dispenser with Chiller/Infuser, Water Hyacinth Stand, and 6-Glass Mason Jar Mugs 15 oz.",
        "modelNumber": "10403SA",
        "productType": "MERCHANDISE",
        "imageId": "b895e509-5fb5-4ef8-a51c-6e66aeb433b3",
        "imageUrl": "https://images.homedepot-static.com/productImages/b895e509-5fb5-4ef8-a51c-6e66aeb433b3/svn/artland-beverage-dispensers-10403sa-64_<SIZE>.jpg"
      },
      "storeSku": {
        "fulfillmentOptions": {
          "fulfillable": true,
          "buyOnlineShipToStore": {
            "eligibilityStatus": true,
            "checkStoreLink": false
          },
          "shipToHome": {
            "eligibilityStatus": true
          },
          "storemessage": "BOSS",
          "storeInventory": "NA",
          "backOrder": false
        },
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 51.26,
          "specialPrice": 51.26,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": false,
        "freeShippingThreshold": "$45",
        "freeShippingMessage": "Free"
      },
      "ratingsReviews": {
        "averageRating": "1.0",
        "totalReviews": "1"
      }
    },
    {
      "productId": "11111111",
      "itemId": "300902488",
      "productUrl": "/p/Melissa-Van-Hise-25-in-x-31-in-Framed-French-White-Mirror-MIR2902228/300902488",
      "availabilityType": "Online",
      "info": {
        "brandName": "Melissa Van Hise",
        "productLabel": "25 in. x 31 in. Framed French White Mirror",
        "modelNumber": "MIR2902228",
        "productType": "MERCHANDISE",
        "imageId": "6911dace-a19d-4534-aad2-184d954ba874",
        "totalNumberOfOptions": 2,
        "imageUrl": "https://images.homedepot-static.com/productImages/6911dace-a19d-4534-aad2-184d954ba874/svn/melissa-van-hise-mirrors-mir2902228-64_<SIZE>.jpg",
        "swatches": [
          {
            "label": "White",
            "value": "44fe7be3-dae5-4744-b5d8-2464e772a298",
            "url": "/p/Melissa-Van-Hise-25-in-x-31-in-Framed-French-White-Mirror-MIR2902228/300902488"
          },
          {
            "label": "Blue",
            "value": "9ac07172-3617-421d-94e0-d7593f560dd4",
            "url": "/p/Melissa-Van-Hise-25-in-x-31-in-Framed-French-Blue-Mirror-MIR2892228/300902480"
          }
        ]
      },
      "storeSku": {
        "fulfillmentOptions": {
          "fulfillable": true,
          "buyOnlineShipToStore": {
            "eligibilityStatus": true,
            "checkStoreLink": false
          },
          "shipToHome": {
            "eligibilityStatus": true
          },
          "storemessage": "BOSS",
          "storeInventory": "NA",
          "backOrder": false
        },
        "pricing": {
          "uom": "box",
          "unitsPerCase": "0.0",
          "originalPrice": 117.91,
          "specialPrice": 117.91,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": false,
        "itemLevelFreeShip": false
      },
      "ratingsReviews": {
        "averageRating": "0.000000",
        "totalReviews": "0"
      }
    },
    {
      "productId": "300953579",
      "itemId": "300953579",
      "productUrl": "/p/3R-Studios-Parker-Distressed-White-Framed-Mirror-DE5402/300953579",
      "availabilityType": "Online",
      "info": {
        "brandName": "3R Studios",
        "productLabel": "Parker Distressed White Framed Mirror",
        "modelNumber": "DE5402",
        "productType": "MERCHANDISE",
        "imageId": "e5a9a472-6255-4397-b291-d4af9ec0966b",
        "discontinuedItem": true,
        "imageUrl": "https://images.homedepot-static.com/productImages/e5a9a472-6255-4397-b291-d4af9ec0966b/svn/3r-studios-mirrors-de5402-64_<SIZE>.jpg"
      },
      "storeSku": {
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 219.72,
          "specialPrice": 219.72,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": false,
        "itemLevelFreeShip": false
      },
      "ratingsReviews": {
        "averageRating": "4.0",
        "totalReviews": "1"
      }
    },
    {
      "productId": "11111111",
      "itemId": "300958492",
      "productUrl": "/p/International-Concepts-3-Piece-Natural-Dining-Set-K01-42DP-C212-2/300958492",
      "availabilityType": "Online",
      "info": {
        "brandName": "International Concepts",
        "productLabel": "3-Piece Natural Dining Set",
        "modelNumber": "K01-42DP-C212-2",
        "productType": "MERCHANDISE",
        "imageId": "544c483e-fa30-408b-bc70-285d66a6f792",
        "totalNumberOfOptions": 2,
        "imageUrl": "https://images.homedepot-static.com/productImages/544c483e-fa30-408b-bc70-285d66a6f792/svn/natural-international-concepts-dining-room-sets-k01-42dp-c212-2-64_<SIZE>.jpg",
        "swatches": [
          {
            "label": "Natural",
            "value": "4a77dcf0-af66-4f40-b159-34248743401e",
            "url": "/p/International-Concepts-3-Piece-Natural-Dining-Set-K01-42DP-C212-2/300958492"
          },
          {
            "label": "White and Natural",
            "value": "ec3625bd-5c66-40d7-a378-68a4ef893811",
            "url": "/p/International-Concepts-3-Piece-White-and-Natural-Dining-Set-K02-42DP-C212-2/300958501"
          }
        ]
      },
      "storeSku": {
        "fulfillmentOptions": {
          "fulfillable": false,
          "outofstockOnline": true,
          "storeInventory": "NA",
          "backOrder": false
        },
        "pricing": {
          "uom": "set",
          "unitsPerCase": "0.0",
          "originalPrice": 315.66,
          "specialPrice": 315.66,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": false,
        "itemLevelFreeShip": false
      },
      "ratingsReviews": {
        "averageRating": "5.0",
        "totalReviews": "2"
      }
    },
    {
      "productId": "300989413",
      "itemId": "300989413",
      "productUrl": "/p/Sango-Cyprus-White-16-Piece-Dinnerware-Set-4676-16W/300989413",
      "availabilityType": "Online",
      "info": {
        "brandName": "Sango",
        "productLabel": "Cyprus White 16-Piece Dinnerware Set",
        "modelNumber": "4676-16W",
        "productType": "MERCHANDISE",
        "imageId": "bc76e034-f79e-4a9d-8514-aa8545d668e5",
        "discontinuedItem": true,
        "imageUrl": "https://images.homedepot-static.com/productImages/bc76e034-f79e-4a9d-8514-aa8545d668e5/svn/white-sango-dinnerware-sets-4676-16w-64_<SIZE>.jpg"
      },
      "storeSku": {
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 56.97,
          "specialPrice": 56.97,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": false,
        "freeShippingThreshold": "$45",
        "freeShippingMessage": "Free"
      },
      "ratingsReviews": {
        "averageRating": "3.6",
        "totalReviews": "5"
      }
    },
    {
      "productId": "301001768",
      "itemId": "301001768",
      "productUrl": "/p/Titan-Lighting-Victorian-Mahogany-Cream-China-Cabinet-TN-892786/301001768",
      "availabilityType": "Online",
      "info": {
        "brandName": "Titan Lighting",
        "productLabel": "Victorian Mahogany Cream China Cabinet",
        "modelNumber": "TN-892786",
        "productType": "MERCHANDISE",
        "imageId": "91e0da9c-4bab-4d31-9b2c-dcf5434669bc",
        "imageUrl": "https://images.homedepot-static.com/productImages/91e0da9c-4bab-4d31-9b2c-dcf5434669bc/svn/cream-titan-lighting-display-cabinets-tn-892786-64_<SIZE>.jpg"
      },
      "storeSku": {
        "fulfillmentOptions": {
          "fulfillable": false,
          "outofstockOnline": true,
          "storeInventory": "NA",
          "backOrder": false
        },
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 2146.4,
          "specialPrice": 2146.4,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": false,
        "itemLevelFreeShip": false
      },
      "ratingsReviews": {
        "averageRating": "0.000000",
        "totalReviews": "0"
      }
    },
    {
      "productId": "301006595",
      "itemId": "301006595",
      "productUrl": "/p/1-Light-Green-Tint-Hanging-Mason-Jar-Pendant-Set-of-3-87617-3/301006595",
      "availabilityType": "Online",
      "info": {
        "productLabel": "1-Light Green Tint Hanging Mason Jar Pendant (Set of 3)",
        "modelNumber": "87617-3",
        "productType": "MERCHANDISE",
        "imageId": "0f08a921-2b35-4b3c-8578-6823a5fc4a20",
        "discontinuedItem": true,
        "imageUrl": "https://images.homedepot-static.com/productImages/0f08a921-2b35-4b3c-8578-6823a5fc4a20/svn/green-tint-pendant-lights-87617-3-64_<SIZE>.jpg"
      },
      "storeSku": {
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 140.71,
          "specialPrice": 140.71,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": false,
        "freeShippingThreshold": "$45",
        "freeShippingMessage": "Free"
      },
      "ratingsReviews": {
        "averageRating": "0.000000",
        "totalReviews": "0"
      }
    },
    {
      "productId": "301102291",
      "itemId": "301102291",
      "productUrl": "/p/Westinghouse-1-Light-Oil-Rubbed-Bronze-Mini-Pendant-6346000/301102291",
      "availabilityType": "Online",
      "info": {
        "brandName": "Westinghouse",
        "productLabel": "1-Light Oil Rubbed Bronze Mini Pendant",
        "modelNumber": "6346000",
        "productType": "MERCHANDISE",
        "imageId": "044d1a75-9e36-4e38-93fd-3ec835383bb4",
        "imageUrl": "https://images.homedepot-static.com/productImages/044d1a75-9e36-4e38-93fd-3ec835383bb4/svn/oil-rubbed-bronze-westinghouse-pendant-lights-6346000-64_<SIZE>.jpg"
      },
      "storeSku": {
        "fulfillmentOptions": {
          "fulfillable": true,
          "buyOnlineShipToStore": {
            "eligibilityStatus": true,
            "checkStoreLink": false
          },
          "shipToHome": {
            "eligibilityStatus": true
          },
          "storemessage": "BOSS",
          "storeInventory": "NA",
          "backOrder": false
        },
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 47.68,
          "specialPrice": 47.68,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": false,
        "freeShippingThreshold": "$45",
        "freeShippingMessage": "Free"
      },
      "ratingsReviews": {
        "averageRating": "4.5",
        "totalReviews": "2"
      }
    },
    {
      "productId": "301182239",
      "itemId": "301182239",
      "productUrl": "/p/Stratton-Home-Decor-Love-Cheese-Wine-Decorative-Sign-SHD0249/301182239",
      "availabilityType": "Online",
      "info": {
        "brandName": "Stratton Home Decor",
        "productLabel": "Love Cheese Wine Decorative Sign",
        "modelNumber": "SHD0249",
        "productType": "MERCHANDISE",
        "imageId": "1d936b82-2ab5-422e-b66b-a88ffdf81238",
        "discontinuedItem": true,
        "imageUrl": "https://images.homedepot-static.com/productImages/1d936b82-2ab5-422e-b66b-a88ffdf81238/svn/stratton-home-decor-wall-signs-shd0249-64_<SIZE>.jpg"
      },
      "storeSku": {
        "pricing": {
          "uom": "package",
          "unitsPerCase": "0.0",
          "originalPrice": 23.63,
          "specialPrice": 23.63,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": false,
        "freeShippingThreshold": "$45",
        "freeShippingMessage": "FREE with $45 Order"
      },
      "ratingsReviews": {
        "averageRating": "0.000000",
        "totalReviews": "0"
      }
    },
    {
      "productId": "301186601",
      "itemId": "301186601",
      "productUrl": "/p/Stratton-Home-Decor-Stratton-Home-Decor-Coffee-and-Blessings-Decorative-Sign-SHD0254/301186601",
      "availabilityType": "Online",
      "info": {
        "brandName": "Stratton Home Decor",
        "productLabel": "Stratton Home Decor Coffee and Blessings Decorative Sign",
        "modelNumber": "SHD0254",
        "productType": "MERCHANDISE",
        "imageId": "110afbd8-8252-4ad7-bf8c-666ded9f8546",
        "imageUrl": "https://images.homedepot-static.com/productImages/110afbd8-8252-4ad7-bf8c-666ded9f8546/svn/stratton-home-decor-wall-signs-shd0254-64_<SIZE>.jpg"
      },
      "storeSku": {
        "fulfillmentOptions": {
          "fulfillable": true,
          "buyOnlineShipToStore": {
            "eligibilityStatus": true,
            "checkStoreLink": false
          },
          "shipToHome": {
            "eligibilityStatus": true
          },
          "storemessage": "BOSS",
          "storeInventory": "NA",
          "backOrder": false
        },
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 13.25,
          "specialPrice": 13.25,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": false,
        "freeShippingThreshold": "$45",
        "freeShippingMessage": "FREE with $45 Order"
      },
      "ratingsReviews": {
        "averageRating": "5.0",
        "totalReviews": "2"
      }
    },
    {
      "productId": "301225244",
      "itemId": "301225244",
      "productUrl": "/p/Golden-Lighting-Bartlett-1-Light-French-White-Pendant-7312-S-FW/301225244",
      "availabilityType": "Online",
      "info": {
        "brandName": "Golden Lighting",
        "productLabel": "Bartlett 1-Light French White Pendant",
        "modelNumber": "7312-S FW",
        "productType": "MERCHANDISE",
        "imageId": "3ba2f1bc-5173-45a4-bbe8-ed76885d3aeb",
        "imageUrl": "https://images.homedepot-static.com/productImages/3ba2f1bc-5173-45a4-bbe8-ed76885d3aeb/svn/french-white-golden-lighting-pendant-lights-7312-s-fw-64_<SIZE>.jpg"
      },
      "storeSku": {
        "fulfillmentOptions": {
          "fulfillable": true,
          "buyOnlineShipToStore": {
            "eligibilityStatus": true,
            "checkStoreLink": false
          },
          "shipToHome": {
            "eligibilityStatus": true
          },
          "storemessage": "BOSS",
          "storeInventory": "NA",
          "backOrder": false
        },
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 135,
          "specialPrice": 135,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": false,
        "freeShippingThreshold": "$45",
        "freeShippingMessage": "Free"
      },
      "ratingsReviews": {
        "averageRating": "0.000000",
        "totalReviews": "0"
      }
    },
    {
      "productId": "301225418",
      "itemId": "301225418",
      "productUrl": "/p/Golden-Lighting-Bartlett-1-Light-French-White-Sconce-7312-1W-FW/301225418",
      "availabilityType": "Online",
      "info": {
        "brandName": "Golden Lighting",
        "productLabel": "Bartlett 1-Light French White Sconce",
        "modelNumber": "7312-1W FW",
        "productType": "MERCHANDISE",
        "imageId": "01a8d36d-b048-4aae-81c5-02968e4c8d66",
        "imageUrl": "https://images.homedepot-static.com/productImages/01a8d36d-b048-4aae-81c5-02968e4c8d66/svn/french-white-golden-lighting-sconces-7312-1w-fw-64_<SIZE>.jpg"
      },
      "storeSku": {
        "fulfillmentOptions": {
          "fulfillable": false,
          "outofstockOnline": true,
          "storeInventory": "NA",
          "backOrder": false
        },
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 127,
          "specialPrice": 127,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": false,
        "freeShippingThreshold": "$45",
        "freeShippingMessage": "Free"
      },
      "ratingsReviews": {
        "averageRating": "3.0",
        "totalReviews": "1"
      }
    },
    {
      "productId": "301284729",
      "itemId": "301284729",
      "productUrl": "/p/National-Tree-Company-22-in-Pink-Roses-in-Ceramic-Pot-RAS-WDP002-1/301284729",
      "availabilityType": "Online",
      "info": {
        "brandName": "National Tree Company",
        "productLabel": "22 in. Pink Roses in Ceramic Pot",
        "modelNumber": "RAS-WDP002-1",
        "productType": "MERCHANDISE",
        "imageId": "a4c363ed-71f5-4444-ae04-0af03e6b10b2",
        "imageUrl": "https://images.homedepot-static.com/productImages/a4c363ed-71f5-4444-ae04-0af03e6b10b2/svn/pink-national-tree-company-artificial-flowers-ras-wdp002-1-64_<SIZE>.jpg"
      },
      "storeSku": {
        "fulfillmentOptions": {
          "fulfillable": true,
          "buyOnlineShipToStore": {
            "eligibilityStatus": true,
            "checkStoreLink": false
          },
          "shipToHome": {
            "eligibilityStatus": true
          },
          "storemessage": "BOSS",
          "storeInventory": "NA",
          "backOrder": false
        },
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 82.63,
          "specialPrice": 82.63,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": false,
        "freeShippingThreshold": "$45",
        "freeShippingMessage": "Free"
      },
      "ratingsReviews": {
        "averageRating": "0.000000",
        "totalReviews": "0"
      }
    },
    {
      "productId": "301415266",
      "itemId": "301415266",
      "productUrl": "/p/Mrs-Fields-Half-N-Half-Cupcake-Pan-115/301415266",
      "availabilityType": "Online",
      "info": {
        "brandName": "Mrs. Fields",
        "productLabel": "Half N Half Cupcake Pan",
        "modelNumber": "115",
        "productType": "MERCHANDISE",
        "imageId": "b3e79841-07bc-4354-a774-7d4662a5a5f9",
        "imageUrl": "https://images.homedepot-static.com/productImages/b3e79841-07bc-4354-a774-7d4662a5a5f9/svn/non-stick-mrs-fields-specialty-bakeware-115-64_<SIZE>.jpg"
      },
      "storeSku": {
        "fulfillmentOptions": {
          "fulfillable": false,
          "outofstockOnline": true,
          "storeInventory": "NA",
          "backOrder": false
        },
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 18.81,
          "specialPrice": 18.81,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": false,
        "freeShippingThreshold": "$45",
        "freeShippingMessage": "FREE with $45 Order"
      },
      "ratingsReviews": {
        "averageRating": "0.000000",
        "totalReviews": "0"
      }
    },
    {
      "productId": "301416408",
      "itemId": "301416408",
      "productUrl": "/p/Auratic-14-oz-Foresta-Mugs-Set-of-2-15-00265/301416408",
      "availabilityType": "Online",
      "info": {
        "brandName": "Auratic",
        "productLabel": "14 oz. Foresta Mugs (Set of 2)",
        "modelNumber": "15-00265",
        "productType": "MERCHANDISE",
        "imageId": "3e6cba19-d1c0-48c7-864a-b9cab247eb48",
        "discontinuedItem": true,
        "imageUrl": "https://images.homedepot-static.com/productImages/3e6cba19-d1c0-48c7-864a-b9cab247eb48/svn/auratic-mugs-15-00265-64_<SIZE>.jpg"
      },
      "storeSku": {
        "pricing": {
          "uom": "box",
          "unitsPerCase": "0.0",
          "originalPrice": 16.8,
          "specialPrice": 16.8,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": false,
        "freeShippingThreshold": "$45",
        "freeShippingMessage": "FREE with $45 Order"
      },
      "ratingsReviews": {
        "averageRating": "0.000000",
        "totalReviews": "0"
      }
    },
    {
      "productId": "301610913",
      "itemId": "301610913",
      "productUrl": "/p/Cangshan-R-Series-3-Piece-Knife-Set-62458/301610913",
      "availabilityType": "Online",
      "info": {
        "brandName": "Cangshan",
        "productLabel": "R Series 3-Piece Knife Set",
        "modelNumber": "62458",
        "productType": "MERCHANDISE",
        "imageId": "fe0f44d0-cff7-43f0-b7e5-c3aea4f74117",
        "imageUrl": "https://images.homedepot-static.com/productImages/fe0f44d0-cff7-43f0-b7e5-c3aea4f74117/svn/cangshan-knife-sets-62458-64_<SIZE>.jpg"
      },
      "storeSku": {
        "fulfillmentOptions": {
          "fulfillable": true,
          "buyOnlineShipToStore": {
            "eligibilityStatus": true,
            "checkStoreLink": false
          },
          "shipToHome": {
            "eligibilityStatus": true
          },
          "storemessage": "BOSS",
          "storeInventory": "NA",
          "backOrder": false
        },
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 149.95,
          "specialPrice": 149.95,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": true,
        "freeShippingMessage": "Free"
      },
      "ratingsReviews": {
        "averageRating": "0.000000",
        "totalReviews": "0"
      }
    },
    {
      "productId": "301613407",
      "itemId": "301613407",
      "productUrl": "/p/Titan-Lighting-Sentiments-3-in-x-10-in-Black-Wood-Clear-and-Frosted-Glass-Tray-Candle-Holders-TN-893021/301613407",
      "availabilityType": "Online",
      "info": {
        "brandName": "Titan Lighting",
        "productLabel": "Sentiments 3 in. x 10 in. Black Wood, Clear and Frosted Glass Tray Candle Holders",
        "modelNumber": "TN-893021",
        "productType": "MERCHANDISE",
        "imageId": "01f28538-abd9-4714-8488-1c76b671da8d",
        "imageUrl": "https://images.homedepot-static.com/productImages/01f28538-abd9-4714-8488-1c76b671da8d/svn/multi-titan-lighting-candle-holders-tn-893021-64_<SIZE>.jpg"
      },
      "storeSku": {
        "fulfillmentOptions": {
          "fulfillable": true,
          "buyOnlineShipToStore": {
            "eligibilityStatus": true,
            "checkStoreLink": false
          },
          "shipToHome": {
            "eligibilityStatus": true
          },
          "storemessage": "BOSS",
          "storeInventory": "NA",
          "backOrder": false
        },
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 22.5,
          "specialPrice": 22.5,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": false,
        "freeShippingThreshold": "$45",
        "freeShippingMessage": "FREE with $45 Order"
      },
      "ratingsReviews": {
        "averageRating": "0.000000",
        "totalReviews": "0"
      }
    },
    {
      "productId": "11111111",
      "itemId": "301704061",
      "productUrl": "/p/IMAX-Julian-8-in-Marble-Cake-Stand-82503/301704061",
      "availabilityType": "Online",
      "info": {
        "brandName": "IMAX",
        "productLabel": "Julian 8 in. Marble Cake Stand",
        "modelNumber": "82503",
        "productType": "MERCHANDISE",
        "imageId": "3f2c71d4-ef3f-4ee6-a167-7737548ae91c",
        "totalNumberOfOptions": 1,
        "imageUrl": "https://images.homedepot-static.com/productImages/3f2c71d4-ef3f-4ee6-a167-7737548ae91c/svn/imax-cake-tiered-stands-82503-64_<SIZE>.jpg",
        "swatches": [
          {
            "label": "White",
            "value": "6508210d-74df-46dd-a269-6709dc54fb03",
            "url": "/p/IMAX-Julian-8-in-Marble-Cake-Stand-82503/301704061"
          }
        ]
      },
      "storeSku": {
        "fulfillmentOptions": {
          "fulfillable": false,
          "outofstockOnline": true,
          "storeInventory": "NA",
          "backOrder": false
        },
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 29,
          "specialPrice": 29,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": false,
        "freeShippingThreshold": "$45",
        "freeShippingMessage": "FREE with $45 Order"
      },
      "ratingsReviews": {
        "averageRating": "0.000000",
        "totalReviews": "0"
      }
    },
    {
      "productId": "301828968",
      "itemId": "301828968",
      "productUrl": "/p/Tag-20-in-x-20-in-Blue-Bali-Block-Print-Napkin-4-Pack-TAG206892/301828968",
      "availabilityType": "Online",
      "info": {
        "brandName": "Tag",
        "productLabel": "20 in. x 20 in. Blue Bali Block Print Napkin (4-Pack)",
        "modelNumber": "TAG206892",
        "productType": "MERCHANDISE",
        "imageId": "a55c73b7-bda4-4eda-84e4-1d43e0a2bec6",
        "discontinuedItem": true,
        "imageUrl": "https://images.homedepot-static.com/productImages/a55c73b7-bda4-4eda-84e4-1d43e0a2bec6/svn/blues-tag-cloth-napkins-napkin-rings-tag206892-64_<SIZE>.jpg"
      },
      "storeSku": {
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 32.95,
          "specialPrice": 32.95,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": false,
        "freeShippingThreshold": "$45",
        "freeShippingMessage": "FREE with $45 Order"
      },
      "ratingsReviews": {
        "averageRating": "0.000000",
        "totalReviews": "0"
      }
    },
    {
      "productId": "301853147",
      "itemId": "301853147",
      "productUrl": "/p/Stonebriar-Collection-11-in-x-6in-Galvanized-Metal-Containers-with-Rope-Trim-and-Handles-Set-of-3-SB-6097S3/301853147",
      "availabilityType": "Online",
      "info": {
        "brandName": "Stonebriar Collection",
        "productLabel": "11 in. x 6in. Galvanized Metal Containers with Rope Trim and Handles (Set of 3)",
        "modelNumber": "SB-6097S3",
        "productType": "MERCHANDISE",
        "imageId": "73fd1e0e-0f8a-4a18-aaef-0025425327b1",
        "imageUrl": "https://images.homedepot-static.com/productImages/73fd1e0e-0f8a-4a18-aaef-0025425327b1/svn/grey-stonebriar-collection-decorative-baskets-boxes-sb-6097s3-64_<SIZE>.jpg"
      },
      "storeSku": {
        "fulfillmentOptions": {
          "fulfillable": true,
          "buyOnlineShipToStore": {
            "eligibilityStatus": true,
            "checkStoreLink": false
          },
          "shipToHome": {
            "eligibilityStatus": true
          },
          "storemessage": "BOSS",
          "storeInventory": "NA",
          "backOrder": false
        },
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 19.18,
          "specialPrice": 19.18,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": false,
        "freeShippingThreshold": "$45",
        "freeShippingMessage": "FREE with $45 Order"
      },
      "ratingsReviews": {
        "averageRating": "0.000000",
        "totalReviews": "0"
      }
    },
    {
      "productId": "11111111",
      "itemId": "301856499",
      "productUrl": "/p/Sunjoy-Alberta-White-Body-with-Wood-Top-Kitchen-Cart-with-2-Cabinets-and-1-Drawer-120306005W/301856499",
      "availabilityType": "Online",
      "info": {
        "brandName": "Sunjoy",
        "productLabel": "Alberta White Body with Wood Top Kitchen Cart with 2 Cabinets and 1 Drawer",
        "modelNumber": "120306005W",
        "productType": "MERCHANDISE",
        "imageId": "06ddd377-108d-4041-a712-ccf2a7296b87",
        "totalNumberOfOptions": 3,
        "imageUrl": "https://images.homedepot-static.com/productImages/06ddd377-108d-4041-a712-ccf2a7296b87/svn/white-sunjoy-kitchen-carts-120306005w-64_<SIZE>.jpg",
        "swatches": [
          {
            "label": "White",
            "value": "c21972fb-89ad-48e9-9a41-8fc04dd7943b",
            "url": "/p/Sunjoy-Alberta-White-Body-with-Wood-Top-Kitchen-Cart-with-2-Cabinets-and-1-Drawer-120306005W/301856499"
          },
          {
            "label": "Gray",
            "value": "25ff30be-a46a-4c4f-899f-0528b5d7dfdd",
            "url": "/p/Sunjoy-Alberta-Gray-Body-with-Wood-Top-Kitchen-Cart-with-2-Cabinets-and-1-Drawer-120306005G/301856510"
          },
          {
            "label": "Navy",
            "value": "aa4c96b2-6dc2-4866-a9e6-46f3f1ab59b6",
            "url": "/p/Sunjoy-Alberta-Navy-Body-with-Wood-Top-Kitchen-Cart-with-2-Cabinets-and-1-Drawer-120306005N/301857064"
          }
        ]
      },
      "storeSku": {
        "fulfillmentOptions": {
          "fulfillable": false,
          "outofstockOnline": true,
          "storeInventory": "NA",
          "backOrder": false
        },
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 186.42,
          "specialPrice": 149.88,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 36.54,
          "percentageOff": 20
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": true,
        "freeShippingMessage": "Free"
      },
      "ratingsReviews": {
        "averageRating": "3.5",
        "totalReviews": "6"
      }
    },
    {
      "productId": "301958476",
      "itemId": "301958476",
      "productUrl": "/p/3R-Studios-Paulownia-Wood-Carved-Decorative-Tray-DA6636/301958476",
      "availabilityType": "Online",
      "info": {
        "brandName": "3R Studios",
        "productLabel": "Paulownia Wood Carved Decorative  Tray",
        "modelNumber": "DA6636",
        "productType": "MERCHANDISE",
        "imageId": "03df58fe-4b83-4fbe-bc64-1381fd9d9616",
        "imageUrl": "https://images.homedepot-static.com/productImages/03df58fe-4b83-4fbe-bc64-1381fd9d9616/svn/wood-3r-studios-decorative-trays-da6636-64_<SIZE>.jpg"
      },
      "storeSku": {
        "fulfillmentOptions": {
          "fulfillable": true,
          "buyOnlineShipToStore": {
            "eligibilityStatus": true,
            "checkStoreLink": false
          },
          "shipToHome": {
            "eligibilityStatus": true
          },
          "storemessage": "BOSS",
          "storeInventory": "NA",
          "backOrder": false
        },
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 40.43,
          "specialPrice": 40.43,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": true,
        "freeShippingMessage": "Free"
      },
      "ratingsReviews": {
        "averageRating": "4.6",
        "totalReviews": "5"
      }
    },
    {
      "productId": "301973814",
      "itemId": "301973814",
      "productUrl": "/p/Stonebriar-Collection-Brown-Wood-Tray-with-Leather-Handles-SB-6027A/301973814",
      "availabilityType": "Online",
      "info": {
        "brandName": "Stonebriar Collection",
        "productLabel": "Brown Wood Tray with Leather Handles",
        "modelNumber": "SB-6027A",
        "productType": "MERCHANDISE",
        "imageId": "9abde40c-b63b-41fd-8a57-8e9078f1ca6f",
        "imageUrl": "https://images.homedepot-static.com/productImages/9abde40c-b63b-41fd-8a57-8e9078f1ca6f/svn/brown-stonebriar-collection-decorative-trays-sb-6027a-64_<SIZE>.jpg"
      },
      "storeSku": {
        "fulfillmentOptions": {
          "fulfillable": true,
          "buyOnlineShipToStore": {
            "eligibilityStatus": true,
            "checkStoreLink": false
          },
          "shipToHome": {
            "eligibilityStatus": true
          },
          "storemessage": "BOSS",
          "storeInventory": "NA",
          "backOrder": false
        },
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 27.14,
          "specialPrice": 27.14,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": false,
        "freeShippingThreshold": "$45",
        "freeShippingMessage": "FREE with $45 Order"
      },
      "ratingsReviews": {
        "averageRating": "0.000000",
        "totalReviews": "0"
      }
    },
    {
      "productId": "301989787",
      "itemId": "301989787",
      "productUrl": "/p/3-Tier-White-Iron-Mesh-Tray-Cart-65687/301989787",
      "availabilityType": "Online",
      "info": {
        "productLabel": "3-Tier White Iron Mesh Tray Cart",
        "modelNumber": "65687",
        "productType": "MERCHANDISE",
        "imageId": "95fd6b36-a58e-42cf-8e06-8a32adf2093a",
        "imageUrl": "https://images.homedepot-static.com/productImages/95fd6b36-a58e-42cf-8e06-8a32adf2093a/svn/white-tool-carts-65687-64_<SIZE>.jpg"
      },
      "storeSku": {
        "fulfillmentOptions": {
          "fulfillable": false,
          "outofstockOnline": true,
          "storeInventory": "NA",
          "backOrder": false
        },
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 101.37,
          "specialPrice": 101.37,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": true,
        "freeShippingMessage": "Free"
      },
      "ratingsReviews": {
        "averageRating": "0.000000",
        "totalReviews": "0"
      }
    },
    {
      "productId": "302116836",
      "itemId": "302116836",
      "productUrl": "/p/Raleigh-Mini-Stripe-Orange-5-ft-x-7-ft-6-in-Rectangle-Indoor-Outdoor-Area-Rug-RAL57B43017/302116836",
      "availabilityType": "Online",
      "info": {
        "productLabel": "Raleigh Mini Stripe Orange 5 ft. x 7 ft. 6 in. Rectangle Indoor/Outdoor Area Rug",
        "modelNumber": "RAL57B43017",
        "productType": "MERCHANDISE",
        "imageId": "519ddeb9-e697-4db3-b944-42dd93b7163e",
        "discontinuedItem": true,
        "imageUrl": "https://images.homedepot-static.com/productImages/519ddeb9-e697-4db3-b944-42dd93b7163e/svn/orange-outdoor-rugs-ral57b43017-64_<SIZE>.jpg"
      },
      "storeSku": {
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 425,
          "specialPrice": 425,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": false,
        "freeShippingThreshold": "$45",
        "freeShippingMessage": "Free"
      },
      "ratingsReviews": {
        "averageRating": "0.000000",
        "totalReviews": "0"
      }
    },
    {
      "productId": "302175868",
      "itemId": "302175868",
      "productUrl": "/p/Wilton-Large-Ultimate-Cake-Leveler-415800/302175868",
      "availabilityType": "Online",
      "info": {
        "brandName": "Wilton",
        "productLabel": "Large Ultimate Cake Leveler",
        "modelNumber": "415800",
        "productType": "MERCHANDISE",
        "imageId": "5fd90c0f-5799-41fd-b579-6e2c18c07580",
        "discontinuedItem": true,
        "imageUrl": "https://images.homedepot-static.com/productImages/5fd90c0f-5799-41fd-b579-6e2c18c07580/svn/wilton-pastry-tools-415800-64_<SIZE>.jpg"
      },
      "storeSku": {
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 15.09,
          "specialPrice": 15.09,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": false,
        "freeShippingThreshold": "$45",
        "freeShippingMessage": "FREE with $45 Order"
      },
      "ratingsReviews": {
        "averageRating": "0.000000",
        "totalReviews": "0"
      }
    },
    {
      "productId": "302346286",
      "itemId": "302346286",
      "productUrl": "/p/Cathy-s-Concepts-Pick-Your-Poison-Mason-Jar-Mixology-Set-HW16-2305-PP/302346286",
      "availabilityType": "Online",
      "info": {
        "brandName": "Cathy's Concepts",
        "productLabel": "\"Pick Your Poison\" Mason Jar Mixology Set",
        "modelNumber": "HW16-2305-PP",
        "productType": "MERCHANDISE",
        "imageId": "5d3e6d29-c950-4fd3-9c4a-ec8f4451a8ea",
        "imageUrl": "https://images.homedepot-static.com/productImages/5d3e6d29-c950-4fd3-9c4a-ec8f4451a8ea/svn/cathy-s-concepts-bar-tools-hw16-2305-pp-64_<SIZE>.jpg"
      },
      "storeSku": {
        "fulfillmentOptions": {
          "fulfillable": true,
          "buyOnlineShipToStore": {
            "eligibilityStatus": true,
            "checkStoreLink": false
          },
          "shipToHome": {
            "eligibilityStatus": true
          },
          "storemessage": "BOSS",
          "storeInventory": "NA",
          "backOrder": false
        },
        "pricing": {
          "uom": "set",
          "unitsPerCase": "0.0",
          "originalPrice": 35.74,
          "specialPrice": 35.74,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": true,
        "freeShippingMessage": "Free"
      },
      "ratingsReviews": {
        "averageRating": "0.000000",
        "totalReviews": "0"
      }
    },
    {
      "productId": "11111111",
      "itemId": "302453515",
      "productUrl": "/p/Alaterre-Furniture-Country-Cottage-White-Antique-Media-Console-Table-ACCA14WA/302453515",
      "availabilityType": "Online",
      "info": {
        "brandName": "Alaterre Furniture",
        "productLabel": "Country Cottage White Antique Media/Console Table",
        "modelNumber": "ACCA14WA",
        "productType": "MERCHANDISE",
        "imageId": "3cc7b52b-fbaa-47a7-9320-9400945d1757",
        "totalNumberOfOptions": 3,
        "imageUrl": "https://images.homedepot-static.com/productImages/3cc7b52b-fbaa-47a7-9320-9400945d1757/svn/white-alaterre-furniture-console-tables-acca14wa-64_<SIZE>.jpg",
        "swatches": [
          {
            "label": "White",
            "value": "ec3e0cd3-6ee1-4b88-a48f-abd4d26e4846",
            "url": "/p/Alaterre-Furniture-Country-Cottage-White-Antique-Media-Console-Table-ACCA14WA/302453515"
          },
          {
            "label": "Blue",
            "value": "71a12312-d207-438f-a03a-bc5cc3dd9770",
            "url": "/p/Alaterre-Furniture-Country-Cottage-Blue-Antique-Media-Console-Table-ACCA14BA/302453511"
          },
          {
            "label": "Red",
            "value": "6efbc8f5-bdfd-43c0-8767-aab4227ad16b",
            "url": "/p/Alaterre-Furniture-Country-Cottage-Red-Antique-Media-Console-Table-ACCA14RA/302453498"
          }
        ]
      },
      "storeSku": {
        "fulfillmentOptions": {
          "fulfillable": true,
          "buyOnlineShipToStore": {
            "eligibilityStatus": true,
            "checkStoreLink": false
          },
          "shipToHome": {
            "eligibilityStatus": true
          },
          "storemessage": "BOSS",
          "storeInventory": "NA",
          "backOrder": false
        },
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 241.55,
          "specialPrice": 241.55,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": true,
        "freeShippingMessage": "Free"
      },
      "ratingsReviews": {
        "averageRating": "4.3333",
        "totalReviews": "3"
      }
    },
    {
      "productId": "302483004",
      "itemId": "302483004",
      "productUrl": "/p/222-Fifth-Sutton-Kitchen-Island-7002WH752A1B34/302483004",
      "availabilityType": "Online",
      "info": {
        "brandName": "222 Fifth",
        "productLabel": "Sutton Kitchen Island",
        "modelNumber": "7002WH752A1B34",
        "productType": "MERCHANDISE",
        "imageId": "77af07f6-6ab2-4eb6-827e-c70e4d0062a9",
        "imageUrl": "https://images.homedepot-static.com/productImages/77af07f6-6ab2-4eb6-827e-c70e4d0062a9/svn/antique-white-222-fifth-kitchen-islands-7002wh752a1b34-64_<SIZE>.jpg"
      },
      "storeSku": {
        "fulfillmentOptions": {
          "fulfillable": false,
          "outofstockOnline": true,
          "storeInventory": "NA",
          "backOrder": false
        },
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 1634.99,
          "specialPrice": 1634.99,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": false,
        "itemLevelFreeShip": false
      },
      "ratingsReviews": {
        "averageRating": "4.3333",
        "totalReviews": "3"
      }
    },
    {
      "productId": "302702508",
      "itemId": "302702508",
      "productUrl": "/p/Gaia-Tablescapes-Dinnerware-16-Piece-White-Barolo-Dinnerware-Set-BT22328-16PC/302702508",
      "availabilityType": "Online",
      "info": {
        "brandName": "Gaia Tablescapes",
        "productLabel": "Dinnerware 16-Piece White Barolo Dinnerware Set",
        "modelNumber": "BT22328-16PC",
        "productType": "MERCHANDISE",
        "imageId": "57da2962-e407-4e3f-b9a8-6fb9012ec5b9",
        "imageUrl": "https://images.homedepot-static.com/productImages/57da2962-e407-4e3f-b9a8-6fb9012ec5b9/svn/white-gaia-tablescapes-dinnerware-sets-bt22328-16pc-64_<SIZE>.jpg"
      },
      "storeSku": {
        "fulfillmentOptions": {
          "fulfillable": false,
          "outofstockOnline": true,
          "storeInventory": "NA",
          "backOrder": false
        },
        "pricing": {
          "uom": "each",
          "unitsPerCase": "0.0",
          "originalPrice": 74.97,
          "specialPrice": 74.97,
          "alternatePriceDisplay": false,
          "lowerPrice": false,
          "dollarOff": 0,
          "percentageOff": 0
        }
      },
      "shipping": {
        "eligibleForFreeShipping": true,
        "itemLevelFreeShip": true,
        "freeShippingMessage": "Free"
      },
      "ratingsReviews": {
        "averageRating": "0.000000",
        "totalReviews": "0"
      }
    },
    {
      "itemId": "300384719",
      "message": "Product Id is Inactive"
    },
    {
      "itemId": "206461204",
      "message": "Product Id is Inactive"
    },
    {
      "itemId": "206804017",
      "message": "Product Id is Inactive"
    },
    {
      "itemId": "301769609",
      "message": "Product Id is Inactive"
    },
    {
      "itemId": "207104200",
      "message": "Product Id is Inactive"
    },
    {
      "itemId": "206461177",
      "message": "Product Id is Inactive"
    },
    {
      "itemId": "300934667",
      "message": "Product Id is Inactive"
    }
  ],
  searchReport: {
    totalProducts: 41982,
    keyword: '',
    pageSize: 24,
    startIndex: 0,
    sortBy: 'updatedAt',
    sortOrder: 'desc'
  }

};
