import { GA_ID } from '../../../../constants/environment';

export const categories = {
  ENGAGMENT: 'engagement'
};

export const sendPageview = ({ pathname }) => {
  if (!/access_token/.test(pathname) && pathname) {
    window.gtag('config', GA_ID, {
      page_title: pathname,
      page_path: pathname
    });
  }
};

export const sendEvent = (action, { event_category, event_label, value }) => {
  if (window.gtag) {
    const opts = {
      event_category,
      event_label,
      value
    };
    window.gtag('event', action, opts);
  }
};
