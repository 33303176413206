
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class RowHeader extends Component {

  render() {
    const { title } = this.props;
    return (
      <div className="row-header">
        <div className="row-header__title">
          <span className="row-header__text text-caps">{title}</span>
        </div>
      </div>
    );
  }
}

RowHeader.propTypes = {
  title: PropTypes.string
};

export default RowHeader;
