import * as types from '../../../constants/ActionTypes';
import { guid } from '../../../util';
import { util } from './actions';
const defaultState = {
  canvas: {
    height: 0,
    width: 0
  },
  coords: {
    height: 0,
    width: 0,
    x: 0,
    y: 0
  },
  isCanvasDirty: false,
  items: [],
  historyItems: [],
  itemState: [],
  gridViewStatus: true,
  pinterestPreview: false
};

const round = (val, places = 4) => {
  return parseFloat(val.toFixed(places));
};

export const deserializeHotspotItems = ({ items = [], canvas, visionHotspot = false }) => {
  items.sort((a, b) => (a.position > b.position ? 1 : -1));
  return items.map(item => {
    const x = parseFloat(item.bbXPosition * canvas.width, 0);
    const y = parseFloat(item.bbYPosition * canvas.height, 0);
    const totalWidth = item.bbXPosition + item.bbWidth;
    const totalHeight = item.bbYPosition + item.bbHeight;
    let bbWidth = item.bbWidth;
    let bbHeight = item.bbHeight;
    if (totalWidth >= 1) {
      const extraWidth = totalWidth - 1;
      bbWidth = item.bbWidth - extraWidth;
    }
    if (totalHeight >= 1) {
      const extraHeight = totalHeight - 1;
      bbHeight = item.bbHeight - extraHeight;
    }
    const hotspotPriorityStatus = item.priority ? item.priority : false;
    let dotCoord = {};
    if (item.hsXPosition && item.hsYPosition) {
      dotCoord = {
        x: (item.hsXPosition * canvas.width) - x,
        y: (item.hsYPosition * canvas.height) - y
      };
    }
    // let itemId = item.itemId;
    let itemIds;
    let product = {
      itemId: item.itemId
    };
    if (visionHotspot) {
      itemIds = item.itemIds;
    }
    return {
      id: guid(),
      coords: {
        x: x,
        y: y,
        width: parseFloat(bbWidth * canvas.width, 0),
        height: parseFloat(bbHeight * canvas.height, 0)
      },
      dotCoord,
      itemIds,
      item: product,
      priorityStatus: hotspotPriorityStatus,
      replacement: !item.exactMatch
    };
  });
};

export const updateDotcord = ({ dotCoord, oldCoord, newCoord }) => {
  if (!dotCoord) return null;
  if (!newCoord) return dotCoord;
  const dx = newCoord.x - oldCoord.x;
  const dy = newCoord.y - oldCoord.y;
  return {
    x: dotCoord.x + dx,
    y: dotCoord.y + dy
  };
};

export const getModifiedItems = ({ dragItemIndex, dropItemIndex, allItems = [] }) => {
  let items = allItems.slice();
  if (items.length === 0) {
    return items;
  }
  const item = items[dragItemIndex];
  // remove drag item
  items.splice(dragItemIndex, 1);
  items.splice(dropItemIndex, 0, item);
  return items;
};

export const getItemsOnPriority = ({ hotspotId, allItems = [], priorityStatus = false }) => {
  let items = allItems.slice();
  if (items.length === 0) {
    return items;
  }
  const currentItemIndex = allItems.findIndex(item => item.id === hotspotId);
  if (currentItemIndex > 0 || !priorityStatus) {
    const currentItem = allItems[currentItemIndex];
    const priorityItems = allItems.filter(hotspot => {
      return hotspot.item && hotspot.priorityStatus && hotspot.item.$imageSrc;
    });
    if (priorityItems.length > 0) {
      let dropIndex = 0;
      if (priorityStatus) {
        dropIndex = priorityItems.length;
      } else {
        dropIndex = priorityItems.length - 1;
      }
      items.splice(currentItemIndex, 1);
      items.splice(dropIndex, 0, currentItem);
    } else {
      items.splice(currentItemIndex, 1);
      items.splice(0, 0, currentItem);
    }
  }
  return items;
};

export const setHistoryItems = ({ items, historyItems }) => {
  if (historyItems && historyItems.length > 0) {
    const historyLength = historyItems.length;
    if (historyLength === 10) {
      historyItems.splice(0, 1);
    }
  }
  historyItems.push(items);
  return historyItems;
};

export const getPreviousVersionItems = ({ items, historyItems }) => {
  let hotspotItems;

  if (historyItems && historyItems.length > 0) {
    hotspotItems = historyItems.splice(historyItems.length - 1, 1);
    hotspotItems = hotspotItems[0];
  } else {
    hotspotItems = items;
  }

  return hotspotItems;
};

export const setHistoryItemsOnHotspotMove = ({ items, itemState, id, versionItems }) => {
  const hotspotItem = [];

  if (itemState && itemState.length > 0) {
    const itemsNotMoved = items.filter(item => item.id !== id);
    const itemMoved = itemState.find(item => item.id === id);

    if (itemMoved) {
      hotspotItem.push(itemMoved);

      const itemsVersioned = [...itemsNotMoved, ...hotspotItem];
      const historyItems = setHistoryItems({ items: itemsVersioned, historyItems: versionItems });
      return historyItems;
    }
  }
  return versionItems;
};

export function mapHotspotProducts({ templateItems = [], existingItems, canvas }) {

  return templateItems.map(hotspot => {
    const imageCanvas = canvas;
    // if the template._item is in the existing items set,
    // then use it
    // otherwise attempt to find hotspot item by
    // closest hotspot
    if (hotspot._item && hotspot._item.itemId) {
      const itemId = hotspot._item.itemId;
      const index = existingItems.findIndex(h => h.item && h.item.itemId === itemId);
      if (index > -1) {
        return {
          ...hotspot,
          item: hotspot._item
        };
      }
    }


    // find the closest hotspot in the existing items
    const x1 = hotspot.dotCoord.x + hotspot.coords.x;
    const y1 = hotspot.dotCoord.y + hotspot.coords.y;
    if (x1 > imageCanvas.width || y1 > imageCanvas.height) {
      return {};
    }
    const distances = existingItems.map(existingSpot => {
      const x2 = existingSpot.dotCoord.x + existingSpot.coords.x;
      const y2 = existingSpot.dotCoord.y + existingSpot.coords.y;
      const a = Math.abs(x1 - x2);
      const b = Math.abs(y1 - y2);
      const c = Math.hypot(a, b);
      return c;
    });
    const minDistance = Math.min(...distances);
    const index = distances.indexOf(minDistance);
    if (existingItems[index]) {
      const item = existingItems[index].item;
      if (item) {
        return {
          ...hotspot,
          item
        };
      }
    }
    return hotspot;
  }).filter(items => items.id);
}

const CurationHotSpotReducer = (state = defaultState, action) => {
  let items;
  switch (action.type) {
    case types.HOTSPOT_SET_CANVAS_COORDS:
      return {
        ...state,
        isCanvasDirty: false,
        canvas: {
          ...action.coords
        }
      };
    case types.HOTSPOT_SET_DRAG:
      return {
        ...state,
        isDragging: action.drag,
        isCreating: action.isCreating,
        coords: {
          ...state.cooords,
          start: {
            ...action.coords
          }
        },
        isCanvasDirty: true
      };
    case types.HOTSPOT_RESET_DRAG_COORDS:
      return {
        ...state,
        activeHotspotId: null,
        isMoving: false,
        isResizing: false,
        coords: {
          ...state.coords,
          ...action.coords
        },
        isCanvasDirty: true,
        items: state.items.map(hotspot => {
          return {
            ...hotspot,
            active: false
          };
        })
      };
    case types.HOTSPOT_SET_NEW_HOTSPOT:
      return {
        ...state,
        isMoving: false,
        isCreating: false,
        isResizing: false,
        isCanvasDirty: true,
        historyItems: setHistoryItems({ items: state.items, historyItems: state.historyItems }),
        items: [...state.items.map(h => {
          return { ...h, active: false };
        }) || [], { coords: { ...action.coords }, id: action.id, active: true }]
      };
    case types.HOTSPOT_CLEAR:
      return {
        ...state,
        isCanvasDirty: true,
        historyItems: setHistoryItems({ items: state.items, historyItems: state.historyItems }),
        items: []
      };
    case types.HOTSPOT_LOAD_ITEMS:

      return {
        ...state,
        isCanvasDirty: true,
        items: action.items,
        itemState: action.items,
        historyItems: []
      };
    case types.HOTSPOT_APPLY_TEMPLATE:
      items = mapHotspotProducts(
        {
          templateItems: action.items,
          existingItems: state.items,
          canvas: action.canvas
        });
      return {
        ...state,
        isCanvasDirty: true,
        items,
        itemState: items,
        historyItems: []
      };
    case types.HOTSPOT_SAVE_TEMPLATE:
      return {
        ...state,
        hotspotTemplates: [
          ...(state.hotspotTemplates || []),
          {
            id: guid(),
            name: action.name,
            items: action.hotspotItems.map(hotspotItem => {
              return {
                ...hotspotItem,
                // save it for applying if in the applied template
                // but remove it from the template iteself
                _item: hotspotItem.item,
                item: null
              };
            })
          }
        ]
      };
    case types.HOTSPOT_CLEAR_TEMPLATES:
      return {
        ...state,
        hotspotTemplates: []
      };
    case types.HOTSPOT_SET_REPOSITION:
      return {
        ...state,
        isMoving: action.move,
        isCanvasDirty: true,
        activeHotspotId: action.id,
        items: state.items.map(item => {
          if (item.id === action.id) {
            return {
              ...item,
              isMoving: action.move,
              mouse: null
            };
          }
          return item;
        })
      };
    case types.HOTSPOT_SET_RESIZE:
      return {
        ...state,
        isCanvasDirty: true,
        isResizing: action.resize,
        activeHotspotId: action.id,
        items: state.items.map(hotspot => {
          if (hotspot.id === action.id) {
            return {
              ...hotspot,
              direction: action.direction,
              isResizing: action.resize
            };
          }
          return hotspot;
        })
      };
    case types.HOTSPOT_SET_REPOSITION_COORDS:
      return {
        ...state,
        isCanvasDirty: true,
        items: state.items.map(item => {
          if (item.id === action.id) {
            return {
              ...item,
              mouse: {
                ...action.mousePosition
              },
              coords: {
                ...item.coords,
                ...action.coords
              }
            };
          }
          return item;
        })
      };
    case types.CREATE_DOT_ON_HOTSPOT:
      return {
        ...state,
        isCanvasDirty: true,
        historyItems: setHistoryItems({ items: state.items, historyItems: state.historyItems }),
        itemState: state.items,
        items: state.items.map(item => {
          if (item.id === action.id) {
            return {
              ...item,
              dotCoord: {
                ...item.dotCoord,
                ...action.coords
              }
            };
          }
          return item;
        })
      };
    case types.DELETE_HOTSPOT:
      return {
        ...state,
        isCanvasDirty: true,
        historyItems: setHistoryItems({ items: state.items, historyItems: state.historyItems }),
        items: state.items.filter(item => {
          return item.id !== action.hotspotId;
        })
      };
    case types.CLEAR_SPOT_IN_HOTSPOT:
      return {
        ...state,
        isCanvasDirty: true,
        historyItems: setHistoryItems({ items: state.items, historyItems: state.historyItems }),
        items: state.items.map(item => {
          if (item.id === action.hotspotId) {
            return {
              ...item,
              dotCoord: {}
            };
          }
          return item;
        })
      };
    case types.HOTSPOT_SET_ACTIVE:
      return {
        ...state,
        isCanvasDirty: true,
        activeHotspotId: action.id,
        items: state.items.map(hotspot => {
          if (hotspot.id === action.id) {
            return {
              ...hotspot,
              active: true,
              hidden: false
            };
          }
          return {
            ...hotspot,
            active: false
          };
        })
      };
    case types.HOTSPOT_TOGGLE_VISIBILITY:
      return {
        ...state,
        isCanvasDirty: true,
        items: state.items.map(hotspot => {
          if (!action.hotspotId || hotspot.id === action.hotspotId) {
            return {
              ...hotspot,
              hidden: typeof action.show !== 'undefined'
                ? !action.show
                : !hotspot.hidden
            };
          }
          return hotspot;
        })
      };
    case types.HOTSPOT_SET_ITEM:
      return {
        ...state,
        isCanvasDirty: true,
        historyItems: setHistoryItems({ items: state.items, historyItems: state.historyItems }),
        itemState: state.items,
        items: state.items.map((item) => {
          if (item.id === action.hotspotId) {
            return {
              ...item,
              item: action.item ?
                { ...action.item }
                : null
            };
          }
          return item;
        })
      };
    case types.HOTSPOT_GRID_VIEW_STATUS:
      return {
        ...state,
        gridViewStatus: !state.gridViewStatus
      };
    case types.HOTSPOT_UPDATED_ITEM:
      return {
        ...state,
        isCanvasDirty: true,
        items: getModifiedItems({
          dragItemIndex: action.dragItemsIndex,
          dropItemIndex: action.dropItemsIndex,
          allItems: state.items
        })
      };
    case types.HOTSPOT_TOGGLE_PRIORITY:
      return {
        ...state,
        isCanvasDirty: true,
        items: state.items.map(item => {
          if (item.id === action.id) {
            return {
              ...item,
              priorityStatus: action.priorityStatus
            };
          }
          return item;
        })
      };
    case types.HOTSPOT_UPDATED_ITEM_ON_PRIORITY:
      return {
        ...state,
        isCanvasDirty: true,
        items: getItemsOnPriority({
          hotspotId: action.hotspotId,
          allItems: state.items,
          priorityStatus: action.priorityStatus
        })
      };
    case types.HOTSPOT_TOGGLE_EXACT_MATCH_AND_REPLACEMENT:
      return {
        ...state,
        isCanvasDirty: true,
        items: state.items.map(item => {
          if (item.id === action.id) {
            return {
              ...item,
              replacement: action.replacement
            };
          }
          return item;
        })
      };
    case types.SET_PREVIOUS_HOTSPOT:
      return {
        ...state,
        isCanvasDirty: true,
        items: getPreviousVersionItems({ items: state.items, historyItems: state.historyItems })
      };
    case types.UPDATE_HISTORY_ITEMS_ON_MOVE:
      return {
        ...state,
        isCanvasDirty: true,
        historyItems: setHistoryItemsOnHotspotMove(
          {
            items: state.items,
            itemState: state.itemState,
            versionItems: state.historyItems,
            id: action.id
          }),
        itemState: state.items
      };
    case types.UPDATE_HISTORY_ON_SET_ITEMS:
      return {
        ...state,
        isCanvasDirty: true,
        itemState: state.items
      };
    case types.HOTSPOT_TOGGLE_PINTEREST_PREVIEW:
      return {
        ...state,
        pinterestPreview: !state.pinterestPreview
      };
    case types.SET_HOTSPOT_PINTEREST_PREVIEW_DETAILS:
      return {
        ...state,
        pinterestPreviewHotspot: action.pinterestPreviewHotspot
      };
    case types.SET_DEFAULT_GRID_VIEW_PINTEREST_PREVIEW:
      return {
        ...state,
        pinterestPreviewHotspot: [],
        pinterestPreview: false,
        gridViewStatus: true
      };
    default:
      return state;
  }
};

export const checkHotspotMove = ({ state, hotpspotId }) => {
  const itemsState = state.hotspots.itemState;
  const hotspotItems = state.hotspots.items;
  const hotspotItemWithoutMove = itemsState.find(item => item.id === hotpspotId);
  const hotspotItemWithMove = hotspotItems.find(item => item.id === hotpspotId);
  if (hotspotItemWithoutMove
      && hotspotItemWithoutMove.coords
      && hotspotItemWithMove
      && hotspotItemWithoutMove.coords
      && ((hotspotItemWithoutMove.coords.x !== hotspotItemWithMove.coords.x)
         || (hotspotItemWithoutMove.coords.y !== hotspotItemWithMove.coords.y)
         )
      ) {
    return true;
  } else if (hotspotItemWithMove && hotspotItemWithMove.coords && !hotspotItemWithoutMove) {
    return true;
  }
  return false;
};

export const getActiveHotstopDetails = ({ hotspots, hotSpotId }) => {
  const activeHotspotId = hotSpotId || hotspots.activeHotspotId;
  return hotspots.items.find(item => item.id === activeHotspotId);
};

export const getHotspotWithoutItem = ({ state }) => {
  return (state.items || []).filter(hotspot => !hotspot.item || !hotspot.item.itemId);
};

export const getHotspotWithItem = ({ state }) => {
  return (state.items || []).filter(hotspot => hotspot.item
                                               && hotspot.item.$imageSrc
                                               && hotspot.item.storeSku
                                               && hotspot.item.storeSku.fulfillmentOptions);
};

export const getHotspotDiscontinuedItem = ({ state }) => {
  return (state.items || []).filter(hotspot => hotspot.item
                                               && (!hotspot.item.$imageSrc
                                                    || !hotspot.item.storeSku
                                                    || !hotspot.item.storeSku.fulfillmentOptions
                                                  )
                                               && hotspot.item.itemId);
};

export const togglePriorityFalseForDiscontinuedItems = ({ discontinuedItem }) => {

  return discontinuedItem.map(item => {

    const unAvailableItem = item;
    unAvailableItem.priorityStatus = false;

    return unAvailableItem;
  });
};

const getMatchingItems = ({ lItems, items }) => {
  let allItems = [];
  const matchingItems = lItems.map(listItem => {
    const listItemId = parseInt(listItem.itemId, 10);

    return items.find(imageItem => listItemId === imageItem.itemId);
  }).filter(i => i);

  for (let i = 0; i < matchingItems.length; i++) {

    if (!allItems || allItems.length === 0) {
      allItems = items.filter(imageItems => matchingItems[i].itemId === imageItems.itemId);
    } else {
      allItems = allItems.concat(items.filter(imageItems =>
        matchingItems[i].itemId === imageItems.itemId)
      );
    }
  }
  return allItems;
};

export const getHotspotImage = ({ list, imageGuid }) => {
  const imageBoxItems = (list.imageBoundBox || []).filter(im => im.guid === imageGuid);
  if ((list.items[0] && list.items[0].length > 0) || (list.items && list.items.length > 0)) {
    const lItems = list.type === 'collection'
      ? (list.items[0].items || [])
      : (list.items || []);
    const emptyItems = imageBoxItems.filter(imageItems => !imageItems.itemId);
    const items = imageBoxItems.filter(imageItems => imageItems.itemId);
    const itemsInListAndImage = getMatchingItems({ lItems, items });
    const hotspotImageItems = [...itemsInListAndImage, ...emptyItems];
    return hotspotImageItems;
  } else if (imageBoxItems && imageBoxItems.length > 0) {
    return imageBoxItems;
  }
  return [];
};

export const serializeHotspotItems = ({ state, imageGuid }) => {
  const canvas = state.hotspots.canvas;
  return state.hotspots.items.map((hotspot, index) => {
    const bbXPosition = util.toPercent({ value: hotspot.coords.x, total: canvas.width });
    const bbYPosition = util.toPercent({ value: hotspot.coords.y, total: canvas.height });
    const hotspotExactMatch = !hotspot.replacement;
    const hotspotPriority = hotspot.priorityStatus ? hotspot.priorityStatus : false;
    const hsXPosition = hotspot.dotCoord &&
    hotspot.dotCoord.x &&
    util.toPercent({ value: hotspot.dotCoord.x, total: canvas.width }) + bbXPosition;
    const hsYPosition = hotspot.dotCoord &&
    hotspot.dotCoord.y &&
    util.toPercent({ value: hotspot.dotCoord.y, total: canvas.height }) + bbYPosition;
    return {
      guid: imageGuid,
      itemId: hotspot.item && hotspot.item.itemId,
      bbHeight: util.toPercent({ value: hotspot.coords.height, total: canvas.height }),
      bbWidth: util.toPercent({ value: hotspot.coords.width, total: canvas.width }),
      bbXPosition,
      bbYPosition,
      hsXPosition,
      hsYPosition,
      exactMatch: hotspotExactMatch,
      priority: hotspotPriority,
      position: index
    };
  });
};

export default CurationHotSpotReducer;
