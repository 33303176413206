import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import * as actions from './merchandisingAttributes.actions';
import MerchComponent from './MerchandisingAttributes.component';

const isMerchandisingAttrExist = ({ state }) => {
  if (state.maintenanceDetails.meta && state.maintenanceDetails.meta.dimensions) {
    return true;
  }
  return false;
};

const mapStateToProps = (state, ownProps) => {
  const itemId = ownProps.match.params.itemId;
  const attributes = (isMerchandisingAttrExist({ state }) ?
    state.maintenanceDetails.meta.dimensions : []);
  return {
    item: {
      itemId
    },
    attributes
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    actions: bindActionCreators({
      ...actions,
    }, dispatch)
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MerchComponent)
);