export default {
  searchReport: {
    totalProducts: 20,
    pageSize: 10,
    currentPage: 0,
    resultType: 'sliders'
  },
  items: [
    {
      itemId: '207194174',
      title: 'Lattice Folding White Outdoor Adirondack Chair (2-Pack)',
      imageSrc: '//www.homedepot.com/catalog/productImages/400_compressed/' +
      '48/480ac697-9a16-4fcb-a1af-beb5a8465a38_400_compressed.jpg',
      productUrl: '/p/Lattice-Folding-White-Outdoor-Adirondack-Chair' +
      '-2-Pack-2-1-1088WHITE/207194174',
      modelNumber: '2.1.1088WHITE',
      price: '139.96'
    },
    {
      itemId: '205972430',
      title: 'Maya Eucalyptus Sectional Corner Patio Chair with Khaki Cushions by Jamie Durie',
      imageSrc: '//www.homedepot.com/catalog/productImages/400_compressed/79/' +
      '79290ba8-6726-4034-bcd4-2bba1be392bf_400_compressed.jpg',
      productUrl: '/p/Amazonia-Maya-Eucalyptus-Sectional-Corner-Patio-Chair-with' +
      '-Khaki-Cushions-by-Jamie-Durie-SC-DERBCOR/205972430',
      modelNumber: 'SC DERBCOR',
      price: '551.24'
    },
    {
      itemId: '205972431',
      title: 'Maya Eucalyptus Sectional Middles Patio Chair with Khaki Cushions by Jamie Durie',
      imageSrc: '//www.homedepot.com/catalog/productImages/400_compressed/2e/' +
      '2e9dbe78-3259-455f-b1ed-7dc5a0aaab0a_400_compressed.jpg',
      productUrl: '/p/Amazonia-Maya-Eucalyptus-Sectional-Middles-Patio-Chair-with' +
      '-Khaki-Cushions-by-Jamie-Durie-SC-DERBMID/205972431',
      modelNumber: 'SC DERBMID',
      price: '649.00'
    },
    {
      itemId: '300278147',
      title: 'Pinery Right Arm Patio Chair with Beige Cushion',
      imageSrc: '//www.homedepot.com/catalog/productImages/400_compressed/ca/' +
      'ca2ebe05-df96-4cc5-8e99-04116a56c3e1_400_compressed.jpg',
      productUrl: '/p/ZUO-Pinery-Right-Arm-Patio-Chair-with-Beige-Cushion-703794/300278147',
      modelNumber: '703794',
      price: '898.00'
    },
    {
      itemId: '203526723',
      title: 'Beverly Patio Sectional Middle Chair with Beverly Beige Cushion',
      imageSrc: '//www.homedepot.com/catalog/productImages/400_compressed/b7/' +
      'b7a6ad5b-0e97-4476-acc6-97018fe9a1b7_400_compressed.jpg',
      productUrl: '/p/Hampton-Bay-Beverly-Patio-Sectional-Middle-Chair-with-' +
      'Beverly-Beige-Cushion-65-610233M/203526723',
      modelNumber: '65-610233M',
      price: '199.00'
    },
    {
      itemId: '205169301',
      title: 'Edington Right Arm Facing Patio Sectional Chair with Celery Cushion',
      imageSrc: '//www.homedepot.com/catalog/productImages/400_compressed/b1/' +
      'b13e0c29-b93b-474a-b65b-4ae194d39e63_400_compressed.jpg',
      productUrl: '/p/Hampton-Bay-Edington-Right-Arm-Facing-Patio-Sectional-Chair-with-' +
      'Celery-Cushion-141-034-RSC/205169301',
      modelNumber: '141-034-RSC',
      price: '139.30'
    },
    {
      itemId: '203056405',
      title: 'Beverly Patio Sectional Middle Chair with Cardinal Cushion',
      imageSrc: '//www.homedepot.com/catalog/productImages/400_compressed/89/' +
      '893a68ce-dfd8-4b16-a57c-198e91cf7a31_400_compressed.jpg',
      productUrl: '/p/Hampton-Bay-Beverly-Patio-Sectional-Middle-Chair-with-' +
      'Cardinal-Cushion-65-510233M/203056405',
      modelNumber: '65-510233M',
      price: '159.20'
    },
    {
      itemId: '204411326',
      title: 'Eastham Patio Lounge Chair (2-Pack)',
      imageSrc: '//www.homedepot.com/catalog/productImages/400_compressed/c7/' +
      'c750bef8-edaf-43cb-a6e0-e91a56b2bd9c_400_compressed.jpg',
      productUrl: '/p/Hampton-Bay-Eastham-Patio-Lounge-Chair-2-Pack-754-000-001/204411326',
      modelNumber: '754.000.001',
      price: '259.00'
    },
    {
      itemId: '207112318',
      title: 'Original Dream 360° Rotating Single Patio Lounge Chair with Orange Zest Cushion',
      imageSrc: '//www.homedepot.com/catalog/productImages/400_compressed/87/' +
      '8743cb5e-6416-46fc-a984-63b1115f98c8_400_compressed.jpg',
      productUrl: '/p/Vivere-Original-Dream-360-Rotating-Single-Patio-Lounge-Chair-with-' +
      'Orange-Zest-Cushion-DRM360-OZ/207112318',
      modelNumber: 'DRM360-OZ',
      price: '266.43'
    },
    {
      itemId: '204395953',
      title: 'Bryant Cove Patio Dining Chair (2-Pack)',
      imageSrc: '//www.homedepot.com/catalog/productImages/400_compressed/67/' +
      '67fd8b54-2747-448f-98de-df1710151748_400_compressed.jpg',
      productUrl: '/p/Martha-Stewart-Living-Bryant-Cove-Patio-Dining-Chair-2-Pack-DYBC-D/204395953',
      modelNumber: 'DYBC-D',
      price: '199.00'
    },
    {
      itemId: '205949109',
      title: 'Naples All-Weather Dark Wicker Patio Right or Left Arm ' +
      'Sectional Chair with Putty Cushions',
      imageSrc: '//www.homedepot.com/catalog/productImages/400_compressed/23/' +
      '23130c0c-6582-4e91-a5b5-33e7b1204aac_400_compressed.jpg',
      productUrl: '/p/Home-Decorators-Collection-Naples-All-Weather-Dark-Wicker-Patio-' +
      'Right-or-Left-Arm-Sectional-Chair-with-Putty-Cushions-7765930870/205949109',
      modelNumber: '7765930870',
      price: '459.00'
    },
    {
      itemId: '205465743',
      title: 'Northshore Left Arm Patio Sectional Chair with Harvest Cushion and ' +
      'Regency Wren Outdoor Throw Pillow -- STOCK',
      imageSrc: '//www.homedepot.com/catalog/productImages/400_compressed/43/' +
      '4377f171-75d9-4cbe-8e93-c61f675d7f77_400_compressed.jpg',
      productUrl: '/p/Brown-Jordan-Northshore-Left-Arm-Patio-Sectional-Chair-with-' +
      'Harvest-Cushion-and-Regency-Wren-Outdoor-Throw-Pillow-STOCK-DY6061-LEF/205465743',
      modelNumber: 'DY6061-LEF',
      price: '699.00'
    },
    {
      itemId: '205465462',
      title: 'Northshore Patio Corner Sectional Chair in Harvest with ' +
      'Regency Wren Outdoor Throw Pillow -- STOCK',
      imageSrc: '//www.homedepot.com/catalog/productImages/400_compressed/9e/' +
      '9edb5730-3b0d-4851-9fdf-f1d78d7b7458_400_compressed.jpg',
      productUrl: '/p/Brown-Jordan-Northshore-Patio-Corner-Sectional-Chair-in-' +
      'Harvest-with-Regency-Wren-Outdoor-Throw-Pillow-STOCK-DY6061-COR/205465462',
      modelNumber: 'DY6061-COR',
      price: '799.00'
    },
    {
      itemId: '204396109',
      title: 'Clairborne Motion Patio Lounge Chair with Cushion ' +
      'Insert (2-Pack) (Slipcovers Sold Separately)',
      imageSrc: '//www.homedepot.com/catalog/productImages/400_compressed/6f/' +
      '6f88100a-2961-4f8d-9f46-fb0cf055eb56_400_compressed.jpg',
      productUrl: '/p/Hampton-Bay-Clairborne-Motion-Patio-Lounge-Chair-with-' +
      'Cushion-Insert-2-Pack-Slipcovers-Sold-Separately-DY11079-A-B/204396109',
      modelNumber: 'DY11079-A-B',
      price: '320.00'
    },
    {
      itemId: '206723751',
      title: 'Naples All-Weather Brown Wicker Patio Corner Sectional Chair with Spice Cushions',
      imageSrc: '//www.homedepot.com/catalog/productImages/400_compressed/3d/' +
      '3d43a034-0abe-4965-aaff-8c306b44b13e_400_compressed.jpg',
      productUrl: '/p/Home-Decorators-Collection-Naples-All-Weather-Brown-Wicker-' +
      'Patio-Corner-Sectional-Chair-with-Spice-Cushions-7765950590/206723751',
      modelNumber: '7765950590',
      price: '749.00'
    },
    {
      itemId: '300278157',
      title: 'Pinery Patio Dining Chair with Beige Cushion (Pack of 2)',
      imageSrc: '//www.homedepot.com/catalog/productImages/400_compressed/c4/' +
      'c4a833ee-cc7f-42e1-a7b1-e7003eaed22c_400_compressed.jpg',
      productUrl: '/p/ZUO-Pinery-Patio-Dining-Chair-with-Beige-Cushion-Pack-of-2-703790/300278157',
      modelNumber: '703790',
      price: '636.00'
    },
    {
      itemId: '205169335',
      title: 'Edington Armless Middle Center Patio Sectional Chair with ' +
      'Cushion Insert (Slipcovers Sold Separately)',
      imageSrc: '//www.homedepot.com/catalog/productImages/400_compressed/d1/' +
      'd148337f-3c59-4156-961c-511feb6eb51f_400_compressed.jpg',
      productUrl: '/p/Hampton-Bay-Edington-Armless-Middle-Center-Patio-Sectional-' +
      'Chair-with-Cushion-Insert-Slipcovers-Sold-Separately-141-034-CTR-NF/205169335',
      modelNumber: '141-034-CTR-NF',
      price: '86.80'
    },
    {
      itemId: '300278148',
      title: 'Pier Corner Patio Sectional Chair with Gray Cushion',
      imageSrc: '//www.homedepot.com/catalog/productImages/400_compressed/ef/' +
      'ef843c0f-ee49-4a58-8cfb-139d0a3c994b_400_compressed.jpg',
      productUrl: '/p/ZUO-Pier-Corner-Patio-Sectional-Chair-with-Gray-Cushion-703788/300278148',
      modelNumber: '703788',
      price: '478.00'
    },
    {
      itemId: '203018733',
      title: 'Cibola Patio Sectional Corner Chair with Nutmeg Cushions',
      imageSrc: '//www.homedepot.com/catalog/productImages/400_compressed/9d/' +
      '9d2afac1-d1be-44ad-acaf-2ead45aee777_400_compressed.jpg',
      productUrl: '/p/Hampton-Bay-Cibola-Patio-Sectional-Corner-Chair-with-' +
      'Nutmeg-Cushions-FW-HUNCACHF-I2/203018733',
      modelNumber: 'FW-HUNCACHF-I2',
      price: '369.00'
    },
    {
      itemId: '300278152',
      title: 'Pinery Armless Middle Patio Sectional Chair with Beige Cushion',
      imageSrc: '//www.homedepot.com/catalog/productImages/400_compressed/2d/' +
      '2d63fdc5-f616-43e1-bfeb-79d0acda59f1_400_compressed.jpg',
      productUrl: '/p/ZUO-Pinery-Armless-Middle-Patio-Sectional-Chair-' +
      'with-Beige-Cushion-703796/300278152',
      modelNumber: '703796',
      price: '598.00'
    },
    {
      itemId: '100679749',
      title: 'Palmetto Estates Patio Dining Chair-DISCONTINUED',
      imageSrc: '//www.homedepot.com/catalog/productImages/400_compressed/f2/' +
      'f2290bff-02dd-4ebf-8e2c-c75c17e83938_400_compressed.jpg',
      productUrl: '/p/Thomasville-Palmetto-Estates-Patio-Dining-Chair-' +
      'DISCONTINUED-12271-311-1/100679749',
      modelNumber: '12271-311-1',
      price: '199.00'
    },
    {
      itemId: '205169300',
      title: 'Edington Bronze Right Arm Patio Sectional Chair ' +
      'with Cushion Insert (Slipcovers Sold Separately)',
      imageSrc: '//www.homedepot.com/catalog/productImages/400_compressed/7d/' +
      '7dbcd49d-4ba8-461b-834e-9cbc50af8789_400_compressed.jpg',
      productUrl: '/p/Hampton-Bay-Edington-Bronze-Right-Arm-Patio-Sectional-Chair-with-' +
      'Cushion-Insert-Slipcovers-Sold-Separately-141-034-RSC-NF/205169300',
      modelNumber: '141-034-RSC-NF',
      price: '73.50'
    },
    {
      itemId: '300278146',
      title: 'Pinery Left Arm Patio Sectional Chair with Beige Cushion',
      imageSrc: '//www.homedepot.com/catalog/productImages/400_compressed/07/' +
      '075a20ae-d239-44e1-a39f-cc319cd80e68_400_compressed.jpg',
      productUrl: '/p/ZUO-Pinery-Left-Arm-Patio-Sectional-Chair-' +
      'with-Beige-Cushion-703795/300278146',
      modelNumber: '703795',
      price: '898.00'
    },
    {
      itemId: '204400313',
      title: 'Beverly Patio Sectional Middle Chair with ' +
      'Cushion Insert (Slipcovers Sold Separately)',
      imageSrc: '//www.homedepot.com/catalog/productImages/400_compressed/ac/' +
      'acf8dd94-5ad0-44c2-bcf1-2e357bc76dad_400_compressed.jpg',
      productUrl: '/p/Hampton-Bay-Beverly-Patio-Sectional-Middle-Chair-with-' +
      'Cushion-Insert-Slipcovers-Sold-Separately-55-510233M/204400313',
      modelNumber: '55-510233M',
      price: '127.40'
    }
  ]
};
