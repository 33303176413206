import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from './actions';
import Accordion from './component';

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.id;
  const title = ownProps.title;
  return {
    title,
    id,
    children: ownProps.children,
    loaders: state.loaders || {},
    className: ownProps.className || '',
    subtitle: ownProps.subtitle || ''
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    actions: bindActionCreators({
      ...actions
    }, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Accordion);
