import * as types from '../../../../constants/ActionTypes';
import * as util from '../../../../util';
import * as api from '../../../../util/api';
const PAGE_SIZE = 24;

export const createNewListAndRouteToIt = ({ type = 'curation',
id = new Date().getTime() } = {}) => {
  return (dispatch, getState) => {
    const guid = util.guid();
    const state = getState();
    const meta = state.app.meta;
    // return api.createList({ type })
    //   .then(resp => {
    let resp = {
      id,
      name: type === 'curation' ? '[curation]' : '',
      isTemp: true
    };
    dispatch({ type: types.CREATE_CURATION_LIST_FROM_TEMPLATE, meta, resp, listType: type });
    return resp;
  };
};

export const resetUISettings = ({ id } = {}) => {
  return (dispatch, getState) => {
    const state = getState();
    const meta = state.app.meta;
    const activeTab = state.ui.menu.activeTab;
    dispatch({ type: types.RESET_UI_SETTINGS, id, meta });
    dispatch({ type: types.SET_ACTIVE_MENU_TAB, activeTab });
  };
};

export const toggleListSelection = ({ listId }) => {
  return (dispatch, getState) => {
    dispatch({ type: types.TOGGLE_LIST_SELECTION, listId });
    // Clearing previous selected from app level meta dimensions.
    dispatch({ type: types.CLEAR_APP_META_ATTRIBUTES_SELECTION });
  };
};

export const updateSelectedListsMetaData = ({ value, dimensionId, attributeId, listType }) => {
  return (dispatch, getState) => {
    dispatch({ type: types.UPDATE_APP_METADATA, dimensionId, attributeId, value, listType });
    dispatch({ type: types.UPDATE_SELECTED_LISTS_METADATA, dimensionId, attributeId, value });
    dispatch({ type: types.CLEAR_MULTI_LIST_ATTRIBUTE_EDIT_MODE });
    dispatch({ type: types.GENERIC_ERROR_HANDLER_CLEAR });
    const state = getState();
    // Get the selected lists and pass it to metaUpdate serice.
    const lists = state.curationList.lists.filter(l => l._selected);
    return api.updateMultiListMetaData({ lists, type: listType }).then(response => {
      if (response.errorCode) {
        dispatch({ type: types.ADD_CURATION_ERROR, response: [response] });
      }
      dispatch({
        type: types.SET_MULTI_LIST_DATA, response, meta: state.app.meta
      });
    });
  };
};

export const setCurationFilterKeyword = ({ keyword }) => {
  return { type: types.SET_CURATION_KEYWORD_FILTER, keyword };
};

export const toggleDefaultCurationList = ({ defaultCurationList = false }) => {
  return { type: types.SET_DEFAULT_CURATION_LIST, defaultCurationList };
};

export const getLists = ({ startIndex = 0, sortBy, sortOrder,
  keyword, type = 'curation', pinterest } = {}) => {
  if(sortBy=="count"){
     sortBy="collectionCount";
  }
  return (dispatch, getState) => {
    // const userId = state.app.user.userId;
    dispatch({ type: types.INITIATE_LOADER, loader: 'listByUser' });
    api.getLists({ startIndex, sortBy, sortOrder, keyword, type, pinterest })
    .then(resp => {
      dispatch({ type: types.REMOVE_LOADER, loader: 'listByUser' });
      dispatch({ type: types.CLEAR_APP_META_ATTRIBUTES_SELECTION });
      const state = getState();
      dispatch({ type: types.REFRESH_CURATION_LISTS, resp, meta: state.app.meta });
    });
  };
};

export const setSortType = ({ sortName, sortType, listType }) => {
  return (dispatch, getState) => {
    const state = getState();
    const keyword = state.ui.curationList.filterKeyword;
    dispatch(getLists({
      startIndex: 0,
      sortBy: sortName,
      sortOrder: sortType,
      keyword,
      type: listType
    }));
    dispatch({ type: types.SET_SORT_TYPE, sortName, sortType });
  };
};

export const filterCurations = ({ keyword, type }) => {
  return (dispatch) => {
    dispatch({ type: types.RESET_SORT_TYPE });
    dispatch(getLists({ keyword, type }));
  };
};

export const handlePageChanged = ({ newPage, type = 'curation' }) => {
  return (dispatch, getState) => {
    const state = getState();
    const keyword = state.ui.curationList.filterKeyword;
    const sortBy = state.ui.curationList.sortName !== ''
      ? state.ui.curationList.sortName
      : 'updatedAt';
    const sortOrder = state.ui.curationList.sortType;
    dispatch(getLists({
      startIndex: Math.ceil(newPage) * PAGE_SIZE,
      sortBy,
      sortOrder,
      keyword,
      type
    }));
  };
};

export const publishLists = ({ type = 'curation' }) => {
  return (dispatch, getState) => {
    let state = getState();
    dispatch({ type: types.CLEAR_MULTI_LIST_ATTRIBUTE_EDIT_MODE });
    // Get the selected lists and pass it to publishmultilist serice.
    const lists = state.curationList.lists.filter(l => l._selected);
    dispatch({ type: types.INITIATE_LOADER, loader: 'curation-status' });
    dispatch({ type: types.GENERIC_ERROR_HANDLER_CLEAR });
    const listIds = lists.map(list => {
      return { listId: list.id };
    });
    return api.publishMultiList({ listIds, type })
      .then(resp => {
        dispatch({ type: types.REMOVE_LOADER, loader: 'curation-status' });
        if (resp.errorCode) {
          dispatch({ type: types.ADD_CURATION_ERROR, response: [resp] });
        } else if (resp.errorModel) {
          dispatch({ type: types.ADD_CURATION_ERROR, response: resp.errorModel });
        }
        if (resp.lists) {
          dispatch({ type: types.UPDATE_MULTI_LIST_STATUS, lists: resp.lists });
        }
      });
  };
};

export const unpublishLists = ({ type = 'curation' }) => {
  return (dispatch, getState) => {
    let state = getState();
    dispatch({ type: types.CLEAR_MULTI_LIST_ATTRIBUTE_EDIT_MODE });
    // Get the selected lists and pass it to unpublish lists serice.
    const lists = state.curationList.lists.filter(l => l._selected);
    dispatch({ type: types.INITIATE_LOADER, loader: 'curation-unpublish-status' });
    dispatch({ type: types.GENERIC_ERROR_HANDLER_CLEAR });
    const listIds = lists.map(list => {
      return { listId: list.id };
    });
    return api.deactivateMultilist({ listIds, type })
      .then(resp => {
        dispatch({ type: types.REMOVE_LOADER, loader: 'curation-unpublish-status' });
        if (resp.errorCode) {
          dispatch({ type: types.ADD_CURATION_ERROR, response: [resp] });
        } else if (resp.errorModel) {
          dispatch({ type: types.ADD_CURATION_ERROR, response: resp.errorModel });
        }
        if (resp.lists) {
          dispatch({ type: types.UPDATE_MULTI_LIST_STATUS, lists: resp.lists });
        }
      });
  };
};

export const toggleListNameEditMode = ({ listId }) => {
  return { type: types.TOGGLE_LIST_NAME_EDIT_MODE, listId };
};

export const updateSelectedListName = ({ listId, name }) => {
  return (dispatch, getState) => {
    dispatch({ type: types.UPDATE_CURATION_NAME, name, curationId: listId });
    dispatch({ type: types.INITIATE_LOADER, loader: `multilist-name-update-${listId}` });
    dispatch({ type: types.GENERIC_ERROR_HANDLER_CLEAR });
    const state = getState();
    const list = state.curationList.lists.find(l => l.id === listId);
    api
      .updateListMeta({
        listId,
        list,
        type: list.type
      })
      .then(response => {
        dispatch({ type: types.REMOVE_LOADER, loader: `multilist-name-update-${listId}` });
        if (response.errorCode) {
          dispatch({ type: types.ADD_CURATION_ERROR, response: [response] });
        } else {
          dispatch({ type: types.SET_LIST_DATA_WITH_NAME_EDIT, id: list.id,
            response, meta: state.app.meta });
        }
      });
  };
};

export const toggleAttributeAddRemove = ({ listId, attributeId, dimensionId }) => {
  return (dispatch, getState) => {
    dispatch({ type: types.CLEAR_APP_META_ATTRIBUTES_SELECTION });
    dispatch({ type: types.TOGGLE_MULTI_LIST_ATTRIBUTE_EDIT_MODE, listId,
      dimensionId, attributeId });
  };
};

export const resetPinterestStatus = () => {
  return { type: types.RESET_PINTEREST_STATUS, value: null };
};

export const resetSortType = () => {
  return { type: types.RESET_SORT_TYPE };
};

export const addRemoveCurationIdToSearch = ({ listId }) => {
  return (dispatch, getState) => {
    let state = getState();
    let searchKeyword;
    let filterKeyword = state.ui.curationList.filterKeyword;
    if (filterKeyword === '' || filterKeyword.indexOf('id:') === -1) {
      filterKeyword = 'id:';
    }
    if (filterKeyword.indexOf(listId) !== -1) {
      searchKeyword = filterKeyword.replace(listId + ',', '');
    } else {
      searchKeyword = filterKeyword.replace(/id:(?:|!\d+,)+/, function (match) {
        return match + listId + ',';
      });
    }
    if (searchKeyword.split(':')[1] === '') {
      searchKeyword = '';
    }
    dispatch({ type: types.SET_CURATION_KEYWORD_FILTER, keyword: searchKeyword });
  };
};

export const filterListByPinterest = ({ event, pinterest, listType }) => {
  let isChecked = !event.target.previousSibling.checked;
  return (dispatch, getState) => {
    const state = getState();
    const keyword = state.ui.curationList.filterKeyword;
    const sortBy = state.ui.curationList.sortName !== ''
    ? state.ui.curationList.sortName
    : 'updatedAt';
    const sortOrder = state.ui.curationList.sortType;
    const startIndex = 0;
    const type = listType;
    dispatch({ type: types.RESET_PINTEREST_STATUS });
    if (isChecked) {
      dispatch({ type: types.SET_PINTEREST_STATUS, value: pinterest });
      dispatch({ type: types.INITIATE_LOADER, loader: 'listByUser' });
      api.getLists({ startIndex, sortBy, sortOrder, keyword, type, pinterest })
      .then(resp => {
        dispatch({ type: types.REMOVE_LOADER, loader: 'listByUser' });
        dispatch({ type: types.REFRESH_CURATION_LISTS, resp, meta: state.app.meta });
      });
    } else if (!isChecked) {
      dispatch({ type: types.INITIATE_LOADER, loader: 'listByUser' });
      api.getLists({ startIndex, sortBy, sortOrder, keyword, type })
      .then(resp => {
        dispatch({ type: types.REMOVE_LOADER, loader: 'listByUser' });
        dispatch({ type: types.REFRESH_CURATION_LISTS, resp, meta: state.app.meta });
      });
    }
  };
};

export const isCheckedForMultiEdit = ({ listId }) => {
  return (dispatch, getState) => {
    let state = getState();
    const filterKeyword = state.ui.curationList.filterKeyword;
    if (filterKeyword.indexOf('id:') !== -1 && filterKeyword.indexOf(listId) !== -1) {
      return true;
    }
    return false;
  };
};
