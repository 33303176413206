import { API_HOST } from '../../../../constants/environment';
import * as types from '../../../../constants/ActionTypes';
import * as api from '../../../../util/api';
import { fetchCurationList } from '../../App/CurateList/actions';
import resp from './mock';

export const setSearchKeyword = ({ keyword }) => {
  return { type: types.SET_SEARCH_KEYWORD, keyword };
};

export const submitSearchForCuration = ({ keyword, pageSize = 24, startIndex = 0, id }) => {
  return (dispatch, getState) => {
    const state = getState();
    const meta = state.app.meta;
    const list = state.curationList.lists.find(l => l.id === id);
    if (list && list.type === 'collection') {
      dispatch({ type: types.SET_ACTIVE_MENU_TAB, activeTab: 'collections' });
    }
    if (window.gtag) {
      window.gtag('event', 'search', {
        event_label: keyword,
        select_content: list.type
      });
    }
    dispatch(fetchCurationList({ id, keyword, startIndex }))
      .then(res => {
        dispatch({ type: types.SET_ITEM_SELECTED_PAGINATION_VALUE, value: startIndex + 1 });
      });
  };
};

export const submitSearch = ({ keyword }) => {
  return (dispatch) => {
    dispatch({ type: types.CLEAR_ERROR_MESSAGE });
    dispatch({ type: types.INITIATE_LOADER, loader: 'itemResult' });
    if (window.gtag) {
      window.gtag('event', 'search', {
        event_label: keyword,
        select_content: 'curation'
      });
    }
    api.getItemsByKeyword({ keyword })
      .then(res => {
        dispatch({ type: types.REMOVE_LOADER, loader: 'itemResult' });
        dispatch({ type: types.SET_ITEM_SELECTED_PAGINATION_VALUE, value: 1 });
        dispatch({ type: types.UPDATE_ITEMS_DATA, value: res, resultType: 'searchNav' });
        dispatch({ type: types.TOGGLE_CURATELIST_BULK_MODE, bulkMode: false });
      });
    // @TODO uncomment when api is ready
    // fetch(`${API_HOST}/product-search?keyword=${keyword}`)
    //   .then(resp => resp.json())
    //   .then(resp => {
    //     if (resp && resp.length) {
    //       dispatch({ type: types.UPDATE_ITEMS_DATA, value: resp });
    //     }
    //   });
  };
};
