import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import * as actions from './DimensionClassifications.actions';
import DimensionClassificationsComponent from './DimensionClassfications.component';

const mapStateToProps = (state, ownProps) => {

  const dimensionId = ownProps.match.params.id;

  const attributeId = ownProps.match.params.attributeId;

  const users = state.dimensionClassification
                && state.dimensionClassification.users
                && state.dimensionClassification.users.length > 0
                  ? state.dimensionClassification.users
                  : [];

  const searchReport = state.dimensionClassification
                       && state.dimensionClassification.searchReport
                        ? state.dimensionClassification.searchReport
                        : {};

  return {
    loaders: state.app.loaders,
    dimensionId,
    attributeId,
    users,
    searchReport
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    actions: bindActionCreators({
      ...actions,
      handlePageChanged: (newPage) => {
        return (dis, getState) => {
          const attributeId = ownProps.match.params.attributeId;
          const dimensionId = ownProps.match.params.id;
          ownProps.history.push(`/userClassifications/dimensions/${Math.ceil(newPage)
            }/${dimensionId}/${attributeId}`);
        };
      }
    }, dispatch)
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DimensionClassificationsComponent)
);