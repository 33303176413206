import * as types from '../../../constants/ActionTypes';
import { API_HOST } from '../../../constants/environment';
import * as api from '../../../util/api';
import metaDataResponse from './mock';
import * as util from '../../../util';
import { createCollectionFromCuration } from '../App/ReviewList/actions';

export const setSliderValue = ({ sliderId, value }) => {
  return { type: types.SET_SLIDER_VALUE, sliderId, value };
};

export const loginUser = () => {
  return { type: types.LOGIN_USER };
};

export const clearError = () => {
  return { type: types.CLEAR_ERROR_MESSAGE };
};

export const mockPageReloadForReduxStore = (dispatch) => {
  dispatch({ type: types.SET_ACTIVE_MENU_TAB, activeTab: 'curations' });
  dispatch({ type: types.RESET_SORT_TYPE });
  dispatch({ type: types.CLEAR_APP_META_ATTRIBUTES_SELECTION });
  dispatch({ type: types.SET_CURATION_KEYWORD_FILTER, keyword: '' });
};

export const logoutUser = () => {
  return (dispatch) => {
    return api.logoutUser()
      .then(resp => {
        dispatch({ type: types.LOGOUT_USER });
        mockPageReloadForReduxStore(dispatch);
        dispatch(clearError());
        window.sessionStorage.removeItem('auth_token');
        let logoutUrl = util.getAuthLogoutUrl();
        window.location.replace(logoutUrl);
      });
  };
};

export const getUserDetails = () => {
  return (dispatch) => {
    return api.getUserDetails()
      .then(resp => {
        if (resp) {
          dispatch({ type: types.SET_USERNAME, resp });
        }
        return resp;
      });
  };
};

export const getloggedinUserToken = (code) => {
  return (dispatch) => {
    return api.getloggedinUserToken(code)
      .then(resp => {
        if (resp) {
          // console.log(resp);
        }
        return resp;
      });
  };
};

export const createErrorMessage = ({ error }) => {
  return (dispatch) => {
    // @TODO
    console.log('@TODO, create error message ui handler');
  };
};

export const fetchMetaData = () => {
  return (dispatch) => {
    return api.getAllFilters()
      .then(resp => {
        dispatch({ type: types.SET_META_DATA, meta: resp });
      });
  };
};
let ONCE;
export const curationListListner = (router) => {
  return (dispatch, getState) => {
    if (ONCE) return;
    ONCE = true;
    util.addEvent(window, 'message', (message) => {
      // const THD_DOMAIN_REGEX = /^http(s?):\/\/.*\.homedepot(dev)?.com(:?\d*)$/;
      // if (!THD_DOMAIN_REGEX.test(message.origin)) return;
      let data = message.data;
      if (typeof data === 'string') {
        try {
          data = JSON.parse(data);
        } catch (e) {
          //
          return;
        }
      }
      if (data.action === 'event' && data.type === 'get_curation_list') {
        api.cloneCurationList({
          curationId: data.currentList.id,
          curationName: data.listName,
          type: data.currentList.type
        }).then(resp => {
          if (resp && resp.errorCode !== 500) {
            const state = getState();
            dispatch({
              type: types.ADD_LIST,
              curation: resp,
              meta: state.app.meta
            });
            setTimeout(() => {
              router.push(`/${resp.type}/review/` + resp.id);
            }, 500);
          } else if (resp && resp.errorCode === 500) {
            alert(resp.description);
          }
        });
      } else if (data.action === 'event' && data.type === 'create_collection_From_curation') {
        dispatch(createCollectionFromCuration({ listId: parseInt(data.listId, 10) }))
        .then(resp => {
          setTimeout(() => {
            router.push(`/collection/review/${resp.id}`);
          }, 500);
        });
      }
    });
  };
};

export const authenticateUser = ({ userName, password }) => {
  return (dispatch, getState) => {
    dispatch({ type: types.SET_USERNAME, userName });
  };
};
