import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from './actions';
import { updateItemPosition } from '../../actions';
import * as itemActions from '../../../CurateList/actions';
import component from './component';

function mapStateToProps(state, ownProps) {
  const list = ownProps.list;
  const itemId = ownProps.itemId || ownProps.id || ownProps.item.id;
  let item = list.items.find(i => (i.id || i.itemId) === itemId);
  const index = ownProps.index;
  return {
    list,
    ui: state.ui,
    item,
    itemId,
    index
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {

  return {
    actions: bindActionCreators({
      ...actions,
      ...itemActions,
      updateItemPosition

    }, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(component);
