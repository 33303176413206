import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './style.scss';


class FileDropZone extends Component {
  render() {
    const { connectDropTarget, isOver, canDrop, actions, list } = this.props;
    return connectDropTarget(
      <div
        className={`curation-list__message ${isOver
          ? 'curation-list__message--droppable'
          : ''
        }`}
      >
        <span className="discover-icon"></span>
        <h2>HOW TO DISCOVER PRODUCTS</h2>
        <ol>
          <li>Slide the filters on the left</li>
          <li>Search for a specific product above</li>
        </ol>
        <br />
        <h2>HOW TO UPLOAD PRODUCTS</h2>
        <ol start="3">
          <li>Drag and drop a excel spread sheet here with OMSIDs</li>
          <div className="discover-option">Or</div>
          <li>
            <input
              onChange={(e) => {
                e.preventDefault();
                e.persist();
                actions.handleFileSelect(e, { listId: list.id });
              }
              }
              type="file"
            />
          </li>
          <div className="discover-option">Or</div>
          <li>Copy and Paste OMSIDs into a
            <a className="u__default-link discover-textbox"
              onClick={(e) => {
                e.preventDefault();
                actions.showOmsidImportTextBox();
              }}
            > text box</a>
          </li>
        </ol>
      </div>
    );
  }
}

FileDropZone.propTypes = {
  connectDropTarget: PropTypes.func,
  list: PropTypes.object,
  isOver: PropTypes.bool,
  canDrop: PropTypes.bool,
  actions: PropTypes.object
};

export default FileDropZone;