import * as types from '../../../../../../constants/ActionTypes';
import * as api from '../../../../../../util/api';
import diff from 'deep-diff';
import { updateImageSrc } from '../../../CurateList/modules/Item/reducer';

export function versionizeList(list) {
  const copy = {
    ...list,
    meta: {
      ...list.meta
    }
  };
  const dims = copy.meta.dimensions.slice();

  copy.meta.dimensions = dims.map(dim => {
    const attrs = dim.attributes.slice();
    return {
      ...dim,
      attributes: attrs.filter(attr => attr.value)
    };
  });

  copy.items = list.items.map(item => {
    // if (list.type === 'curation') {
    const ret = {
      ...item
    };
    Object.keys(ret).forEach(key => {
      if (/^$|^_/.test(key)) {
        delete ret[key];
      }
    });
    return ret;
   // }
  });
  return copy;
}

// You need to get
export function loadVersionFor({ listId, versionNumber }) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      // currentVersion
      const state = getState();
      let lists = state.curationList.lists.find(l => l.id === listId);
      const listType = lists.type;
      if (typeof versionNumber === 'string') {
        return resolve(versionizeList(lists));
      }
      return api.getListVersionDetails({ listId, versionNumber, listType }).then(payload => {
        const list = payload;
        if (list.type === 'collection') {
          // fetch the curations
          let promises = list.items.map(curation => {
            return api.getListDetailsById({ listId: curation.id, type: 'curation' })
              .then(response => {
                return response;
              }).catch(err => {
                return {
                  error: 'Curation does not exist',
                };
              });
          });
          Promise.all(promises).then(responses => {

            list.items = list.items.map(curation => {
              return {
                ...curation,
                items: responses.find(r => r.id === curation.id || {}).items
              };
            });

            dispatch({
              type: types.LOAD_VERSION_FOR_LIST, listId, versionId: versionNumber, payload: list
            });
            resolve(list);
          });
        } else {
          // ?do you need to fetch the products for curations?
          resolve(list);
        }
      }).catch(e => {
        console.error(e);
        reject(e);
      });
    });
  };
}

export function loadVersionsFor({ listId, newPage = 0 }) {
  return (dispatch, getState) => {

    const startIndex = (Math.ceil(newPage) * 24);

    const state = getState();
    const list = state.curationList.lists.find(l => l.id === listId);
    const listType = list.type;
    dispatch({ type: types.INITIATE_LOADER, loader: 'versions-list' });

    return api.getListVersions({ listId, startIndex, listType }).then(response => {

      let versions = response.versions;

      const searchReport = {
        totalVersions: response.searchReport.totalVersions,
        pageSize: response.searchReport.pageSize,
        startIndex: response.searchReport.startIndex,
        newPage
      };

      if (newPage > 0) {
        const listVersions = list.versions;
        listVersions.splice(0, 1);
        versions = [...listVersions, ...response.versions];
      }

      const payload = [{
        createdAt: list.updatedAt,
        createdBy: 'UNKNOWN',
        id: list.id,
        listVersion: 'Unpublished Changes',
        current: true
      }, ...versions];

      dispatch({ type: types.UPDATE_VERSIONS_FOR_LIST, listId, payload, searchReport });
      dispatch({ type: types.REMOVE_LOADER, loader: 'versions-list' });
      return payload;
    }).catch(e => {
      console.error(e);
    });
  };
}

export function cleanItem(item) {
  const i = {
    ...item
  };
  Object.keys(i).forEach(key => {
    if ((/^_|^\$/.test(key))
      || key === 'groupName'
      || key === 'classifiedAt'
      || key === 'lastupdatedBy') {
      delete i[key];
    }
  });
  delete i.type;
  delete i.results;

  if (item.items) {
    i.items = item.items.map(cleanItem);
  }

  return i;
}


export function splitDetails(details) {

  const ret = {
    details: {
      name: details.name,
      description: details.description,
      status: details.status,
      // updatedAt: details.updatedAt
      // totalChildren: details.totalChildren
    },
    items: details
      .items.map(cleanItem),
    meta: {
      dimensions: details.meta.dimensions.map(dim => {
        return dim.attributes.map(attr => dim.name + ':' + attr.name);
      })
      .reduce((a, c) => a.concat(c), [])
      .reduce((a, c) => {
        const pieces = c.split(':');
        const key = pieces[0];
        const value = pieces[1];
        const arr = (a[key] || []);
        arr.push(value);
        return {
          ...a,
          [key]: arr
        };

      }, {})
    },
    images: details.images,
    imageBoundBox: details.imageBoundBox
  };

  Object.keys(ret.meta.dimensions).forEach(key => {
    ret.meta.dimensions[key] = ret.meta.dimensions[key].join(', ');
  });
  return ret;
}


function diffArray(lhs = [], rhs = [], items = []) {
  let max = lhs.length > rhs.length
    ? lhs.length
    : rhs.length;

  if (max === 0) {
    return items;
  }

  const l = lhs.slice();
  const r = rhs.slice();

  const a = {
    ...lhs[0]
  };
  const b = {
    ...rhs[0]
  };
  if (a.collectionType === 'curation') {
    a.items = [];
    b.items = [];
  }

  const d = diff(a, b);

  if (!d) {
    items.push(lhs[0]);

    l.shift();
    r.shift();

    return diffArray(l, r, items);
  }

  // not the same

  // item removed from right hand side
  let cur = lhs[0];
  let lAddtionIndex = -1;
  let rAdditionIndex = -1;

  if (cur) {
    lAddtionIndex = rhs.findIndex(n => {
      const id = n.itemId || n.id;
      return id === (cur.id || cur.itemId);
      // JSON.stringify(n) === JSON.stringify(cur)
    });
    if (lAddtionIndex === -1 && cur) {
      items.push({
        item: cur,
        diff: true,
        type: 'D'
      });
      l.shift();
      return diffArray(l, r, items);
    }
  }

  // item added to right hand side
  let curR = rhs[0];
  if (curR) {
    rAdditionIndex = lhs.findIndex(n => {
      const id = n.itemId || n.id;
      return id === (curR.id || curR.itemId);
    });
    if (rAdditionIndex === -1) {
      // push the current index of rhs into items
      items.push({
        item: curR,
        diff: true,
        type: 'A'
      });
      r.shift();
      return diffArray(l, r, items);
    }
  }

  // its a move in items
  if (lAddtionIndex !== -1 && rAdditionIndex !== -1) {
    items.push({
      item: cur,
      diff: true,
      type: 'D'
    });
    l.shift();
  } else {
    r.shift();
    l.shift();
  }

  return diffArray(l, r, items);
}

export function compareVersions(old, current) {

  const details = splitDetails(current);
  const detailsOld = splitDetails(old);

  const items = diffArray(detailsOld.items, details.items);
  const images = diffArray(detailsOld.images, details.images);
  const keys = Object.keys(detailsOld.meta.dimensions);
  const meta = diff(detailsOld.meta.dimensions, details.meta.dimensions);
  // delete detailsOld.meta.dimensions[keys[keys.length - 1]];

  // details.meta.dimensions.pop();


  return {
    details: diff(detailsOld.details, details.details),
    meta,
    items: items.map(item => {
      if (item.item) {
        if (item.item.type === 'curation' || item.item.collectionType === 'curation') {
          return {
            ...item,
            item: {
              ...item.item,
              items: item.item.items.map(i => {
                return {
                  ...i,
                  $imageSrc: updateImageSrc({ data: i })
                };
              })
            }
          };
        }
        // product
        return {
          ...item,
          item: {
            ...item.item,
            $imageSrc: updateImageSrc({ data: (item.item) })
          }
        };
      }
      if (item.type === 'curation' || item.collectionType === 'curation') {
        return {
          ...item,
          item: {
            ...item,
            items: item.items.map(i => {
              return {
                ...i,
                $imageSrc: updateImageSrc({ data: i })
              };
            })
          }
        };
      }
      return {
        ...item,
        $imageSrc: updateImageSrc({ data: item })
      };
    }),
    images,
    imageBoundBox: diffArray(detailsOld.imageBoundBox, details.imageBoundBox)
  };
}

export function loadDiffFor({ listId, versionNumber, compareToVersionId, current }) {
  return (dispatch) => {
    return new Promise(resolve => {
      const p = [
        dispatch(loadVersionFor({ listId, versionNumber })),
        dispatch(loadVersionFor({ listId, versionNumber: compareToVersionId }))
      ];
      if (current) {
        p.reverse();
      }
      Promise.all(p).then(([old, c]) => {

        const d = compareVersions(old, c);
        dispatch({
          type: types.LOAD_DIFF_FOR_VERSION,
          listId,
          versionNumber,
          compareToVersionId,
          diff: d,
          state: old
        });
        resolve();
      });
    });
  };
}

export function refreshUnpublishedchanges({ listId, compareToVersionId, current = false }) {
  return (dispatch, getState) => {
    dispatch(loadDiffFor({ listId, versionNumber: 'Unpublished Changes',
      compareToVersionId, current }));
  };
}

export function revertVersion({ listId, listVersion }) {
  return (dispatch, getState) => {
    const state = getState();
    const list = state.curationList.lists.find(l => l.id === listId);
    const version = (list.versions || []).find(v => v.listVersion === listVersion);
    if (version && version.state) {
      // revert the state
      api.updateListMeta({ list: version.state, type: list.type })
        .then(response => {
          api
          .saveList({ list: version.state })
          .then(resp => {
            dispatch({ type: types.SET_REVIEW_LIST_STATUS, listId, status: resp.status });
            window.location.reload();
          });

        });
    }
  };
}

export function closeVersionRows({ listId }) {
  return { type: types.CLOSE_ALL_VERSION_ROWS, listId };
}

export function toggleRow({ listVersion, listId }) {
  return (dispatch, getState) => {
    const state = getState();
    const list = state.curationList.lists.find(l => l.id === listId);
    const versions = list.versions || [];
    const version = versions.find(v => v.listVersion === listVersion);
    const isOpen = version._open;
    dispatch({ type: types.TOGGLE_VERSION_ROW, listVersion, listId });
    if (!isOpen) {
      const compareToVersionId = list.versions[1].listVersion;
      dispatch(loadDiffFor({ listId: list.id, versionNumber: listVersion,
        compareToVersionId, current: version.current }));
    }
  };
}