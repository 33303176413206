import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ItemDragSource from '../Item/ItemDragSource';
import Pager from 'react-pager';
import InlineMessage from '../../../../modules/InlineMessage/component';

class CurationItems extends Component {
  render() {
    const {
      list,
      curationListView,
      searchReport,
      loaders,
      actions,
      multiItemPreview,
      ui,
      itemIds,
      hasSearchResults
    } = this.props;
    return (
    <div className="curation-list-set">
      <div className="row-header-wrapper">
        <div className="row-header">
          <div className="row-header__title">
            {list.$count > 0 && <span className="row-header__text text-caps items__header">
              {list.type} ({list.$count})
            </span>}
            <div className="curation-list__header items__header-links">
            {/* {ui.error && (
            <InlineMessage type="error" message={ui.error.message} />
          )} */}
              <a className="curation-list__view" href="#"
                onClick={(e) => {
                  e.preventDefault();
                  actions.changeCurationListView({ viewType: 'curation' });
                }}
              >
                <i
                  className={curationListView === 'grid' ? 'fa fa-list icon-fade' : 'fa fa-list'}
                  aria-hidden="true"
                ></i>
              </a>
              <a className="curation-list__view" href="#"
                onClick={(e) => {
                  e.preventDefault();
                  actions.changeCurationListView({ viewType: 'grid' });
                }}
              >
                <i
                  className={curationListView === 'curation'
                    ? 'fa fa-th-large icon-fade'
                    : 'fa fa-th-large'
                  }
                  aria-hidden="true"
                ></i>
              </a>
              <a
                className={`row-header__link ${ui.curateList.editMode
                  ? 'curationList__Sort--Header'
                  : ''}`
                }
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  actions.toggleCurateListEditMode();
                }}
              >
                {!ui.curateList.editMode &&
                  <span>Remove</span>
                }
                {ui.curateList.editMode &&
                  <span>Cancel</span>
                }
              </a>
              {list.type === 'curation' && <a
                className={`row-header__link ${ui.curateList.bulkMode
                  ? 'curationList__Sort--Header'
                  : ''}`
                }
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  actions.toggleCurateListBulkMode({ });
                  actions.clearError();
                }}
              >
                {!ui.curateList.bulkMode &&
                  <span>Add OMSIDs</span>
                }
                {ui.curateList.bulkMode &&
                  <span>Cancel</span>
                }
              </a>}
            </div>
          </div>
        </div>
      </div>
      {ui.error && (
            <InlineMessage type="error" message={ui.error.message} />
          )}
      <div className="curation-list-set--items grid">
      {ui.curateList.bulkMode && <div className="curation-list-set--bulk">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            // submit it
            const text = e.target.elements['bulk-items'].value;
            actions.addItemsToListBulk({ text, listId: list.id });
          }}
        >
        <textarea
          name="bulk-items"
          defaultValue={itemIds.join('\n')}
        />
          <button
            type="submit"
            className="bttn bttn--primary"
          >
          <span className="bttn__content">
            {loaders['item-update-buil'] &&
              <span className="segment-spinner segment-spinner--bttn"></span>}
            {!loaders['item-update-bulk'] &&
              <span>Update Items</span>
            }
          </span>
          </button>
        </form>
      </div>
      }
      {!ui.curateList.bulkMode &&
        hasSearchResults &&
        !loaders['item-update'] &&
        list.items.length === 0 && <div className="curation-list__message-drop">
        <span className="drag-drop-icon"></span>
        <h2>DRAG AND DROP</h2>
        <p>products from the results to start building your curation</p>
      </div>
        }
        {!ui.curateList.bulkMode && list.items.map((item, index) => {
          return (<div
            key={'set-' + (item.itemId || item.id)}
            className={curationListView === 'curation' ? 'col__12-12' : 'col__4-12'}
          >
            <ItemDragSource
              index={index}
              item={item}
              size="small"
              list={list}
              type="set"
              multiItemPreview={multiItemPreview}
            />
          </div>);
        })}
      </div>
      {!ui.curateList.bulkMode && <div className="pagination-wrapper">
        {list.$count > 24 &&
          <Pager
            total={list.$count / ui.pagination.totalListPerPage}
            current={ui.pagination.selectedCurationpage - 1}
            visiblePages={2}
            titles={{ first: '<<', last: '>>' }}
            className="hd-pagination__wrapper"
            onPageChanged={actions.handlePageChanged}
          />
        }
      </div>
      }
      {!ui.curateList.bulkMode && <div className="curation-list--action">
        <a
          href="#"
          className={`bttn bttn--primary ${list.$count === 0 ? 'disabled' : ''}`}
          onClick={(e) => {
            e.preventDefault();
            if (list.$count > 0) {
              actions.saveCuration({ list });
            }
          }}
        >
          <span className="bttn__content">
            {loaders['item-update'] &&
              <span className="segment-spinner segment-spinner--bttn"></span>}
            {!loaders['item-update'] &&
              <span>{list.isTemp ? `Create ${list.type}` : `Save ${list.type}` }</span>
            }
          </span>
        </a>
      </div>
      }
    </div>);
  }
}

CurationItems.propTypes = {
  list: PropTypes.object,
  itemIds: PropTypes.array,
  curationListView: PropTypes.string,
  actions: PropTypes.shape({
    saveCuration: PropTypes.func.isRequired
  }),
  multiItemPreview: PropTypes.object,
  ui: PropTypes.object,
  searchReport: PropTypes.object,
  loaders: PropTypes.object,
  hasSearchResults: PropTypes.bool
};

export default CurationItems;
