import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from './versions.actions';
import component from './Versions.component';
import { withRouter } from 'react-router-dom';

function mapStateToProps(state, ownProps) {
  const type = ownProps.match.params.listType;
  const list = state.curationList.lists
    .find(l => l.id === parseInt(ownProps.match.params.id, 10)) || {};

  const latestVersion = list.versions && list.versions.length > 1
    ? list.versions[1]
    : null;

  return {
    list,
    ui: state.ui,
    errors: state.curationList.errorModel,
    loaders: state.app.loaders,
    listType: type,
    latestVersion
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {

  const type = ownProps.match.params.listType;
  return {
    navigate: (path) => {
      ownProps.history.push(path);
    },
    actions: bindActionCreators({
      ...actions,
    }, dispatch)
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(component)
);// eslint-disable-line new-cap;
