import * as types from '../../../../../../constants/ActionTypes';
import { API_HOST } from '../../../../../../constants/environment';
import listAnalytics from '../../../mock/listAnalytics';
import * as api from '../../../../../../util/api';
import { addAllSelectedToSet, replaceItemsInSet } from '../../actions';
import { copyPrimaryCurationToCollection } from '../../../ReviewList/actions';
import { updateImageSrc } from '../Item/reducer';

// @todo need loaders
export const saveCuration = ({ list }) => {
  return (dispatch, getState) => {
    const state = getState();
    const meta = state.app.meta;
    return new Promise((resolve, reject) => {
      const c = state.curationList.lists.find(cur => cur.id === list.id);
      // if list is collection and has title ']', move name and decription properties
      let method = list.isTemp
        ? api.createList
        : api.saveList;
      let options = { list: c, type: list.type };
      method(options)
      .then(response => {
        // dispatch({ type: types.UPDATE_LIST_DETAILS, listId, listDetails: resp, meta });
        // Reset Tag UI Settings.
        dispatch({ type: types.SET_CURATION_TAGS_CATEGORY, category: '0', listId: list.id });
        dispatch({ type: types.SHOW_HIDE_TAG_CATEGORY_DROPDOWN, value: false });
        dispatch({ type: types.SET_IS_TAG_INPUT_FIELD_FOCUS_IN, isTagInputFocus: false });
        dispatch({
          type: types.SET_LIST_DATA,
          id: list.id,
          response: { ...response, isTemp: false },
          meta
        });
        dispatch({ type: types.SET_LIST_ANALYTICS, results: listAnalytics, listId: list.id });
        if (state.ui.curateList.editMode) {
          dispatch({ type: types.TOGGLE_CURATELIST_EDIT_MODE });
        }
        if (state.ui.curateList.bulkMode) {
          dispatch({ type: types.TOGGLE_CURATELIST_BULK_MODE });
        }
        if (list.isTemp) {
          dispatch(copyPrimaryCurationToCollection({ listId: response.id }))
          .then(e => {
            resolve(response);
          });
        } else {
          resolve(response);
        }
      })
      .catch(err => {
        console.log(err);
        resolve();
      });
    });
  };
};

export const changeCurationListView = ({ viewType }) => {
  return (dispatch) => {
    dispatch({ type: types.CHANGE_CURATION_LIST_VIEW, viewType });
  };
};

export const getList = ({ list, currentPage, totalListPerPage }) => {
  // calculate start and end item indexes
  let startIndex = (currentPage - 1) * totalListPerPage;
  const items = list.items;
  let endIndex = Math.min(startIndex + totalListPerPage - 1, (items || []).length - 1);
  let template = {
    items: (items || []).slice(startIndex, endIndex + 1)
  };
  return {
    ...list,
    ...template
  };
};

export const toggleCurateListEditMode = () => {
  return { type: types.TOGGLE_CURATELIST_EDIT_MODE };
};

export const toggleCurateListBulkMode = () => {
  return { type: types.TOGGLE_CURATELIST_BULK_MODE };
};

export const addItemsToListBulk = ({ text, listId }) => {
  const unqiueIds = {};
  return (dispatch, getState) => {
    let str = text.replace(/,/g, '\n');
    const ids = str.split('\n').map(id => {
      return id.trim();
    })
    .filter(String)
    .filter((itemId) => {
      if (!unqiueIds[itemId]) {
        unqiueIds[itemId] = true;
        return true;
      }
      return false;
    })
    .filter((itemId) => {
      return /^\d+$/.test(itemId);
    })
    .filter((itemId) => {
      return (itemId + '').length >= 7;
    });
    dispatch({ type: types.INITIATE_LOADER, loader: 'item-update-bulk' });
    api.getItemsByIds({ ids })
      .then(skus => {
        const items = skus.map(item => {
          return {
            ...item,
            $imageSrc: updateImageSrc({ data: item }),
          };
        });
        if (items && items.length > 0) {
          dispatch({ type: types.CLEAR_ERROR_MESSAGE });
          dispatch(replaceItemsInSet({ listId, items }));
          dispatch(toggleCurateListBulkMode());
        } else {
          dispatch({ type: types.SET_ERROR_MESSAGE, message: 'OMSID not found.' });
        }
        dispatch({ type: types.REMOVE_LOADER, loader: 'item-update-bulk' });
      });
    // call api for ids
  };
};

export const handlePageChanged = ({ newPage }) => {
  return (dispatch) => {
    dispatch({ type: types.SET_CURATION_SELECTED_PAGINATION_VALUE, value: newPage + 1 });
  };
};
