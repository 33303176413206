import React, { Component } from 'react';
import PropTypes from 'prop-types';
import HeaderContainer from '../../modules/Header';
import { formatDate } from '../../../../util';
import Accordion from '../../modules/Accordion';
import CheckboxGroup from '../../modules/CheckboxGroup';
import Pager from 'react-pager';
import { GCS_IMAGE_BUCKET_LIFESTYLE } from '../../../../constants/environment';
import defaultProduct from '../../../../assets/images/default-product.jpg';
import pinterestLogo from '../../../../assets/images/pinterest-logo.png';
import TagsContainer from '../../modules/Tag';
import InlineMessage from '../../modules/InlineMessage/component';
import ColumnHeader from './components/ColumnHeader.component';
import NewCollection from './components/NewCollection.component';
import NewCuration from './components/NewCuration.component';
import CurationForm from './components/CurationForm.component';
import PinterestHeader from './components/PinterestHeader.component';
import StickyAttributes from './components/StickyAttributes.component';
import './style.scss';

const capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const calculateImagePosition = ({ el }) => {
  if (!el) return;
  let frameWidth = 275;
  let frameHeight = 275;
  let img = el;
  img.onload = () => {
    let width = img.width;
    let height = img.height;
    let left = -1 * (width - frameWidth) / 2;
    let top = -1 * (height - frameHeight) / 2;

    img.style.left = left + 'px';
    img.style.top = top + 'px';
  };
};

class CurationListComponent extends Component {
  componentDidMount() {
    const { actions, listType } = this.props;
    actions.resetPinterestStatus();
    this.updateList();
    actions.resetSortType();
    let title = '';
    if (listType === 'curation') {
      title = 'Curations';
    } else if (listType === 'collection') {
      title = 'Collections';
    }
    document.title = title;
  }
  componentDidUpdate(previousProps) {
    const { listType, actions, location, defaultCurationList, pinterestFilter } = this.props;
    if (location.action !== 'POP' && location.pathname !== previousProps.location.pathname) {
      this.updateList();
    } else if (pinterestFilter.yes && defaultCurationList) {
      actions.resetPinterestStatus();
      actions.toggleDefaultCurationList({ defaultCurationList: false });
      this.updateList(true);
    } else if (!pinterestFilter.yes && defaultCurationList) {
      actions.toggleDefaultCurationList({ defaultCurationList: false });
    }
    if (location.pathname !== previousProps.location.pathname) {
      let title = '';
      if (listType === 'curation') {
        title = 'Curations';
      } else if (listType === 'collection') {
        title = 'Collections';
      }
      document.title = title;
    }
  }
  updateList(defaultPinterest = false) {
    const { listType, actions, location, pinterestFilter, match } = this.props;
    const keyword = match.params.keyword || '';
    let startIndex = (match.params.page || 0) * 24;
    const page = match.params.page || '0';
    let sortBy = match.params.sortBy;
    let sortOrder = match.params.sortOrder;
    let pinterest;
    // @TODO rework pinterest filter. kinda confusing
    if (!defaultPinterest && pinterestFilter.yes) {
      pinterest = true;
    } else if (!defaultPinterest && pinterestFilter.no) {
      pinterest = false;
    }
    actions.getLists({ type: listType, startIndex, sortBy, sortOrder, keyword, pinterest });
  }
  render() {
    const {
      curationLists,
      keyword,
      loaders,
      userAccess,
      searchReport,
      sortName,
      sortType,
      actions,
      listType,
      location,
      viewMode,
      selectedListCount,
      meta,
      multiListSelected,
      selectedCurationCount,
      errors,
      pinterestFilter,
      match
    } = this.props;
    return (<div className="grid curationizer-main">
    <HeaderContainer location={location} />
    {userAccess &&
      listType === 'collection' && (
        <NewCollection loaders={loaders} actions={actions} />
      )}
    {userAccess &&
      listType === 'curation' && (
        <NewCuration loaders={loaders} actions={actions} />
      )}
    <div className="col__7-12">
      <CurationForm actions={actions} keyword={keyword} listType={listType} />
    </div>
    <div className="col__3-12">
      {viewMode === 'grid' &&
        <a
          className="curationList__Sort--Header"
          onClick={(e) => {
            e.preventDefault();
            // actions.filterCurations({ keyword, type: listType, view: 'list' });
            actions.setUIListView({ type: 'list' });
          }}
          href="#"
        >
          <i className="fa fa-list curationlist__viewType" aria-hidden="true"></i>
        </a>
      }
      {viewMode === 'list' && multiListSelected && selectedCurationCount &&
        <a
          className="curationList__Sort--Header"
          onClick={(e) => {
            e.preventDefault();
            actions.goToMultiListEditView({ type: listType, keyword });
          }}
          href="#"
        >
          <i className="fa fa-th-large curationlist__viewType" aria-hidden="true"></i>
          <a className="compare">Compare</a>
        </a>
      }
      <PinterestHeader pinterestFilter={pinterestFilter} actions={actions} listType={listType}
        match={match}
      />
    </div>
    {loaders.listByUser && (
      <div className="col__12-12 curationList-loader">
        <div className="pre-loader">
          <div className="segment-spinner" />
        </div>
      </div>
    )}
    {!loaders.listByUser &&
        curationLists.length === 0 && <div className="col__12-12">
        <div className="curation-flash">No list found</div>
        </div>}
    {viewMode === 'grid' && errors.length > 0 &&
      <div className="grid flush">
        {errors.map((err, i) => {
          return (<InlineMessage type="danger" key={i}
            message={err.description}
          />);
        })}
      </div>
    }
    {viewMode === 'list' && <div className="col__12-12">
      {!loaders.listByUser &&
        curationLists.length > 0 && (
          <div className="curationListHeader">
            <div className="grid">
            {listType === 'collection' &&
              <div className="list-multi-select">
                <h3 className="curationListHeader__title checkboxMargin">
                </h3>
              </div>
            }
            {listType === 'curation' &&
              <div className="list-multi-select">
                <h3 className="curationListHeader__title checkboxMargin">
                </h3>
              </div>
            }
              <ColumnHeader
                _sortName={'name'}
                _label={[`${capitalize(listType)}`, ' Name']}
                _className={'list--name__align'}
                actions={actions}
                sortName={sortName}
                sortType={sortType}
                listType={listType}
              />
              <ColumnHeader
                _sortName={'id'}
                _label={[`${capitalize(listType)}`, ' ID']}
                _className={''}
                actions={actions}
                sortName={sortName}
                sortType={sortType}
                listType={listType}
              />
              <ColumnHeader
                _sortName={'groupName'}
                _label={['Related ', `${capitalize(listType)} ID`]}
                _className={''}
                actions={actions}
                sortName={sortName}
                sortType={sortType}
                listType={listType}
              />
              {listType === 'curation' &&
                <ColumnHeader
                  _sortName={'count'}
                  _label={['Collection', 'Count']}
                  _className={''}
                  actions={actions}
                  sortName={sortName}
                  sortType={sortType}
                  listType={listType}
                />
              }
              <ColumnHeader
                _sortName={'createdBy'}
                _label={'Curator'}
                _className={''}
                actions={actions}
                sortName={sortName}
                sortType={sortType}
                listType={listType}
              />
              <ColumnHeader
                _sortName={'createdAt'}
                _label={'Created At'}
                _className={''}
                actions={actions}
                sortName={sortName}
                sortType={sortType}
                listType={listType}
              />
              <ColumnHeader
                _sortName={'updatedAt'}
                _label={'Updated At'}
                _className={''}
                actions={actions}
                sortName={sortName}
                sortType={sortType}
                listType={listType}
              />
              <ColumnHeader
                _sortName={'totalChildren'}
                _label={listType === 'curation' ? ' Products' : ' Curations'}
                _className={''}
                actions={actions}
                sortName={sortName}
                sortType={sortType}
                listType={listType}
              />
               { listType === 'collection' &&
                <ColumnHeader
                _sortName={'hotspotCount'}
                _label={'# of hotspots'}
                _className={''}
                actions={actions}
                sortName={sortName}
                sortType={sortType}
                listType={listType}
              />
            }
            <div className="list-multi-select"></div>
            </div>
          </div>
        )}
    </div>
    }
    {viewMode !== 'list' && <div className="col__12-12 curate-list__border">
    </div>
    }
    {!loaders.listByUser &&
      <div
        className={ `${viewMode === 'list' ?
          'col__12-12' : 'col__9-12 curation-list__padding curate-list__sidebar'}`}
      >
      <div className="curationListBody grid">
        {!loaders.listByUser &&
          curationLists.map((list, index) => {
            return (
              <div key={index} className={`${viewMode === 'grid' ?
              'col__4-12 list__pod' : 'col__12-12'} `
             }>
              { viewMode === 'list' &&
                <div key={list.id} className={`grid ${index % 2 === 0 ?
                    'curationList__body' : ''}`
               }>
                  {listType === 'curation' && list && list.images && list.images.length > 0 &&
                    <div className="checkbox-btn list-multi-select refinements__checkbox">
                      <img alt="" className="thumbnail"
                        src={`${GCS_IMAGE_BUCKET_LIFESTYLE}${list.images[0].guid}`}
                      />
                    </div>
                  }
                  {listType === 'collection' && list && list.images && list.images.length > 0 &&
                    <div className="checkbox-btn list-multi-select refinements__checkbox">
                      <img alt="" className="thumbnail"
                        src={`${GCS_IMAGE_BUCKET_LIFESTYLE}${list.images[0].guid}`}
                      />
                    </div>
                  }
                  {listType === 'collection' && list && list.images && list.images.length === 0 &&
                    <div className="checkbox-btn list-multi-select white">
                    </div>
                  }
                  {listType === 'curation' && list && list.images && list.images.length === 0 &&
                    <div className="checkbox-btn list-multi-select white">
                    </div>
                  }
                  <div className={`${listType === 'collection' ? 'col__1-8' : 'col__1-9'}
                    curationList__cell list--name__align`}
                  >
                    <h5>
                      <a
                        href="#"
                        className="curationList__cell-link"
                        onClick={e => {
                          e.preventDefault();
                          actions.navigateToListDetailsPage({
                            listId: list.id,
                            type: list.type
                          });
                        }}
                      >
                        {list.pinterestSTLStatus &&
                          <span className="pinterest__wrapper">
                            <img alt="pinterestLogo"
                              className="pinterest-logo"
                              src={pinterestLogo}
                            />
                          </span>
                        }
                        <span>{list.name}</span>
                      </a>
                    </h5>
                  </div>
                  <div className={`${listType === 'collection' ? 'col__1-8' : 'col__1-9'}
                    curationList__cell`}
                  >
                    <h5>
                      <a
                        href="#"
                        onClick={e => {
                          e.preventDefault();
                          actions.navigateToListDetailsPage({
                            listId: list.id,
                            type: list.type
                          });
                        }}
                      >
                        {list.id}
                      </a>
                    </h5>
                  </div>
                  <div className={`${listType === 'collection' ? 'col__1-8' : 'col__1-9'}
                    curationList__cell`}
                  >
                    <h5>
                      {list.groupName !== '' &&
                        <a
                          className="row-header__link"
                          href="#"
                          onClick={e => {
                            e.preventDefault();
                            if (list.groupName !== '') {
                              actions.filterCurations({
                                keyword: 'relation-name:' + list.groupName,
                                type: listType,
                                view: 'grid'
                              });
                            }
                          }}
                        >
                          {list.groupName}
                        </a>
                      }
                    </h5>
                  </div>
                  {listType === 'curation' &&
                    <div className="col__1-9 curationList__cell">
                      <h5>{list.collectionCount}</h5>
                    </div>
                  }
                  <div className={`${listType === 'collection' ? 'col__1-8' : 'col__1-9'}
                    curationList__cell`}
                  >
                    <h5 className="">{list.createdBy}</h5>
                  </div>
                  <div className={`${listType === 'collection' ? 'col__1-8' : 'col__1-9'}
                    curationList__cell`}
                  >
                    <h5 className="">
                      {formatDate(new Date(parseInt(list.createdAt, 10)), 'MM/dd/yyyy')}
                    </h5>
                  </div>
                  <div className={`${listType === 'collection' ? 'col__1-8' : 'col__1-9'}
                    curationList__cell`}
                  >
                    <h5 className="">
                      {formatDate(new Date(parseInt(list.updatedAt, 10)), 'MM/dd/yyyy')}
                    </h5>
                  </div>
                  <div className={`${listType === 'collection' ? 'col__1-8' : 'col__1-9'}
                    curationList__cell`}
                  >
                    <h5 className="">{list.totalChildren || 0}</h5>
                  </div>                 
                  { listType === 'collection' &&
                  <div className="col__1-8 curationList__cell"
                  >
                    <h5 className="">{list.hotspotCount || 0}</h5>
                  </div>
                  } 
                   <div className="list-multi-select"></div>
                </div>
                }
                { viewMode === 'grid' &&
                  <div key={list.id} className="curationList__grid--wrapper">
                    <div className="grid">
                      <div
                        className={`${list._selected ? 'curation-list--selected' : ''}`}
                        onClick={(e) => {
                          e.preventDefault();
                          if (list.$canEdit) {
                            actions.toggleListSelection({
                              listId: list.id,
                            });
                          }
                        }}
                      >
                        {list.images.length > 0 &&
                          <div className="image-wrapper">
                            <img
                              src={`${GCS_IMAGE_BUCKET_LIFESTYLE}${list.images[0].guid}`}
                              alt="multiList"
                              className="multiList-Image"
                              ref={(e) => {
                                calculateImagePosition({ el: e });
                              }}
                              onError = {(e) => {
                                console.log('err');
                                e.target.src = defaultProduct;
                              }}
                            />
                          </div>
                        }
                        {list.images.length === 0 &&
                          <div>
                            <img
                              className="multiItem-noImage"
                              src={`${defaultProduct}`}
                              alt="multiList"
                            />
                          </div>
                        }
                      </div>
                      <div className="col__6-12 list__name--link">
                        <a
                          href="#"
                          onClick={e => {
                            e.preventDefault();
                            actions.navigateToListDetailsPage({
                              listId: list.id,
                              type: list.type
                            });
                          }}
                        >
                        ID: {list.id}
                        </a>
                      </div>
                      <div className="col__6-12 multilist-status">
                        <span className="reviewList-details__value">{list.status}</span>
                      </div>
                      <div className="col__12-12">
                        {(list.ui || {}).nameEdit &&
                          <form className="input-group--inline"
                            onSubmit={(e) => {
                              e.preventDefault();
                              const name = e.target.elements.curation_name.value;
                              if (name !== '') {
                                actions.updateSelectedListName({ listId: list.id, name });
                              }
                            }}
                          >
                            <div className="grid">
                              <div className="col__10-12 list__name--wrapper">
                                <input
                                  type="text"
                                  className="form-input__field list-name"
                                  placeholder="Name"
                                  maxLength="99"
                                  name="curation_name"
                                  defaultValue={list.name}
                                />
                              </div>
                              <div className="col__2-12 list__name--wrapper">
                                {loaders[`multilist-name-update-${list.id}`] &&
                                  <div className="pre-loader">
                                    <div className="segment-spinner"></div>
                                  </div>
                                }
                                {!loaders[`multilist-name-update-${list.id}`] &&
                                  <button>
                                    <i className="fa fa-floppy-o list_name--save"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                }
                              </div>
                            </div>
                          </form>
                        }
                        {!(list.ui || {}).nameEdit && <div><a
                          href="#"
                          className="reviewList-name__container"
                          onClick={(e) => {
                            e.preventDefault();
                            if (list.$canEdit) {
                              actions.toggleListNameEditMode({ listId: list.id });
                            }
                          }}
                        >
                          <span className="reviewList-name">{list.name}</span>
                          {list.$canEdit && <i className="fa fa-pencil slider__listId--icon"
                            aria-hidden="true"
                          ></i>}
                        </a></div>}
                      </div>
                      <div className="col__12-12 multilist__attributes--wrapper">
                        {list.meta.$dimensions.map((dimension, key, dimensionArray) => {
                          return (
                            dimension.attributes.filter((attr) => {
                              return attr.value;
                            }).map((attr, i, attrArray) => {
                              return (
                                <span key={i} className="multiList-attribute">
                                  <a
                                    href="#"
                                    id={`attr_${dimension.id}_${attr.id}`}
                                    className="multilist-link"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      if (list.$canEdit) {
                                        actions.toggleAttributeAddRemove({ listId: list.id,
                                          attributeId: attr.id, dimensionId: dimension.id });
                                      }
                                    }}
                                  >
                                    <span>{attr.name}</span>
                                  </a>
                                  { attr.multiListEdit &&
                                    <span className="multilist-close"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        actions.updateCurationMetaData({
                                          value: false, curationId: list.id,
                                          attributeId: attr.id, dimensionId: dimension.id
                                        });
                                      }}
                                    >
                                    </span>
                                  }
                                  <span className="multilist__attribute--seperator">|</span>
                                </span>
                              );
                            })
                          );
                        })}
                      </div>
                    </div>
                  </div>
                }
              </div>
            );
          })}
        <div className="col__12-12">
          <div className="pagination-wrapper">
            {!loaders.listByUser &&
              searchReport.totalProducts > 24 && (
                <Pager
                  total={searchReport.totalProducts / searchReport.pageSize}
                  current={Math.floor(searchReport.startIndex / searchReport.pageSize)}
                  visiblePages={searchReport.$visiblePages}
                  titles={{ first: '<<', last: '>>' }}
                  className="hd-pagination__wrapper"
                  onPageChanged={actions.handlePageChanged}
                />
              )}
          </div>
        </div>
      </div>
    </div>
    }
    {!loaders.listByUser && curationLists.length > 0 && viewMode === 'grid' &&
      <div className="col__3-12">
        <StickyAttributes
          loaders={loaders}
          selectedListCount={selectedListCount}
          meta={meta}
          actions={actions}
          listType={listType}
        />
      </div>
    }
  </div>);
  }
}
/* eslint-enable */
CurationListComponent.propTypes = {
  curationLists: PropTypes.array,
  listType: PropTypes.string,
  sortName: PropTypes.string,
  searchReport: PropTypes.object,
  sortType: PropTypes.string,
  keyword: PropTypes.string,
  userAccess: PropTypes.bool,
  actions: PropTypes.shape({
    createNewListAndRouteToIt: PropTypes.func.isRequired
  }),
  loaders: PropTypes.object,
  location: PropTypes.object,
  viewMode: PropTypes.string,
  selectedListCount: PropTypes.number,
  meta: PropTypes.object,
  errors: PropTypes.array,
  multiListSelected: PropTypes.bool,
  selectedCurationCount: PropTypes.bool,
  pinterestFilter: PropTypes.object,
  startIndex: PropTypes.number,
  sortBy: PropTypes.string,
  sortOrder: PropTypes.string,
  params: PropTypes.object,
  isEnablePinterest: PropTypes.bool,
  defaultCurationList: PropTypes.bool,
  match: PropTypes.object
};

export default CurationListComponent;
