import * as types from '../../../../../../constants/ActionTypes';
import { addItemToSet, removeItemFromSet } from '../../actions';
import * as api from '../../../../../../util/api';

export const toggleItemSelection = ({ itemId, listId, resultSet }) => {
  return { type: types.TOGGLE_RESULTS_ITEM_SELECTION, itemId, listId, resultSet };
};

export const selectCurationItem = ({ item, index, list }) => {
  return (dispatch) => {
    const itemType = item.type === 'curation'
      ? 'curation'
      : 'item';
    const id = item.type === 'curation'
    ? item.id
    : item.itemId;
    dispatch({ type: types.SELECT_CURATION_ITEM, id, index, listId: list.id, itemType });
    dispatch({
      type: types.INITIATE_LOADER,
      loader: 'item-details'
    });
    api.getListDetailsById({ listId: item.id, type: item.type })
      .then(response => {
        dispatch({
          type: types.SET_RESULTS_FOR_ITEM,
          results: response.items,
          itemId: item.id,
          listId: list.id,
          itemType: 'curation'
        });
        dispatch({
          type: types.REMOVE_LOADER,
          loader: 'item-details'
        });
      }).catch(err => {
        console.log(err);
        dispatch({
          type: types.REMOVE_LOADER,
          loader: 'item-details'
        });
      });
  };
};

export const doubleClickItem = ({ item, list, serverCall = false }) => {
  const index = list.items.findIndex(i => (i.id || i.itemId) === (item.id || item.itemId));
  if (index === -1) {
    return addItemToSet({ item, listId: list.id, serverCall });
  }
  return removeItemFromSet({ item, listId: list.id, serverCall, type: list.type });
};
