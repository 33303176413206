import React, { Component } from 'react';
import PropTypes from 'prop-types';
import HeaderContainer from '../modules/Header';
import './AppStyle.scss';
import image from '../../../assets/images/gorgeous-bright-living-room.jpg';

class AppComponent extends Component {
  render() {
    const {
      children,
      params,
      results,
      curation,
      sliders,
      actions,
      navigate,
      authUrl,
      error,
      loaders
    } = this.props;
    return (
      <div>
        <i className="icon_homedepot"></i>
        <div className="background_image"></div>
        <div className="grid curationizer-main-login-box curationizer-main__login">
          <div className="col__12-12" style={{ textAlign: 'center' }}>
            <h1><b>Welcome to DaVinci</b></h1>
            <p>a place to create scenes, publish, and inspire</p>
          </div>
          <div className="col__12-12">
            {loaders['get-user'] && <div className="pre-loader">
                <div className="segment-spinner"></div>
              </div>}
            <form onSubmit={(e) => {
              console.log('Test commit');
              e.preventDefault();
              window.location = authUrl;
            }}
            >
              <div>
              <button
                className="bttn bttn--primary--login"
                type="submit"
              >
                <span className="bttn__content">Sign In</span>
              </button>
              {error && <div><br /><div className="curation-flash">{error}</div></div>}
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

AppComponent.propTypes = {
  actions: PropTypes.object,
  children: PropTypes.object,
  params: PropTypes.object,
  results: PropTypes.object,
  curation: PropTypes.array,
  sliders: PropTypes.array,
  navigate: PropTypes.func,
  authUrl: PropTypes.string,
  error: PropTypes.string,
  loaders: PropTypes.object
};

export default AppComponent;
