import * as types from '../../../../../../constants/ActionTypes';
import { fetchItemList, clearResultsSet } from '../../actions';
import { addRemoveTagValue } from '../../../../modules/Tag/actions';
import * as api from '../../../../../../util/api';


export const setSliderValues = ({ dispatch, listId, slider }) => {
  const sliderType = 'dimension';
  dispatch({
    type: types.SET_SLIDER_VALUE,
    sliderId: slider.id,
    value: slider.weight,
    listId,
    sliderType,
    complete: false
  });

  if (slider.attributes && slider.attributes.length > 0) {
    slider.attributes.map((sliderAttributes) => {
      return dispatch({
        type: types.SET_SLIDER_ATTRIBUTE_VALUE,
        sliderId: slider.id,
        attributeId: sliderAttributes.id,
        value: sliderAttributes.weight ? sliderAttributes.weight : 0,
        listId,
        sliderType
      });
    });
  }
};

export const setTagValues = ({ dispatch, listId, slider }) => {
  dispatch(addRemoveTagValue({ id: listId }));
};

export const setFilterValues = ({ dispatch, listId, filters, filterId }) => {
  const sliderType = 'filter';
  dispatch({
    type: types.SET_REFINEMENT_ATTRIBUTE_VALUE,
    attributeId: filters.id,
    sliderId: filterId,
    value: filters.weight,
    listId,
    sliderType
  });
};

export const fileUploadLoader = () => {
  return (dispatch) => {
    dispatch({ type: types.INITIATE_LOADER, loader: 'vision-image-upload' });
  };
};

export const fileUploadError = () => {
  return (dispatch) => {
    const errorMessage = 'File Size is too Large. Please select Correct File!!!';
    dispatch({ type: types.SET_ERROR_MESSAGE, message: errorMessage });
    dispatch({ type: types.REMOVE_LOADER, loader: 'image-upload' });
  };
};

export const uploadVisionImg = ({ image, listId }) => {
  return (dispatch, getState) => {
    const state = getState();

    dispatch({ type: types.CLEAR_ERROR_MESSAGE });

    api.getMerchandisingAttributes({ file: image.files[0] })
        .then(resp => {

          if (Object.keys(resp.merchandisingAttributes).length === 0
              && resp.imageBoundBox.length === 0
              && resp.items.length === 0) {
            const errorMessage = 'Unable to find matching products.';
            dispatch({ type: types.SET_ERROR_MESSAGE, message: errorMessage });
            dispatch({ type: types.REMOVE_LOADER, loader: 'vision-image-upload' });
            return;
          }
          dispatch({
            type: types.SET_VISION_IMAGE_ATTRIBUTES,
            imageBoundBox: resp.imageBoundBox || [],
            items: resp.items || [],
            merchandisingAttributes: resp.merchandisingAttributes || {}
          });
          if (resp.merchandisingAttributes
              && resp.merchandisingAttributes.dimensions
            && resp.merchandisingAttributes.dimensions.length > 0) {
            const sliderDimensions = resp.merchandisingAttributes.dimensions.map((dimensions) => {
              const dimension = [];
              if (dimensions.id === 100 || dimensions.id === 200) {
                return dimensions;
              }
              return dimension;
            });

            sliderDimensions.map((slider) => {
              return setSliderValues({ slider, dispatch, listId });
            });
          }

          if (resp.merchandisingAttributes
              && resp.merchandisingAttributes.filters
              && resp.merchandisingAttributes.filters.length > 0
              && resp.merchandisingAttributes.filters[0].attributes
              && resp.merchandisingAttributes.filters[0].attributes.length > 0) {
            const filterId = resp.merchandisingAttributes.filters[0].id;
            resp.merchandisingAttributes.filters[0].attributes.map((filters) => {
              return setFilterValues({ filters, dispatch, listId, filterId });
            });
          }
          dispatch(fetchItemList({ id: listId }));
          dispatch({ type: types.REMOVE_LOADER, loader: 'vision-image-upload' });
        })
        .catch(e => {
          console.error(e);
          dispatch({ type: types.REMOVE_LOADER, loader: 'vision-image-upload' });
        });

  };
};

export const getSimilarItems = ({ itemId, listId }) => {
  return (dispatch) => {
    dispatch({ type: types.SET_VISION_ITEM_ACTIVE, activeItemId: itemId });
    dispatch(fetchItemList({ id: listId, itemId }));
    dispatch({ type: types.SET_UNSET_ITEM_BY_ITEM, itemByItem: true });
  };
};

export const getHotspotImages = ({ itemIds }) => {
  return (dispatch, getState) => {
    const state = getState();
    const items = (itemIds || []).map((itemId) => {
      return state.vision.items.filter(item => item.itemId === itemId);
    }).filter(i => i);

    dispatch({
      type: types.LOAD_HOTSPOT_IMAGES,
      hotspotItems: items,
    });
  };
};

export const removeVisionImage = ({ listId, meta }) => {
  return (dispatch) => {
    dispatch({ type: types.CLEAR_ERROR_MESSAGE });
    dispatch({ type: types.REMOVE_VISION_IMAGE, listId });
    dispatch(clearResultsSet({ id: listId }));
    dispatch({ type: types.RESET_UI_SETTINGS, id: listId, meta });
    dispatch({ type: types.RESET_HOTSPOT_IMAGES });
  };
};

export const resetUISettings = () => {
  return (dispatch) => {
    dispatch({ type: types.CLEAR_ERROR_MESSAGE });
    dispatch({ type: types.RESET_VISION_UI_SETTINGS });
    dispatch({ type: types.RESET_HOTSPOT_IMAGES });
  };
};

export const resetHotspotSettings = ({ listId }) => {
  return (dispatch, getState) => {
    dispatch({ type: types.RESET_HOTSPOT_IMAGES });
    const state = getState();
    const itemByItem = state.vision && state.vision.itemByItem ? state.vision.itemByItem : false;
    if (itemByItem) {
      dispatch({ type: types.SET_UNSET_ITEM_BY_ITEM, itemByItem: false });
      dispatch(fetchItemList({ id: listId }));
    }
  };

};
