import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Accordion from '../../../modules/Accordion';
import CheckboxGroup from '../../../modules/CheckboxGroup';
import TagsContainer from '../../../modules/Tag';

class StickyAttributes extends Component {
  render() {
    const {
      selectedListCount,
      listType,
      meta,
      actions,
      loaders
    } = this.props;
    return (
      <div className="attributes-sticky">
          {selectedListCount > 0 &&
            <div className="review-item-box-title">
              <span className="review-item-box-title__text text-caps">
                {`(${selectedListCount}) ${listType}s selected`}
              </span>
            </div>
          }
          <div className="review-item-attributes-wrapper">
            <div className="review-item-box-title">
              <span className="review-item-box-title__text text-caps">{listType} Attributes</span>
            </div>
            {meta.dimensions.map(dimension => {
              return (<Accordion key={dimension.id}
                id={`ac-${dimension.id}`} title={dimension.name}
                subtitle={(() => {
                  let selectedValues = dimension.attributes.filter(attr => {
                    return attr.value;
                  }).map(att => {
                    return att.name;
                  });
                  if (selectedValues.length) {
                    return '[' + selectedValues.join(', ') + ']';
                  }
                  return '';
                })()
                }
              >
              <CheckboxGroup
                id={`review-${dimension.id}`}
                elements={dimension.attributes}
                disabled={selectedListCount === 0}
                onChange={(value, element) => {
                  if (selectedListCount > 0) {
                    actions.updateSelectedListsMetaData({
                      value, attributeId: element.id, dimensionId: dimension.id, listType
                    });
                  }
                }}
              />
            </Accordion>);
            })}
            <TagsContainer disabled={selectedListCount === 0}
              type="multiEdit"
              listType={listType}
            />
          </div>
          <div className="grid">
            <div className="col__12-12">
              <a
                href="#"
                className={`bttn bttn--primary ${((selectedListCount === 0) ? 'disabled' : '')}`}
                onClick={(e) => {
                  e.preventDefault();
                  if (selectedListCount > 0) {
                    actions.publishLists({ type: listType });
                  }
                }}
              >
                <span className="bttn__content">
                  {loaders['curation-status'] &&
                    <span className="segment-spinner segment-spinner--bttn"></span>}
                  {!loaders['curation-status'] &&
                    <span>
                      Publish
                    </span>
                  }
                </span>
              </a>
            </div>
            <div className="col__12-12">
              <a
                href="#"
                className={`bttn bttn--primary ${((selectedListCount === 0) ? 'disabled' : '')}`}
                onClick={(e) => {
                  e.preventDefault();
                  if (selectedListCount > 0) {
                    actions.unpublishLists({ type: listType });
                  }
                }}
              >
                <span className="bttn__content">
                  {loaders['curation-unpublish-status'] &&
                    <span className="segment-spinner segment-spinner--bttn"></span>}
                  {!loaders['curation-unpublish-status'] &&
                    <span>
                      {`Deactivate ${listType}s`}
                    </span>
                  }
                </span>
              </a>
            </div>
          </div>
        </div>
    );
  }
}

StickyAttributes.propTypes = {
  selectedListCount: PropTypes.number,
  listType: PropTypes.string,
  meta: PropTypes.object,
  loaders: PropTypes.object,
  actions: PropTypes.object
};

export default StickyAttributes;