
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import './versions.style.scss';
import Pager from 'react-pager';
import RowHeader from '../../../../modules/RowHeader';
import Diff from './modules/Diff';
import { formatDate } from '../../../../../../util';

class VersionsComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      openRows: [],
      openingRows: []
    };
  }

  componentDidMount() {
    const { list, actions } = this.props;
    const listId = list.id;
    actions.closeVersionRows({ listId });
    actions.loadVersionsFor({ listId });
  }

  toggleRow(listVersion) {
    const { list, actions } = this.props;
    actions.toggleRow({ listVersion, listId: list.id });
  }


  render() {
    const { list, navigate, actions, latestVersion } = this.props;
    const { openingRows = [], revertLoader = {} } = this.state;
    if (!latestVersion) return null;

    return (
    <div className="versions">
      <RowHeader title="Versions" />
      <div className="grid version__table">
        <div className="col__12-12 version__row version__row--title">
            <span>Version #</span>
            <span>Date</span>
            <span>User</span>
          </div>
        { (list.versions || []).map((version, i) => {
          if (version.listVersion === 'Unpublished Changes' && list.status === 'active') {
            return null;
          }
          return (
            <Fragment key={i}>
              <div
                key={i}
                className={`col__12-12 version__row-wrapper
                ${version.listVersion === latestVersion.listVersion ? 'latest_version' : ''}
                `
              }
              >
              {!version.diff && version._open &&
                <div className="pre-loader">
                      <div className="segment-spinner"></div>
                </div>
              }
              <div className=" grid">
                <div
                  className="col__8-12 version__row"
                  onClick={(e) => {
                    if (version.listVersion !== latestVersion.listVersion) {
                      this.toggleRow(version.listVersion);
                    }
                  }}
                >
                  <span>{version.listVersion}</span>
                  <span>{formatDate(version.createdAt, 'MM/dd/yyyy hh:mm:ssTT')}</span>
                  <span>{version.createdBy}</span>
                </div>
                <div
                  className="version__actions  col__4-12"
                >
                  {typeof version.listVersion === 'string' &&
                    <a className="bttn-outline bttn-outline--primary bttn--inline"
                      onClick={(e) => {
                        e.preventDefault();
                        actions.refreshUnpublishedchanges({
                          listId: list.id,
                          compareToVersionId: list.versions.length - 1,
                          current: true
                        });
                      }}
                    >
                      <span className="bttn__content">Refresh</span>
                    </a>
                  }
                  {version._open && list.$canEdit &&
                  <a className="bttn bttn--primary bttn--inline"
                    onClick={e => {
                      e.preventDefault();
                      const state = { revertLoader: {} };
                      state.revertLoader[list.id] = true;
                      if (!revertLoader[list.id]) {
                        actions.revertVersion({ listId: list.id,
                          listVersion: version.listVersion });
                      }
                      this.setState(state);
                    }}
                  ><span className="bttn__content">
                    {revertLoader[list.id] &&
                      <span className="segment-spinner segment-spinner--bttn "></span>}
                    {!revertLoader[list.id] && <span>Revert</span>}
                    </span>
                  </a>
                  }
                </div>
              </div>
              {version._open && <div>
                {version.diff && <Diff
                  version={version}
                  list={list}
                  navigate={navigate}
                  latestVersion={latestVersion}
                />}
                </div>
              }
            </div>

            </Fragment>
          );
        })}
        { list.searchReport.totalVersions >
          ((list.searchReport.newPage + 1) * list.searchReport.pageSize) &&
        <a className="bttn bttn--primary bttn--inline"
          onClick={e => {
            e.preventDefault();
            actions.loadVersionsFor({ listId: list.id, newPage: list.searchReport.newPage + 1 });
          }}
        >
          <span className="bttn__content">Load More</span>
        </a>}
      </div>
    </div>
    );
  }
}

VersionsComponent.propTypes = {
  list: PropTypes.object,
  actions: PropTypes.object,
  navigate: PropTypes.func,
  latestVersion: PropTypes.object
};

export default VersionsComponent;
