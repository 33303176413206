import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ImgItemPreview from './component';
import { GCS_IMAGE_BUCKET_LIFESTYLE } from '../../../../../../../../constants/environment';
import defaultProduct from '../../../../../../../../assets/images/default-product.jpg';

const createPreviewImage = ({ index = 0, src = defaultProduct }) => {
  return {
    src
  };
};

const getImageMeasurements = (state, type) => {
  let width = 125;
  let height = 125;
  if (type === 'results') {
    // Result page 6 column or 4 column(75px, 125px)
    const itemView = state.item.itemView;
    if (itemView === 6) {
      width = 75;
      height = 75;
    }
  } else if (type === 'set') {
    // Grid or list (75px or 50px)
    const viewType = state.ui.curationList.viewType;
    if (viewType !== 'grid') {
      width = 50;
      height = 50;
    } else {
      width = 75;
      height = 75;
    }
  } else if (type === 'review') {
    // review list noOfColumn (75px, 125px, 150px, 250px)
    const NoOfColumns = state.ui.ReviewList.NoOfColumns;
    switch (NoOfColumns) {
      case 2:
        width = 260;
        height = 260;
        break;
      case 3:
        width = 165;
        height = 165;
        break;
      case 4:
        width = 125;
        height = 125;
        break;
      case 6:
        width = 75;
        height = 75;
        break;
      default:
        width = 150;
        height = 150;
    }
  }
  return {
    width,
    height
  };
};

const mapStateToProps = (state, ownProps) => {
  const type = ownProps.type;
  const dimensions = getImageMeasurements(state, type);
  const height = dimensions.height;
  const width = dimensions.width;
  let item = ownProps.item;
  item.$previewImages = [];
  let src;
  if (item.items && item.items.length) {
    try {
      src = item.items[0].info.imageUrl.replace(/<SIZE>/g, 145);
    } catch (e) {
      src = defaultProduct;
    }
  }
  if (item.images && item.images.length) {
    let primaryImage = GCS_IMAGE_BUCKET_LIFESTYLE + item.images[0].guid;
    item.$frontPreViewImage = createPreviewImage({ index: 2, src: primaryImage });
    item.$previewImages.unshift(createPreviewImage({ index: 0, src }));
  } else {
    item.$frontPreViewImage = createPreviewImage({ index: 2, src });
    item.$previewImages.unshift(createPreviewImage({ index: 0 }));
  }
  item.$previewImages.unshift(createPreviewImage({ index: 1 }));
  return {
    item,
    height,
    width
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    actions: bindActionCreators({

    }, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ImgItemPreview);
