import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CurationItems from './modules/Items';
import { DropTarget } from 'react-dnd';
import './style.scss';

const spec = {
  canDrop(props, monitor) {
    const item = monitor.getItem().item;
    let inSet;
    // cant drop on same item
    const items = props.list.items;
    // return item.props.product.itemId !== props.product.itemId;
    // dont allow dropping of item thats already in item set
    inSet = !!items.find(result => (result.id || result.itemId) === (item.id || item.itemId));
    return !inSet;
  },
  drop(props, monitor) {
    const item = monitor.getItem().item;
    const listId = props.list.id;
    if (item._selected && item.type !== 'curation') {
      props.actions.addAllSelectedToSet({ listId });
    } else {
      props.actions.addItemToSet({ item, listId });
    }
  }
};

const collect = (connect, monitor) => {
  const info = {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop()
  };
  return info;
};

class ItemSetDropComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: props.list.items
    };
  }
  // change made to fix issue 424 https://github.homedepot.com/DecorX/curationizer/issues/424
  // componentWillReceiveProps makes sure that the items are never replaced
  // while the component is in a loading state.
  componentWillReceiveProps(nextProps) {
    const loading = nextProps.loaders['item-update'];
    if (!loading) {
      this.setState({ items: nextProps.list.items });
    }
  }
  render() {
    const { connectDropTarget, isOver, canDrop } = this.props;
    const className = ['col__4-12'];
    if (isOver && canDrop) {
      className.push('curation--list--droppable');
    }
    // replaces the props list items with the component's stateful items
    const { items } = this.state;
    const list = { ...this.props.list, items };
    const totalItems = (items || []).filter(item => item._selected).length;
    const multiItemPreview = this.props.generateMultiItemPreview({
      totalItems,
      size: 'small'
    });
    return connectDropTarget(
      <div className={className.join(' ')}>
        <CurationItems {...this.props} list={list} multiItemPreview={multiItemPreview} />
      </div>
    );
  }
}

ItemSetDropComponent.propTypes = {
  position: PropTypes.objectOf(PropTypes.number),
  isOver: PropTypes.bool,
  connectDropTarget: PropTypes.func,
  movePiece: PropTypes.func,
  canDrop: PropTypes.bool,
  children: PropTypes.object,
  results: PropTypes.object,
  list: PropTypes.object,
  generateMultiItemPreview: PropTypes.func,
  multiItemPreview: PropTypes.object
};

export default DropTarget("DRAG_SOURCE", spec, collect)(ItemSetDropComponent);// eslint-disable-line
