
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import './diff.style.scss';
import RowHeader from '../../../../../../modules/RowHeader';
import defaultImage from '../../../../../../../../assets/images/default-product.jpg';
import { GCS_IMAGE_BUCKET_LIFESTYLE } from '../../../../../../../../constants/environment';
import { imageErrorHandler } from '../../../../../../../../util/index';
import { deserializeHotspotItems } from
  '../../../../../../../overlay/Hotspots/CurationHotSpotReducer';
import LifestyleDiff from './LifestyleDiff.component';
import ReviewItem from '../../../ReviewItemContainer/ReviewItem';

class DiffComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      boxes: []
    };
  }

  filterNew(item) {
    if (!item.diff) return true;
    if (item.type === 'A') return true;
    return false;
  }

  filterOld(item) {
    if (!item.diff) return true;
    if (item.type === 'D') return true;
    return false;
  }
  hasProductChanges(items = []) {
    return !!items.filter(i => i.diff).length;
  }

  hasAttributeChanges(details, meta) {
    if ((!details.length || details.length === 0) && !meta.length) {
      return false;
    }
    return true;
  }
  renderProductListItems(item, i) {
    return (<li key={i} className="diff-products__row">
    {item.itemId && <span>
      <img role="presentation" src={item.$imageSrc || defaultImage} width="100" />
    </span>}
    {item.diff && <span
      className={item.type === 'A'
        ? 'diff-products__new-item'
        : 'diff-products__deleted-item'}
    >
      <img role="presentation"
        src={item.item.$imageSrc || defaultImage}
        width="100"
      /></span>}
  </li>);
  }

  renderCurationListItem(item, i) {
    const { list } = this.props;

    const getClassToAdd = ({ items }) => {
      let classToAdd = '';

      if (items && items.type) {
        if (items.type === 'A') {
          classToAdd = 'diff-curations__row-new';
        } else if (items.type === 'D') {
          classToAdd = 'diff-curations__row-deleted';
        }
      }

      return classToAdd;
    };

    return (<li key={i}
      className={`diff-curations__row
        ${getClassToAdd({ items: item })}`}
    >
      <ReviewItem list={list} item={item.item || item} displayRemove={false} />
    </li>);
  }

  renderNoDiffMessage() {
    return (
      <div className="no-diff">
        <div>
          <span>No Changes!</span>
          <i className="fa fa-check-circle-o" aria-hidden="true"></i>
        </div>
      </div>
    );
  }


  render() {
    const { version, latestVersion } = this.props;
    const {
      details = [],
      items = [],
      images = [],
      imageBoundBox = [],
      meta = []
    } = version.diff;
    const { list, navigate } = this.props;
    const hasProductChanges = this.hasProductChanges(items);
    const hasBoundBoxDiff = !!imageBoundBox.filter(i => i.diff).length;
    const hasImageDiffs = !!images.filter(i => i.diff).length;
    const { boxes = [] } = this.state;
    return (
      <div className="grid diff">

        <div className="col__4-12">
          <RowHeader title="Attributes" />
        </div>

        <div className="col__4-12">
          <RowHeader title={list.type === 'curation' ? 'Products' : 'Curations'} />
        </div>

        <div className="col__4-12">
          <RowHeader title="Images" />
        </div>

        <div className="col__4-12 diff-attributes">
          {!this.hasAttributeChanges(details, meta) && this.renderNoDiffMessage('Attributes')}
          {this.hasAttributeChanges(details, meta) && <ul>
            <li className="diff-attributes__row diff-attributes__row--title">
            <span className="diff-attributes__cell diff-attributes__cell--name
              diff-attributes__cell--title"
            >
              Name
            </span>
            <span className="diff-attributes__cell diff-attributes__cell--old-value
              diff-attributes__cell--title"
            >
            Version {!version.current ? version.listVersion : latestVersion.listVersion}
            </span>
            <span className="diff-attributes__cell diff-attributes__cell--new-value
              diff-attributes__cell--title"
            >
            Version {version.current ? version.listVersion : latestVersion.listVersion}
            </span>
            </li>
          {details.map((diff, i) => {
            return (
            <li key={i} className="diff-attributes__row">
              <span className="diff-attributes__cell diff-attributes__cell--name">
                {diff.path.join(':')}
              </span>
              <span className="diff-attributes__cell diff-attributes__cell--old-value">
                {diff.lhs}
              </span>
              <span className="diff-attributes__cell diff-attributes__cell--new-value">
                {diff.rhs}
              </span>
            </li>
            );
          })}
          {meta.map((diff, i) => {
            return (<li key={i} className="diff-attributes__row">
              <span className="diff-attributes__cell diff-attributes__cell--dim-name">
                {diff.path.join(':')}
              </span>
              <span className="diff-attributes__cell diff-attributes__cell--old-value">
                {diff.lhs}
              </span>
              <span className="diff-attributes__cell diff-attributes__cell--new-value">
                {diff.rhs}
              </span>
            </li>);
          })}
          </ul>}
        </div>
        <div className="col__4-12 diff-products">
            {!hasProductChanges && this.renderNoDiffMessage()}
            {hasProductChanges && list.type === 'curation' &&
            <Fragment>
             <ul>
              <li className="diff-products__row diff-products__row--title">
                Version {!version.current ? version.listVersion : latestVersion.listVersion}
              </li>
              {items
              .filter(this.filterOld)
             .map(this.renderProductListItems)}</ul>
            <ul>
              <li className="diff-products__row diff-products__row--title">
                Version {version.current ? version.listVersion : latestVersion.listVersion}
              </li>
              {items
              .filter(this.filterNew)
             .map(this.renderProductListItems)}</ul>
             </Fragment>}
            {hasProductChanges && list.type === 'collection' &&
              <ul>{items.map(this.renderCurationListItem.bind(this))}</ul>}
        </div>
        <div className="col__4-12 diff-hotspots">
          {!hasImageDiffs && !hasBoundBoxDiff && this.renderNoDiffMessage()}
          {(hasImageDiffs || hasBoundBoxDiff) && <ul>
          {images.map((image, i) => {
            return (<LifestyleDiff key={i} image={image} imageBoundBox={imageBoundBox} />);
          })}
          </ul>}
        </div>
    </div>
    );
  }
}

DiffComponent.propTypes = {
  version: PropTypes.object,
  latestVersion: PropTypes.object,
  list: PropTypes.object,
  navigate: PropTypes.func
};
export default DiffComponent;
