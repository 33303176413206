import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './item.scss';
import ImgItemPreview from './modules/ImgItemPreview';
import { waitForDoubleClick } from '../../../../../../util';

class CurationComponent extends Component {
  render() {
    const {
      item,
      style,
      type
    } = this.props;
    return (
      <div>
        <ImgItemPreview item={item} type={type} />
        <div className="curation-list--item-content--bg" data-item-id={item.itemId}></div>
          <div className="curation-list--item-content">
            {style.size === 'large' &&
            <div className="curation-list--item-content-text">
              <div className="curation__item--label">{item.name}</div>
              <p>Curation ID #{item.itemId || item.id}</p>
            </div>
            }
            {style.size === 'small' &&
            <div className="curation-list--item-content-text">
              <p>Curation ID #{item.itemId || item.id}</p>
            </div>
            }
          </div>
    </div>);
  }
}

CurationComponent.propTypes = {
  actions: PropTypes.shape({
    toggleItemSelection: PropTypes.func,
    addItemToSet: PropTypes.func
  }),
  list: PropTypes.object,
  item: PropTypes.object,
  curationListView: PropTypes.string,
  style: PropTypes.object,
  ui: PropTypes.object,
  loaders: PropTypes.object,
  type: PropTypes.string
};

class ProductComponent extends Component {

  render() {
    const {
      item,
      style
    } = this.props;
    return (
      <div>
        <div className="curation-list--item-content--bg" data-item-id={item.itemId}></div>
        <div className="curation-list--item-content">
          {style.size === 'large' &&
          <div className="curation-list--item-content-text">
            {item.info && item.info.brandName && <p>{item.info.brandName}</p>}
            <div className="curation__item--label curation__item--text">
              {item.info ? item.info.productLabel : ''}
            </div>
            <div className="curation__item--label curation__item--text">
              {item.vendor ? item.vendor : ''}
            </div>
            <p className="curation__item--omsid">OMSID #{item.itemId || item.id}</p>
            <span className="reviews">
              <span className="stars"
                rel={item.ratingsReviews.averageRating}
                style={{ width: ((item.ratingsReviews.averageRating * 100) / 5) + '%' }}
              >{item.ratingsReviews.averageRating}</span>
            </span>
            <p>${item.storeSku.pricing.specialPrice}/{item.storeSku.pricing.uom}</p>
          </div>
          }
          {style.size === 'small' &&
          <div className="curation-list--item-content-text">
            <p>OMSID #{item.itemId || item.id}</p>
          </div>
          }
        </div>
    </div>);
  }
}

ProductComponent.propTypes = {
  actions: PropTypes.shape({
    toggleItemSelection: PropTypes.func,
    addItemToSet: PropTypes.func
  }),
  list: PropTypes.object,
  item: PropTypes.object,
  curationListView: PropTypes.string,
  style: PropTypes.object,
  ui: PropTypes.object,
  loaders: PropTypes.object,
  type: PropTypes.string
};

class ItemComponent extends Component {

  render() {
    const {
      item,
      loaders,
      list,
      ui,
      actions,
      curationListView,
      style,
      type,
      index
    } = this.props;
    return (
    <div className={curationListView === 'curation' ?
      'curation-list--row' : 'curation-list--column'
   }>
      {item.$inCuration && type !== 'set' && <div>
        <span className="mw-icon-form-check-solid curation-list--check"></span>
      </div>}
      <div
        className={`
          ${list.type === 'collection' ? 'u__curation' : 'u__item'}
          curation-list--item ${item._selected && (!item.$inCuration || type === 'set')
          ? 'curation-list--item--selected' : ''}`}
        style={style.container}
        onClick={(e) => {
          if (e.shiftKey) {
            if (item.type === 'curation') {
              actions.openCurationForItem({ item });
            } else {
              actions.openPipForItem({ item });
            }
          } else if (item.type === 'curation' && type !== 'set') {
            // show curation details
            waitForDoubleClick(() => {
              actions.selectCurationItem({ list, item, index });
            });
          } else if (!item.$inCuration || type === 'set') {
            e.stopPropagation();

            actions.toggleItemSelection({
              itemId: item.id || item.itemId,
              listId: list.id,
              resultSet: type
            });
          }
        }}
        onDoubleClick={(e) => {
          if (!item.$inCuration || type === 'set') {
            if (list.type !== 'collection' || (list.type === 'collection' && type !== 'review')) {
              e.stopPropagation();
              actions.doubleClickItem({ item, list, serverCall: type === 'review' });
            }
          }
        }}
      >
      {item.type !== 'product' && <div>
        <CurationComponent item={item} style={style} type={type} />
      </div>}
      {item.type === 'product' && <ProductComponent item={item} style={style} />}
      </div>
      {ui.curateList.editMode && !style.text && type === 'set' &&
        <a className="curation-list--item__edit" href="#"
          onClick={(e) => {
            e.preventDefault();
            actions.removeItemFromSet({ item, listId: list.id, type: list.type });
          }}
        ></a>
      }
      {!style.text && curationListView === 'curation' && type === 'set' &&
        <div className="curation-list--item__name">
          <div className="curation-list--item__name-title">
            {item.info ? item.info.productLabel : ''}</div>
            <div className="curation-list--item__name-subtitle">
              {list.type === 'curation' ? 'OMSID' : 'Curation ID'} #{item.itemId || item.id}
            </div>
        </div>
      }
    </div>);
  }
}

ItemComponent.propTypes = {
  actions: PropTypes.shape({
    toggleItemSelection: PropTypes.func,
    addItemToSet: PropTypes.func
  }),
  index: PropTypes.number,
  list: PropTypes.object,
  item: PropTypes.object,
  curationListView: PropTypes.string,
  style: PropTypes.object,
  ui: PropTypes.object,
  loaders: PropTypes.object,
  type: PropTypes.string
};

export default ItemComponent;
