import React, { Component } from 'react';
import PropTypes from 'prop-types';
import HeaderContainer from '../../../modules/Header';
import SimilarItems from './Modules/SimilarItems';
import './style.scss';
import ProductDetails from './Modules/ProductDetails';
import MerchandisingAttributes from './Modules/MerchandisingAttributes';
class MaintenanceDetailsComponent extends Component {

  componentDidMount() {
    //
    const { actions, item } = this.props;
    if (item.itemId) {
      actions.getProductAttributes({ itemId: item.itemId });
    }
    document.title = "Products Details";
  }
  render() {
    const {
      loaders,
      item
    } = this.props;
    return (
      <div className="grid curationizer-main">
        <HeaderContainer />
        <div className="grid">
          <div className="col__12-12 product-details-div">
            <ProductDetails />
          </div>
          <div className="col__12-12">
            <div>
              <div className="col__12-12 curation--item__Description">
                  <MerchandisingAttributes itemId={item.itemId} />
              </div>
            </div>
          </div>
        </div>
        <div className="grid">
          <div className="col__12-12">
            <SimilarItems itemId={item.itemId} />
          </div>
        </div>
      </div>
    );
  }
}

MaintenanceDetailsComponent.propTypes = {
  loaders: PropTypes.object,
  meta: PropTypes.object,
  actions: PropTypes.object,
  item: PropTypes.object
};

export default MaintenanceDetailsComponent;
