import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './SimilarItems.style.scss';
import { SVG_CLOSE_PATH } from '../../../../../../../constants/environment';
class SimilarItemsComponent extends Component {

  componentDidMount() {
    const { itemId, actions } = this.props;
    actions.loadSimilarItems({ itemId });
    actions.resetSimilarItemsSlider();
  }
  render() {
    const {
      loaders,
      showSimilarItems,
      similarItems,
      actions,
      itemId,
      curationAndItemSelected
    } = this.props;
    return (
      <div className="grid similar_items--wrapper">
        <div className="col__12-12">
        <section className={`toggle-form ${showSimilarItems ? 'active' : ''}`}>
            <div className={`formwrap ${showSimilarItems ? 'active' : ''}`}>
            <div style={{ padding: '0 10px', zIndex: 100 }}
              className="col__12-12 popUp__header similar-items__header"
            >
              <div className="row-header">
                <div className="row-header__title">
                  <span className="row-header__text similar-items__title">Similar Items</span>
                </div>
                <a
                  href="#"
                  className="thd-overlay__close similar_items--close"
                  onClick={e => {
                    e.preventDefault();
                    actions.toggleSimilarItems({ showSimilarItems });
                  }}
                >
                  <div className="popUp__close">
                    {' '}
                    <svg viewBox="0 0 32 32">
                      <title>close</title>
                      <path d={SVG_CLOSE_PATH} />
                    </svg>
                  </div>
                </a>
              </div>
            </div>
              {/* Start of Similar Items Content*/}
              <div className="grid">
                <div className="col__12-12">
                  <form
                    onSubmit={e => {
                      e.preventDefault();
                    }}
                  >
                    <input
                      type="text"
                      id="keyword"
                      name="keyword"
                      className="form-input__field input-group--inline__input similar-items__search"
                      placeholder="search Item"
                      onChange={e => {
                        e.preventDefault();
                        actions.searchForSimilarItems({ keyword: e.target.value });
                      }}
                    />
                  </form>
                </div>
                {loaders['similar-items'] && (
                  <div className="col__12-12 curationList-loader">
                    <div className="pre-loader">
                      <div className="segment-spinner" />
                    </div>
                  </div>
                )}
                {!loaders['similar-items'] && similarItems && similarItems.map((item, index) => {
                  return (
                  <div className="col__4-12" key={index}>
                    <div
                      className={`curation-list--item ${item.isSelected ?
                        'similar-item__selected' : ''}`}
                      onClick={(e) => {
                        e.preventDefault();
                        actions.toggleSimilarItemSelection({ itemId: item.itemId,
                          isSelected: item.isSelected });
                      }}
                      style={{
                        width: '115px',
                        height: '115px',
                        float: 'none',
                        backgroundImage: `url(${item.$imageSrc})`
                      }}
                    >
                      <div className="curation-list--item-content--bg"></div>
                      <div className="curation-list--item-content">
                        <div className="curation-list--item-content-text">
                          <p>{`OMSID #${item.itemId}`}</p>
                          <div className="curation__item--label similiar-item-label">
                          {item.info.productLabel}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  );
                })}
                {!loaders['similar-items'] &&
                  <div className="col__12-12 similar-items-action" style={{ zIndex: 100 }}>
                    <a href="#" className={`bttn bttn--primary
                    ${!curationAndItemSelected ? 'disabled' : ''}`}
                      onClick={(e) => {
                        e.preventDefault();
                        actions.replaceItem({ itemId });
                      }}
                    >
                      <span className="bttn__content"><span>Replace Item</span></span>
                    </a>
                  </div>
                }
              </div>
              {/* End of Similar Items Content*/}
            </div>
            <div className={`toggle-bg ${showSimilarItems ? 'active' : ''}`}></div>
          </section>
        </div>
      </div>
    );
  }
}

SimilarItemsComponent.propTypes = {
  loaders: PropTypes.object,
  showSimilarItems: PropTypes.bool,
  curationAndItemSelected: PropTypes.bool,
  itemId: PropTypes.string,
  similarItems: PropTypes.array,
  actions: PropTypes.object
};

export default SimilarItemsComponent;
