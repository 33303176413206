import * as types from '../../../../../../constants/ActionTypes';
import mockState from './mock.js';
const initialState = {
  itemView: 4,
  itemId: 0
};

export const updateImageSrc = ({ data, size = '400' }) => {
  let path = data.info ? data.info.imageUrl : '';
  if (path) {
    return path.replace(/<SIZE>/g, size);
  }
  return '';
};

export default function app(state = initialState, action) {
  switch (action.type) {
    case types.FILTER_RESULTS:
      return {
        ...state,
        results: state.results.filter(item => {
          return action.filteredItemIds.indexOf(item.itemId) === -1;
        })
      };
    case types.TOGGLE_RESULTS_ITEM_SELECTION:
      return {
        ...state,
        results: {
          ...state.results,
          items: ((state.results || {}).items || []).map(item => {
            if (action.resultSet === 'set') return item;
            if ((item.id || item.itemId) === action.itemId) {
              return {
                ...item,
                _selected: !item._selected
              };
            }
            return item;
          })
        }
      };
    case types.ADD_ITEMS_TO_SET:
      return {
        ...state,
        results: {
          ...state.results,
          items: ((state.results || {}).items || []).map(item => {
            return {
              ...item,
              _saved: false,
              _selected: false
            };
          })
        }
      };
    case types.ADD_ITEM_TO_SET:
      return {
        ...state,
        results: {
          ...state.results,
          items: ((state.results || {}).items || []).map(item => {
            return {
              ...item,
              _selected: false
            };
          })
        }
      };
    case types.REMOVE_ITEMS_FROM_SET:
      return {
        ...state,
        results: {
          ...state.results,
          items: state.results.items.map(item => {
            return {
              ...item,
              _selected: false
            };
          })
        }
      };
    case types.REMOVE_ITEM_FROM_SET:
      return {
        ...state,
        results: {
          ...state.results,
          items: ((state.results || {}).items || []).map(item => {
            return {
              ...item,
              _selected: false
            };
          })
        }
      };
    case types.SELECT_CURATION_ITEM:
      return {
        ...state,
        results: {
          ...state.results,
          items: ((curations) => {
            return curations.map((item, i) => {
              if (item.id === action.id) {
                return {
                  ...item,
                  _selected: true
                };
              }
              return {
                ...item,
                _selected: false
              };
            });
          })(state.results.items || [])
        }
      };
    case types.UPDATE_ITEMS_DATA:
      return {
        ...state,
        results: {
          searchReport: {
            ...action.value.searchReport,
            resultType: action.resultType
          },
          items: (action.value.items ||
                  action.value.lists ||
                  action.value.skus ||
                  []).map(item => {
                    if (item.type === 'curation') {
                      return {
                        ...item,
                        items: item.skus || item.items
                      };
                    }
                    return {
                      ...item,
                      storeSku: {
                        pricing: {},
                        ...item.storeSku
                      },
                      $imageSrc: updateImageSrc({ data: item })
                    };
                  })
        }
      };
    case types.SET_RESULTS_FOR_ITEM:
      return {
        ...state,
        results: {
          ...state.results,
          items: ((curations) => {
            if (action.itemType === 'curation') {
              return curations.map(curation => {
                if (curation.id === action.itemId) {
                  return {
                    ...curation,
                    items: (action.results || []).map(sku => {
                      return {
                        ...sku,
                        $imageSrc: updateImageSrc({ data: sku })
                      };
                    })
                  };
                }
                return curation;
              });
            }
            return curations;
          })((state.results && state.results.items) || [])
        }
      };
    case types.RESET_UI_SETTINGS:
      return {
        ...state,
        results: null
      };
    case types.TOGGLE_ITEM_RESULT_VIEW:
      return {
        ...state,
        itemView: action.view
      };
    case types.SET_ITEM_ID_FOR_ITEM_BY_ITEM:
      return {
        ...state,
        itemId: action.itemId
      };
    case types.UN_SET_ITEM_ID_FOR_ITEM_BY_ITEM:
      return {
        ...state,
        itemId: 0
      };
    default:
      return state;
  }
}

export const getResultItemById = ({ itemId, state = [] }) => {
  return state.results.skus.find(item => item.itemId === itemId);
};

export const getCurationItemById = ({ itemId, state = [] }) => {
  return state.curation.find(item => item.itemId === itemId);
};

export const hydrateItems = ({
  results = { skus: [], curations: [] }, items = [], listType = 'curation' }) => {
  const ret = {
    ...results
  };
  ret.$items = (results.items || []).map(resultItem => {
    const itemIndex = items
      .findIndex(item => (item.itemId || item.id) === (resultItem.itemId || resultItem.id));
    return {
      ...resultItem,
      $prevewImage: '',
      $inCuration: itemIndex > -1
    };
  });
  return ret;
};
