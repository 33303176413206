import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as types from '../../../../../../constants/ActionTypes';
import { bindActionCreators } from 'redux';
import * as actions from './actions';
import { GCS_IMAGE_BUCKET_LIFESTYLE } from '../../../../../../constants/environment';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';

import component from './component';

function mapStateToProps(state, ownProps) {
  const list = ownProps.list;
  const item = ownProps.item;

  const imageSrc = list.primaryImage
    ? GCS_IMAGE_BUCKET_LIFESTYLE + list.primaryImage
    : state.ui.reviewPreviewImage || '';


  list.images = list.images || [];

  list.$canUploadMoreImages = list.images.length < 4;


  return {
    list,
    imageSrc,
    ui: state.ui,
    loaders: state.app.loaders || {}
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {

  // dispatch((d, getState) => {
  //   const state = getState();
  //   const list = state.curationList.lists.find(l => l.id, ownProps.list.id);
  //   if ((list.images || []).length === 0) {
  //     dispatch({ type: types.ADD_EMPTY_IMAGE, listId: list.id });
  //   }
  // });

  return {
    actions: bindActionCreators({
      ...actions,
    }, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(component);
/*
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(

));

*/