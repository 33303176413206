import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './style.scss';

class Slider extends Component {
  render() {
    const {
      listId,
      slider,
      actions,
      generateThumbImage
    } = this.props;
    return (
      <div className="accordion__wrapper">
      <div className="accordion__item">
        <input
          className="accordion__input"
          id={`ac-${slider.id}`}
          name="accordion-2"
          type="checkbox"
        />
        <label className="accordion__label" htmlFor={`ac-${slider.id}`}>
          <div className="accordion__trigger">
            <div className="accordion__plus" />
          </div>

          <div className="slider-text-wrapper">
            <div className="slider-text-wrapper__text">{slider.name}</div>
            {slider.type === 'dimension' && (
              <div className="slider-text-wrapper__slider numeric-slider" id={'sId-' + slider.id}>
                <ControlledInput
                  slider={slider}
                  listId={listId}
                  weight={slider.weight}
                  attribute={null}
                  callback={actions.setSliderValue}
                />
                <style>
                  {`#sId-${slider.id} input[type=range]::-webkit-slider-thumb {
                  background-image: url(${generateThumbImage({ value: slider.weight })});
                 }
                `}
                </style>
              </div>
            )}
            {slider.type === 'dimension' && (
              <div
                className={`slider-text-wrapper__lock ${
                  slider.isLocked ? 'slider-text-wrapper__lock--locked' : ''
                }`}
              >
                <a
                  href="#"
                  onClick={e => {
                    e.preventDefault();
                    actions.toggleSliderLockState({
                      sliderId: slider.id,
                      listId,
                      sliderType: slider.type
                    });
                  }}
                >
                  <i
                    className={`fa fa-${slider.isLocked ? 'lock' : 'unlock'}`}
                    aria-hidden="true"
                  />
                </a>
              </div>
            )}
          </div>
        </label>

        <div className="accordion__content-wrapper accordion__content-scroll">
          <div className="accordion__content">
            {slider.type !== 'filter' &&
              slider.attributes.map((attribute, i) => {
                return (
                  <div key={i} className="slider-attribute__row">
                    <div className="slider-attribute__name">{attribute.name}</div>
                    <div className="slider-attribute__slider test__non--refinement">
                      <ControlledInput
                        slider={slider}
                        listId={listId}
                        weight={attribute.weight}
                        attributeId={attribute.id}
                        callback={actions.setSliderAttributeValue}
                      />
                    </div>
                  </div>
                );
              })}

            {slider.type === 'filter' && (
              <div className="slider-attribute__row">
                <div className="checkbox-btn refinements__checkbox refinement-checkbox__label">
                  <input
                    className="checkbox-btn__input"
                    type="checkbox"
                    value="value-1"
                    id={`${slider.id}-rc-all`}
                    name={`${slider.id}-rc-all`}
                    checked={slider._lockAll}
                    readOnly
                  />
                  <label
                    className="checkbox-btn__label"
                    htmlFor={`${slider.id}-rc-all`}
                    onClick={e => {
                      actions.setAllAttributeValuesOfRefinement({
                        listId,
                        sliderType: slider.type,
                        sliderId: slider.id,
                        value: slider._lockAll ? 0 : 100
                      });
                    }}
                  >
                    All
                  </label>
                </div>
              </div>
            )}
            {slider.type === 'filter' &&
              slider.attributes
              .sort((a, b) => a.name > b.name ? 1 : -1)
              .map((attribute, i) => {
                return (
                  <div key={i} className="slider-attribute__row">
                    <div className="checkbox-btn refinements__checkbox refinement-checkbox__label">
                      <input
                        className="checkbox-btn__input"
                        type="checkbox"
                        value="value-1"
                        id={`${slider.id}-rc-${i}`}
                        name={`${slider.id}-rc-${i}`}
                        checked={attribute._locked}
                        readOnly
                      />
                      <label
                        className="checkbox-btn__label test-attribute__label"
                        htmlFor={`${slider.id}-rc-${i}`}
                        onClick={e => {
                          actions.setRefinementAttributeValue({
                            listId,
                            sliderType: slider.type,
                            sliderId: slider.id,
                            attributeId: attribute.id,
                            value: attribute._locked ? 0 : 100
                          });
                        }}
                      >
                        {attribute.name}
                      </label>
                    </div>
                    <span className="refinements__value"> {attribute.value}</span>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>);
  }
}

const isArrowKey = (keyCode) => {
  const arrowKeyCodes = [37, 38, 39, 40];
  return arrowKeyCodes.some(x => x === keyCode);
};

class ControlledInput extends Component {
  render() {
    const {
      slider,
      weight,
      attributeId = null,
      listId,
      callback
    } = this.props;
    const args = {
      attributeId,
      listId,
      sliderType: slider.type,
      sliderId: slider.id,
      sliderIsLocked: slider.isLocked
    };
    return (
    <input
      type="range"
      min="0"
      max="100"
      step="1"
      onChange={e => {
        const { value } = e.target;
        callback({
          ...args,
          value
        });
      }}
      onMouseUp={e => {
        const { value } = e.target;
        callback({
          ...args,
          value,
          complete: true,
          fetch: true
        });
        return null;
      }}
      onKeyUp={e => {
        if (isArrowKey(e.keyCode)) {
          const { value } = e.target;
          callback({
            ...args,
            value,
            complete: true,
            fetch: true
          });
        }
      }}
      value={weight}
    />);
  }
}

ControlledInput.propTypes = {
  weight: PropTypes.number,
  slider: PropTypes.object,
  attributeId: PropTypes.number,
  listId: PropTypes.number,
  callback: PropTypes.func,
  complete: PropTypes.bool
};

Slider.propTypes = {
  generateThumbImage: PropTypes.func,
  listId: PropTypes.number,
  slider: PropTypes.object,
  actions: PropTypes.object
};

export default Slider;
