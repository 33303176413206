import * as types from '../../../constants/ActionTypes';
import { guid } from '../../../util';
import * as api from '../../../util/api';
import Overlay from 'thd-overlay';
import { deserializeHotspotItems,
         serializeHotspotItems,
         getHotspotImage,
         checkHotspotMove } from './CurationHotSpotReducer';

const TIMESTAMPS = {};

const offset = (elem) => {
  let docElem;
  let win;
  let rect;
  let doc;
  const PADDING = 10;

  if (!elem) {
    return null;
  }
  rect = elem.getBoundingClientRect();
  // Make sure element is not hidden (display: none) or disconnected
  if (rect.width || rect.height || elem.getClientRects().length) {
    doc = elem.ownerDocument;
    win = window;
    docElem = doc.documentElement;
    return {
      top: rect.top + win.pageYOffset - docElem.clientTop + PADDING,
      left: rect.left + win.pageXOffset - docElem.clientLeft + PADDING
    };
  }
};

export const util = {
  toPercent: ({ value, total }) => {
    return parseFloat((value / total).toFixed(10));
  }
};

export const removeLoaders = () => {
  return { type: types.REMOVE_LOADER, loader: 'hotspot-loader' };
};

export const setHotspotItem = ({ hotspotId, item }) => {
  return (dispatch, getState) => {
    dispatch({ type: types.HOTSPOT_SET_ITEM, hotspotId, item });
    const state = getState();
    dispatch({ type: types.UPDATE_HISTORY_ON_SET_ITEMS, items: state.hotspots.items });
  };
};

export const toggleHotspotVisibility = ({ hotspotId } = {}) => {
  return { type: types.HOTSPOT_TOGGLE_VISIBILITY, hotspotId };
};

export const toggleAllHotspotVisibility = ({ hotspotId, show } = {}) => {
  return { type: types.HOTSPOT_TOGGLE_VISIBILITY, show };
};

export const toggleGridListView = () => {
  return { type: types.HOTSPOT_GRID_VIEW_STATUS };
};

export const togglePinterestPreview = () => {
  return { type: types.HOTSPOT_TOGGLE_PINTEREST_PREVIEW };
};

export const saveHotspotTemplate = ({ hotspotItems, name }) => {
  return (dispatch) => {
    const payload = { type: types.HOTSPOT_SAVE_TEMPLATE, hotspotItems, name };
    dispatch(payload);
    Overlay.postMessage({
      event: {
        type: 'redux_event',
        message: {
          ...payload
        }
      }
    });
  };
};

export const clearHotspotTemplate = () => {
  return (dispatch) => {
    const payload = { type: types.HOTSPOT_CLEAR_TEMPLATES };
    dispatch(payload);
    Overlay.postMessage({
      event: {
        type: 'redux_event',
        message: {
          ...payload
        }
      }
    });
  };
}

export const applyHotspotTemplate = ({ id }) => {
  return (dispatch, getState) => {
    const state = getState();
    const templates = state.hotspots.hotspotTemplates || [];
    const template = templates.find(t => t.id === id);
    if (template) {
      const items = template.items;
      dispatch({
        type: types.HOTSPOT_APPLY_TEMPLATE,
        id,
        items,
        canvas: state.hotspots.canvas
      });
    }

  };
};

export const setDefaultHotspotView = () => {
  return { type: types.SET_DEFAULT_GRID_VIEW_PINTEREST_PREVIEW };
};

export const updateHotSpotItemIndex = ({ dragItemIndex, dropItemIndex }) => {
  return {
    type: types.HOTSPOT_UPDATED_ITEM,
    dragItemsIndex: dragItemIndex,
    dropItemsIndex: dropItemIndex
  };
};

export const saveHotpotsForList = ({ listId, imageGuid, canEdit, dirtyCheck = false }) => {
  return (dispatch, getState) => {
    const state = getState();
    const list = state.curationList.lists.find(l => l.id === listId);
    return new Promise((resolve, reject) => {
      if ((dirtyCheck && !state.hotspots.isCanvasDirty) || !canEdit) {
        return resolve();
      }
      const hotspotsSerialized = serializeHotspotItems({ state, imageGuid });
      dispatch({ type: types.INITIATE_LOADER, loader: 'hotspot-loader' });
      api
        .updateListMeta({
          list: {
            ...list,
            imageBoundBox: hotspotsSerialized.concat(
              // Add other lifestyle image hotspots to the array
              (list.imageBoundBox || []).filter(b => b.guid !== imageGuid)
            )
          },
          type: list.type
        })
        .then(response => {
          if (response.errorCode) {
            dispatch({ type: types.ADD_CURATION_ERROR, response: [response] });
          } else {
            const payload = {
              type: types.SET_LIST_DATA_WITHOUT_ITEMS,
              id: list.id,
              response,
              meta: state.app.meta
            };
            dispatch(payload);
            dispatch({ type: types.GENERIC_ERROR_HANDLER_CLEAR });
            dispatch({ type: types.REMOVE_LOADER, loader: 'hotspot-loader' });
            Overlay.postMessage({
              event: {
                type: 'redux_event',
                message: {
                  ...payload
                }
              }
            });
            resolve(response);
          }
        })
        .catch(e => {
          console.error(e);
          dispatch({ type: types.REMOVE_LOADER, loader: 'hotspot-loader' });
          reject(e);
        });
    });
  };
};
export const loadHotspotsFor = ({ listId, canvas, imageGuid }) => {
  return (dispatch, getState) => {
    const state = getState();
    const list = state.curationList.lists.find(l => l.id === listId);
    const imageBoundBoxItems = getHotspotImage({ list, imageGuid });
    const hotspots = deserializeHotspotItems({
      items: imageBoundBoxItems,
      canvas: canvas || state.hotspots.canvas
    });
    let products = [];
    if (list.type === 'curation') {
      products = list.items || [];
    } else if (list.items.length) {
      products = list.items[0].items;
    }
    let mapped = hotspots.map(item => {
      if (item.item && item.item.itemId) {
        const p = products.find(i => parseInt(i.itemId, 10) === item.item.itemId);
        if (p) {
          return {
            ...item,
            item: p
          };
        }
        return item;
      }
      return item;
    });
    dispatch({ type: types.HOTSPOT_LOAD_ITEMS, items: mapped });
  };
};

export const getSvgWrapper = (node) => {
  if (!node) return null;
  if (node.className.indexOf && node.className.indexOf('hotspot_canvas') !== -1) {
    return node;
  }
  return getSvgWrapper(node.parentNode);
};

export const getMousePosition = (e) => {
  if (!e) return {};
  const { left, top } = offset(getSvgWrapper(e.currentTarget));
  const mouseX = e.pageX - left;
  const mouseY = e.pageY - top;
  return { mouseX, mouseY };
};

export const clearHotspots = () => {
  return { type: types.HOTSPOT_CLEAR };
};

export const intializeNewHotspot = (e) => {
  return (dispatch, getState) => {
    const { mouseX, mouseY } = getMousePosition(e);
    const coords = { x: mouseX, y: mouseY };

    dispatch({ type: types.HOTSPOT_SET_DRAG, drag: true, coords, isCreating: true });
    dispatch({ type: types.HOTSPOT_RESET_DRAG_COORDS, coords });
  };
};

export const resizeNewHotspot = (e) => {
  return (dispatch, getState) => {
    const state = getState();
    if (state.hotspots.isDragging) {
      const { mouseX, mouseY } = getMousePosition(e);
      if (!mouseX || !mouseY) return;
      const startX = state.hotspots.coords.start.x;
      const startY = state.hotspots.coords.start.y;
      const dX = mouseX - startX;
      const dY = mouseY - startY;
      const coords = {
        width: Math.abs(dX),
        height: Math.abs(dY)
      };
      if (dX < 0) {
        coords.width = startX + dX;
      }
      if (dY < 0) {
        coords.height = startY + dY;
      }
      if (startX + coords.width > state.hotspots.canvas.width) {
        return;
      }
      if (isNaN(coords.width) || isNaN(coords.height)) {
        return;
      }
      dispatch({ type: types.HOTSPOT_RESET_DRAG_COORDS, coords });
    }
  };
};

export const createNewHotspot = (e) => {
  return (dispatch, getState) => {
    const state = getState();
    if (state.hotspots.isDragging) {
      dispatch({ type: types.HOTSPOT_SET_DRAG, drag: false });
      // create bounding box object
      const coords = {
        width: 0,
        height: 0,
        x: 0,
        y: 0
      };
      const hotspotCoords = {
        ...state.hotspots.coords,
      };
      delete hotspotCoords.start;
      const id = guid();
      if (hotspotCoords.width < 20 || !hotspotCoords.height) return;
      if (hotspotCoords.height < 20 || !hotspotCoords.height) return;
      dispatch({ type: types.HOTSPOT_SET_NEW_HOTSPOT, coords: hotspotCoords, id });
      // create a default center
      const dotCoords = {
        x: (hotspotCoords.width / 2),
        y: (hotspotCoords.height / 2),
      };
      dispatch({ type: types.CREATE_DOT_ON_HOTSPOT, coords: dotCoords, id });
      dispatch({ type: types.HOTSPOT_RESET_DRAG_COORDS, coords });
      const newState = getState();
      dispatch({ type: types.UPDATE_HISTORY_ON_SET_ITEMS, items: newState.hotspots.items });
      dispatch({ type: types.HOTSPOT_SET_ACTIVE, id });
    }
  };
};

export const moveHotspot = (e) => {
  return (dispatch, getState) => {
    const state = getState();
    let id = state.hotspots.activeHotspotId || e.target.dataset.id;
    if (!id) return;
    // 2nd condition, moving hotspot
    const hotspot = (state.hotspots.items || []).find(h => h.id === id);
    const { mouseX, mouseY } = getMousePosition(e);
    const mousePosition = { mouseX, mouseY };
    if (hotspot && hotspot.mouse && typeof hotspot.mouse.mouseX !== 'undefined') {
      const dX = mouseX - hotspot.mouse.mouseX;
      const dY = mouseY - hotspot.mouse.mouseY;
      const coords = {
        x: hotspot.coords.x + dX,
        y: hotspot.coords.y + dY
      };
      if (coords.x + hotspot.coords.width > state.hotspots.canvas.width && dX > 0) {
        coords.x = hotspot.coords.x;
      }
      // if the bbox is outside the bounds and the direction the user drags is further outside
      // the bounds, dont allow it
      if (coords.y + hotspot.coords.height > state.hotspots.canvas.height && dY > 0) {
        coords.y = hotspot.coords.y;
      }
      if (coords.x < 0) {
        coords.x = 0;
      }
      if (coords.y < 0) {
        coords.y = 0;
      }
      dispatch({ type: types.HOTSPOT_SET_REPOSITION_COORDS, coords, mousePosition, id, hotspot });
    } else if (hotspot) {
      dispatch({ type: types.HOTSPOT_SET_REPOSITION_COORDS, mousePosition,
        id: hotspot.id, hotspot });
    }
  };
};

export const resizeHotspot = (e) => {
  return (dispatch, getState) => {
    const state = getState();
    if (!e) return;
    let id = state.hotspots.activeHotspotId || (e && e.target && e.target.dataset.id);
    if (!id) return;
    const { mouseX, mouseY } = getMousePosition(e);
    // 2nd condition, moving hotspot
    const hotspot = (state.hotspots.items || []).find(h => h.id === id);
    const direction = hotspot.direction;
    const hotspotId = e.target.dataset.id;
    let currentDotCoord = hotspot.dotCoord;
    let dotCoord = {};
    let startX = hotspot.coords.x;
    let startY = hotspot.coords.y;
    if (direction === 'NW') {
      startX = hotspot.coords.x + hotspot.coords.width;
      startY = hotspot.coords.y + hotspot.coords.height;
    } else if (direction === 'NE') {
      startX = hotspot.coords.x;
      startY = hotspot.coords.y + hotspot.coords.height;
    } else if (direction === 'SW') {
      startX = hotspot.coords.x + hotspot.coords.width;
      startY = hotspot.coords.y;
    } else if (direction === 'SE') {
      startX = hotspot.coords.x;
      startY = hotspot.coords.y;
    }
    const dX = mouseX - startX;
    const dY = mouseY - startY;
    const coords = {
      width: Math.abs(dX),
      height: Math.abs(dY)
    };
    if (dX < 0) {
      coords.width = startX + dX;
    }
    if (dY < 0) {
      coords.height = startY + dY;
    }
    // prevents from going over edges
    if (coords.width + hotspot.coords.width > state.hotspots.canvas.width) {
      const totalWidth = coords.width + hotspot.coords.width;
      const canvasWidth = state.hotspots.canvas.width;
      const moreWidth = totalWidth - canvasWidth;
      coords.width = coords.width - moreWidth;
    }
    if (coords.height + hotspot.coords.height > state.hotspots.canvas.height) {
      const totalHeight = coords.height + hotspot.coords.height;
      const canvasHeight = state.hotspots.canvas.height;
      const moreheight = totalHeight - canvasHeight;
      coords.height = coords.height - moreheight;
    }
    if (coords.width < 0) {
      coords.width = 0;
    }
    if (coords.height < 0) {
      coords.height = 0;
    }
    // Repositioned Dot spot values
    if (coords.width > 0 && coords.height > 0) {
      if (currentDotCoord.x) {
        dotCoord.x = (currentDotCoord.x * coords.width) / hotspot.coords.width;
        dotCoord.y = (currentDotCoord.y * coords.height) / hotspot.coords.height;
        dispatch({ type: types.CREATE_DOT_ON_HOTSPOT, coords: dotCoord, id: hotspot.id });
      }
      dispatch({ type: types.HOTSPOT_SET_REPOSITION_COORDS,
        coords, id: hotspot.id });
    }
  };
};

export const onMouseLeave = (e) => {
  return (dispatch, getState) => {
    // console.log('mouseleave', e);
    // dispatch({ type: types.HOTSPOT_SET_DRAG, drag: false });
  };
};

export const setImageCoords = ({ image, listId }) => {
  return (dispatch, getState) => {
    const coords = {
      height: image.height,
      width: image.width
    };
    if (image.height === 0 || image.width === 0) {
      setTimeout(() => {
        dispatch(setImageCoords({ image, listId }));
      }, 500);
    } else {
      dispatch({ type: types.HOTSPOT_SET_CANVAS_COORDS, coords });
    }
  };
};

// Moving the hotspot
export const onMouseDown = ({ e }) => {
  return (dispatch, getState) => {
    TIMESTAMPS.mouseDown = new Date().getTime();
    if (e) e.stopPropagation();
    const state = getState();
    const id = e.target.dataset.id;
    if (!id) {
      dispatch(intializeNewHotspot(e));
    } else {
      // move or resize the hotspot
      const hotspot = (state.hotspots.items || []).find(h => h.id === id);
      if (hotspot.hidden) return;
      if (e.target.tagName === 'rect') {
        dispatch({ type: types.HOTSPOT_SET_REPOSITION, move: true, id: hotspot.id });
      } else if (e.target.tagName === 'circle' && e.target.dataset.name !== 'target-dot') {
        const direction = e.target.dataset.direction;
        dispatch({ type: types.HOTSPOT_SET_RESIZE, resize: true, direction, id: hotspot.id });
      }
    }
  };
};

export const onMouseUp = ({ e, synthetic = false }) => {
  e.stopPropagation();
  return (dispatch, getState) => {
    const state = getState();
    TIMESTAMPS.mouseUp = new Date().getTime();
    const id = e.target.dataset.id;
    if (!id) {
      dispatch(createNewHotspot(e));
      return;
    }
    const hotspot = (state.hotspots.items || []).find(h => h.id === id);
    if (hotspot.hidden) return;
    if (state.hotspots.isMoving) {
      dispatch({ type: types.HOTSPOT_SET_REPOSITION, move: false, id: hotspot.id });
      if (checkHotspotMove({ state, hotpspotId: hotspot.id })) {
        dispatch({ type: types.UPDATE_HISTORY_ITEMS_ON_MOVE, id: hotspot.id });
      }
    } else if (state.hotspots.isResizing) {
      dispatch({ type: types.HOTSPOT_SET_RESIZE, resize: false, id: hotspot.id });
    }
    if (!synthetic) {
      // setTimeout(() => {
      dispatch({ type: types.HOTSPOT_SET_ACTIVE, id: hotspot.id });
      // }, 300);
    }
  };
};

export const onMouseMove = ({ e }) => {
  return (dispatch, getState) => {
    TIMESTAMPS.mouseMove = new Date().getTime();
    if (e) e.stopPropagation();
    if (!e) return;
    if (e.buttons === 0) {
      dispatch(onMouseUp({ e, synthetic: true }));
      return;
    }
    // if the mouse is not down, return
    // user drags off of element and then mouseups

    // 4 conditions
    // 1. creating
    // 2. moving rectangle
    // 3. resizing rectangle
    // 4. hovering over rectangle (nothing)
    const state = getState();
    // 1st condition, create
    if (state.hotspots.isCreating) {
      dispatch(resizeNewHotspot(e));
    } else if (state.hotspots.isMoving) {
      dispatch(moveHotspot(e));
    } else if (state.hotspots.isResizing) {
      dispatch(resizeHotspot(e));
    }
  };
};

let to;
export const createHotspotDot = ({ e, hotspot }) => {
  return (dispatch, getState) => {
    if (e.target.nodeName === 'circle') return;
    if (TIMESTAMPS.mouseUp - TIMESTAMPS.mouseDown > 200 || !hotspot.active) {
      return;
    }
    if (hotspot.hidden) {
      return;
    }
    const { mouseX, mouseY } = getMousePosition(e);
    const hotspotX = hotspot.coords.x;
    const hotspotY = hotspot.coords.y - 5;
    const coords = {
      x: mouseX - hotspotX,
      y: mouseY - hotspotY
    };
    dispatch({ type: types.CREATE_DOT_ON_HOTSPOT, coords, id: hotspot.id });
    dispatch({ type: types.HOTSPOT_SET_ACTIVE, id: hotspot.id });
  };
};

export const deleteHotspot = ({ hotspotId }) => {
  return { type: types.DELETE_HOTSPOT, hotspotId };
};

export const setHotspotToPreviousVersion = () => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch({ type: types.SET_PREVIOUS_HOTSPOT });
  };
};
export const clearSpotInHotspot = ({ hotspotId }) => {
  return { type: types.CLEAR_SPOT_IN_HOTSPOT, hotspotId };
};
export const setActiveHotspot = ({ itemId }) => {
  const hotspotItemId = itemId;
  return (dispatch, getState) => {
    const state = getState();
    const hotspot = (state.hotspots.items || []);
    const clickedHotspot = hotspot.find(items => items.item && items.item.itemId === hotspotItemId);
    if (clickedHotspot) {
      dispatch({ type: types.HOTSPOT_SET_REPOSITION, move: false, id: clickedHotspot.id });
      dispatch({ type: types.HOTSPOT_SET_ACTIVE, id: clickedHotspot.id });
    }
  };
};
