import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from './actions';
import Slider from './component';

const mapStateToProps = (state, ownProps) => {
  const slider = ownProps.slider;
  const listId = ownProps.listId;
  return {
    listId,
    slider,
    loaders: state.loaders || {}
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    generateThumbImage: actions.generateThumbImage,
    actions: bindActionCreators({
      ...actions
    }, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Slider);
