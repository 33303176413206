import React, { Component } from 'react';
import PropTypes from 'prop-types';

class PinterestHeader extends Component {
  render() {
    const {
      pinterestFilter,
      actions,
      listType,
      match
    } = this.props;
    let keyword = match && match.params && match.params.keyword ? match.params.keyword : '';
    let startIndex = (match && match.params && match.params.page ? match.params.page : 0) * 24;
    let sortBy = match && match.params && match.params.sortBy ? match.params.sortBy : '';
    let sortOrder = match && match.params && match.params.sortOrder ? match.params.sortOrder : '';
    return (
      <nav className="drop-down__header pinterest__header">
        <span className="drop-down__title pinterest__title">All</span>
        {document.querySelector('.pinterest__title') &&
          document.querySelector('.pinterest__title').innerText === 'All' &&
          <div className="drop-down__content-wrapper">
            <ul className="drop-down__list">
              <PublishedToPinterest listType={listType}
                pinterestFilter={pinterestFilter}
                actions={actions}
              />
              <NotPublished listType={listType}
                pinterestFilter={pinterestFilter}
                actions={actions}
              />
            </ul>
          </div>
        }
        {document.querySelector('.pinterest__title') &&
          document.querySelector('.pinterest__title').innerText === 'Published to Pinterest' &&
          <div className="drop-down__content-wrapper">
            <ul className="drop-down__list">
              <NotPublished listType={listType}
                pinterestFilter={pinterestFilter}
                actions={actions}
              />
              <All listType={listType}
                pinterestFilter={pinterestFilter}
                startIndex={startIndex}
                sortBy={sortBy}
                sortOrder={sortOrder}
                keyword={keyword}
                pinterestFilter={pinterestFilter}
                actions={actions}
              />
            </ul>
          </div>
        }
        {document.querySelector('.pinterest__title') &&
          document.querySelector('.pinterest__title').innerText === 'Not Published' &&
          <div className="drop-down__content-wrapper">
            <ul className="drop-down__list">
              <PublishedToPinterest listType={listType}
                pinterestFilter={pinterestFilter}
                actions={actions}
              />
              <All listType={listType}
                pinterestFilter={pinterestFilter}
                startIndex={startIndex}
                sortBy={sortBy}
                sortOrder={sortOrder}
                keyword={keyword}
                pinterestFilter={pinterestFilter}
                actions={actions}
              />
            </ul>
          </div>
        }
      </nav>
    );
  }
}

/* eslint-disable */
const PublishedToPinterest = ({ listType, pinterestFilter, actions}) => (
  <li className="drop-down__item">
              <span className="drop-down__hover-effect">
                <div className="checkbox-btn">
                  <input
                    className="checkbox-btn__input pinterest-btn__checkbox"
                    type="checkbox"
                    id="Pinterest_yes"
                    checked={pinterestFilter.yes}
                    name="Pinterest_yes"
                  />
                  <label
                    htmlFor="Pinterest_yes"
                    onClick={(e) => {
                      e.preventDefault();
                      if (!e.target.previousSibling.disabled) {
                        actions.filterListByPinterest({
                          event: e,
                          pinterest: true,
                          listType
                        });
                        document.querySelector('.pinterest__title').
                          innerText = 'Published to Pinterest';
                      }
                    }}
                  >Published to Pinterest</label>
                </div>
              </span>
            </li>
);

const NotPublished = ({ listType, pinterestFilter, actions }) => (
  <li className="drop-down__item">
              <span className="drop-down__hover-effect">
                <div className="checkbox-btn">
                  <input
                    className="checkbox-btn__input pinterest-btn__checkbox"
                    type="checkbox"
                    id="Pinterest_no"
                    checked={pinterestFilter.no}
                    name="Pinterest_no"
                  />
                  <label
                    htmlFor="Pinterest_no"
                    onClick={(e) => {
                      if (!e.target.previousSibling.disabled) {
                        e.preventDefault();
                        actions.filterListByPinterest({
                          event: e,
                          pinterest: false,
                          listType
                        });
                        document.querySelector('.pinterest__title').innerText = 'Not Published';
                      }
                    }}
                  >Not Published</label>
                </div>
              </span>
            </li>
);

const All = ({ listType, startIndex, sortBy, sortOrder, keyword, pinterestFilter, actions }) => (
  <li className="drop-down__item" onClick={(e) => {
    e.preventDefault();
    actions.getLists({ type: listType, startIndex, sortBy, sortOrder, keyword,
      pinterestFilter });
    document.querySelector('.pinterest__title').innerText = 'All';
  }}
  >
    <span className="drop-down__hover-effect">
      <div className="checkbox-btn">
        <input
          className="checkbox-btn__input pinterest-btn__checkbox"
          type="checkbox"
          id="all"
          name="all"
        />
        <label
          htmlFor="all"
        >All</label>
      </div>
    </span>
  </li>
);

PinterestHeader.propTypes = {
  pinterestFilter: PropTypes.object,
  actions: PropTypes.object,
  listType: PropTypes.string,
  match: PropTypes.object
};

export default PinterestHeader;