module.exports = [{
  id: 'd1',
  position: 0,
  type: 'dimension',
  name: 'Style',
  attributes: [
    {
      id: 'datttr1',
      name: 'Art Deco',
      value: false
    },
    {
      id: 'dattr0',
      name: 'Glam',
      value: false
    },
    {
      id: 'dattr1',
      name: 'Modern',
      value: false
    },
    {
      id: 'dattr2',
      name: 'Mid-Century Modern',
      value: false
    },
    {
      id: 'dattr3',
      name: 'Cottage',
      value: false
    },
    {
      id: 'dattr4',
      name: 'Coastal',
      value: false
    },
    {
      id: 'dattr5',
      name: 'Farmhouse',
      value: false
    },
    {
      id: 'dattr6',
      name: 'Rustic',
      value: false
    },
    {
      id: 'dattr7',
      name: 'Mission',
      value: false
    },
    {
      id: 'dattr8',
      name: 'Classic',
      value: false
    },
    {
      id: 'dattr9',
      name: 'Industrial',
      value: false
    },
    {
      id: 'dattr10',
      name: 'Bohemian',
      value: false
    },
    {
      id: 'dattr11',
      name: 'Southwestern',
      value: false
    }
  ]
}, {
  id: 'd2',
  position: 1,
  type: 'dimension',
  name: 'Room',
  attributes: [
    {
      id: 'd2attr0',
      name: 'Kitchen & Dining Room',
      value: false
    },
    {
      id: 'd2attr1',
      name: 'Living Room',
      value: false
    },
    {
      id: 'd2attr2',
      name: 'Home Office',
      value: false
    },
    {
      id: 'd2attr3',
      name: 'Bedroom',
      value: false
    },
    {
      id: 'd2attr4',
      name: 'Bathroom',
      value: false
    },
    {
      id: 'd2attr5',
      name: 'Entryway',
      value: false
    },
    {
      id: 'd2attr6',
      name: 'Outdoor',
      value: false
    },
    {
      id: 'd2attr7',
      name: 'Laundry Room',
      value: false
    },
    {
      id: 'd2attr8',
      name: 'Kids Room',
      value: false
    }
  ]
}, {
  id: 'd3',
  position: 3,
  name: 'Color Family',
  type: 'dimension',
  attributes: [
    {
      id: 'd3attr0',
      name: 'Neutral',
      value: false
    },
    {
      id: 'd3attr1',
      name: 'Pastel',
      value: false
    },
    {
      id: 'd3attr2',
      name: 'Primary',
      value: false
    },
    {
      id: 'd3attr3',
      name: 'Neon',
      value: false
    },
    {
      id: 'd3attr4',
      name: 'Dark',
      value: false
    },
    {
      id: 'd3attr5',
      name: 'Light',
      value: false
    },
    {
      id: 'd3attr6',
      name: 'Warm',
      value: false
    },
    {
      id: 'd3attr7',
      name: 'Cool',
      value: false
    },
    {
      id: 'd3attr8',
      name: 'Metallic',
      value: false
    },
    {
      id: 'd3attr9',
      name: 'Earth Tones',
      value: false
    },
    {
      id: 'd3attr10',
      name: 'Grayscale',
      value: false
    },
    {
      id: 'd3attr11',
      name: 'Multi',
      value: false
    },
    {
      id: 'd3attr12',
      name: 'Clear',
      value: false
    }
  ]
}, {
  id: 'd4',
  position: 2,
  type: 'dimension',
  name: 'Color',
  attributes: [
    {
      id: 'd4attr0',
      name: 'Red',
      value: false
    },
    {
      id: 'd4attr1',
      name: 'Orange',
      value: false
    },
    {
      id: 'd4attr2',
      name: 'Yellow',
      value: false
    },
    {
      id: 'd4attr3',
      name: 'Green',
      value: false
    },
    {
      id: 'd4attr4',
      name: 'Blue',
      value: false
    },
    {
      id: 'd4attr5',
      name: 'Turquoise/Teal/Aqua',
      value: false
    },
    {
      id: 'd4attr6',
      name: 'Purple',
      value: false
    },
    {
      id: 'd4attr7',
      name: 'Pink',
      value: false
    },
    {
      id: 'd4attr8',
      name: 'Brown',
      value: false
    },
    {
      id: 'd4attr9',
      name: 'Black',
      value: false
    },
    {
      id: 'd4attr10',
      name: 'Gray',
      value: false
    },
    {
      id: 'd4attr11',
      name: 'White',
      value: false
    },
    {
      id: 'd4attr12',
      name: 'Gold',
      value: false
    },
    {
      id: 'd4attr13',
      name: 'Silver',
      value: false
    },
    {
      id: 'd4attr14',
      name: 'Copper',
      value: false
    }
  ]
}];
