import * as types from '../../../../constants/ActionTypes';
import { fetchItemList } from '../../App/CurateList/actions';
import * as gaActions from '../../modules/GoogleAnalytics/googleAnalytics.actions';

export const setSliderValue = ({ sliderIsLocked, value, listId,
  sliderId, sliderType, complete = false, fetch = false }) => {
  return (dispatch, getState) => {
    if (!sliderIsLocked) {
      dispatch({
        type: types.SET_SLIDER_VALUE,
        sliderId,
        value,
        listId,
        sliderType,
        complete
      });
      if (fetch) dispatch(fetchItemList({ id: listId }));
    }
  };
};

export const setSliderAttributeValue = ({ sliderId, attributeId, value,
  listId, sliderType, fetch = false }) => {
  return (dispatch, getState) => {
    const state = getState();
    const dimension = state.app.meta.dimensions.find(d => d.id === sliderId);
    const attribute = dimension.attributes.find(a => a.id === attributeId);
    gaActions.sendEvent('dimension', {
      event_category: gaActions.categories.ENGAGMENT,
      event_label: dimension.name + ': ' + attribute.name,
      value
    });
    dispatch({
      type: types.SET_SLIDER_ATTRIBUTE_VALUE,
      sliderId,
      attributeId,
      value,
      listId,
      sliderType
    });
    if (fetch) dispatch(fetchItemList({ id: listId }));
  };
};

export const setRefinementAttributeValue = ({
  sliderId,
  attributeId,
  value,
  listId,
  sliderType }) => {
  return (dispatch, getState) => {
    const state = getState();
    const filter = state.app.meta.filters.find(f => f.id === sliderId);
    const attribute = filter.attributes.find(a => a.id === attributeId);
    gaActions.sendEvent('filter', {
      event_category: gaActions.categories.ENGAGMENT,
      event_label: filter.name + ': ' + attribute.name,
      value: value === 100 ? 1 : 0
    });
    dispatch({
      type: types.SET_REFINEMENT_ATTRIBUTE_VALUE,
      attributeId,
      sliderId,
      value,
      listId,
      sliderType
    });
    dispatch(fetchItemList({ id: listId }));
  };
};

export const setAllAttributeValuesOfRefinement = ({
  listId,
  sliderType,
  sliderId,
  value
}) => {
  return (dispatch) => {
    dispatch({
      type: types.SET_ALL_ATTRIBUTE_VALUES_OF_REFINEMENT,
      sliderId,
      listId,
      sliderType,
      value
    });
    dispatch(fetchItemList({ id: listId }));
  };
};

export const toggleSliderLockState = ({ sliderId, listId, sliderType }) => {
  return { type: types.TOGGLE_SLIDER_LOCK, sliderId, listId, sliderType };
};

const PIXEL_RATIO = (() => {
  const ctx = document.createElement('canvas').getContext('2d');
  const dpr = window.devicePixelRatio || 1;
  const bsr = ctx.webkitBackingStorePixelRatio ||
            ctx.mozBackingStorePixelRatio ||
            ctx.msBackingStorePixelRatio ||
            ctx.oBackingStorePixelRatio ||
            ctx.backingStorePixelRatio || 1;

  return dpr / bsr;
})();

const createHiDPICanvas = function createHiDPICanvas(w, h, ratio = PIXEL_RATIO) {
  const can = document.createElement('canvas');
  can.width = w * ratio;
  can.height = h * ratio;
  can.style.width = w + 'px';
  can.style.height = h + 'px';
  can.getContext('2d').setTransform(ratio, 0, 0, ratio, 0, 0);
  return can;
};
export const generateThumbImage = ({ value }) => {
  const canvas = createHiDPICanvas(25, 25);
  const ctx = canvas.getContext('2d');
  ctx.fillStyle = '#F96302';
  ctx.font = '9px opensans-regular';
  const valueAsString = value.toFixed(0);
  let x = 5;
  if (valueAsString.length === 1) {
    x = 8;
  } else if (valueAsString.length === 3) {
    x = 1;
  }
  ctx.fillText(valueAsString + '%', x, 16);
  return canvas.toDataURL();
};
