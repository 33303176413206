module.exports = [
  {
    id: 500,
    position: null,
    type: 'tag',
    name: 'Setting',
    attributes: [
      {
        id: 100,
        name: 'Loft'
      },
      {
        id: 200,
        name: 'Flat'
      },
      {
        id: 300,
        name: 'Open-Concept'
      },
      {
        id: 400,
        name: 'Penthouse'
      },
      {
        id: 500,
        name: 'Apartment'
      },
      {
        id: 600,
        name: 'Studio'
      },
      {
        id: 700,
        name: 'Basement'
      },
      {
        id: 800,
        name: 'Adobe'
      },
      {
        id: 900,
        name: 'Factory'
      },
      {
        id: 1000,
        name: 'Warehouse'
      },
      {
        id: 1100,
        name: 'Cabin'
      },
      {
        id: 1200,
        name: 'Bungalow'
      },
      {
        id: 1300,
        name: 'Barn'
      },
      {
        id: 1400,
        name: 'Kitchen'
      },
      {
        id: 1500,
        name: 'Dining Room'
      },
      {
        id: 1600,
        name: 'Bar'
      },
      {
        id: 1700,
        name: 'Library'
      },
      {
        id: 1800,
        name: 'Lounge'
      },
      {
        id: 1900,
        name: 'Den'
      },
      {
        id: 2000,
        name: 'Ranch'
      },
      {
        id: 2100,
        name: 'Lodge'
      },
      {
        id: 2200,
        name: 'Workshop'
      },
      {
        id: 2300,
        name: 'Villa'
      },
      {
        id: 2400,
        name: 'Study'
      },
      {
        id: 2500,
        name: 'Laundry Room'
      },
      {
        id: 2600,
        name: 'Apothecary'
      },
      {
        id: 2700,
        name: 'Mudroom'
      },
      {
        id: 2800,
        name: 'A-Frame'
      },
      {
        id: 2900,
        name: 'Atrium'
      },
      {
        id: 3000,
        name: 'Shed'
      },
      {
        id: 3100,
        name: 'Mancave'
      },
      {
        id: 3200,
        name: 'Garage'
      },
      {
        id: 3300,
        name: 'Camper/Airstream'
      },
      {
        id: 3400,
        name: 'Tiny House'
      },
      {
        id: 3500,
        name: 'Summer Home'
      },
      {
        id: 3600,
        name: 'Family Room'
      },
      {
        id: 3700,
        name: 'Playroom'
      },
      {
        id: 3800,
        name: 'Sunroom'
      },
      {
        id: 3900,
        name: 'Atelier'
      },
      {
        id: 4000,
        name: 'Workspace'
      }
    ]
  },
  {
    id: 600,
    position: null,
    type: 'tag',
    name: 'Environment',
    attributes: [
      {
        id: 100,
        name: 'Beach'
      },
      {
        id: 200,
        name: 'Sea Cliff'
      },
      {
        id: 300,
        name: 'Forest'
      },
      {
        id: 400,
        name: 'Mountain'
      },
      {
        id: 500,
        name: 'Lake'
      },
      {
        id: 600,
        name: 'River'
      },
      {
        id: 700,
        name: 'Desert'
      },
      {
        id: 800,
        name: 'Urban'
      },
      {
        id: 900,
        name: 'Rural'
      },
      {
        id: 1000,
        name: 'Suburban'
      },
      {
        id: 1100,
        name: 'Outdoors'
      }
    ]
  },
  {
    id: 700,
    position: null,
    type: 'tag',
    name: 'Locations',
    attributes: [
      {
        id: 100,
        name: 'France'
      },
      {
        id: 200,
        name: 'Paris'
      },
      {
        id: 300,
        name: 'London'
      },
      {
        id: 400,
        name: 'Italy'
      },
      {
        id: 500,
        name: 'Spain'
      },
      {
        id: 600,
        name: 'Mexico'
      },
      {
        id: 700,
        name: 'Southwest'
      },
      {
        id: 800,
        name: 'East Coast'
      },
      {
        id: 900,
        name: 'West Coast'
      },
      {
        id: 1000,
        name: 'Cape Cod'
      },
      {
        id: 1100,
        name: 'New York'
      },
      {
        id: 1200,
        name: 'Brooklyn'
      },
      {
        id: 1300,
        name: 'Chicago'
      },
      {
        id: 1400,
        name: 'Hollywood'
      },
      {
        id: 1500,
        name: 'Morocco'
      },
      {
        id: 1600,
        name: 'India'
      },
      {
        id: 1700,
        name: 'Greece'
      },
      {
        id: 1800,
        name: 'Asia'
      },
      {
        id: 1900,
        name: 'Africa'
      },
      {
        id: 2000,
        name: 'United States'
      },
      {
        id: 2100,
        name: 'South America'
      }
    ]
  },
  {
    id: 800,
    position: null,
    type: 'tag',
    name: 'Design Features',
    attributes: [
      {
        id: 100,
        name: 'Gears'
      },
      {
        id: 200,
        name: 'Pulleys'
      },
      {
        id: 300,
        name: 'Wheels'
      },
      {
        id: 400,
        name: 'Edison bulbs'
      },
      {
        id: 500,
        name: 'Hairpin'
      },
      {
        id: 600,
        name: 'Live Edge'
      },
      {
        id: 700,
        name: 'Painted'
      },
      {
        id: 800,
        name: 'Carved'
      },
      {
        id: 900,
        name: 'Embellished'
      },
      {
        id: 1000,
        name: 'Embroidered'
      },
      {
        id: 1100,
        name: 'Printed'
      },
      {
        id: 1200,
        name: 'Tufted'
      },
      {
        id: 1300,
        name: 'Woven'
      },
      {
        id: 1400,
        name: 'Macrame'
      },
      {
        id: 1500,
        name: 'High-low'
      },
      {
        id: 1600,
        name: 'Shag'
      }
    ]
  },
  {
    id: 900,
    position: null,
    type: 'tag',
    name: 'Symbolic Features',
    attributes: [
      {
        id: 100,
        name: 'Buddha'
      },
      {
        id: 200,
        name: 'Hamsa'
      },
      {
        id: 300,
        name: 'Lotus'
      },
      {
        id: 400,
        name: 'Skulls'
      },
      {
        id: 500,
        name: 'Feathers'
      },
      {
        id: 600,
        name: 'Arrows'
      },
      {
        id: 700,
        name: 'Sunburst'
      },
      {
        id: 800,
        name: 'Sputnik'
      },
      {
        id: 900,
        name: 'Fleur de Lis'
      },
      {
        id: 1000,
        name: 'Astronomy'
      },
      {
        id: 1100,
        name: 'Animals'
      },
      {
        id: 1200,
        name: 'Animals_Barnyard'
      },
      {
        id: 1300,
        name: 'Animals_Forest'
      },
      {
        id: 1400,
        name: 'Animals_Marine'
      }
    ]
  },
  {
    id: 1000,
    position: null,
    type: 'tag',
    name: 'Marketing Features',
    attributes: [
      {
        id: 200,
        name: 'Modular'
      },
      {
        id: 300,
        name: 'Multi-Functional'
      },
      {
        id: 400,
        name: 'Performance Fabric'
      },
      {
        id: 500,
        name: 'Ultimate Comfort'
      },
      {
        id: 600,
        name: 'Customizable'
      },
      {
        id: 700,
        name: 'Heirloom Quality'
      },
      {
        id: 800,
        name: 'Fair Trade'
      },
      {
        id: 900,
        name: 'Eco-Friendly'
      },
      {
        id: 1000,
        name: 'Organic'
      },
      {
        id: 1100,
        name: 'Non-Toxic'
      },
      {
        id: 1200,
        name: 'FSC Certified Wood'
      },
      {
        id: 1300,
        name: 'Handmade'
      },
      {
        id: 1400,
        name: 'Sustainable'
      }
    ]
  }
];
