import XDate from 'xdate';
import defaultProduct from '../assets/images/default-product.jpg';
import {
  SSO_AUTH_URL,
  SSO_AUTH_LOGOUT_URL,
  REDIRECT_URI
} from '../constants/environment';
import HTML5Backend from 'react-dnd-html5-backend';
import { DragDropContext } from 'react-dnd';

export function loadExcelDependency() {
  return import(/* webpackChunkName: "xlsx" */ 'xlsx');
}

export function getAuthUrl() {
  return SSO_AUTH_URL
    .replace('{REDIRECT_URI}', REDIRECT_URI);
}
let _DND_CONTEXT;
export function getDNDContext() {
  if (_DND_CONTEXT) return _DND_CONTEXT;
  _DND_CONTEXT = DragDropContext(HTML5Backend);//eslint-disable-line
  return _DND_CONTEXT;
}

export function getAuthLogoutUrl() {
  return SSO_AUTH_LOGOUT_URL
    .replace('{REDIRECT_URI}', REDIRECT_URI);
}
export function processAuthToken(hash, route = true) {
  const result = /token_type=([^&]+)&access_token=([^&]+)/.exec(hash);
  if (result && result[1] === 'bearer') {
    const token = result[2];
    const isBrowser = typeof window !== 'undefined';
    if (isBrowser) window.sessionStorage.setItem('auth_token', token);
    const path = `${window.location.origin}${window.location.pathname}#curations`;
    if (route) {
      window.location = path;
    }
    return path;
  }
  return null;
}
export function processAuthCode(tokenResp, route = true) {
  // const tokenResp = {
  //   // eslint-disable-next-line max-len
  //   access_token: 'eyJhbGciOiJSUzI1NiIsImtpZCI6InV2cm91cUswT01zXy1Bbjc0TlRLSDhJTF9KZyIsInBpLmF0bSI6ImtwY2EifQ.eyJzY29wZSI6Im9wZW5pZCIsImNsaWVudF9pZCI6IjIxZWQ3M2Q2LTMzM2QtNGUwYS1iZjE0LTEyOTQxMjI0OWYyNyIsImlzcyI6Imh0dHBzOi8vaWRlbnRpdHktcWEuaG9tZWRlcG90LmNvbSIsImp0aSI6IjA0d0tKemsyTWFhVzJqRk9STmlPcE1SdEN4S1pPSjFnIiwiYXVkIjoiMjFlZDczZDYtMzMzZC00ZTBhLWJmMTQtMTI5NDEyMjQ5ZjI3Iiwic3ViIjoiS1hCODgwMCIsIm5iZiI6MTcxODg3NDk0NiwicGkuc3JpIjoic0kwdEhSdy1CS1EtLXRQV1NsMFNSeE16QnR3Li5jX045Lm0yNHpGcTB6SWY1eTJhNVJrTHpnNE5UTzMiLCJ0b2tlbl90eXBlIjoiYWNjZXNzX3Rva2VuIiwiaWF0IjoxNzE4ODc1MDYxLCJleHAiOjE3MTg4NzY4NjZ9.be-vALBZ4SsqGOoXvyW7rjYjGnZRPNDjveEwYr1iGXS2lIPcxPf_4NW1-5ThqvIPH-s-EqvXweRkbRfNM0dIofEBUqS5GVgmzX-KJQZKM-LjQlt7dnhRyL7Y5W_8sh5HaQZrFI2WDB_BGWwyboUspcknTNNoLDzMKUXuauZmAqaxgB4Mic08uUBmbKPOVhwz7PV2wxYjhvJPE-Te7Ks_99hAB6CPLQ3d30GZfXc54X16LVZMWtUge2yTVMpvY8OlJrDqlRPOL7fo8TyfQtp8f9VIj8X6cSn3aoWbo7JG4yvxH6IsftMCR6zabR_sf3-7Zl7FnOEp6UZEaRHCr1K0Pw',
  //   refresh_token: 'aDVHSoo8YyyBbfmDdVLmOMWMKjlyi08IhMbG3DkMtl',
  //   // eslint-disable-next-line max-len
  //   id_token: 'eyJhbGciOiJSUzI1NiIsImtpZCI6InV2cm91cUswT01zXy1Bbjc0TlRLSDhJTF9KZyJ9.eyJzdWIiOiJLWEI4ODAwIiwiYXVkIjoiMjFlZDczZDYtMzMzZC00ZTBhLWJmMTQtMTI5NDEyMjQ5ZjI3IiwianRpIjoiYVdxNVZURWdpcTFFU1RCSW9FZW9rNiIsImlzcyI6Imh0dHBzOi8vaWRlbnRpdHktcWEuaG9tZWRlcG90LmNvbSIsImlhdCI6MTcxODg3NTAzNiwiZXhwIjoxNzE4OTAzODM2LCJhdXRoX3RpbWUiOjE3MTg4NzUwMzUsInVzZXJfdHlwZSI6IkNvbnRyYWN0b3IiLCJsYXN0X25hbWUiOiJCSEVFTVNJTkdIIiwidG9rZW5fdHlwZSI6ImlkX3Rva2VuIiwiZmlyc3RfbmFtZSI6IktBUlRIS0VZQU4iLCJqb2JfdGl0bGUiOiJDTlRSIiwiZW1haWwiOiJLQVJUSEtFWUFOX0JIRUVNU0lOR0hAaG9tZWRlcG90LmNvbSIsImxvY2F0aW9uX251bWJlciI6IjQzODMiLCJsb2NhdGlvbl90eXBlIjoiQ09SUCIsInByZWZlcnJlZF9sYW5ndWFnZSI6ImVuX1VTIiwiZGVwYXJ0bWVudF9udW1iZXIiOiIwIiwibm9uY2UiOiJmMGlYalhWb21pQXptdDlPc0R2eVhxN0JxMnBUN3FpTzFBR1ltUllLa0VZIn0.Cy5uBZTsyTVJJkSFKs9fRzI0q_RZRLGHux3Fbn2ULIcKX_5MmzortuiDHEU55rr_y22MhGVCY2W1LMC1yRTQU6s31iWis6d5ybZ5uprwWKgiYAEWdPvyadJY4WSmwZADyeq_w-PXJavsTPXjFKdmRFqPqZHSUmQ10OR-kGQK840KhAVCUM41D_1n-kPsEylO0qgGOfNgROGssH8Kop-I7iEZvzwIhb9-JNgi1ObTSFz_e8XK0zojWLBGflgq6ZQyE-3RF3cQBTAgUeP-rQQa01ZqqSMEZ0HindUnL74_nO3MCML2hH4PpTszQ1Vwdfz5ogUWhbP39uP4S_iyz9lI0w',
  //   token_type: 'Bearer',
  //   expires_in: '1799'
  // };
  const isBrowser = typeof window !== 'undefined';
  if (isBrowser) window.sessionStorage.setItem('auth_token', tokenResp.access_token);
  const path = `${window.location.origin}${window.location.pathname}#curations`;
  if (route) {
    window.location = path;
  }
  return path;
}
export function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
    s4() + '-' + s4() + s4() + s4();
}

export function formatDate(date, formatString = 'MM/dd/yyyy') {
  const d = new XDate(date);
  return d.toString(formatString);
}
export const SVG_CLOSE_PATH = 'M27.229 8.391l-3.385-3.386-7.843 7.838-7.84-7.84-3.386 3.385 7.609 7.608-7.613 7.612 3.385 3.386 7.843-7.838 7.84 7.841 3.387-3.386-7.61-7.608z';// eslint-disable-line max-len

export function readBrowserCookie(name, cookie) {
  const nameEQ = name + '=';
  const ca = cookie
    ? cookie.split(';')
    : document.cookie.split(';');
  let i;
  for (i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1, c.length);
    }
    if (c.indexOf(nameEQ) === 0) {
      return decodeURIComponent(c.substring(nameEQ.length, c.length));
    }
  }
  return '';
}

export function escapeCookie(cookieValue) {
  return encodeURIComponent(cookieValue)
              .replace(/\(/g, '%28')
              .replace(/\)/g, '%29');
}

export function setBrowserCookie(cookieName, cookieValue, expInSec, path = '/', domain = null) {
  let expDt = new Date();
  expDt.setTime(expDt.getTime() + (expInSec * 1000));
  const expDtString = (expDt === null) ? '' : expDt.toUTCString();
  let cookieString = [cookieName + '=' + escapeCookie(cookieValue),
    ' expires=' + expDtString,
    ` path=${path}`].join(';');

  if (domain) {
    cookieString += '; domain=' + domain;
  }

  document.cookie = cookieString;
}

export function getAuthToken() {
  if (typeof window !== 'undefined') {
    return window.sessionStorage.getItem('auth_token');
  }
  return '';
}

let LOCAL_STORAGE_AVAILABLE;
export function storageAvailable(type) {
  let storage;
  const keyVal = '__storage_test';
  if (typeof LOCAL_STORAGE_AVAILABLE === 'boolean') {
    return LOCAL_STORAGE_AVAILABLE;
  }
  try {
    storage = window[type];
    storage.setItem(keyVal, keyVal);
    storage.removeItem(keyVal);
    LOCAL_STORAGE_AVAILABLE = true;
    return true;
  } catch (e) {
    LOCAL_STORAGE_AVAILABLE = false;
    return false;
  }
}

export function addEvent(element, eventType, handler, useCapture) {
  let i;
  const capture = typeof useCapture !== 'undefined'
    ? useCapture
    : true;
  if (element.length && !element.window) {
    element.forEach(el => addEvent(el, eventType, handler));
  } else {
    if (element.addEventListener) { // DOM Level 2 browsers
      element.addEventListener(eventType, handler, capture);
    } else if (element.attachEvent) { // IE <= 8
      element.attachEvent('on' + eventType, handler);
    } else { // ancient browsers
      element['on' + eventType] = handler;// eslint-disable-line
    }
  }
}

export function getJoinArrayElements({ array }) {
  let joinedElements = '';
  if (array && array.length > 0) {
    joinedElements = array.join();
  }
  return joinedElements;
}


export function createDefaultProduct() {
  // const rand = Math.random();
  return {
    info: {
      imageUrl: defaultProduct
    }
  };
}

export function getUserPermission({ state, list }) {
  let canEdit = false;
  if ((state.app.user.userId === list.createdBy && state.app.user.isCurator)
      || state.app.user.isAdmin) {
    canEdit = true;
  }
  return canEdit;
}

export function calculateHeight({ item = {}, columns, editMode, selectedOpen, type }) {
  let height = 50;
  if (columns === 2) {
    height += 250;
  } else if (columns === 3) {
    height += 150;
  } else if (columns === 4) {
    height += 125;
  } else if (columns === 5) {
    height += 120;
  } else if (columns === 6) {
    height += 75;
  }

  if (!item._selected || editMode) return null;
  let items = 0;
  let rowHeight = 120;
  let descriptionHeight = 20;

  if (document.querySelector('.results-item--selected .curation--item__Description')) {
    descriptionHeight += document.
      querySelector('.results-item--selected .curation--item__Description').offsetHeight;
  } else if (document.
      querySelector('.open-item__container--selected .curation--item__Description')) {
    descriptionHeight += document.
      querySelector('.open-item__container--selected .curation--item__Description').offsetHeight;
  }
  if (type === 'collection' || (item.type === 'curation' && !item.message)) {
    items = (item.items || []).length;
    rowHeight = 100;
  } else {

    items = item.results && item.results.items
      ? (item.results.items.length || 1)
      : 0;

    if (item.results &&
      item.results.searchReport &&
      item.results.searchReport.totalProducts > 24) {
      // This condition is to fix the item height when we have less than 18 items by item result.
      // Otherwise, item details container overlaping on next row items.
      rowHeight = 140;
    }
  }
  if (items < 18) {
    items = items + 6;
  }
  return height + descriptionHeight + (Math.ceil(items / 6) * rowHeight);
}
let clicks = 0;
let doubleClickTO = null;
export function waitForDoubleClick(cb) {
  clicks++;
  doubleClickTO = setTimeout(() => {
    if (clicks === 2) {
      clearTimeout(doubleClickTO);
    } else {
      cb();
    }
    clicks = 0;
  }, 200);
}


export function imageErrorHandler(e) {
  e.target.src = defaultProduct;// eslint-disable-line
}

export function requireAuth(nextState, replace) {
  // return (nextState, transition) => {
  if (!getAuthToken()) {
    replace('/');
    return;
  }
}
