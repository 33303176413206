import * as types from '../../../../constants/ActionTypes';
import * as api from '../../../../util/api';

export const getUserClassificationDetails = ({}) => {
  return (dispatch) => {
    dispatch({
      type: types.INITIATE_LOADER,
      loader: 'load-user-classification-details'
    });
    dispatch({
      type: types.RESET_USER_CLASSIFICATION_DETAILS
    });
    return api
      .getUserClassificationDetails()
      .then(response => {
        dispatch({
          type: types.SET_USER_CLASSIFICATION_DETAILS,
          userClassificationDimensions: response.dimensions ? response.dimensions : [],
          styleClassifications: response.dimensions
                                && response.dimensions.length > 0
                                && response.dimensions.find(dimensions => dimensions.id === 100),
          roomClassifications: response.dimensions
                               && response.dimensions.length > 0
                               && response.dimensions.find(dimensions => dimensions.id === 200),
          colorClassifications: response.dimensions
                                && response.dimensions.length > 0
                                && response.dimensions.find(dimensions => dimensions.id === 300),
          colorFamilyClassifications: response.dimensions
                                   && response.dimensions.length > 0
                                   && response.dimensions.find(dimensions => dimensions.id === 400),
        });
        dispatch({ type: types.REMOVE_LOADER, loader: 'load-user-classification-details' });
      })
      .catch(e => {
        console.error(e);
        dispatch({ type: types.REMOVE_LOADER, loader: 'load-user-classification-details' });
      });
  };

};