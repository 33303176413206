import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ImgItemPreview from '../../../../CurateList/modules/Item/modules/ImgItemPreview';
import defaultProduct from '../../../../../../../assets/images/item-discontinued.jpg';
import './review-item.scss';
import { imageErrorHandler } from "../../../../../../../util";

const propTypes = {
  list: PropTypes.object,
  ui: PropTypes.object,
  item: PropTypes.object,
  actions: PropTypes.shape({}),
  index: PropTypes.number,
  loaders: PropTypes.object,
  style: PropTypes.object,
  displayRemove: PropTypes.bool
};


class ReviewItemCuration extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true
    };
  }

  componentDidMount() {
    const { actions, list, item } = this.props;
    const listId = list.id;
    const curationId = item.id;
    const type = 'curation';

    actions.fetchCurtationForList({ listId, curationId, type })
      .then(ok => {
        this.setState({ isLoading: false });
      }).catch(e => {
        // @todo handle error
        this.setState({ isLoading: false });
      });
  }

  render() {
    const {
      ui,
      list,
      item,
      actions,
      index,
      loaders,
      style,
      displayRemove = true
    } = this.props;
    const { isLoading } = this.state;
    return (
    <div className="review-items__pod">
    {ui.curateList.editMode && displayRemove &&
      <a className="curation-list--item__edit" href="#"
        onClick={(e) => {
          e.preventDefault();
          actions.removeItemFromSet({
            item,
            listId: list.id,
            type: list.type,
            section: 'reviewEdit' });
        }}
      ></a>
    }
    <div>
      <div className="review_items__pod-header">
        <div className="review_items__pod-title">
          <h2>{item.name}</h2>
          {!isLoading && item.items && <p>
            {item.items.length} items
          </p>}
        </div>
        <div className="review_items__pod_count">
          <a
            href="#"
            className="curation-link"
            onClick={e => {
              e.preventDefault();
              // if (item.type === 'curation') {
              if (displayRemove) {
                actions.openCurationForItem({ item });
              }
              // }
            }}
            style={{ padding: 'auto 5px', textDecoration: 'underline' }}
          >[ID: {item.id}]
          </a>
        </div>
        <div className="review_items__pod_status">Curation Status: {item.status}</div>
      </div>
    </div>
    {!isLoading && item.items && <div className=" grid curations-items-div">
      {item.items.map((product, i) => {
        if (i <= 7) {
          return (
            <div className={`col__3-12 ${(i === 7 && item.items.length > 8)
              ? 'last-item' : ''}`} key={product.itemId}
            >
              <img className="collection-curation-image" src={product.$imageSrc} alt="product" />
              <div className={`${i === 7 && item.items.length - 8 !== 0 ?
                'after' : 'hide'}`}
              >+{item.items.length - 7}</div>
            </div>
          );
        }
      })}
    </div>}
    {(isLoading) &&
      <div className="col__12-12 review-item__loader">
        <div className="pre-loader">
          <div className="segment-spinner"></div>
        </div>
      </div>
      }
    </div>
    );
  }
}

ReviewItemCuration.propTypes = propTypes;


class ReviewItemProduct extends Component {

  render() {
    const {
      ui,
      list,
      item,
      actions,
      index
    } = this.props;
    return (
    <div className="review-item__pod">
      <a
        href="#"
        onClick={e => {
          e.preventDefault();
          if (e.shiftKey) {
            if (item.type === 'curation') {
              if (item.message === 'Product Id is Inactive') {
                alert('Item Discontinued');
                return null;
              }
              actions.openCurationForItem({ item });
            } else {
              actions.openPipForItem({ item });
            }
          } else if (!ui.curateList.editMode && list.$canEdit) {
            actions.selectItem({ list, item, index });
          }
        }}
      >
        {list.type === 'collection' && <ImgItemPreview item={item} type="review" />}
        {list.type !== 'collection' && (
          <img alt={item.itemId} src={item.$imageSrc || defaultProduct} />
        )}
      </a>
      {ui.curateList.editMode && (
        <a
          className="curation-list--item__edit"
          href="#"
          onClick={e => {
            e.preventDefault();
            actions.removeItemFromSet({
              item,
              listId: list.id,
              section: 'reviewEdit',
              type: list.type
            });
          }}
        />
      )}
    </div>
    );
  }
}

ReviewItemProduct.propTypes = propTypes;

// @todo handle imaging of collection items
const ReviewItem = props => (
  <div className="">
    {props.list.type === 'collection' && <ReviewItemCuration {...props} />}
    {props.list.type === 'curation' && <ReviewItemProduct {...props} />}
  </div>
);

ReviewItem.propTypes = propTypes;

export default ReviewItem;
