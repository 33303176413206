import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from './Vision.actions';
import VisionComponent from './Vision.component';
import { getItemsImageBoundBox } from './Vision.reducer';

const mapStateToProps = (state, ownProps) => {

  const listId = ownProps.listId;

  const hotspotItems = state.vision && state.vision.hotspotItems
                          ? state.vision.hotspotItems : [];

  const imageBoundBox = getItemsImageBoundBox({ state });

  const items = state.vision && state.vision.items ? state.vision.items : [];

  const merchandisingAttributes = state.vision && state.vision.merchandisingAttributes
                          ? state.vision.merchandisingAttributes
                          : {};

  const meta = state.app && state.app.meta ? state.app.meta : {};

  const activeItemId = state.vision && state.vision.activeItemId ? state.vision.activeItemId : 0;

  return {
    items,
    hotspotItems,
    loaders: state.app.loaders,
    imageBoundBox,
    merchandisingAttributes,
    listId,
    meta,
    activeItemId
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    actions: bindActionCreators({
      ...actions
    }, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VisionComponent);
