import { API_HOST, SEARCH_API_URL } from '../constants/environment';
import { serializeMeta } from '../apps/curator/App/CurationList/reducer';
import { getAuthToken } from '../util';
import fetchJSONP from 'fetch-jsonp';
import mockVersion from './mock/mockVersion.json';
import mockVersions from './mock/mockVersions.json';
import mockAttributes from './mock/mockMerchandisingAttributes.js';
import mockProductDetails from './mock/mockProductDetails';
import mockItem from './mock/mockItem';
import mockProducts from './mock/mock2';
import queryString from 'query-string';

// function fetch(url, options) {
//   console.log(`%c ${url}`, 'background: #222; color: #bada55');
//   console.log('OPTIONS:', options);
//   return new Promise(resolve => {
//   });
// }

export const toJson = response => {
  return response.json().then(data => {
    if (response.ok) {
      return data;
    }
    return Promise.reject({ response, data });
  });
};

function getHeaders() {
  return new Headers({
    'content-type': 'application/json',
    Authorization: `Bearer ${getAuthToken()}`
  });
}
function getTokenHeader() {
  return new Headers({
    'content-type': 'application/json'
  });
}
export function createList({ type = 'curation', list = {} } = {}) {
  let name;
  if (type === 'curation') {
    name = '[curation]';
  } else {
    name = '';
  }

  const items = (list.items || []).map((item, i) => {
    return {
      itemId: item.itemId || item.id,
      position: item.index || i || 0
    };
  });

  const options = {
    method: 'POST',
    headers: getHeaders(),
    credentials: 'include',
    body: JSON.stringify({
      name,
      description: '',
      items
    })
  };
  // https://hd-digitaldecor-dev.appspot.com/discover-svcs/v1/list/createlist
  return fetch(`${API_HOST}/${type}/create?showDiscontinuedItems=true`, options)
    .then(resp => resp.json())
    .catch(err => {
      console.log(err);
    });
}

export function createCollection({}) {
  // @todo
}

export function updateListMeta({ list, type }) {
  const fields = serializeMeta({ list });
  const options = {
    method: 'POST',
    headers: getHeaders(),
    credentials: 'include',
    body: JSON.stringify({
      listid: list.id,
      ...fields
    })
  };
  let t = type || list.type;

  return fetch(`${API_HOST}/${t}/metaupdate?showDiscontinuedItems=true`, options)
    .then(toJson);
}

export function updateMultiListMetaData({ lists, type }) {
  const SearializeMuliList = lists.map(list => {
    return serializeMeta({ list });
  });
  const options = {
    method: 'POST',
    headers: getHeaders(),
    credentials: 'include',
    body: JSON.stringify(SearializeMuliList)
  };

  return fetch(`${API_HOST}/${type}/metaupdatemultipleList`, options)
    .then(resp => resp.json())
    .catch(err => {
      console.log(err);
    });
}

export function addItemsToSet({ items, curationId }) {
  const data = {
    id: curationId,
    // @todo change items to skus when bed updates
    items: items.map(item => {
      return {
        itemId: item.itemId,
        index: item.index || 0
      };
    })
  };
  return new Promise((resolve, reject) => {
    const options = {
      method: 'POST',
      headers: getHeaders(),
      credentials: 'include',
      body: JSON.stringify(data)
    };
    fetch(`${API_HOST}/list/item/add-update`, options)
      .then(resp => resp.json())
      .catch(err => {
        reject(err);
      });
  });
}

export function saveList({ list }) {

  const data = {
    listid: list.id || list.itemId,
    // @todo change items to skus when bed updates
    items: list.items.map((item, i) => {
      return {
        itemId: item.itemId || item.id,
        position: item.index || i || 0
      };
    })
  };
  const options = {
    method: 'POST',
    headers: getHeaders(),
    credentials: 'include',
    body: JSON.stringify(data)
  };
  return fetch(`${API_HOST}/${list.type}/itemupdate?showDiscontinuedItems=true`, options)
    .then(resp => resp.json())
    .catch(console.log);
}

export function removeItemsFromSet({ listId, items, type = 'curation' }) {
  const data = {
    listid: listId,
    items: items.map(item => { return { itemId: parseInt((item.id || item.itemId), 10) }; })
  };
  const options = {
    method: 'POST',
    headers: getHeaders(),
    credentials: 'include',
    body: JSON.stringify(data)
  };
  return fetch(`${API_HOST}/${type}/item/delete?showDiscontinuedItems=true`, options)
    .then(resp => resp.json())
    .catch(console.log);
}

export function getClassificationForList({ listId }) {
  const options = {
    method: 'GET',
    headers: getHeaders(),
    credentials: 'include',
  };
  return fetch(`${API_HOST}/curation/getcurationclassification?listId=${listId}`, options)
    .then(resp => resp.json())
    .catch(console.error);
}

export function getListVersions({ listId, startIndex = 0, pageSize = 24, listType }) {
  const options = {
    method: 'GET',
    headers: getHeaders(),
    credentials: 'include',
  };
  let url = `${API_HOST}/curation/getlistversions?listId=${listId}&startIndex=${startIndex}&pageSize=${pageSize}`;// eslint-disable-line max-len
  if (listType === 'collection') {
    url = `${API_HOST}/collection/getlistversions?listId=${listId}&startIndex=${startIndex}&pageSize=${pageSize}`;// eslint-disable-line max-len
  }

  return fetch(url, options)
    .then(toJson);
}

export function getListVersionDetails({ listId, versionNumber = 0, listType }) {
  const options = {
    method: 'GET',
    headers: getHeaders(),
    credentials: 'include',
  };
  let url = `${API_HOST}/curation/getlistversionbyid?listId=${listId}&versionNumber=${versionNumber}`;// eslint-disable-line max-len
  if (listType === 'collection') {
    url = `${API_HOST}/collection/getlistversionbyid?listId=${listId}&versionNumber=${versionNumber}`;// eslint-disable-line max-len
  }
  return fetch(url, options)
    .then(toJson);
}


export function getCurationsByDecorAttributes({ data }) {
  const options = {
    method: 'POST',
    credentials: 'include',
    headers: getHeaders(),
    body: JSON.stringify(data)
  };
  return fetch(`${API_HOST}/collection/getcurationsbydecorattributes`, options)
    .then(resp => resp.json())
    .catch(console.log);
}

export function getMerchandisingAttributes({ file }) {
  const formData = new FormData();
  formData.append('image', file);// event.target.files[0] data going here
  const headers = new Headers({
    Authorization: `Bearer ${getAuthToken()}`
  });

  const opts = {
    method: 'POST',
    body: formData,
    credentials: 'include',
    headers
  };

  return fetch(`${API_HOST}/image/getmerchandisingattribute`, opts)
    .then(toJson);
}

export function getItemsByDecorAttributes({ data }) {

  const options = {
    method: 'POST',
    credentials: 'include',
    headers: getHeaders(),
    body: JSON.stringify(data)
  };
  return fetch(`${API_HOST}/item/getitemsbydecorattributes`, options)
    .then(resp => resp.json())
    .catch(console.log);
}

export function getAllFilters() {
  const options = {
    headers: getHeaders(),
    credentials: 'include'
  };
  return fetch(`${API_HOST}/metadata/getfilters?show=dimensions,refinements,filters,tags`, options)
    .then(resp => resp.json())
    .catch(err => {
      console.log(err);
    });
}
export function getloggedinUserToken(code) {
  const options = {
    headers: getTokenHeader(),
    credentials: 'include',
    'Access-Control-Allow-Origin': '*'
  };
  return fetch(`${API_HOST}/user/getloggedinusertoken?authCode=` + code, options)
    .then(resp => resp.json())
    .catch(err => {
      console.log(err);
    });
}
export function getItemsByImage({ data }) {
  return new Promise((resolve, reject) => {
    const options = {
      method: 'POST',
      headers: getHeaders(),
      credentials: 'include',
      body: JSON.stringify(data)
    };
    fetch(`${API_HOST}/items/getItemsByImage`, options)
      .then(resp => resp.json())
      .catch(err => {
        reject(err);
      });
  });
}

export function getItemsByKeyword({ keyword, pageSize = 24, startIndex = 0 }) {
  const options = {
    headers: getHeaders(),
    credentials: 'include'
  };
  return fetch(`${API_HOST}/keyword/getsearchproductdetails?keyword=${keyword}&pageSize=${pageSize}&startIndex=${startIndex}`, options)// eslint-disable-line max-len
    .then(resp => resp.json())
    .catch(err => {
      console.log(err);
    });
}

export function getItemsByIds({ ids, pageSize = 24 }) {
  const opts = {
    headers: getHeaders(),
    credentials: 'include'
  };

  const batches = [/* , [], [], etc  */];
  const batchSize = 24;
  ids.forEach((id, index) => {
    if (index % batchSize === 0) {
      batches.push([]);
    }
    let currentBatch = batches[batches.length - 1];
    currentBatch.push(id);
  });

  const promises = batches.map((batch, batchIndex) => {
    let url = `${API_HOST}/itemId/getsearchproductdetails?itemid=${batch.join(',')}&pageSize=${pageSize}`;// eslint-disable-line max-len
    return fetch(url, opts)
    .then(resp => resp.json())
    .then(resp => {
      return {
        response: resp,
        batchIndex
      };
    })
    .catch(console.error);
  });

  return Promise.all(promises)
    .then(responses => {
      if (responses && responses.length > 0 && responses[0]) {
        responses.sort((a, b) => {
          return a.batchIndex < b.batchIndex ? -1 : 1;
        });
        let skus = [];
        responses.forEach(resp => {
          skus = skus.concat(resp.response.skus);
        });
        return skus;
      }
      return [];
    });
}

export function getItemsByItem({ itemId, pageSize = 24, startIndex = 0, sortByDimensions = [] }) {

  const opts = {
    credentials: 'include',
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({
      itemId,
      pageSize,
      startIndex,
      sortByDimensions
    })
  };
  return fetch(`${API_HOST}/item/getitemsbyitem`, opts)
    .then(resp => resp.json())
    .catch(console.error);
}

export function cloneCurationList({ curationId, curationName, type = 'curation' }) {
  const options = {
    method: 'POST',
    headers: getHeaders(),
    credentials: 'include',
    body: JSON.stringify({
      listid: curationId,
      name: curationName
    })
  };
  return fetch(`${API_HOST}/${type}/clone?showDiscontinuedItems=true`, options)
    .then(resp => resp.json())
    .catch(console.error);
}

export function getListsExternal({ keyword = '', startIndex = 0, pageSize = 24, sortBy = 'createdAt', sortOrder = 'desc', type = 'curation', skusLimit = 3 }) {// eslint-disable-line
  // https://hd-digitaldecor-dev.appspot.com/discover-svcs/v1/list/getlistsbyuser?userid=kxb8800
  // @todo update `+1`

  let url = `${API_HOST}/${type}/getlistsbykeyword?keyword=${keyword}&startIndex=${startIndex}&pageSize=${pageSize}&listSkuLimit=${skusLimit}&showDiscontinuedItems=true`;// eslint-disable-line

  const options = {
    headers: getHeaders(),
    credentials: 'include',
  };
  return fetch(url, options)
    .then(resp => resp.json())
    .catch(console.error);
}

export function getLists({ keyword = '', startIndex = 0, pageSize = 24, sortBy = 'updatedAt', sortOrder = 'desc', type = 'curation', pinterest }) {// eslint-disable-line
  // https://hd-digitaldecor-dev.appspot.com/discover-svcs/v1/list/getlistsbyuser?userid=kxb8800
  // @todo update `+1`
  let url = `${API_HOST}/${type}/getlists?{keyword}sortBy=${sortBy}&sortOrder=${sortOrder}&startIndex=${startIndex}&pageSize=${pageSize}{pinterest}`;// eslint-disable-line
  if (keyword) {
    url = url.replace('{keyword}', `keyword=${keyword}&`);
  } else {
    url = url.replace('{keyword}', '');
  }
  if (pinterest === true || pinterest === false) {
    url = url.replace('{pinterest}', `&pinterest=${pinterest}`);
  } else {
    url = url.replace('{pinterest}', '');
  }

  const options = {
    headers: getHeaders(),
    credentials: 'include',
  };
  return fetch(url, options)
    .then(resp => resp.json())
    .catch(console.error);
}

export function getProductAttributes({ itemId }) {
  let url = `${API_HOST}/maintenance/getproductattributes?itemId=${itemId}`;

  const options = {
    headers: getHeaders(),
    credentials: 'include',
  };
  return fetch(url, options)
    .then(toJson)
    .catch(console.error);
}

export function getAssociatedCollections({ listId, startIndex }) {
  let url = `${API_HOST}/curation/getassociatedcollections?listId=${listId}&startIndex=${startIndex}&pageSize=24`;// eslint-disable-line

  const options = {
    headers: getHeaders(),
    credentials: 'include'
  };
  return fetch(url, options)
    .then(toJson)
    .catch(console.error);
}

export function getListsByUserId({ userId, keyword = '', startIndex = 0, pageSize = 24, sortBy = 'createdAt', sortOrder = 'desc', type = 'curation' }) {// eslint-disable-line
  // https://hd-digitaldecor-dev.appspot.com/discover-svcs/v1/list/getlistsbyuser?userid=kxb8800
  // @todo update `+1`
  let url = `${API_HOST}/${type}/getlistsbyuser?userId=${userId}&{keyword}sortBy=${sortBy}&sortOrder=${sortOrder}&startIndex=${startIndex}&pageSize=${pageSize}`;// eslint-disable-line
  if (keyword) {
    url = url.replace('{keyword}', `keyword=${keyword}&`);
  } else {
    url = url.replace('{keyword}', '');
  }
  const options = {
    headers: getHeaders(),
    credentials: 'include',
  };
  return fetch(url, options)
    .then(resp => resp.json())
    .catch(console.error);
}

export function getListDetailsById({ listId, type = 'curation' }) {
  const options = {
    headers: getHeaders(),
    credentials: 'include',
  };
  return fetch(`${API_HOST}/${type}/getdetailsbyid?listId=${listId}&showDiscontinuedItems=true`,
    options)
  .then(resp => resp.json())
  .catch(console.error);

}

export function publishCuration({ curationId, type = 'curation' }) {
  const options = {
    method: 'POST',
    headers: getHeaders(),
    credentials: 'include',
    body: JSON.stringify({
      listId: curationId
    })
  };
  return fetch(`${API_HOST}/${type}/publish`, options)
  .then(resp => resp.json())
  .catch(console.error);

}

export function publishMultiList({ listIds, type = 'curation' }) {
  const options = {
    method: 'POST',
    headers: getHeaders(),
    credentials: 'include',
    body: JSON.stringify(listIds)
  };
  return fetch(`${API_HOST}/${type}/publishmultiplelist`, options)
  .then(resp => resp.json())
  .catch(console.error);
}

export function deactivateMultilist({ listIds, type = 'curation' }) {
  const options = {
    method: 'POST',
    headers: getHeaders(),
    credentials: 'include',
    body: JSON.stringify(listIds)
  };
  return fetch(`${API_HOST}/${type}/deactivatemultiplelist`, options)
  .then(resp => resp.json())
  .catch(console.error);
}


export function deactivateCuration({ curationId, type = 'curation' }) {
  const options = {
    method: 'POST',
    headers: getHeaders(),
    credentials: 'include',
    body: JSON.stringify({
      listId: curationId
    })
  };
  return fetch(`${API_HOST}/${type}/deactivate`, options)
  .then(resp => resp.json())
  .catch(console.error);

}

export function deleteCuration({ curationId, type = 'curation' }) {
  const options = {
    method: 'POST',
    headers: getHeaders(),
    credentials: 'include',
    body: JSON.stringify({
      listId: curationId
    })
  };
  return fetch(`${API_HOST}/${type}/delete`, options)
  .then(resp => resp.json())
  .catch(console.error);
}

export function logoutUser() {
  const options = {
    method: 'GET',
    credentials: 'include',
    headers: getHeaders()
  };
  return fetch(`${API_HOST}/user/logout`, options)
  .catch(console.error);
}

export function getUserDetails() {
  console.log('getHeaders()', getHeaders());
  const options = {
    headers: getHeaders(),
    credentials: 'include',
  };
  return fetch(`${API_HOST}/user/getloggedinuserinfo`, options)
  .then(resp => resp.json())
  .catch(console.error);
}
export function replaceItem(req) {
  console.log(req);

  // Modify below lines when API end point ready
  return new Promise(resolve => {
    resolve({ curations: [...mockProductDetails.curations] });
  });
}
export function uploadImage({ file }) {
  const formData = new FormData();
  formData.append('file', file);// event.target.files[0] data going here
  const headers = new Headers({
    Authorization: `Bearer ${getAuthToken()}`
  });

  const opts = {
    method: 'POST',
    body: formData,
    credentials: 'include',
    headers
  };


  return fetch(`${API_HOST}/image/upload`, opts)
    .then(resp => resp.json())
    .catch(console.log);

}

export function getClassificationDetails() {
  let url = `${API_HOST}/user/getclassificationdetails`;

  const options = {
    headers: getHeaders(),
    credentials: 'include',
  };
  return fetch(url, options)
    .then(toJson)
    .catch(console.error);
}

export function getUserClassificationDetails() {
  let url = `${API_HOST}/user/getuserclassificationdetails`;

  const options = {
    headers: getHeaders(),
    credentials: 'include',
  };
  return fetch(url, options)
    .then(toJson)
    .catch(console.error);
}

export function getUserClassificationByDimensions({
                                                    dimensionId,
                                                    attributeId,
                                                    startIndex = 0,
                                                    pageSize = 24 }) {
  let url = `${API_HOST}/user/getuserclassificationbydimensions?dimensionId=${dimensionId}&attributeId=${attributeId}&startIndex=${startIndex}&pageSize=${pageSize}`; // eslint-disable-line
  const options = {
    headers: getHeaders(),
    credentials: 'include',
  };
  return fetch(url, options)
    .then(toJson)
    .catch(console.error);
}

export function getUserClassificationDetailsById({ userId }) {
  let url = `${API_HOST}/user/getuserclassificationdetailsbyid?userId=${userId}`;

  const options = {
    headers: getHeaders(),
    credentials: 'include',
  };
  return fetch(url, options)
    .then(toJson)
    .catch(console.error);
}

export function updateClassificationDetails({ user, sku }) {
  const options = {
    method: 'POST',
    headers: getHeaders(),
    credentials: 'include',
    body: JSON.stringify({
      User: user,
      SKU: sku
    })
  };
  return fetch(`${API_HOST}/user/updateclassificationdetails`, options)
    .then(toJson);
}

export function updateProductInCuration({
                                          itemId,
                                          curationIds,
                                          allCurations,
                                          userAction,
                                          replaceItemId
                                        }) {
  const options = {
    method: 'POST',
    headers: getHeaders(),
    credentials: 'include',
    body: JSON.stringify({
      itemId,
      curationIds,
      allCurations,
      userAction,
      replaceItemId
    })
  };
  return fetch(`${API_HOST}/maintenance/updateproductincuration`, options)
    .then(toJson);
}

export function getProductAssociatedCurations({ itemId, startIndex = 0, pageSize = 24 }) {
  const options = {
    headers: getHeaders(),
    credentials: 'include',
  };
  return fetch(`${API_HOST}/maintenance/getproductassociatedcurations?itemId=${itemId}&startIndex=${startIndex}&pageSize=${pageSize}`, options).then(toJson);// eslint-disable-line
}

export function getHotspotDetails({ listId, listType }) {
  const options = {
    headers: getHeaders(),
    credentials: 'include',
  };
  return fetch(`${API_HOST}/${listType}/gethotspotdetails?listId=${listId}`, options)
    .then(toJson);
}


export function getProductLists({
  startIndex = 0,
  pageSize = 24,
  sortBy = '',
  sortOrder = 'asc',
  keyword = ''
}) {
  const options = {
    headers: getHeaders(),
    credentials: 'include',
  };
  let params = {
    startIndex,
    pageSize,
    sortBy,
    sortOrder,
  };
  if (keyword) {
    params.keyword = keyword;
  }
  const qs = queryString.stringify(params);
  return fetch(`${API_HOST}/maintenance/getproductlist?${qs}`, options)
    .then(toJson);
}

// @TODO integrate with api
export function getVersion({ listId, versionId }) {
  return new Promise((resolve, reject) => {
    resolve({
      ...mockVersion,
      images: [...mockVersion.images],
      imageBoundBox: [...mockVersion.imageBoundBox]
    });
  });
}

export function getVersions({ listId, versionId }) {
  return new Promise((resolve, reject) => {
    resolve([...mockVersions]);
  });
}

export function fetchProductMerchandisingAttributes({ itemId }) {
  return new Promise(resolve => {
    resolve(mockAttributes);
  });
}

export const getProductDetails = ({ productId }) => {
  return new Promise((resolve, reject) => {
    resolve({
      ...mockProductDetails,
      curations: [...mockProductDetails.curations]
    });
  });
};

export const getItems = () => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(mockItem);
    }, 1000);
  });
};

export function fetchItems({ keyword }) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(mockProducts.lists);
    }, 1000);
  });
}
