import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from './actions';
import Search from './component';

const mapStateToProps = (state, ownProps) => {
  return {
    keyword: state.ui.pagination.keyword || '',
    loaders: state.loaders || {},
    listType: ownProps.listType,
    list: ownProps.list
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    actions: bindActionCreators({
      ...actions
    }, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);
