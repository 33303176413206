import _configs from './configs.json';

function getEnvironment() {
  if (typeof window !== 'undefined') {
    if (/decor-dev\.homedepot\.com/.test(window.location.host)) {
      return 'dev';
    } else if (/localhost/.test(window.location.host)) {
      return 'local';
    }
  }
  return 'prod';
}

// Get Current Environment from host
export const ENV = getEnvironment();

// Get Environment Specific properties
function getEnvProp(propName) {
  // If configs not exist for an environment, use llc configs
  let envConfigs = typeof _configs[ENV] !== 'undefined' ?
    _configs[ENV] : _configs.defaults;
  // If environment specific configs not exist, use generic configs
  return typeof envConfigs[propName] !== 'undefined' ?
    envConfigs[propName] : _configs.defaults[propName];
}

export const DEV_HOST = 'localhost';
export const APP_CONTEXT = 'curator';
export const API_HOST = getEnvProp('DISCOVER_API_HOST') + '/discover-svcs/v1';
// export const SSO_AUTH_URL = 'https://identity-qa.homedepot.com/as/authorization.oauth2?response_type=code&client_id=21ed73d6-333d-4e0a-bf14-129412249f27&scope=openid&state=uuid4()&redirect_uri=https://decor-dev.homedepot.com/davinci/index.html&nonce=f0iXjXVomiAzmt9OsDvyXq7Bq2pT7qiO1AGYmRYKkEY';
// eslint-disable-next-line max-len
export const SSO_AUTH_URL = getEnvProp('AUTH_HOST') + '/as/authorization.oauth2?client_id=' + getEnvProp('AUTH_CLIENT_ID') + '&response_type=code&state=uuid4()&redirect_uri={REDIRECT_URI}&scope=openid&nonce=f0iXjXVomiAzmt9OsDvyXq7Bq2pT7qiO1AGYmRYKkEY';// eslint-disable-line max-len
// eslint-disable-next-line max-len
// export const SSO_AUTH_LOGOUT_URL = getEnvProp('AUTH_HOST') + '/logout.do?client_id=' + getEnvProp('AUTH_CLIENT_ID') + '&redirect_uri={REDIRECT_URI}&duration=temporary&scope=openid'; // eslint-disable-line max-len
// eslint-disable-next-line max-len
export const SSO_AUTH_LOGOUT_URL = getEnvProp('AUTH_HOST') + '/idp/startSLO.ping?clientId=' + getEnvProp('AUTH_CLIENT_ID') + '&TargetResource={REDIRECT_URI}';
export const REDIRECT_URI = getEnvProp('APP_HOST') + getEnvProp('REDIRECT_URI');
export const GCS_IMAGE_BUCKET_LIFESTYLE = getEnvProp('GCS_IMAGE_URL') + '/lifestyleimages/raw/';
export const GCS_IMAGE_BUCKET = getEnvProp('GCS_IMAGE_URL');
export const GA_ID = 'UA-117251874-1';
export const SVG_CLOSE_PATH = 'M27.229 8.391l-3.385-3.386-7.843 7.838-7.84-7.84-3.386 3.385 7.609 7.608-7.613 7.612 3.385 3.386 7.843-7.838 7.84 7.841 3.387-3.386-7.61-7.608z';// eslint-disable-line max-len
