import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import * as actions from './actions';
import component from './component';

const mapStateToProps = (state, ownProps) => {
  const searchReport = state.maintenance.searchReport || {};
  const sortName = searchReport && searchReport.sortBy ? searchReport.sortBy : '';
  const sortOrder = searchReport && searchReport.sortOrder ? searchReport.sortOrder : '';
  const pages = Math.ceil(searchReport.totalProducts / searchReport.pageSize);
  searchReport.$visiblePages = pages > 5 ? 5 : pages;
  let userAccess = false;
  if (state.app.user) {
    userAccess = state.app.user.isCurator || state.app.user.isAdmin;
  }
  const skuLists = state.maintenance.items || [];
  return {
    searchReport,
    userAccess,
    sortName: ownProps.match.params.sortBy ? ownProps.match.params.sortBy : sortName,
    sortType: ownProps.match.params.sortOrder ? ownProps.match.params.sortOrder : sortOrder,
    loaders: state.app.loaders,
    meta: state.app.meta,
    keyword: state.maintenance.keyword || '',
    skuLists
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    actions: bindActionCreators({
      ...actions,
      setProductFilterKeyword: actions.setProductFilterKeyword,
      addRemoveCurationIdToSearch: actions.addRemoveCurationIdToSearch,
      sortList: ({ sortName, sortType, listType }) => {
        return (dis) => {
          const keyword = ownProps.match.params.keyword;
          let path = [
            'products',
            '0'
          ];
          path.push(sortName);
          path.push(sortType);
          ownProps.history.push('/' + path.join('/'));
        };
      },
      filterProducts: ({ keyword }) => {
        return () => {
          dispatch(actions.setProductFilterKeyword({ keyword }));
          ownProps.history.push(`/products/0/${encodeURIComponent(keyword)}`);
        };
      },
      handlePageChanged: (newPage) => {
        return (dis, getState) => {
          const state = getState();
          let sort = '';
          if (ownProps.match.params.sortBy) {
            sort = '/' + ownProps.match.params.sortBy + '/' + ownProps.match.params.sortOrder;
          }
          if (ownProps.match.params.keyword) {
            ownProps.history.push(`/products/${Math.ceil(newPage)
            }/${ownProps.match.params.keyword}${sort}`);
          } else {
            ownProps.history.push(`/products/${Math.ceil(newPage)}${sort}`);
          }
        };
      },
    }, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(component));
