import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from './CollectionsList.actions';
import component from './CollectionsList.component';
import { withRouter } from 'react-router-dom';

function mapStateToProps(state, ownProps) {
  const type = ownProps.match.params.listType;
  const list = state.curationList.lists
    .find(l => l.id === parseInt(ownProps.match.params.id, 10)) || {};
  const collections = state.curationList.collections || [];

  return {
    list,
    collections,
    ui: state.ui,
    errors: state.curationList.errorModel,
    loaders: state.app.loaders,
    listType: type
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const type = ownProps.match.params.listType;
  return {
    navigate: (path) => {
      ownProps.history.push(path);
    },
    actions: bindActionCreators({
      ...actions,
    }, dispatch)
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(component)
);// eslint-disable-line new-cap;