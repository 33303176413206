import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from './actions';
import { generateMultiItemPreview } from '../../../CurateList/actions';
import { hydrateItems } from '../../../CurateList/modules/Item/reducer';
import component from './component';


function mapStateToProps(state, ownProps) {
  const list = ownProps.list;
  const item = ownProps.item;
  const pageType = ownProps.type;
  const results = hydrateItems({
    results: item.results,
    items: list.items,
    itemType: 'curation'
  });
  const NoOfColumns = pageType === 'results'
    ? state.item.itemView : state.ui.ReviewList.NoOfColumns;
  return {
    list,
    item: {
      ...item,
      results,
      pageType
    },
    ui: state.ui,
    NoOfColumns,
    multiItemPreview: generateMultiItemPreview({
      totalItems: (results.items || []).filter(i => i._selected).length,
      size: 'small'
    }),
    loaders: state.app.loaders || {}
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {

  return {
    actions: bindActionCreators({
      ...actions,
      handlePageChanged: (newPage) => {
        return () => {
          const item = ownProps.item;
          const list = ownProps.list;
          dispatch(actions.handlePageChanged({ newPage, itemId: item.itemId, listId: list.id }));
        };
      }
    }, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(component);
