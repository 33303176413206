import React, { Component } from 'react';
import { PropTypes } from 'prop-types';

const getClass = (type) => {
  if (type === 'error') return 'danger';
  return type;
};

class InlineMessage extends Component {
  render() {
    const {
      message,
      type = 'error',
      className
    } = this.props;
    return (
      <div className={'alert-inline alert-inline--' + getClass(type)} >
        <span className="alert-inline__message">{message}</span>
      </div>
    );
  }
}

InlineMessage.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  className: PropTypes.string
};

export default InlineMessage;
