import itemsMockState from '../../App/CurateList/modules/Item/mock.js';
import * as types from '../../../../constants/ActionTypes';
import { API_HOST } from '../../../../constants/environment';
import { fetchItemList, fetchCurationList } from '../../App/CurateList/actions';
import * as api from '../../../../util/api';
import * as gaActions from '../GoogleAnalytics/googleAnalytics.actions';

export const setInputTagValue = ({ value }) => {
  return (dispatch) => {
    dispatch({ type: types.SET_CURATION_INPUT_TAG_VALUE, value });
  };
};

export const addRemoveTagValue = ({
  categoryId,
  attributeId,
  listId,
  type,
  tagAttrSelected
}) => {
  return (dispatch, getState) => {
    const state = getState();
    const category = state.app.meta.tags.find(t => t.id === categoryId);
    const attribute = category.attributes.find(a => a.id === attributeId);
    gaActions.sendEvent('tag', {
      event_category: gaActions.categories.ENGAGMENT,
      event_label: category.name + ': ' + attribute.name,
      value: !tagAttrSelected ? 1 : 0
    });
    dispatch({
      type: types.SET_CURATION_SEARCH_TAGS_VALUE,
      categoryId,
      attributeId,
      listId,
      pageType: type,
      tagAttrSelected: !tagAttrSelected
    });
    // Fetch items for selected tag in curation page.
    const list = state.curationList.lists.find(l => l.id === listId);
    if (type === 'curation') {
      if (list.type === 'curation') {
        dispatch(fetchItemList({ id: listId }));
      } else {
        dispatch(fetchCurationList({ id: listId }));
      }
    } else {
      dispatch({ type: types.GENERIC_ERROR_HANDLER_CLEAR });
      api.updateListMeta({ list, type: list.type })
      .then(response => {
        if (response.errorCode) {
          dispatch({ type: types.ADD_CURATION_ERROR, response: [response] });
        } else {
          dispatch({ type: types.SET_LIST_DATA_WITHOUT_META, id: list.id,
            response, meta: state.app.meta });
        }
      });
    }
  };
};

export const addRemoveTagValueForMultiEdit = ({
  categoryId,
  attributeId,
  type,
  tagAttrSelected
}) => {
  return (dispatch, getState) => {
    // App level meta update.
    dispatch({
      type: types.UPDATE_APP_TAGS_METADATA,
      categoryId,
      attributeId,
      tagAttrSelected: !tagAttrSelected
    });
    // Update Tags meta to all the selected lists.
    dispatch({
      type: types.UPDATE_MULTI_LISTS_TAGS_VALUE,
      categoryId,
      attributeId,
      tagAttrSelected: !tagAttrSelected });
    const state = getState();
    // Get the selected lists and pass it to metaUpdate serice.
    // const list = state.curationList.lists.filter(l => l._selected);
    state.curationList.lists.map(list => {
      if (list._selected) {
        return api.updateListMeta({ list, type: list.type }).then(response => {
          if (response.errorCode) {
            dispatch({ type: types.ADD_CURATION_ERROR, response: [response] });
          } else {
            dispatch({
              type: types.SET_REVIEW_LIST_STATUS,
              listId: list.id,
              status: response.status
            });
            dispatch({ type: types.GENERIC_ERROR_HANDLER_CLEAR });
          }
        });
      }
      return list;
    });
  };
};
export const setIsTagInputFocus = ({ isTagInputFocus }) => {
  return (dispatch, getState) => {
    let state = getState();
    dispatch({ type: types.SET_IS_TAG_INPUT_FIELD_FOCUS_IN, isTagInputFocus });
    if (state.ui.tag.showCategoryDropDown) {
      dispatch({ type: types.SHOW_HIDE_TAG_CATEGORY_DROPDOWN, value: false });
    }
  };
};

export const toggleTagCategoryListDropDown = () => {
  return (dispatch, getState) => {
    let state = getState();
    const value = state.ui.tag.showCategoryDropDown;
    dispatch({ type: types.SHOW_HIDE_TAG_CATEGORY_DROPDOWN, value: !value });
    if (state.ui.tag.isTagInputFocus) {
      dispatch({ type: types.SET_IS_TAG_INPUT_FIELD_FOCUS_IN, isTagInputFocus: false });
    }
  };
};

export const setTagCategory = ({ value, listId }) => {
  return (dispatch, getState) => {
    dispatch({ type: types.SET_CURATION_TAGS_CATEGORY, category: value, listId });
    dispatch(toggleTagCategoryListDropDown());
  };
};
