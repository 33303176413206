import * as types from '../../../constants/ActionTypes';
const initialState = {
  meta: {},
  loaders: {}
};
const DIMENSIONS = {
  STYLE: 100,
  ROOM: 200,
  COLOR: 300,
  COLOR_FAMILY: 400
};

const fixAbbrevations = (meta) => {
  function fix(t) {
    if (t === 'D') return 'dimension';
    if (t === 'R') return 'refinement';
    if (t === 'T') return 'tag';
    return t;
  }
  return {
    ...meta,
    dimensions: meta.dimensions.map(dim => {
      return {
        ...dim,
        type: fix(dim.type)
      };
    })
  };
};

const shouldRestrictSelectionForCollection = ({ listType, action, attributes, attribute }) => {
  if (listType !== 'collection') return false;
  if (action.dimensionId === DIMENSIONS.ROOM && action.value) return true;
  const mark = attributes.filter(a => a.value).length > 1;
  if (action.dimensionId === DIMENSIONS.STYLE && mark && action.value) return true;
  return false;
};

export default function app(state = initialState, action) {
  switch (action.type) {
    case types.SET_USERNAME:
      return {
        ...state,
        user: {
          userName: action.resp.name,
          userId: action.resp.id,
          isCurator: action.resp.curator,
          isAdmin: action.resp.admin

        }
      };
    case types.LOGIN_USER:
      return {
        ...state,
        loggedIn: true
      };
    case types.LOGOUT_USER:
      return {
        ...state,
        loggedIn: false
      };
    case types.SET_META_DATA:
      return {
        ...state,
        meta: fixAbbrevations(action.meta)
      };
    case types.UPDATE_APP_TAGS_METADATA:
      return {
        ...state,
        meta: {
          ...state.meta,
          tags: state.meta.tags.map(tag => {
            return {
              ...tag,
              attributes: tag.attributes.map(attr => {
                if (tag.id === action.categoryId && attr.id === action.attributeId) {
                  return {
                    ...attr,
                    selected: action.tagAttrSelected
                  };
                }
                return attr;
              })
            };
          })
        }
      };
    case types.UPDATE_APP_METADATA:
      return {
        ...state,
        meta: {
          ...state.meta,
          dimensions: state.meta.dimensions.map(dimension => {
            if (action.dimensionId === dimension.id) {
              return {
                ...dimension,
                attributes: (() => {
                  let curSelected = dimension
                    .attributes
                    .filter(attr => attr.value).map((attr, i) => attr.id);
                  let attrs = dimension.attributes.map(attr => {
                    if (attr.id === action.attributeId) {
                      return {
                        ...attr,
                        value: action.value
                      };
                    }
                    // collections are only allowed to have one value
                    if (shouldRestrictSelectionForCollection({
                      listType: action.listType,
                      action,
                      attributes: dimension.attributes,
                      attribute: attr
                    })) {
                      return {
                        ...attr,
                        value: false
                      };
                    }
                    return attr;
                  });
                  if (action.listType === 'collection' &&
                    action.dimensionId === 100 &&
                    curSelected.length === 2 &&
                    curSelected[1] !== action.attributeId) {
                    attrs.find(attr => attr.id === curSelected[1]).value = true;
                  }
                  return attrs;
                })()
              };
            }
            return dimension;
          })
        }
      };
    case types.CLEAR_APP_META_ATTRIBUTES_SELECTION:
      return {
        ...state,
        meta: {
          ...state.meta,
          dimensions: state.meta.dimensions.map(dimension => {
            return {
              ...dimension,
              attributes: dimension.attributes.map(attr => {
                return {
                  ...attr,
                  value: false
                };
              })
            };
          }),
          tags: state.meta.tags.map(tag => {
            return {
              ...tag,
              attributes: tag.attributes.map(attr => {
                return {
                  ...attr,
                  selected: false
                };
              })
            };
          })
        }
      };
    case types.INITIATE_LOADER:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          [action.loader]: (typeof action.value !== 'undefined' ? action.value : true)
        }
      };
    case types.REMOVE_LOADER:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          [action.loader]: false
        }
      };

    default:
      return state;
  }
}
