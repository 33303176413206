import React, { Component } from 'react';
import PropTypes from 'prop-types';
import HeaderContainer from '../../../modules/Header';
import { Link } from 'react-router-dom';
import Pager from 'react-pager';
import './style.scss';

const capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

class MaintenanceListViewComponent extends Component {

  componentDidMount() {
    const { actions, match } = this.props;
    this.getProducts({ match, actions });
    actions.resetUISettings();
    document.title = "Products";
  }

  componentDidUpdate(previousProps) {
    const { actions, location, match } = this.props;
    if (location.pathname !== previousProps.location.pathname) {
      this.getProducts({ match, actions });
    }
  }

  getProducts({ match, actions }) {
    let startIndex = (match.params.page || 0) * 24;
    const sortBy = match.params.sortBy || '';
    const sortOrder = match.params.sortOrder || '';
    const keyword = match.params.keyword || '';
    actions.getProductLists({ startIndex, sortBy, sortOrder, keyword });
  }

  render() {
    const {
      sortName,
      sortType,
      loaders,
      skuLists,
      keyword,
      actions,
      searchReport
    } = this.props;

    const getProductDepartmentClass = ({ product }) => {
      let productDepartmentClass = '';
      if (product) {
        if (product.department) {
          productDepartmentClass = product.department;
        }
        if (product.productClass) {
          productDepartmentClass = productDepartmentClass + '/' + product.productClass;
        }
      }
      return productDepartmentClass;
    };

    return (<div className="grid curationizer-main">
      <HeaderContainer location={location} />
      {!loaders.listByProduct &&
          skuLists.length === 0 && <div className="col__12-12">
          <div className="curation-flash">No list found</div>
          </div>}
    <div className="col__12-12">
      <div className="curationForm__textWrapper input-group--inline">
        <form
          onSubmit={e => {
            e.preventDefault();
            actions.filterProducts({ keyword });
          }}
        >
          <input
            type="text"
            id="keyword"
            name="keyword"
            className="form-input__field input-group--inline__input maintenance__search"
            placeholder={'Product search'}
            value={keyword}
            onChange={e => {
              e.preventDefault();
              actions.setProductFilterKeyword({ keyword: e.target.value });
            }}
          />
          <button className="bttn input-group--inline__bttn">
            <span className="mw-icon-search bttn__content" />
          </button>
        </form>
      </div>
    </div>
     {loaders.listByProduct && (
        <div className="col__12-12 curationList-loader">
          <div className="pre-loader">
            <div className="segment-spinner" />
          </div>
        </div>
      )}
    {!loaders.listByProduct &&
      <div className="col__12-12">
      <div className="curationListBody grid">
      <div className="col__12-12">
      {!loaders.listByProduct &&
        skuLists.length > 0 && (
          <div className="curationListHeader">
            <div className="grid">
              <ColumnHeader
                _sortName={'itemId'}
                _label={'OMS ID'}
                _className={'list--name__align-products col_headerAlign col__1-7'}
                actions={actions}
                sortName={sortName}
                sortType={sortType}
              />
              <ColumnHeader
                _label={'Product Name'}
                _className={'product-list-not-sortable col__1-3'}
                sortName={sortName}
                sortType={sortType}
              />
              <ColumnHeader
                _label={'Dept/Class #'}
                _className={'product-list-not-sortable  col__1-6'}
                sortName={sortName}
                sortType={sortType}
              />
              <ColumnHeader
                _sortName={'status'}
                _label={'Status'}
                _className={'col__1-6'}
                actions={actions}
                sortName={sortName}
                sortType={sortType}
              />
              <ColumnHeader
                _sortName={'totalCurations'}
                _label={'Curations'}
                _className={'col__1-6'}
                actions={actions}
                sortName={sortName}
                sortType={sortType}
              />
            </div>
          </div>
        )
      }
      </div>
        {!loaders.listByUser &&
          skuLists.map((product, index) => {
            return (
            <div key={index} className="col__12-12">
              <div
                className={`grid ${index % 2 === 0 ? 'curationList__body' : ''}`}
              >
                <div className="col__1-7 curationList__cell curationList__textOverflow">
                  <h5>
                    <Link
                      to={`/product/details/${product.itemId}`}
                    >
                      {product.itemId}
                    </Link>
                  </h5>
                </div>
                <div className="col__1-3 curationList__cell curationList__textOverflow">
                  <h5>
                    <Link
                      to={`/product/details/${product.itemId}`}
                    >
                        {product && <span>
                          {product.brandName && <b>{product.brandName}</b> }
                          {product.label ? ' ' + product.label : ''}
                        </span>
                        }
                    </Link>
                  </h5>
                </div>
                <div className="col__1-6 curationList__cell curationList__textOverflow">
                  <h5>
                    {product && <span>{getProductDepartmentClass({ product })}</span>}
                    </h5>
                </div>
                <div className="col__1-6 curationList__cell curationList__textOverflow">
                  <h5>
                    {product && <span>{product.status ? product.status : ''}</span>}
                  </h5>
                </div>
                <div className="col__1-6 curationList__cell curationList__textOverflow">
                  <h5>
                    {product && <span>{product.totalCurations ? product.totalCurations : ''}</span>}
                  </h5>
                </div>
             </div>
           </div>);
          })}
        </div>
      </div>
    }
    <div className="col__12-12">
      <div className="pagination-wrapper">
        {!loaders.listByProduct &&
          searchReport.totalProducts > 24 && (
            <Pager
              total={searchReport.totalProducts / searchReport.pageSize}
              current={Math.floor(searchReport.startIndex / searchReport.pageSize)}
              visiblePages={searchReport.$visiblePages}
              titles={{ first: '<<', last: '>>' }}
              className="hd-pagination__wrapper"
              onPageChanged={actions.handlePageChanged}
            />
          )}
      </div>
    </div>
    </div>);
  }
}

/* eslint-disable */
const ColumnHeader = ({ _sortName, _label, _className = 'col__1-5', actions, sortName, sortType, listType }) => (
  <div
    className={`curationList__cell ${_className} ${
      sortName === _sortName ? 'curationList__Sort--Header' : ''
    }`}
  >
    <a
      onClick={e => {
        e.preventDefault();
        let sort = 'asc';
        if (sortName === _sortName && sortType === 'asc') {
          sort = 'desc';
        }
        if (_sortName) {
          actions.sortList({ sortName: _sortName, sortType: sort, listType });
        }
      }}
    >
      <h3 className="curationListHeader__title">
        {typeof _label === 'object' ? _label.map((item, i) => <p key={i}>{item}</p>) : _label}
      </h3>
      <i
        className={`fa ${
          sortName === _sortName && sortType === 'desc' ? 'fa-chevron-down' : 'fa-chevron-up'
        } curationList__sort`}
        aria-hidden="true"
      />
    </a>
  </div>
);

MaintenanceListViewComponent.propTypes = {
  sortName: PropTypes.string,
  sortType: PropTypes.string,
  loaders: PropTypes.object,
  skuLists: PropTypes.array,
  actions: PropTypes.object,
  keyword: PropTypes.string,
  searchReport: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object
};

export default MaintenanceListViewComponent;
