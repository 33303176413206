import * as types from '../../../../constants/ActionTypes';
// import XLSX from 'xlsx';
import * as api from '../../../../util/api';
import { serializeAttributes } from '../CurationList/reducer';
import { submitSearchForCuration } from '../../modules/Search/actions';
import { updateImageSrc } from './modules/Item/reducer';
import { loadExcelDependency } from '../../../../util/';
const PAGE_SIZE = 24;

export const removeItemFromSet = ({
  item,
  listId,
  section = '',
  serverCall = false,
  type = 'curation'
}) => {
  // eslint-disable-line
  return (dispatch, getState) => {
    if (section === 'reviewEdit') {
      api
        .removeItemsFromSet({ listId, items: [item], type })
        .then(resp => {
          dispatch({ type: types.REMOVE_ITEM_FROM_SET, item, listId, status: resp.status });
        })
        .catch(console.log);
    } else {
      dispatch({ type: types.REMOVE_ITEM_FROM_SET, item, listId });
    }
  };
};

export const addItemToSet = ({ item, listId, index, resultsItem, serverCall = false }) => {
  return (dispatch, getState) => {
    dispatch({ type: types.ADD_ITEM_TO_SET, item, listId, index });
    if (serverCall) {
      const state = getState();
      const list = state.curationList.lists.find(c => c.id === listId);
      api
        .saveList({ list })
        .then(resp => {
          dispatch({ type: types.SET_REVIEW_LIST_STATUS, listId, status: resp.status });
        })
        .catch(console.log);
    }
  };
};

export const replaceItemsInSet = ({ listId, items }) => {
  return { type: types.REPLACE_ITEMS_IN_SET, listId, items };
};

export const clearResultsSet = ({ listId }) => {
  return { type: types.RESET_UI_SETTINGS };
};

export const addAllSelectedToSet = ({ listId, serverCall = false, items, index }) => {
  return (dispatch, getState) => {
    const state = getState();
    const results = (state.item.results || {}).items;
    const i = items || results.filter(result => result._selected);
    dispatch({ type: types.ADD_ITEMS_TO_SET, listId, items: i, index });
    if (serverCall) {
      const updatedState = getState();
      const list = updatedState.curationList.lists.find(c => (c.id || c.itemId) === listId);
      api
        .saveList({ list })
        .then(resp => {
          dispatch({ type: types.SET_REVIEW_LIST_STATUS, listId, status: resp.status });
        })
        .catch(console.log);
    }
  };
};

export const removeAllSelectedFromSet = ({ list }) => {
  return (dispatch, getState) => {
    const state = getState();

    const items = list.items.filter(item => item._selected);

    dispatch({ type: types.REMOVE_ITEMS_FROM_SET, items, curationId: list.id });
  };
};

export const toggleItemResultView = ({ view }) => {
  return dispatch => {
    dispatch({ type: types.TOGGLE_ITEM_RESULT_VIEW, view });
  };
};

export const updateAttributeWeight = ({ list, dimension, attributeId, weight }) => {
  return (dispatch, getState) => {
    dispatch({
      type: types.SET_REFINEMENT_ATTRIBUTE_VALUE,
      listId: list.id,
      sliderType: dimension.type,
      sliderId: dimension.id,
      attributeId,
      value: weight
    });
  };
};

export const fetchCurationList = ({ id, startIndex = 0, keyword, resultType = 'curations' }) => {
  return (dispatch, getState) => {
    const state = getState();
    const collection = state.curationList.lists.find(l => l.id === id);
    const data = serializeAttributes({ curation: collection, startIndex });
    if (keyword) {
      data.keyword = keyword;
    } else if (state.ui.pagination) {
      data.keyword = state.ui.pagination.keyword;
    }
    return new Promise((resolve, reject) => {
      dispatch({ type: types.INITIATE_LOADER, loader: 'itemResult' });
      api.getCurationsByDecorAttributes({ data }).then(resp => {
        dispatch({ type: types.REMOVE_LOADER, loader: 'itemResult' });
        dispatch({ type: types.UPDATE_ITEMS_DATA, value: resp, resultType });
        dispatch({ type: types.TOGGLE_CURATELIST_BULK_MODE, bulkMode: false });
        resolve(resp);
      });
    });
  };
};

let pendingRequests = 0;
export const fetchItemList = ({ id, listType = 'curation', startIndex = 0, itemId = 0 } = {}) => {
  return (dispatch, getState) => {
    const state = getState();
    // @todo update with serilaized state/ ajax request
    dispatch({ type: types.CLEAR_ERROR_MESSAGE });
    const currentRequest = () =>
      new Promise((resolve, reject) => {
        pendingRequests++;
        const curation = state.curationList.lists.find(l => l.id === id);
        const data = serializeAttributes({ curation, startIndex });
        dispatch({ type: types.INITIATE_LOADER, loader: 'itemResult' });
        if (itemId === 0) {
          api.getItemsByDecorAttributes({ data }).then(resp => {
            resolve(resp);
          });
        } else {
          api.getItemsByItem({ itemId, startIndex }).then(resp => {
            resolve(resp);
          });
        }

      });
    currentRequest().then(resp => {
      if (pendingRequests > 1) {
        pendingRequests--;
        return null;
      }
      if (resp.errorCode) {
        pendingRequests--;
        dispatch({ type: types.REMOVE_LOADER, loader: 'itemResult' });
        console.log('ERROR ', resp.errorCode, ': ', resp.description);
        return null;
      }
      if (itemId !== 0) {
        dispatch({ type: types.SET_ITEM_ID_FOR_ITEM_BY_ITEM, itemId });
      } else {
        dispatch({ type: types.UN_SET_ITEM_ID_FOR_ITEM_BY_ITEM });
      }
      dispatch({ type: types.REMOVE_LOADER, loader: 'itemResult' });
      dispatch({ type: types.UPDATE_ITEMS_DATA, value: resp, resultType: 'decor' });
      dispatch({ type: types.TOGGLE_CURATELIST_BULK_MODE, bulkMode: false });
      pendingRequests--;
      return null;
    })
      .catch(err => {
        pendingRequests--;
        console.log(err);
      });
  };
};

export const filterCurationItemsFromResults = ({ curation }) => {
  const ids = curation.items.map(item => item.itemId);
  return { type: types.FILTER_RESULTS, filteredItemIds: ids };
};

export const fetchCurationListsForCollection = ({
  startIndex = 0,
  pageSize = 24,
  sortBy = 'createdAt',
  keyword = '',
  sortOrder = 'desc'
} = {}) => {
  return (dispatch, getState) => {
    dispatch({ type: types.INITIATE_LOADER, loader: 'itemResult' });
    api
      .getListsExternal({ startIndex, sortBy, sortOrder, keyword, type: 'curation' })
      .then(resp => {
        dispatch({ type: types.REMOVE_LOADER, loader: 'itemResult' });
        if (!resp.error) {
          dispatch({ type: types.UPDATE_ITEMS_DATA, value: resp, resultType: 'curations' });
          dispatch({ type: types.TOGGLE_CURATELIST_BULK_MODE, bulkMode: false });
        }
      });
  };
};

export const generateMultiItemPreview = ({ totalItems = 7, size = 'large' }) => {
  const canvas = document.createElement('canvas');
  const width = size === 'large' ? 146 : 106;
  const height = size === 'large' ? 146 : 106;
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d');
  // border
  ctx.fillStyle = '#666';
  ctx.fillRect(0, 0, canvas.width, canvas.height);
  // background
  ctx.fillStyle = '#7F7F7F';
  ctx.fillRect(2, 2, canvas.width - 4, canvas.height - 4);
  ctx.fillStyle = '#FFFFFF';
  ctx.font = '28px opensans-regular';
  const valueAsString = totalItems.toFixed(0);
  const x = size === 'large' ? 50 : 30;
  const y = size === 'large' ? 83 : 62;
  ctx.fillText('+ ' + valueAsString, x, y);
  const dataUrl = canvas.toDataURL();
  const img = new Image();
  img.src = dataUrl;
  return img;
};

export const getListDetailsById = ({ listId, type = 'curation' }) => {
  return (dispatch, getState) => {
    const state = getState();
    const meta = state.app.meta;
    dispatch({ type: types.INITIATE_LOADER, loader: 'itemResult' });
    return new Promise((resolve, reject) => {
      api
        .getListDetailsById({ listId, type })
        .then(response => {
          dispatch({ type: types.REMOVE_LOADER, loader: 'itemResult' });
          dispatch({ type: types.SET_LIST_DATA, id: listId, response, meta });
          resolve(response);
        })
        .catch(err => {
          dispatch({ type: types.REMOVE_LOADER, loader: 'itemResult' });
          console.log(err);
          resolve();
        });
    });
  };
};

export const handlePageChanged = ({ newPage, id, listType }) => {
  return (dispatch, getState) => {
    const state = getState();
    const resultType = state.item.results.searchReport.resultType;
    if (resultType === 'decor') {
      if (listType === 'curation') {
        const itemId = state.item.itemId ? state.item.itemId : 0;
        return dispatch(fetchItemList({ id, startIndex: Math.ceil(newPage) * PAGE_SIZE, itemId }));
      }
      return dispatch(fetchCurationList({ id, startIndex: Math.ceil(newPage) * PAGE_SIZE }));
    }
    if (resultType === 'curations') {
      let keyword = state.ui.pagination.keyword;
      return dispatch(
        submitSearchForCuration({ keyword, startIndex: Math.ceil(newPage) * PAGE_SIZE, id })
      );
    }
    return new Promise((resolve, reject) => {
      const keyword = state.ui.pagination.keyword;
      dispatch({ type: types.INITIATE_LOADER, loader: 'itemResult' });
      api
        .getItemsByKeyword({ keyword, startIndex: Math.ceil(newPage) * PAGE_SIZE })
        .then(res => {
          dispatch({ type: types.REMOVE_LOADER, loader: 'itemResult' });
          dispatch({ type: types.UPDATE_ITEMS_DATA, value: res, resultType: 'searchNav' });
          window.scrollTo(0, 0);
        })
        .catch(err => {
          dispatch({ type: types.REMOVE_LOADER, loader: 'itemResult' });
          console.log(err);
          resolve();
        });
    });
  };
};

export const handleFileSelect = (evt, { listId }, rABS = true) => {
  return dispatch => {
    loadExcelDependency().then(XLSX => {
      evt.stopPropagation();
      evt.preventDefault();
      const uniqueIds = {};
      let files = evt.target.files;
      let f = files[0];
      let reader = new FileReader();
      reader.onload = function (e) {
        let data = e.target.result;
        if (!rABS) data = new Uint8Array(data);
        let workbook = XLSX.read(data, { type: rABS ? 'binary' : 'array' });
        let firstWorksheet = workbook.Sheets[workbook.SheetNames[0]];
        let newSheet = XLSX.utils.sheet_to_json(firstWorksheet, { header: 1 });
        let rows = newSheet.reduce((acc, current) => {
          return acc.concat(current);
        }, []);
        const ids = rows
          .map(id => {
            return id.trim();
          })
          .filter(String)
          .filter(itemId => {
            if (!uniqueIds[itemId]) {
              uniqueIds[itemId] = true;
              return true;
            }
            return false;
          })
          .filter(itemId => {
            return /^\d+$/.test(itemId);
          })
          .filter(itemId => {
            return (itemId + '').length >= 7;
          });
        dispatch({ type: types.INITIATE_LOADER, loader: 'itemResult' });
        api.getItemsByIds({ ids }).then(skus => {
          const items = skus.map(item => {
            return {
              ...item,
              $imageSrc: updateImageSrc({ data: item })
            };
          });
          let search = { source: 'solr', skus: items };
          search.searchReport = {
            keyword: '',
            searchInterface: 'xlsx',
            sortBy: 'bestmatch',
            sortOrder: 'none',
            startIndex: 0,
            pageSize: items.length,
            totalProducts: items.length
          };
          dispatch({ type: types.REMOVE_LOADER, loader: 'itemResult' });
          dispatch({ type: types.SET_ITEM_SELECTED_PAGINATION_VALUE, value: 1 });
          dispatch({ type: types.UPDATE_ITEMS_DATA, value: search, resultType: 'searchNav' });
          dispatch({ type: types.TOGGLE_CURATELIST_BULK_MODE, bulkMode: false });
        });
      };
      if (rABS) reader.readAsBinaryString(f);
      else reader.readAsArrayBuffer(f);
    });
  };
};
