import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DragSource } from 'react-dnd';
import ReviewItem from './index';
import domtoimage from 'dom-to-image';


const spec = {
  beginDrag(props, dnd, component) {
    const self = this;
    if (props.item._selected) {
      component.props.connectDragPreview(props.multiItemPreview);
    } else {
      component.props.connectDragPreview(component.el);
    }
    return { ...props };
  }
};

function collect(connect, monitor) {
  const item = monitor.getItem();

  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging()
  };
}

class ReviewItemDragSource extends Component {

  render() {
    let { connectDragSource, isDragging, item, list, index } = this.props;

    const opacity = (isDragging) ? 0.5 : 1;
    let className = ['review-item__drag-item'];

    if (!list.$canEdit) {
      connectDragSource = (el) => el;
    }

    if (isDragging) {
      className.push('review-item__drag-item--is-dragging');
    }
    return connectDragSource(<div
      className={className.join(' ')}
      ref={(el) => { this.el = el; }}
    >
      <ReviewItem list={list} item={item} index={index} />
    </div>);
  }
}

ReviewItemDragSource.propTypes = {
  props: PropTypes.shape({
    item: PropTypes.object
  }),
  list: PropTypes.object,
  canDrag: PropTypes.bool,
  item: PropTypes.object,
  connectDragSource: PropTypes.func.isRequired,
  connectDragPreview: PropTypes.func.isRequired,
  isDragging: PropTypes.bool.isRequired,
  index: PropTypes.number
};


export default DragSource(// eslint-disable-line new-cap
  'REVIEW_ITEM_DROPZONE', spec, collect
)(ReviewItemDragSource);
// export default CuratorResultsItemComponent;