import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import { deserializeHotspotItems
} from '../../../../../overlay/Hotspots/CurationHotSpotReducer';

export const getBoundingBoxesForImage = ({ imageBoundBox, canvas }) => {
  return deserializeHotspotItems({ items: imageBoundBox, canvas, visionHotspot: true });
};

class VisionComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      visionSrc: ''
    };
  }

  componentDidMount() {
    const { actions, listId } = this.props;
    actions.resetUISettings();
    actions.resetHotspotSettings({ listId });
  }

  render() {
    const {
      listId,
      actions,
      imageBoundBox,
      hotspotItems,
      items,
      merchandisingAttributes,
      loaders,
      meta,
      activeItemId
    } = this.props;
    const { visionSrc = '' } = this.state;
    const canvas = {
      width: 235,
      height: 300
    };

    if (this.el) {
      canvas.width = this.el.width;
      if (this.el && this.el.height) {
        canvas.height = this.el.height;
      }
    }

    const filterImageBoundBox = ({ boxes }) => {
      return boxes.filter(box => box.dotCoord);
    };

    const onClickHotspot = ({ box }) => {
      actions.getHotspotImages({ itemIds: box.itemIds });
    };

    const onItemClick = ({ itemId }) => {
      actions.getSimilarItems({ itemId, listId });
    };

    const handleImageUpload = ({ image }) => {
      actions.fileUploadLoader();
      let reader = new FileReader();
      let file = image
                 && image.files
                 && image.files.length > 0
                 && image.files[0]
                 ? image.files[0]
                 : '';

      reader.onloadend = () => {
        this.setState({
          visionSrc: reader.result
        });
      };

      reader.readAsDataURL(file);
      if ((image && image.files && image.files.length > 0 && image.files[0].size) > 10485760) {
        actions.fileUploadError();
      } else {
        actions.uploadVisionImg({ image, listId });
      }
    };

    const boxes = getBoundingBoxesForImage({ imageBoundBox, canvas });
    const boundBox = filterImageBoundBox({ boxes });
    return (
      <div className="accordion__wrapper vision">
        <div className="accordion__item">
        <input
          className="accordion__input"
          id="ac-vision"
          name="accordion-vision"
          type="checkbox"
        />
        <label className="accordion__label" htmlFor="ac-vision">
          <div className="accordion__trigger"><div className="accordion__plus"></div></div>
            <div className="slider-text-wrapper">
              <div className="slider-text-wrapper__text">Vision</div>
            </div>
        </label>
        <div className="accordion__content-wrapper">
          <div className="accordion__content">
            <div className="file__container--wrapper">
              <div className={visionSrc === '' ? 'file_upload--container' : 'hide_uplod--wrapper' }
                onClick={(e) => {
                  e.preventDefault();
                  e.currentTarget.nextSibling.click();
                }}
              >
                <div className="upload-icon"></div>
                <label className="file_upload--label">Upload Image</label>
              </div>
              <input type="file" name="visionUpload" accept="accept=image/*"
                className="file__container--wrapper-input"
                onChange={(e) => {
                  const uploadImage = e.target;
                  handleImageUpload({ image: uploadImage, listId });
                  e.target.value = null;// eslint-disable-line
                }}
              />
            </div>

              <div className="list-images">
              <div className="vision_preview--wrapper">
                <div className="vision_preview--container">
                  <div className= {visionSrc !== '' ? 'upload_picture--container'
                    : 'hide_uplod--wrapper' }
                  >
                    <div className="deleteImage"
                      onClick={ (e) => {
                        e.preventDefault();
                        this.setState({ visionSrc: '' });
                        actions.removeVisionImage({ listId, meta });
                      }}
                    >X</div>
                     <div>
                      {boxes && boxes.length > 0 &&
                      <svg className="vision_preview__hotspots"
                        onClick={ (e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          actions.resetHotspotSettings({ listId });
                        }}
                      >
                        {boundBox.map((box, i) => {
                          return (
                              <g
                                key={i}
                                transform={`translate(${box.coords.x},${box.coords.y})`}
                                x={box.coords.x}
                                y={box.coords.y}
                              >

                                <circle
                                  cx={box.dotCoord.x}
                                  cy={box.dotCoord.y}
                                  r={4}
                                  fill="#F96302"
                                  strokeWidth="1"
                                  stroke="white"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onClickHotspot({ box });
                                  }}
                                />
                              </g>);
                        })}
                      </svg>}
                      <img
                        src={visionSrc}
                        className="vision__preview"
                        alt="preview"
                        width="300"
                        ref={(el) => {
                          if (el) this.el = el;
                        }}

                      />
                    </div>
                  </div>
                </div>
              </div>

            </div>

            {hotspotItems && hotspotItems.length > 0 && <div className="list-images">
              <div className="vision_preview--wrapper">
                <div className="vision_preview--container">
                  <div className="hotspot_picture--container">
                    <div className="hotspot_image">
                      {hotspotItems
                      && hotspotItems.length > 0
                      && hotspotItems.map((item, j) => {
                        return (item
                                && item.length > 0
                                && item[0].info
                                && item[0].info.mediaList
                                && item[0].info.mediaList.length > 0
                                && item[0].info.mediaList[0].location
                                && <div className="hotspot-images-div" key={j}>
                                    <img
                                      src= {item[0].info.mediaList[0].location}
                                      className= {activeItemId === item[0].itemId
                                                  ? 'active'
                                                  : ''
                                                }
                                      alt="preview"
                                      width="78"
                                      onClick={ (e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        onItemClick({ itemId: item[0].itemId });
                                      }}
                                    />
                              <div className={'hotspot-image-text ' +
                                              (activeItemId === item[0].itemId
                                              ? 'active-div'
                                              : '')}
                              >{item[0].itemId}</div>
                              </div>);
                      })}
                    </div>
                  </div>
                </div>
              </div>

            </div>}
          </div>
        </div>
      </div>
    </div>);
  }
}

VisionComponent.propTypes = {
  listId: PropTypes.number,
  actions: PropTypes.object,
  items: PropTypes.array,
  merchandisingAttributes: PropTypes.object,
  imageBoundBox: PropTypes.array,
  loaders: PropTypes.object,
  hotspotItems: PropTypes.array,
  meta: PropTypes.object,
  activeItemId: PropTypes.number
};

export default VisionComponent;
