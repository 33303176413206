import React, { Component } from 'react';
import PropTypes from 'prop-types';
import HeaderContainer from '../../modules/Header';
import './style.scss';
import { PieChart, Pie, Cell, Legend, Tooltip } from 'recharts';
import InlineMessage from '../../modules/InlineMessage/component';

const colors = ['#F96302', '#00AE4D', '#FFC20E', '#286FAD', '#CCCCCC', '#ED1C24', '#003417'];


class ClassificationsComponent extends Component {


  componentDidMount() {
    const { actions } = this.props;
    actions.getClassifications({});
    document.title = "Classifications";
  }


  mapDimensionToChart(dimension) {
    const data = dimension.map(attr => {
      return {
        name: attr.name,
        value: attr.weight
      };
    });
    const classifications = data.filter(classification => classification.value > 0);
    return classifications;
  }
  render() {
    const {
      loaders,
      userClassifications,
      skuClassifications,
      actions,
      isAdminUser,
      errorMessage,
      infoMessage
    } = this.props;


    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ midAngle = 0, percent }) => {
      const cx = 200;
      const cy = 100;
      const outerRadius = 80;
      const innerRadius = 0;

      const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
      const x = cx + radius * Math.cos(-midAngle * RADIAN);
      const y = cy + radius * Math.sin(-midAngle * RADIAN);

      return (
        <text x={x}
          y={y}
          fill="white"
          textAnchor={x > cx ? 'start' : 'end'}
          dominantBaseline="central"
        >
          {`${(percent * 100).toFixed(0)}%`}
        </text>
      );
    };

    return (
      <div className="grid curationizer-main">
        <HeaderContainer />
        <div className="grid classifications">
          <div className="col__12-12">
            {!isAdminUser &&
              <div className="col__12-12">
                <div className="curation-flash">
                  You do not have permissions to view this.
                </div>
               </div>
            }
            {isAdminUser &&
            <div className="col__12-12 classifications-details">
              <div className="grid">
                {loaders['load-classification-details']
                  || loaders['submit-classification-changes'] &&
                <div className="col__12-12 curationList-loader">
                  <div className="pre-loader">
                    <div className="segment-spinner" />
                  </div>
                </div>}
                {errorMessage && <div className="grid flush">
                  <span className="curation-replace-msg">
                    <InlineMessage type="danger" message={errorMessage} />
                  </span>
                </div>}
                {infoMessage && <div className="grid flush">
                  <span className="curation-replace-msg">
                    <InlineMessage type="" message={infoMessage} />
                  </span>
                </div>}
                <div className="col__12-12 classifications-type">
                   User Classification
                </div>

                  <div className="col__4-12">

                    <PieChart width={300} height={300} onMouseEnter={this.onPieEnter}>
                      <Pie
                        data={this.mapDimensionToChart(userClassifications)}
                        dataKey="value"
                        cx={200}
                        cy={100}
                        isAnimationActive={false}
                        labelLine={false}
                        label={renderCustomizedLabel}
                        outerRadius={80}
                        fill="#DDDDDD"
                        legendType="line"
                      >
                        {
                          userClassifications.map((entry, index) => {
                            return <Cell key={index} fill={colors[index]} />;
                          })
                        }
                      </Pie>
                      <Tooltip />
                      <Legend />
                    </PieChart>
                  </div>

                <div className="col__8-12">
                  <div className="col__12-12 classifications-header">
                    <div className="classifications-fields">
                      Classification Name
                    </div>

                    <div className="classifications-fields">
                      Classification %
                    </div>

                    <div className="classifications-fields">
                      Change Classification %
                    </div>
                  </div>
                  <div className="col__12-12 classification-rows">
                    <div className="curationListBody grid">
                      <div className="col__12-12">
                        {userClassifications
                          && userClassifications.length > 0
                          && userClassifications.map((classification, index) => {
                            return (
                              <div className={`grid ${index % 2 === 0 ?
                                'curationList__body' : ''}`}
                                key={classification.id}
                              >
                                <div className="classification-cells">
                                  {classification.name}
                                </div>
                                <div className="classification-cells">
                                  {classification.weight}
                                </div>
                                <div className="classification-cells">
                                  <input
                                    className="form-input__field"
                                    name="classification_name"
                                    maxLength="3"
                                    min="0"
                                    max="100"
                                    type="text"
                                    defaultValue={classification.weight || 0}
                                    onChange={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      const value = e.target.value;
                                      if (!isNaN(value)) {
                                        if (value && value >= 0 && value <= 100) {
                                          actions.setClassificationWeight(
                                            {
                                              id: classification.id,
                                              value: parseInt(e.target.value, 10),
                                              type: 'user'
                                            });
                                        } else {
                                          e.target.value = '';// eslint-disable-line
                                        }
                                      } else {
                                        e.target.value = '';// eslint-disable-line
                                      }
                                    }}
                                    onBlur={(e) => {
                                      if (!e.target.value) {
                                        e.target.value = classification.weight;// eslint-disable-line
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            );
                          })

                        }
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            }

            {isAdminUser &&
            <div className="col__12-12 classifications-details">
              <div className="grid">
                <div className="col__12-12 classifications-type">
                  Sku Classification
                </div>

                <div className="col__4-12">

                  <PieChart width={300} height={300} onMouseEnter={this.onPieEnter}>
                    <Pie
                      data={this.mapDimensionToChart(skuClassifications)}
                      dataKey="value"
                      cx={200}
                      cy={100}
                      isAnimationActive={false}
                      labelLine={false}
                      label={renderCustomizedLabel}
                      outerRadius={80}
                      fill="#DDDDDD"
                      legendType="line"
                    >
                      {
                        skuClassifications.map((entry, index) => {
                          return <Cell key={index} fill={colors[index]} />;
                        })
                      }
                    </Pie>
                    <Tooltip />
                    <Legend />
                  </PieChart>
                </div>

                <div className="col__8-12">
                  <div className="col__12-12 classifications-header">
                    <div className="classifications-fields">
                      Classification Name
                    </div>

                    <div className="classifications-fields">
                      Classification %
                    </div>

                    <div className="classifications-fields">
                      Change Classification %
                    </div>
                  </div>
                  <div className="col__12-12 classification-rows">
                    <div className="curationListBody grid">
                      <div className="col__12-12">
                        {skuClassifications
                        && skuClassifications.length > 0
                        && skuClassifications.map((classification, index) => {
                          return (
                            <div className={`grid ${index % 2 === 0 ?
                              'curationList__body' : ''}`}
                              key={classification.id}
                            >
                              <div className="classification-cells">
                                {classification.name}
                              </div>
                              <div className="classification-cells">
                                {classification.weight}
                              </div>
                              <div className="classification-cells">
                                <input type="text"
                                  className="form-input__field"
                                  name="classification_name"
                                  maxLength="3"
                                  min="0"
                                  max="100"
                                  type="text"
                                  defaultValue={classification.weight || 0}
                                  onChange={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    const value = e.target.value;
                                    if (!isNaN(value)) {
                                      if (value && value >= 0 && value <= 100) {
                                        actions.setClassificationWeight(
                                          {
                                            id: classification.id,
                                            value: parseInt(e.target.value, 10),
                                            type: 'sku'
                                          });
                                      } else {
                                        e.target.value = '';// eslint-disable-line
                                      }
                                    } else {
                                     e.target.value = '';// eslint-disable-line
                                    }
                                  }}
                                  onBlur={(e) => {
                                    if (!e.target.value) {
                                       e.target.value = classification.weight;// eslint-disable-line
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          );
                        })

                        }
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              {!loaders['submit-classification-changes']
              && !loaders['load-classification-details']
              && <div className="col__12-12 classifications-type">
                <div className="replace-remove-btn">
                 <a className="bttn bttn--primary bttn--inline btn-product-details"
                   onClick={e => {
                     e.preventDefault();
                     e.stopPropagation();
                     actions.applyClassificationChanges();
                   }}
                 >
                    <span className="bttn__content">Apply Classification Changes</span>
                  </a>

                </div>
              </div>}
            </div>
            }

          </div>
        </div>
      </div>
    );
  }
}

ClassificationsComponent.propTypes = {
  loaders: PropTypes.object,
  skuClassifications: PropTypes.array,
  actions: PropTypes.object,
  userClassifications: PropTypes.array,
  isAdminUser: PropTypes.bool,
  errorMessage: PropTypes.string,
  infoMessage: PropTypes.string
};

export default ClassificationsComponent;
