import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from './actions';
import { toggleCurateListEditMode } from '../CurateList/modules/Items/actions';
import component from './component';
import { getDNDContext, getUserPermission } from '../../../../util';
import { withRouter } from 'react-router-dom';
import { curationListListner } from '../AppActions';
import {
  getListMetaErrors,
  injectDimensionWeight,
  getSpaceCollectionURL
} from '../CurationList/reducer';
import * as types from '../../../../constants/ActionTypes';

const mapStateToProps = (state, ownProps) => {
  const type = ownProps.match.params.listType;
  const list = state.curationList.lists
    .find(l => l.id === parseInt(ownProps.match.params.id, 10)) || {};
  const collections = state.curationList.collections || [];
  list.$canEdit = false;
  const isEnablePinterest = state.app.user ? state.app.user.isAdmin : false;
  list.$canEdit = getUserPermission({ state, list });
  list.$publishErrors = [];
  list.$publishErrors = list.meta ? getListMetaErrors({ list, state }) : [];
  list.$canPublish = list.$publishErrors.length === 0;
  const spaceCollectionURL = type === 'collection' ? getSpaceCollectionURL({ list }) : '';
  return {
    list: injectDimensionWeight(list),
    selectedOpen: !(list.skus || []).every(item => !item._selected),
    ui: state.ui,
    errors: state.curationList.errorModel,
    loaders: state.app.loaders,
    listType: type,
    spaceCollectionURL,
    isEnablePinterest,
    collections
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const type = ownProps.match.params.listType;
  return {
    actions: bindActionCreators({
      ...actions,
      toggleCurateListEditMode,
      deleteCuration: ({ curationId }) => {
        return (d) => {
          const answer = confirm(`Are you sure you want to delete the ${type}?`);// eslint-disable-line
          if (answer) {
            dispatch(actions.deleteCuration({ curationId, type }))
            .then(resp => {
              if (!resp.errorCode) {
                ownProps.history.push(`/${type}s`);
              }
            });
          }
        };
      },
      onload: () => {
        return () => {
          dispatch({ type: types.GENERIC_ERROR_HANDLER_CLEAR });
          dispatch(curationListListner(ownProps.history));
          actions.appMainWindowListener(dispatch);
          return dispatch(actions.fetchListClassication({
            curationId: ownProps.match.params.id,
            type
          })).then(resp => {
            if (type === 'curation') {
              dispatch(actions.
                fetchCurationClassificationValues({ listId: ownProps.match.params.id }))
                .then(res => {
                  if (/default$/.test(ownProps.location.pathname)) {
                    dispatch(actions.
                      defaultClassicationValues({ listId: ownProps.match.params.id }));
                  }
                });
            } else {
              // dispatch(actions.
              //   fetchCurationsForCollection({ listId: ownProps.match.params.id }));
            }
          });

        };
      },
      navigate: (path) => {
        return () => {
          ownProps.history.push(path);
        };
      },
      handleConvertImageToCanvas: (image) => {
        dispatch(actions.convertImageToCanvas(image));
      }
    }, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(
  getDNDContext()(component)// eslint-disable-line new-cap
));
