import React from 'react';
import { combineReducers } from 'redux';
import app from './App/AppReducer';
import item from './App/CurateList/modules/Item/reducer';
import curationList from './App/CurationList/reducer';
import ui from './modules/UIReducer';
import maintenance from './App/Maintenance/List/reducer';
import maintenanceDetails from './App/Maintenance/Details/MaintenanceDetails.reducer';
import vision from './App/CurateList/modules/Vision/Vision.reducer';
import classification from './App/Classifications/Classifications.reducer';
import userClassification from './App/UserClassifications/UserClassifications.reducer';
import dimensionClassification from
    './App/UserClassifications/Modules/DimensionClassifications.reducer';
import hotspots from
  '../overlay/Hotspots/CurationHotSpotReducer';

export default combineReducers({
  app,
  item,
  curationList,
  vision,
  ui,
  hotspots,
  maintenance,
  maintenanceDetails,
  classification,
  userClassification,
  dimensionClassification
});
