module.exports = {
  listAnalytics: [
    {
      name: '% of Items by Style',
      type: 'pie',
      values: [{
        name: 'Bohemian',
        value: 60,
        color: '#F96302'
      }, {
        name: 'Coastal',
        value: 30,
        color: '#f6a477'
      }, {
        name: 'Mid-Century Modern',
        value: 10,
        color: '#CCC'
      },
      {
        name: 'Cottage',
        value: 15,
        color: '#8884d8'
      }
      ],
    },
    {
      id: 'p1',
      type: 'stat',
      name: 'Number of Products',
      value: '60'
    },
    {
      id: 'p2',
      type: 'stat',
      name: 'Total Products Sold',
      value: '60.5K',
      days: [{
        period: 30,
        value: '500'
      },
      {
        period: 90,
        value: '1,235'
      }]
    }, {
      id: 'p3',
      type: 'stat',
      name: 'Total Page Views',
      value: '80.2K',
      days: [{
        period: 30,
        value: '100'
      },
      {
        period: 90,
        value: '300'
      }]
    },
    {
      id: 'p4',
      type: 'price',
      name: 'Total Revenue',
      value: '50.3K',
      days: [{
        period: 30,
        value: '1K'
      },
      {
        period: 90,
        value: '3K'
      }]
    },
    {
      id: 'p5',
      type: 'price',
      name: 'Revenue per Page View',
      value: '159.44',
      days: [{
        period: 30,
        value: '160.11'
      },
      {
        period: 90,
        value: '158.23'
      }]
    },
    {
      id: 'p6',
      type: 'session',
      name: 'Average Session Duration',
      value: '600'
    },
  ]
};