
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ItemDragSource from '../../../CurateList/modules/Item/ItemDragSource';
import { formatDate } from '../../../../../../util';
import ItemContainer from '../../../CurateList/modules/Item';
import './style.scss';
import Pager from 'react-pager';


function calculateTop(columns) {
  if (columns === 2) {
    return 418 + 24 + 'px'; // Image Height + 50 to display the arrow.
  } else if (columns === 3) {
    return 276 + 24 + 'px';
  } else if (columns === 4) {
    return 206 + 24 + 'px';
  } else if (columns === 6) {
    return 136 + 24 + 'px';
  }
  return 150 + 50 + 'px';
}

function getArrayLeft(i, columns) {
  let percent;

  if (columns === 2) {
    if (i % 2 === 0) {
      percent = '20%';
    } else if (i % 2 === 1) {
      percent = '70%';
    }
  } else if (columns === 3) {
    if (i % 3 === 0) {
      percent = '11%';
    } else if (i % 3 === 2) {
      percent = '78%';
    } else if (i % 3 === 1) {
      percent = '44%';
    }
  } else if (columns === 4) {
    if (i % 4 === 0) {
      percent = '8%';
    } else if (i % 4 === 1) {
      percent = '34%';
    } else if (i % 4 === 2) {
      percent = '59%';
    } else if (i % 4 === 3) {
      percent = '84%';
    }
  } else if (columns === 5) {
    if (i % 5 === 0) {
      percent = '7%';
    } else if (i % 5 === 1) {
      percent = '27%';
    } else if (i % 5 === 2) {
      percent = '48%';
    } else if (i % 5 === 3) {
      percent = '67%';
    } else if (i % 5 === 4) {
      percent = '87%';
    }
  } else if (columns === 6) {
    if (i % 6 === 0) {
      percent = '4%';
    } else if (i % 6 === 1) {
      percent = '21%';
    } else if (i % 6 === 2) {
      percent = '38%';
    } else if (i % 6 === 3) {
      percent = '55%';
    } else if (i % 6 === 4) {
      percent = '71%';
    } else if (i % 6 === 5) {
      percent = '88%';
    }
  }
  return {
    left: percent
  };
}

class CollectionItemDetails extends Component {

  render() {
    const {
      item,
      list,
      actions,
      loaders
    } = this.props;
    return (
    <div className="grid">
      <div className="col__12-12 curation--item__Description">
        <a
          href="#"
          className="bttn-outline bttn--remove bttn--inline review-item-details__close"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            actions.closeSelection({ list, item });
          }}
        ></a>
        <div className="curation__item--label"><b>{item.name}</b></div>
        <p>Created by {item.createdBy} on {formatDate(new Date(item.createdAt), 'MM/dd/yyyy')}</p>
        <p className="reviewList__description--display">Description: {item.description}</p>
      </div>
      <div className="col__12-12 review-item__discover">
        <h2>Skus</h2>
      </div>
      {!loaders['item-details'] && (item.items || []).map(sku => {
        return (<div key={sku.itemId} className="col__2-12">
            <ItemContainer item={sku} size="small" type="review" list={list} />
        </div>);
      })}
      {!loaders['item-details'] && item.items && item.items && item.items.length === 0 &&
        <div className="curation-flash col__12-12 curation-noresult">
          No items found
        </div>
      }
      {loaders['item-details'] &&
        <div className="col__12-12 review-item__loader">
          <div className="pre-loader">
            <div className="segment-spinner"></div>
          </div>
        </div>
      }
    </div>
    );
  }
}

CollectionItemDetails.propTypes = {
  list: PropTypes.object,
  item: PropTypes.object,
  actions: PropTypes.object,
  ui: PropTypes.object,
  loaders: PropTypes.object
};

const CurationItemDetails = ({ item, list, actions, multiItemPreview, loaders, ui }) => (
  <div>
    <div className="col__12-12 curation--item__Description">
      <a
        href="#"
        className="bttn-outline bttn--remove bttn--inline review-item-details__close"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          actions.closeSelection({ list, item });
        }}
      ></a>
      <div className="curation__item--label">
       {item.info && <p>
        <b>{item.info.brandName}</b> {(item.info.productLabel || '')}
        </p>
       }
        {item.vendor && <p>{item.vendor}</p>}
        {item.message || ''}
      </div>
      <p>OMSID #{item.itemId || item.id}</p>
      {item.ratingsReviews && <div className="reviews">
        <span className="stars"
          rel={item.ratingsReviews.averageRating}
          style={{ width: ((item.ratingsReviews.averageRating * 100) / 5) + '%' }}
        >{item.ratingsReviews.averageRating}</span>
      </div>}
      {item.storeSku && <p className="clear__float">
        ${item.storeSku.pricing.specialPrice}/{item.storeSku.pricing.uom}
      </p>}
    </div>
    <div className="col__12-12 review-item__discover">
        {item.results &&
          item.results.searchReport &&
          item.results.searchReport.dimensionForSort &&
          item.results.searchReport.dimensionForSort.length > 0 &&
          <div className="review-item__discover-by">
            <nav className="drop-down__header review-item__discover-by--header">
              <span className="drop-down__title">Discover Sort</span>
              <div className="drop-down__content-wrapper">
                <ul className="drop-down__list">
                {(item.results.searchReport.dimensionForSort || []).map((dim, i) => {
                  return (
                    <li key={dim.id} className="drop-down__item">
                      <span className="drop-down__hover-effect">
                        <div className="checkbox-btn">
                          <input
                            className="checkbox-btn__input"
                            type="checkbox"
                            value={dim.id}
                            id={`${dim.id}-rc-${i}`}
                            name="rc1"
                            checked={dim.$selected}
                          />
                          <label
                            className="checkbox-btn__label"
                            htmlFor={`${dim.id}-rc-${i}`}
                            onClick={(e) => {
                              const dimensionId = dim.id;
                              const dimensionName = dim.name;
                              actions.setDiscoverItemBy({
                                dimensionId,
                                dimensionName,
                                itemId: item.itemId,
                                listId: list.id,
                                value: !dim.$selected
                              });
                            }}
                          >
                            Discover by {dim.name}
                          </label>
                        </div>
                      </span>
                    </li>
                  );
                })}
                </ul>
              </div>
            </nav>
          </div>
        }
        <h2>Discover</h2>
        {!loaders['item-details'] &&
          item.results &&
          item.results.items &&
          item.results.items.length === 0 &&
          <div className="curation-flash curation-noresult">
            No items found
          </div>
        }
    </div>
    {loaders['item-details'] &&
      <div className="col__12-12 review-item__loader">
        <div className="pre-loader">
          <div className="segment-spinner"></div>
        </div>
      </div>
    }
    {!loaders['item-details'] && item.results && (item.results.$items || []).map(sku => {
      return (<div key={sku.itemId} className="col__2-12">
        <ItemDragSource
          item={sku}
          size="small"
          type="review"
          list={list}
          multiItemPreview={multiItemPreview}
        />
      </div>);
    })}
    <div className="col__12-12 pagination-wrapper">
      { !loaders['item-details'] &&
        item.results &&
        item.results.searchReport &&
        item.results.searchReport.totalProducts > 24 &&
        <Pager
          total={item.results.searchReport.totalProducts / 24}
          current={Math.floor(item.results.searchReport.startIndex / 24)}
          visiblePages={4}
          titles={{ first: '<<', last: '>>' }}
          className="hd-pagination__wrapper"
          onPageChanged={actions.handlePageChanged}
        />
      }
    </div>
  </div>
);

CurationItemDetails.propTypes = {
  item: PropTypes.object,
  actions: PropTypes.object,
  multiItemPreview: PropTypes.object,
  ui: PropTypes.object,
  loaders: PropTypes.object,
  list: PropTypes.object,
};


const ReviewItemDetails = ({ ...props }) => (
  <div
    style={ { left: ((props.index % props.NoOfColumns) * - 100) + '%',
      top: calculateTop(props.NoOfColumns),
      height: 'auto',
      width: (100 * props.NoOfColumns) + '%' // Width of Review Item Details.
    }}
    className="review_item__details"
  >
    <div className="inlineDetails__arrow"
      style={getArrayLeft(props.index, props.NoOfColumns)}
    ></div>
    {props.list.type === 'collection' && <CollectionItemDetails {...props} />}
    {props.list.type === 'curation' && <CurationItemDetails { ...props } />}
  </div>
);

ReviewItemDetails.propTypes = {
  list: PropTypes.object,
  index: PropTypes.number,
  ui: PropTypes.object,
  NoOfColumns: PropTypes.number
};


export default ReviewItemDetails;
