import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import Pager from 'react-pager';
import { Link } from 'react-router-dom';
import discontinued from '../../../../../../../assets/images/item-discontinued.jpg';
import { imageErrorHandler, getJoinArrayElements } from '../../../../../../../util';
import InlineMessage from '../../../../../modules/InlineMessage/component';

class ProductDetailsComponent extends Component {

  componentDidMount() {
    //
    const { actions, itemId } = this.props;
    actions.getProductDetails({ productId: itemId });
  }

  componentDidUpdate() {
    const { windowScroll } = this.props;
    if (windowScroll) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const {
      loaders,
      item,
      curations,
      allSelectedCuration,
      actions,
      searchReport,
      infoMessage,
      itemId,
      isAdminUser
    } = this.props;
    const getItemProductDescription = ({ product }) => {
      const itemDescription = product.info.productLabel ? product.info.productLabel : '';
      return itemDescription;
    };

    const getItemBrandName = ({ product }) => {
      const itemBrandName = product.info.brandName ? product.info.brandName : '';
      return itemBrandName;
    };

    const getProductType = ({ product }) => {
      let productCategory = '';
      if (product && product.taxonomy) {
        const defaultBreadCrumb = product.taxonomy.find(taxonomy => taxonomy.isDefaultBreadCrumb);
        if (defaultBreadCrumb
            && defaultBreadCrumb.refinements
            && defaultBreadCrumb.refinements.length > 0) {
          const productLabel = defaultBreadCrumb.refinements.map((refinement) => {
            return refinement.label;
          });
          productCategory = getJoinArrayElements({ array: productLabel });
          if (productCategory) {
            productCategory = productCategory.split(',').join('/');
          }
          return productCategory;
        }
      }
      return productCategory;
    };

    const getProductDepartmentClass = ({ product }) => {
      let productDepartmentClass = '';
      if (product && product.info) {
        if (product.info.productDepartment) {
          productDepartmentClass = product.info.productDepartment;
        }
        if (product.info.productClass) {
          productDepartmentClass = productDepartmentClass + '/' + product.info.productClass;
        }
        if (product.info.productSubClass) {
          productDepartmentClass = productDepartmentClass + '/' + product.info.productSubClass;
        }
      }
      return productDepartmentClass;
    };

    const getProductPrice = ({ product }) => {
      let itemPrice = 0;
      if (product && product.storeSkus
        && product.storeSkus.length > 0
        && product.storeSkus[0].pricing) {
        if (product.storeSkus[0].pricing.specialPrice) {
          itemPrice = product.storeSkus[0].pricing.specialPrice;
        } else if (product.storeSkus[0].pricing.originalPrice) {
          itemPrice = product.storeSkus[0].pricing.originalPrice;
        }
        return itemPrice;
      }
      return itemPrice;
    };

    const getImageURL = ({ product }) => {
      if (product && product.info
        && product.info.mediaList
        && product.info.mediaList.length > 0
        && product.info.mediaList[0].location) {
        return product.info.mediaList[0].location;
      }
      return discontinued;
    };

    const getSelectedCurationCount = ({ curationList }) => {
      const curationCount = curationList.filter(curation => curation.isSelected);
      return curationCount.length;
    };

    return (
      <div className="grid product-details">
        {!isAdminUser &&
        <div className="col__12-12">
        <div className="curation-flash">
          You do not have permissions to edit this product
        </div> </div>}
        <div className="grid">
          {!loaders.load_item_details && <div className="col__12-12">
            <div className="col__4-12 product-canvas">
              <img src={getImageURL({ product: item })}
                draggable="false"
                role="presentation"
                onError={imageErrorHandler}
              />
                <div className="product-image__omsid">{item.itemId}</div>
            </div>
            {(item && item.itemId) &&
            <div className="col__8-12 product-details-description">
              <div className="product-details-list">
                {item.ratingsAndReviews &&
                <div className="reviews">
                  <span className="stars"
                    rel={item.ratingsAndReviews.averageRating}
                    style={{ width: ((item.ratingsAndReviews.averageRating * 100) / 5) + '%' }}
                  >{item.ratingsAndReviews.averageRating}</span>
                </div>}
              </div>
              {item && item.info && <h1>
                <span className="brand-name"> {getItemBrandName({ product: item })} </span>
                {getItemProductDescription({ product: item })}
              </h1>
              }

              <div className="product-details-list product-type-class">

              <span className="product-category">
                {getProductType({ product: item })}
              </span>
                <span className="product-type">
                {getProductDepartmentClass({ product: item })}
              </span>
              </div>
              <h1>
                {`$${getProductPrice({ product: item })}`}
              </h1>
            </div>
            }
            {(!item || !item.itemId) &&
            <div className="col__8-12 product-details-description">
              <div className="product-details-list discontinued-item-msg">
                Item is Discontinued
              </div>
            </div>
            }
          </div>}
          {infoMessage && <div className="grid flush">
            <span className="curation-replace-msg">
              <InlineMessage type="" message={infoMessage} />
            </span>
          </div>}
          {curations
            && <div className="col__12-12 product-curation-header no-left-right-border">
            {isAdminUser
            && <div className="checkbox-btn refinements__checkbox refinement-checkbox__label">
              <input
                className="checkbox-btn__input "
                type="checkbox"
                id={`maintenanceCuration-${item.itemId}-all`}
                name={`maintenanceCuration-${item.itemId}-all`}
                checked={allSelectedCuration}
              />
              <label
                className="checkbox-btn__label"
                onClick={(e) => {
                  e.preventDefault();
                  actions.toggleSelectAllCurations({ allSelectedCuration });
                }}
              >
              </label>
            </div>}
            <div className="product-curation-fields">
              Curation ID
            </div>

            <div className="product-curation-fields">
              Curation Name
            </div>

            <div className="product-curation-fields">
              Curation Status
            </div>

            <div className="product-curation-fields">
              Collection Count
            </div>
          </div>
          }
          {(loaders['product-details']
            || loaders.load_item_details
            || loaders['replace-item']
            || loaders.removeItemLoader)
          &&
          <div className="col__12-12 curationList-loader">
            <div className="pre-loader">
              <div className="segment-spinner" />
            </div>
          </div>}
          {curations && curations.length > 0
          && (!loaders['product-details'] && !loaders.removeItemLoader && !loaders['replace-item'])
            && <div className="col__12-12 product-curation-header">
            <div className="curationListBody grid">
              <div className="col__12-12">
                {curations.map((curationList, index) => {
                  return (
                    <div className={`grid ${index % 2 === 0 ?
                      'curationList__body' : ''}`}
                      key={curationList.id}
                    >
                      {isAdminUser
                      && <div className="checkbox-btn list-multi-select refinements__checkbox">
                        <input
                          className="checkbox-btn__input"
                          type="checkbox"
                          id={`multiSelectCheckbox-${curationList.id}-${index}`}
                          name={`multiSelectCheckbox-${curationList.id}-${index}`}
                          checked={curationList.isSelected}

                        />
                        <label
                          className="checkbox-btn__label" htmlFor={
                          `multiSelectCheckbox-${curationList.id}-${index}`
                        }
                          onClick={(e) => {
                            e.preventDefault();
                            actions.toggleCuration({
                              id: curationList.id,
                              isSelected: curationList.isSelected });
                          }}
                        >
                        </label>
                      </div>}

                      <div className={'product-curation-cells '
                      + (!isAdminUser ? 'non-admin-user-curation' : '')}
                      >

                        <Link to={{
                          pathname: `/curation/review/${curationList.id}`
                        }}
                        >
                          {curationList.id} </Link>
                      </div>
                      <div className="product-curation-cells curation-name-cell">
                        <span className="curation-name">{curationList.name}</span>

                      </div>
                      <div className="product-curation-cells">
                        {curationList.status}
                      </div>
                      <div className="product-curation-cells">
                        {curationList.totalCollections}
                      </div>
                    </div>
                  );
                })}
                {!loaders['product-details']
                && !loaders.removeItemLoader && <div className="col__12-12">
                  <div className="pagination-wrapper">
                    {(!loaders.removeItemLoader || !loaders['product-details']) &&
                    searchReport.totalCurations > 24 && (
                      <Pager
                        total={searchReport.totalCurations / searchReport.pageSize}
                        current={Math.floor(searchReport.startIndex / searchReport.pageSize)}
                        visiblePages={searchReport.$visiblePages}
                        titles={{ first: '|<', last: '>|' }}
                        className="hd-pagination__wrapper"
                        onPageChanged={actions.handlePageChanged}
                      />
                    )}
                  </div>
                </div>}
                {curations && curations.length > 0 &&
                <div className="col__12-12 product-summary-details">
                    <div className="product-summary-fields">
                      <span>Total selected curations </span>
                      <span className="product-summary-values">
                        {getSelectedCurationCount({ curationList: curations })}
                       </span>
                    </div>
                    <div className="replace-remove-btn">
                      {(allSelectedCuration
                       || getSelectedCurationCount({ curationList: curations }) > 0)
                        && <a className="bttn bttn--primary bttn--inline btn-product-details"
                          onClick={e => {
                            e.preventDefault();
                            actions.removeItemFromCurations({ itemId });
                          }}
                        >
                          <span className="bttn__content">Remove</span>
                          </a>
                        }
                    </div>
                    <div className="replace-remove-btn">
                      {(allSelectedCuration
                       || getSelectedCurationCount({ curationList: curations }) > 0)
                      && <a className="bttn bttn--primary bttn--inline btn-product-details"
                        onClick={e => {
                          e.preventDefault();
                          actions.toggleSimilarItems({ showSimilarItems: true });
                        }}
                      >
                        <span className="bttn__content">Replace</span>
                      </a>
                      }
                    </div>
                  </div>
                }
                {curations && curations.length > 0 &&
                <div className="col__12-12 product-summary-details">
                  <div className="product-summary-fields">
                    <span>Total collection count in all curations</span>
                    <span className="product-summary-values">
                      {searchReport && searchReport.totalCollections
                        ? searchReport.totalCollections
                        : 0}
                    </span>
                  </div>
                </div>
                }
              </div>
            </div>
          </div>}
          {((!curations || curations.length === 0) &&
            (!loaders['product-details']
            && !loaders.load_item_details
            && !loaders['replace-item']
            && !loaders.removeItemLoader)) &&
            <div className="col__12-12 product-curation-header">
              <div className="curationListBody grid">
                <div className="col__12-12 no-curation">
                  Item is not in any of the curation.
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
}

ProductDetailsComponent.propTypes = {
  loaders: PropTypes.object,
  actions: PropTypes.object,
  itemId: PropTypes.string,
  item: PropTypes.object,
  curations: PropTypes.array,
  allSelectedCuration: PropTypes.bool,
  searchReport: PropTypes.object,
  infoMessage: PropTypes.string,
  windowScroll: PropTypes.bool,
  isAdminUser: PropTypes.bool
};

export default ProductDetailsComponent;