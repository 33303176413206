import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DropTarget } from 'react-dnd';

const spec = {
  canDrop(props, monitor) {
    const item = monitor.getItem();
    // cant drop on same item
    // return item.props.product.itemId !== props.product.itemId;
    // dont allow dropping of item thats already in item set

    return item.list.$canEdit;
  },

  drop(props, monitor) {
    const item = monitor.getItem().item;
    const index = props.index;
    const itemId = item.itemId || item.id;
    const listId = props.list.id;

    const $items = props.list.items;
    const currentIndex = $items.findIndex(i => (i.itemId || i.id) === itemId);
    if (currentIndex !== -1) {
      props.actions.updateItemPosition({ itemId, index, listId });
    } else {
      if (item._selected) {
        const selectedItem = props.list.items.find(i => i._selected);
        if (selectedItem.results) {
          const items = (selectedItem.results.items || [])
            .filter(i => i._selected)
            .map((i, ind) => {
              props.actions.toggleSelectedReviewDiscoveryItem({
                selectedItemId: selectedItem.itemId,
                itemId: i.itemId,
                index
              });
              return i;
            });
          props.actions.addAllSelectedToSet({ listId, serverCall: true, items, index });
        }
      } else {
        props.actions.addItemToSet({ item, listId, index, serverCall: true });
      }
    }

  }
};

function collect(connect, monitor) {

  const info = {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop()
  };

  return info;
}

class ReviewItemDropzoneComponent extends Component {
  render() {
    const { connectDropTarget, isOver, canDrop, results, list, multiItemPreview } = this.props;
    const className = ['review-item__dropzone'];
    if (isOver && canDrop && list.$canEdit) {
      className.push('review-item__dropzone--droppable');
    }


    return connectDropTarget(
      <div className={className.join(' ')}>{this.props.children}</div>
    );
  }
}


ReviewItemDropzoneComponent.propTypes = {
  position: PropTypes.objectOf(PropTypes.number),
  isOver: PropTypes.bool,
  connectDropTarget: PropTypes.func,
  movePiece: PropTypes.func,
  canDrop: PropTypes.bool,
  children: PropTypes.any,
  results: PropTypes.array,
  list: PropTypes.object,
  multiItemPreview: PropTypes.object,
};

export default DropTarget(["REVIEW_ITEM_DROPZONE", "DRAG_SOURCE"], spec, collect)(ReviewItemDropzoneComponent);// eslint-disable-line
