import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from './actions';
import * as parentActions from '../../actions';
import * as reviewItemActions from
  '../../../ReviewList/modules/ReviewItemContainer/ReviewItem/actions';
import Item from './component';
import defaultProduct from '../../../../../../assets/images/default-product.jpg';

const mapStateToProps = (state, ownProps) => {
  const size = ownProps.size || 'large';
  const NoOfCoulmns = state.item.itemView;
  let itemSize = 125;
  if (NoOfCoulmns === 6) {
    itemSize = 75;
  }
  const curationListViewType = state.ui.curationList.viewType;
  const listIconSize = (curationListViewType === 'curation' && ownProps.type === 'set')
    ? 50 : 75;
  const defaults = {
    width: size === 'small'
      ? listIconSize : itemSize,
    height: size === 'small'
      ? listIconSize : itemSize
  };
  const item = ownProps.item;
  if (item.type === 'collection') {
    item.type = 'curation';
  }
  if (!item.type) {
    item.type = item.id ? 'curation' : 'product';
  }
  const style = {
    ...defaults,
    float: (size === 'small' && curationListViewType === 'curation') ? 'left' : 'none'
  };
  if (item.type === 'product') {
    style.backgroundImage = `url(${item.$imageSrc || defaultProduct})`;
  }
  if (item.type === 'curation') {
    item.items = item.items || [];
  }
  return {
    item,
    type: ownProps.type,
    list: ownProps.list,
    index: ownProps.index,
    curationListView: curationListViewType,
    style: { container: style, text: size === 'large', size },
    ui: state.ui || {},
    NoOfCoulmns,
    loaders: state.loaders || {}
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    actions: bindActionCreators({
      ...actions,
      ...parentActions,
      ...reviewItemActions
    }, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Item);
