import * as types from '../../../../../../constants/ActionTypes';
import * as api from '../../../../../../util/api';

export function getCollectionsLists({ listId, newPage = 0 }) {
  return (dispatch, getState) => {
    const startIndex = (Math.ceil(newPage) * 24);

    const state = getState();
    const list = state.curationList.lists.find(l => l.id === listId);
    dispatch({ type: types.INITIATE_LOADER, loader: 'collections-list' });

    return api.getAssociatedCollections({ listId, startIndex }).then(response => {
      let collections = response.Collections ? response.Collections : {};
      if (collections && collections.length >= 0) {
        dispatch({ type: types.LIST_COLLECTIONS_FROM_CURATION, collections });
      }
    }).catch(e => {
      console.error(e);
    });
  };
}