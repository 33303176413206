import React, { Component } from 'react';
import PropTypes from 'prop-types';


class ImgItemPreview extends Component {

  render() {
    const {
      item,
      width,
      height
    } = this.props;
    return (<div
      style={{ width, height }}
      className="curation-list__preview-images"
    >
    {item.$previewImages.map((previewImage, i) => {
      let s = {
        right: ((item.$previewImages.length - 1) * 3) - ((i - 1) * 3) + 'px',
        bottom: ((item.$previewImages.length - 1) * 3) - ((i - 1) * 3) + 'px',
        width: (width - 9) + 'px',
        height: (height - 9) + 'px'
      };
      return (
        <img
          style={s}
          className="curation-list__preview-image"
          role="presentation"
          key={i}
          src={previewImage.src}
        />
      );
    })}
    <div
      style={{
        width: (width - 9) + 'px',
        height: (height - 9) + 'px',
        backgroundImage: `url(${item.$frontPreViewImage.src})`,
      }}
      className="review-list__front-preview--image"
    >
    </div>
  </div>);
  }
}

ImgItemPreview.propTypes = {
  item: PropTypes.object,
  width: PropTypes.number,
  height: PropTypes.number
};

export default ImgItemPreview;
