/*eslint-disable*/
export default [{"itemId":"2000001","imageSrc":"//www.homedepot.com/hdus/en_US/DTCCOMNEW/fetch/collections/staticImages/patio_collection_Alveranda/17PATIO-207189496-ALVERANDA-SILO.jpg","title":"product title","modelNumber":"1232","price":"12.20"},{"itemId":"2000002","imageSrc":"//www.homedepot.com/hdus/en_US/DTCCOMNEW/fetch/collections/staticImages/patio_collection_Alveranda/17PATIO-207189496-ALVERANDA-SILO.jpg","title":"product title","modelNumber":"1232","price":"12.20"},{"itemId":"2000003","imageSrc":"//www.homedepot.com/hdus/en_US/DTCCOMNEW/fetch/collections/staticImages/patio_collection_Alveranda/17PATIO-207189496-ALVERANDA-SILO.jpg","title":"product title","modelNumber":"1232","price":"12.20"},{"itemId":"2000004","imageSrc":"//www.homedepot.com/hdus/en_US/DTCCOMNEW/fetch/collections/staticImages/patio_collection_Alveranda/17PATIO-207189496-ALVERANDA-SILO.jpg","title":"product title","modelNumber":"1232","price":"12.20"},{"itemId":"2000005","imageSrc":"//www.homedepot.com/hdus/en_US/DTCCOMNEW/fetch/collections/staticImages/patio_collection_Alveranda/17PATIO-207189496-ALVERANDA-SILO.jpg","title":"product title","modelNumber":"1232","price":"12.20"},
{"itemId":"2000006","imageSrc":"//www.homedepot.com/hdus/en_US/DTCCOMNEW/fetch/collections/staticImages/patio_collection_Alveranda/17PATIO-207189496-ALVERANDA-SILO.jpg","title":"product title","modelNumber":"1232","price":"12.20"},
{"itemId":"2000007","imageSrc":"//www.homedepot.com/hdus/en_US/DTCCOMNEW/fetch/collections/staticImages/patio_collection_Alveranda/17PATIO-207189496-ALVERANDA-SILO.jpg","title":"product title","modelNumber":"1232","price":"12.20"},
{"itemId":"2000008","imageSrc":"//www.homedepot.com/hdus/en_US/DTCCOMNEW/fetch/collections/staticImages/patio_collection_Alveranda/17PATIO-207189496-ALVERANDA-SILO.jpg","title":"product title","modelNumber":"1232","price":"12.20"},
{"itemId":"2000009","imageSrc":"//www.homedepot.com/hdus/en_US/DTCCOMNEW/fetch/collections/staticImages/patio_collection_Alveranda/17PATIO-207189496-ALVERANDA-SILO.jpg","title":"product title","modelNumber":"1232","price":"12.20"},
{"itemId":"20000010","imageSrc":"//www.homedepot.com/hdus/en_US/DTCCOMNEW/fetch/collections/staticImages/patio_collection_Alveranda/17PATIO-207189496-ALVERANDA-SILO.jpg","title":"product title","modelNumber":"1232","price":"12.20"},
{"itemId":"20000011","imageSrc":"//www.homedepot.com/hdus/en_US/DTCCOMNEW/fetch/collections/staticImages/patio_collection_Alveranda/17PATIO-207189496-ALVERANDA-SILO.jpg","title":"product title","modelNumber":"1232","price":"12.20"},
{"itemId":"20000012","imageSrc":"//www.homedepot.com/hdus/en_US/DTCCOMNEW/fetch/collections/staticImages/patio_collection_Alveranda/17PATIO-207189496-ALVERANDA-SILO.jpg","title":"product title","modelNumber":"1232","price":"12.20"},
{"itemId":"20000013","imageSrc":"//www.homedepot.com/hdus/en_US/DTCCOMNEW/fetch/collections/staticImages/patio_collection_Alveranda/17PATIO-207189496-ALVERANDA-SILO.jpg","title":"product title","modelNumber":"1232","price":"12.20"},
{"itemId":"20000014","imageSrc":"//www.homedepot.com/hdus/en_US/DTCCOMNEW/fetch/collections/staticImages/patio_collection_Alveranda/17PATIO-207189496-ALVERANDA-SILO.jpg","title":"product title","modelNumber":"1232","price":"12.20"},
{"itemId":"20000015","imageSrc":"//www.homedepot.com/hdus/en_US/DTCCOMNEW/fetch/collections/staticImages/patio_collection_Alveranda/17PATIO-207189496-ALVERANDA-SILO.jpg","title":"product title","modelNumber":"1232","price":"12.20"},
{"itemId":"20000016","imageSrc":"//www.homedepot.com/hdus/en_US/DTCCOMNEW/fetch/collections/staticImages/patio_collection_Alveranda/17PATIO-207189496-ALVERANDA-SILO.jpg","title":"product title","modelNumber":"1232","price":"12.20"},
{"itemId":"20000017","imageSrc":"//www.homedepot.com/hdus/en_US/DTCCOMNEW/fetch/collections/staticImages/patio_collection_Alveranda/17PATIO-207189496-ALVERANDA-SILO.jpg","title":"product title","modelNumber":"1232","price":"12.20"},
{"itemId":"20000018","imageSrc":"//www.homedepot.com/hdus/en_US/DTCCOMNEW/fetch/collections/staticImages/patio_collection_Alveranda/17PATIO-207189496-ALVERANDA-SILO.jpg","title":"product title","modelNumber":"1232","price":"12.20"}]