import React, { Component } from 'react';
import PropTypes from 'prop-types';

class CurationForm extends Component {
  render() {
    const {
      actions,
      keyword,
      listType
    } = this.props;
    return (
      <div className="curationForm__textWrapper input-group--inline">
        <form
          onSubmit={e => {
            e.preventDefault();
            actions.filterCurations({ keyword, type: listType });
          }}
        >
          <input
            type="text"
            id="keyword"
            name="keyword"
            className="form-input__field input-group--inline__input"
            placeholder={`${listType} search`}
            value={keyword}
            onChange={e => {
              e.preventDefault();
              actions.setCurationFilterKeyword({ keyword: e.target.value });
            }}
          />
          <button className="bttn input-group--inline__bttn">
            <span className="mw-icon-search bttn__content" />
          </button>
        </form>
      </div>
    );
  }
}

CurationForm.propTypes = {
  actions: PropTypes.object,
  keyword: PropTypes.string,
  listType: PropTypes.string
};

export default CurationForm;