import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ColumnHeader extends Component {
  render() {
    const {
      _sortName,
      _label,
      _className,
      actions,
      sortName,
      sortType,
      listType
    } = this.props;
    return (
      <div
        className={`${listType === 'collection' ? 'col__1-8' : 'col__1-9'}
          curationList__cell ${_className} ${
          sortName === _sortName ? 'curationList__Sort--Header' : ''
        }`}
      >
        <a
          onClick={e => {
            e.preventDefault();
            let sort = 'asc';
            if (sortName === _sortName && sortType === 'asc') {
              sort = 'desc';
            }
            if (actions) {
              actions.sortList({ sortName: _sortName, sortType: sort, listType });
            }
          }}
        >
          <h3 className="curationListHeader__title">
            {typeof _label === 'object' ? _label.map((item, i) => <p key={i}>{item}</p>) : _label}
          </h3>
          <i
            className={`fa ${
              sortName === _sortName && sortType === 'desc' ? 'fa-chevron-down' : 'fa-chevron-up'
            } curationList__sort`}
            aria-hidden="true"
          />
        </a>
      </div>
    );
  }
}

ColumnHeader.propTypes = {
  _sortName: PropTypes.string,
  _label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object
  ]),
  _className: PropTypes.string,
  actions: PropTypes.object,
  sortName: PropTypes.string,
  sortType: PropTypes.string,
  listType: PropTypes.string
};

export default ColumnHeader;