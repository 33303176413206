import * as types from '../../../../../../../constants/ActionTypes';
import * as api from '../../../../../../../util/api';
import { getJoinArrayElements } from "../../../../../../../util";
import { serializeRemoveItem, getSelectedCurationCount } from '../../MaintenanceDetails.reducer';

const loadProductCurations = ({ response, state, dispatch }) => {
  const searchReport = {
    pageSize: response.searchReport.pageSize,
    startIndex: response.searchReport.startIndex,
    totalCurations: response.searchReport.totalCurations,
    totalCollections: response.searchReport.totalCollections,
    $visiblePages: 5
  };

  const payload = {
    type: types.LOAD_PRODUCT_CURATIONS,
    curations: response.curations,
    isSelected: state.maintenanceDetails.allSelectedCuration,
    searchReport,
    status: response.status ? response.status : {}
  };
  dispatch(payload);
};

export const getProductDetails = ({ productId }) => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch({
      type: types.INITIATE_LOADER,
      loader: 'product-details'
    });
    return api
      .getProductAssociatedCurations({ itemId: productId })
      .then(response => {
        loadProductCurations({ response, state, dispatch });
        dispatch({ type: types.REMOVE_LOADER, loader: 'product-details' });
      })
      .catch(e => {
        console.error(e);
        dispatch({ type: types.REMOVE_LOADER, loader: 'product-details' });
      });
  };
};

export const toggleCuration = ({ isSelected, id }) => {
  return (dispatch, getState) => {

    dispatch({ type: types.TOGGLE_CURATION, id, isSelected });
    dispatch({ type: types.SET_WINDOW_SCROLL_TOP, windowScroll: false });

    dispatch(
      { type: types.TOGGLE_UN_SELECT_ALL_CURATIONS,
        allSelectedCuration: false
      });

  };
};

export const removeItemFromCurations = ({ itemId }) => {

  return (dispatch, getState) => {
    const state = getState();
    const curationCount = getSelectedCurationCount({ state });
    const answer = confirm(`You are about to remove this item from ${curationCount} curation(s), are you sure?`); // eslint-disable-line

    if (answer) {
      const userAction = 'remove';
      const removedItemSerialize = serializeRemoveItem({ state, itemId, userAction });
      dispatch({ type: types.SET_WINDOW_SCROLL_TOP, windowScroll: true });
      dispatch({ type: types.INITIATE_LOADER, loader: 'removeItemLoader' });
      api
       .updateProductInCuration(
        {
          itemId: removedItemSerialize.itemId,
          curationIds: removedItemSerialize.curationIds,
          allCurations: removedItemSerialize.allCurations,
          userAction: removedItemSerialize.userAction,
          replaceItemId: removedItemSerialize.replaceItemId
        })
        .then(response => {
          loadProductCurations({ response, state, dispatch });
          const publishedCurations = response.status
                                && response.status.activeModeCurationsUpdatedAndPublished
                                  ? response.status.activeModeCurationsUpdatedAndPublished
                                  : [];

          const editModeNonPublishedCurations = response.status
                                  && response.status.editModeCurationsUpdatedAndNotPublished
                                    ? response.status.editModeCurationsUpdatedAndNotPublished
                                    : [];

          const inactiveModeNonPublishedCurations = response.status
                                    && response.status.inActiveModeCurationsUpdatedAndNotPublished
                                      ? response.status.inActiveModeCurationsUpdatedAndNotPublished
                                      : [];

          const infoMessage = `Product ${removedItemSerialize.itemId} has been removed ` +
              `from ${curationCount} curation(s). ` +
             `${publishedCurations.length} curation(s) were republished.` +
             ` ${editModeNonPublishedCurations.length} curation(s) remain in edit status, and` +
           ` ${inactiveModeNonPublishedCurations.length} curation(s) remain in inactive status.`;

          dispatch({ type: types.SET_PRODUCT_CURATION_INFO_MESSAGE, infoMessage });

          dispatch({ type: types.REMOVE_LOADER, loader: 'removeItemLoader' });
        })
        .catch(e => {
          console.error(e);
          dispatch({ type: types.REMOVE_LOADER, loader: 'removeItemLoader' });
        });
    }
    return '';
  };
};

export const handlePageChanged = ({ itemId, newPage }) => {
  return (dispatch, getState) => {
    const state = getState();
    const startIndex = (Math.ceil(newPage) * 24);
    dispatch({
      type: types.INITIATE_LOADER,
      loader: 'product-details'
    });
    dispatch({ type: types.SET_WINDOW_SCROLL_TOP, windowScroll: true });
    return api
      .getProductAssociatedCurations({ itemId, startIndex })
      .then(response => {
        loadProductCurations({ response, state, dispatch });
        dispatch({ type: types.REMOVE_LOADER, loader: 'product-details' });
      })
      .catch(e => {
        console.error(e);
        dispatch({ type: types.REMOVE_LOADER, loader: 'product-details' });
      });
  };
};

export const toggleSelectAllCurations = ({ allSelectedCuration }) => {
  return (dispatch) => {
    dispatch({ type: types.TOGGLE_SELECT_ALL_CURATIONS, allSelectedCuration });
    dispatch({ type: types.SET_WINDOW_SCROLL_TOP, windowScroll: false });
  };
};

export const toggleSimilarItems = ({ showSimilarItems }) => {
  return (dispatch) => {
    dispatch({ type: types.TOGGLE_MAINTENANCE_SIMILAR_ITEMS, showSimilarItems });
    dispatch({ type: types.SET_WINDOW_SCROLL_TOP, windowScroll: false });
  };
};