import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import * as actions from './Classifications.actions';
import ClassficationsComponent from './Classfications.component';


const mapStateToProps = (state, ownProps) => {

  const skuClassifications = state.classification && state.classification.skuClassification
                             ? state.classification.skuClassification
                             : [];

  const userClassifications = state.classification && state.classification.userClassification
                              ? state.classification.userClassification
                              : [];

  const errorMessage = state.classification && state.classification.errorMessage
                       ? state.classification.errorMessage
                       : '';

  const infoMessage = state.classification && state.classification.infoMessage
                      ? state.classification.infoMessage
                      : '';

  const isAdminUser = state.app.user.isAdmin || false;
  return {
    loaders: state.app.loaders,
    skuClassifications,
    userClassifications,
    errorMessage,
    infoMessage,
    isAdminUser
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    actions: bindActionCreators({
      ...actions
    }, dispatch)
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ClassficationsComponent)
);