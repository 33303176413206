module.exports = [{
  id: 'r1',
  position: 0,
  type: 'refinement',
  name: 'Category',
  attributes: [
    { name: 'Lighting', value: 123 },
    { name: 'Seating', value: 18 },
    { name: 'Tables', value: 12 },
    { name: 'Shelving & Storage', value: 0 },
    { name: 'Home Accents', value: 32 },
    { name: 'Pillows + Throws', value: 129 },
    { name: 'Rugs', value: 22 },
    { name: 'Kids', value: 62 },
    { name: 'Mirrors', value: 87 },
    { name: 'Wallpaper & Borders', value: 122 },
    { name: 'Wall Art', value: 154 },
    { name: 'Wall Decor - Other', value: 187 },
    { name: 'Bedding & Bath', value: 197 },
    { name: 'Bed Frames', value: 164 },
    { name: 'Tableware & Bar', value: 119 },
    { name: 'Kitchen Storage & Organization', value: 183 },
    { name: 'Small Appliances', value: 192 },
    { name: 'Cooking & Food Preparation', value: 174 }
  ]
}, {
  id: 'r2',
  position: 0,
  type: 'refinement',
  name: 'Price Point',
  attributes: [
    { name: '$0 - $25', value: 93 },
    { name: '$25 - $50', value: 86 },
    { name: '$50 - $100', value: 17 },
    { name: '$100 - $200', value: 87 },
    { name: '$200 - $500', value: 76 },
    { name: '$500- $750', value: 53 },
    { name: '$750 - $1000', value: 98 },
    { name: '$1000 - $1500', value: 76 },
    { name: '$1500 - $2000', value: 239 },
    { name: '$2000 +', value: 200 }
  ]
},
{
  id: 'r3',
  position: 0,
  type: 'refinement',
  name: 'Material',
  attributes: [
    { name: 'Crystal', value: 111 },
    { name: 'Stone', value: 100 },
    { name: 'Stone Marble', value: 93 },
    { name: 'Stone Granite', value: 276 },
    { name: 'Metal', value: 239 },
    { name: 'Metal Iron', value: 187 },
    { name: 'Metal Brass', value: 194 },
    { name: 'Metal Copper', value: 201 },
    { name: 'Metal Aluminum', value: 239 },
    { name: 'Metal Steel', value: 231 },
    { name: 'Metal Steel Stainless', value: 214 },
    { name: 'Metal Pewter', value: 435 },
    { name: 'Metal Silver', value: 234 },
    { name: 'Metal Chrome', value: 654 },
    { name: 'Metal Bronze', value: 345 },
    { name: 'Metal Nickel', value: 164 },
    { name: 'Metal Gold', value: 261 },
    { name: 'Metal Zinc', value: 167 },
    { name: 'Wood', value: 163 },
    { name: 'Wood Faux', value: 278 },
    { name: 'Wood Faux Wood MDF', value: 257 },
    { name: 'Wood Faux Wood Particle Board', value: 163 },
    { name: 'Wood Solid', value: 231 },
    { name: 'Wood Plywood', value: 198 },
    { name: 'Wood Driftwood', value: 154 },
    { name: 'Wood_Walnut', value: 109 },
    { name: 'Wood_Pine', value: 103 },
    { name: 'Wood_Mahogany', value: 181 },
    { name: 'Wood_Teak', value: 160 },
    { name: 'Wood_Birch', value: 117 },
    { name: 'Wood_Cherry', value: 11 },
    { name: 'Wood_Ash', value: 93 },
    { name: 'Wood_Maple', value: 13 },
    { name: 'Wood_Bamboo', value: 23 },
    { name: 'Wood_Oak', value: 98 },
    { name: 'Wood_Spruce', value: 8 },
    { name: 'Wood_Cedar', value: 9 },
    { name: 'Wood_Larch', value: 98 },
    { name: 'Wood_Fir', value: 876 },
    { name: 'Wood_Beech', value: 298 },
    { name: 'Textile', value: 651 },
    { name: 'Textile_Wool', value: 276 },
    { name: 'Textile_Linen', value: 163 },
    { name: 'Textile_Silk', value: 163 },
    { name: 'Textile_Cotton', value: 163 },
    { name: 'Textile_Canvas', value: 163 },
    { name: 'Textile_Denim', value: 163 },
    { name: 'Textile_Burlap', value: 163 },
    { name: 'Textile_Leather', value: 163 },
    { name: 'Textile_Faux Leather', value: 163 },
    { name: 'Textile_Velvet', value: 163 },
    { name: 'Textile_Felt', value: 163 },
    { name: 'Texile_Synthetic', value: 163 },
    { name: 'Textile_Nylon', value: 163 },
    { name: 'Textile_Olefin', value: 163 },
    { name: 'Textile_Polyester', value: 163 },
    { name: 'Hide', value: 163 },
    { name: 'Fur', value: 163 },
    { name: 'Rope', value: 163 },
    { name: 'Seashell', value: 163 },
    { name: 'Lacquer', value: 163 },
    { name: 'Lucite', value: 163 },
    { name: 'Acrylic', value: 163 },
    { name: 'Mirror', value: 163 },
    { name: 'Recycled', value: 163 },
    { name: 'Cork', value: 163 },
    { name: 'Chalk', value: 163 },
    { name: 'Concrete', value: 163 },
    { name: 'Rattan', value: 163 },
    { name: 'Jute', value: 163 },
    { name: 'Brick', value: 163 },
    { name: 'Terracotta', value: 163 },
    { name: 'Reclaimed', value: 163 },
    { name: 'Wicker', value: 163 },
    { name: 'Plastic', value: 163 },
    { name: 'Ceramic', value: 163 },
    { name: 'Ceramic Porcelain', value: 163 },
    { name: 'Ceramic Vitreous China', value: 163 },
    { name: 'Ceramic Earthenware', value: 163 },
    { name: 'Ceramic Stoneware', value: 163 },
    { name: 'Laminate', value: 163 },
    { name: 'Glass', value: 163 },
    { name: 'Glass_Sea', value: 163 },
    { name: 'Glass_Stained', value: 163 },
    { name: 'Clay', value: 163 },
    { name: 'Paper', value: 163 },
    { name: 'Fiberglass', value: 163 },
    { name: 'Melamine', value: 163 },
    { name: 'Rubber', value: 163 },
    { name: 'Resin', value: 163 },
    { name: 'Vinyl', value: 163 }
  ]
}, {
  id: 'r4',
  position: 0,
  type: 'refinement',
  name: 'Finish',
  attributes: [
    { name: 'Textured', value: 112 },
    { name: 'Hammered', value: 65 },
    { name: 'Galvanized', value: 174 },
    { name: 'Patina', value: 13 },
    { name: 'Semi-Gloss', value: 98 },
    { name: 'Patterned', value: 76 },
    { name: 'Whitewashed', value: 74 },
    { name: 'Distressed', value: 102 },
    { name: 'Handscraped', value: 184 },
    { name: 'Stained', value: 184 },
    { name: 'Painted', value: 145 },
    { name: 'Laminate', value: 764 },
    { name: 'Thermofoil Wrap', value: 283 },
    { name: 'Unfinished', value: 983 },
    { name: 'Glazed', value: 273 },
    { name: 'Polished', value: 184 },
    { name: 'Antiqued', value: 673 },
    { name: 'Unlacquered', value: 398 },
    { name: 'Oil Rubbed', value: 276 },
    { name: 'Rubbed', value: 162 },
    { name: 'Finished', value: 123 },
    { name: 'Matte', value: 133 },
    { name: 'Clear', value: 143 },
    { name: 'Stainless', value: 143 },
    { name: 'Frosted', value: 113 },
    { name: 'Honed', value: 143 },
    { name: 'Obscured', value: 173 },
    { name: 'Prefinished', value: 161 },
    { name: 'Brushed', value: 191 },
    { name: 'Smooth', value: 123 },
    { name: 'High Sheen', value: 103 },
    { name: 'Low Sheen', value: 113 },
    { name: 'Gloss', value: 109 }
  ]
}
];