import React, { Component } from 'react';
import PropTypes from 'prop-types';
import HeaderContainer from '../../../../modules/Header';
import '../style.scss';
import { Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis } from "recharts";

const colors = ['#F96302', '#00AE4D', '#FFC20E', '#286FAD', '#CCCCCC', '#ED1C24', '#003417'];
class UserClassificationDetailsComponent extends Component {


  componentDidMount() {
    const { actions, userId } = this.props;
    actions.getUserClassificationDetailsById({ userId });
    document.title = 'User Classification Details By Id';
  }

  mapDimensionToBarChart(dimension) {
    const data = dimension.map(attr => {
      return {
        name: attr.name,
        [attr.name]: attr.weight
      };
    });
    return data;
  }

  render() {
    const {
      loaders,
      styleClassificationsById,
      roomClassificationsById,
      roomDimensions,
      styleDimensions,
      userId
    } = this.props;

    return (
      <div className="grid curationizer-main">
        <HeaderContainer />
        <div className="grid userClassifications">
          <div className="col__12-12">
            <div className="col__12-12 classifications-details">
              <div className="grid">
                <div className="col__12-12 classifications-type">
                  User Classification Details By {userId} and Dimension Room
                </div>
                {loaders['load-user-classification-details-by-id'] &&
                <div className="col__12-12 curationList-loader">
                  <div className="pre-loader">
                    <div className="segment-spinner" />
                  </div>
                </div>}
                {roomClassificationsById && roomClassificationsById.attributes
                && <div className="col__9-12">

                  <BarChart width={1000}
                    height={400}
                    data={this.mapDimensionToBarChart(roomDimensions)}
                    margin={{ top: 5, right: 30, left: 20, bottom: 10 }}
                    layout="vertical"
                    barSize={50}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <YAxis dataKey="name"
                      type="category"
                      style={{ fontSize: 10 }}
                      interval={0}
                      tick
                    />
                    <XAxis
                      type="number"
                      style={{ fontSize: 10 }}
                      interval={0}
                      tick
                      domain={[0, 100]}
                    />
                    <Tooltip />
                    {
                      roomDimensions.map((attr, index) => {
                        return (<Bar dataKey={attr.name}
                          fill={colors[index]}
                          key={index}
                        />);
                      })
                    }
                  </BarChart>
                </div>}

                {roomClassificationsById && roomDimensions && roomDimensions.length > 0
                && <div className="col__3-12">
                  <div className="col__12-12 classifications-header">
                    <div className="classifications-fields">
                      Classification Name
                    </div>

                    <div className="classifications-fields">
                      Classification %
                    </div>
                  </div>

                  <div className="col__12-12 classification-rows">
                    <div className="curationListBody grid">
                      <div className="col__12-12">
                        {roomClassificationsById && roomDimensions && roomDimensions.length > 0
                        && roomDimensions.map((classification, index) => {
                          return (
                            <div className={`grid ${index % 2 === 0 ?
                              'curationList__body' : ''}`}
                              key={classification.id}
                            >
                              <div className="classification-cells">
                                <h5>
                                  {classification.name}
                                </h5>
                              </div>
                              <div className="classification-cells">
                                {classification.weight}
                              </div>
                            </div>
                          );
                        })

                        }
                      </div>
                    </div>
                  </div>
                </div>}
                <div className="col__12-12 classifications-type">
                  User Classification Details By {userId} and Dimension Style
                </div>
                {styleClassificationsById && styleDimensions && styleDimensions.length > 0
                && <div className="col__9-12">

                  <BarChart width={1000}
                    height={600}
                    data={this.mapDimensionToBarChart(styleDimensions)}
                    margin={{ top: 5, right: 30, left: 20, bottom: 10 }}
                    barSize={60}
                    layout="vertical"
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <YAxis dataKey="name"
                      type="category"
                      style={{ fontSize: 10 }}
                      interval={0}
                      tick
                    />
                    <XAxis
                      type="number"
                      style={{ fontSize: 10 }}
                      interval={0}
                      tick
                      domain={[0, 100]}
                    />
                    <Tooltip />
                    {
                      styleDimensions.map((attr, index) => {
                        return (<Bar dataKey={attr.name}
                          fill={colors[index]}
                          key={index}
                        />);
                      })
                    }
                  </BarChart>
                </div>}
                {styleClassificationsById && styleDimensions && styleDimensions.length > 0
                && <div className="col__3-12">
                  <div className="col__12-12 classifications-header">
                    <div className="classifications-fields">
                      Classification Name
                    </div>

                    <div className="classifications-fields">
                      Classification %
                    </div>
                  </div>

                  <div className="col__12-12 classification-rows">
                    <div className="curationListBody grid">
                      <div className="col__12-12">
                        {styleClassificationsById && styleDimensions && styleDimensions.length > 0
                        && styleDimensions.map((classification, index) => {
                          return (
                            <div className={`grid ${index % 2 === 0 ?
                              'curationList__body' : ''}`}
                              key={classification.id}
                            >
                              <div className="classification-cells">
                                <h5>
                                  {classification.name}
                                </h5>
                              </div>
                              <div className="classification-cells">
                                {classification.weight}
                              </div>
                            </div>
                          );
                        })

                        }
                      </div>
                    </div>
                  </div>
                </div>}

              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

UserClassificationDetailsComponent.propTypes = {
  loaders: PropTypes.object,
  userId: PropTypes.string,
  actions: PropTypes.object,
  styleClassificationsById: PropTypes.object,
  roomClassificationsById: PropTypes.object,
  roomDimensions: PropTypes.array,
  styleDimensions: PropTypes.array
};

export default UserClassificationDetailsComponent;
