import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InlineMessage from '../../../modules/InlineMessage/component';

class CurateListHeader extends Component {
  render() {
    const {
      list,
      errors,
      actions,
      DAVINCI_DESCRIPTION_ERR
    } = this.props;
    return (
      <div>
        <div className="curate-list__header">
              <div>
              <span>
                <label className="form-input__label" htmlFor="curation_name">
                  <span className="form-input__label-content">{list.type} Name
                  </span>
                  <span className="red"> (Required)</span>
                </label>
                <input
                  type="text"
                  className={`form-input__field ${list.name === '' ? 'curation_name--error' : ''}`}
                  placeholder="Enter the name of your curation here..."
                  maxLength="99"
                  name="curation_name"
                  disabled={!list.$canEdit}
                  defaultValue={list.name || ''}
                  onChange={(e) => {
                    e.preventDefault();
                    const name = e.target.value;
                    if (list.name === name) {
                      alert('This name has already been used. Please use a different name.'); // eslint-disable-line
                    }
                    if (list.name !== name) {
                      actions.saveCurationName({ name });
                    }
                  }}
                  onBlur={(e) => {
                    const name = e.target.value;
                    actions.updateCurationName({ curationId: list.id, name });
                  }}
                />
              </span>
              {list.type=="collection" &&
              <span>
                <label className="form-input__label" htmlFor="curation_name">
                  <span className="form-input__label-content">Internal Name
                  </span>
                </label>
                <input
                  type="text"
                  className=""
                  className={`form-input__field ${list.internalRoomName === '' ? 'curation_name--error' : ''}`}
                  placeholder="Enter the internal name"
                  maxLength="99"
                  name="internal_name"
                  disabled={!list.$canEdit}
                  defaultValue={list.internalRoomName || ''}
                  onChange={(e) => {
                    e.preventDefault();
                    const internalRoomName = e.target.value;
                    if (list.internalRoomName === internalRoomName) {
                      alert('This name has already been used. Please use a different name.'); // eslint-disable-line
                    }
                    if (list.internalRoomName !== internalRoomName) {
                      actions.saveinternalRoomName({ internalRoomName });
                    }
                  }}
                  onBlur={(e) => {
                    const internalRoomName = e.target.value;
                    actions.updateInternalRoomName({ curationId: list.id, internalRoomName });
                  }}
                />
              </span>
              }
            </div>
          </div>
          <div className="curate-list__header">
            <div className="">
            <span className="form-input">
              <label className="form-input__label" htmlFor="curation_description">
                <span className="form-input__label-content">{list.type} Description</span>
              </label>
              {errors.length > 0 &&
                <div className="grid flush">
                  {errors.filter(error => {
                    return error.errorCode === DAVINCI_DESCRIPTION_ERR;
                  }).map((err, i) => {
                    return (<InlineMessage type="danger" key={i}
                      message={err.description}
                    />);
                  })}
                </div>
              }
              <textarea
                type="text"
                className="form-input__field reviewList__description--edit"
                placeholder="Describe your curation here..."
                name="curation_description"
                rows="8"
                maxLength={list.type === 'curation' ? 1600 : 800}
                disabled={!list.$canEdit}
                defaultValue={list.description}
                onBlur={(e) => {
                  e.preventDefault();
                  const description = e.target.value;
                  actions.updateCurationDescription({ curationId: list.id, description });
                }}
              ></textarea>
            </span>
            </div>
          </div>
      </div>
    );
  }
}

CurateListHeader.propTypes = {
  list: PropTypes.object,
  actions: PropTypes.object,
  errors: PropTypes.array,
  DAVINCI_DESCRIPTION_ERR: PropTypes.number
};

export default CurateListHeader;
