import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DragSource } from 'react-dnd';
import ItemContainer from './index';

const spec = {
  beginDrag(props, dnd, component) {
    const self = this;
    if (props.item._selected) {
      component.props.connectDragPreview(props.multiItemPreview);
    } else {
      component.props.connectDragPreview(component.el);
    }
    return { ...props };
  }
};

const collect = (connect, monitor) => {
  const item = monitor.getItem();
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging()
  };
};

class ItemDragSourceComponent extends Component {
  render() {
    let { connectDragSource, isDragging, item, type, index, list } = this.props;
    const canDrag = !item._added;
    const opacity = (isDragging || !canDrag) ? 0.5 : 1;
    let className = ['item-drag'];
    if (item.$inCuration && type !== 'set') {
      connectDragSource = el => el;
      className.push('item-drag--off');
    }
    if (item.$inCuration && type !== 'set') {
      className.push('item-drag--in-curation');
    }
    if (isDragging) {
      className.push('item-drag--is-dragging');
    }
    return connectDragSource(<div
      style={{ width: '100%' }}
      className={className.join(' ')}
      ref={(el) => {
        this.el = el;
      }}
    >
      <ItemContainer item={item} {...this.props} />
    </div>);
  }
}

ItemDragSourceComponent.propTypes = {
  props: PropTypes.shape({
    item: PropTypes.object
  }),
  canDrag: PropTypes.bool,
  item: PropTypes.object,
  connectDragSource: PropTypes.func.isRequired,
  connectDragPreview: PropTypes.func.isRequired,
  isDragging: PropTypes.bool.isRequired,
  type: PropTypes.string,
  itemView: PropTypes.number,
  index: PropTypes.number,
  list: PropTypes.object
};


export default DragSource(// eslint-disable-line new-cap
  'DRAG_SOURCE', spec, collect
)(ItemDragSourceComponent);
// export default CuratorResultsItemComponent;
