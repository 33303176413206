import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from './actions';
import { getsearchTaglist, getAppliedTags } from '../../App/CurationList/reducer';
import Tag from './component';

const mapStateToProps = (state, ownProps) => {
  const tags = state.app.meta.tags;
  const listId = ownProps.listId;
  const listType = ownProps.listType;
  const type = ownProps.type;
  const inputTagValue = state.ui.tag.inputTagValue;
  let tagSearchList = [];
  let appliedTags = [];
  let isTagInputFocus = state.ui.tag.isTagInputFocus;
  if (inputTagValue !== '' || isTagInputFocus) {
    tagSearchList = getsearchTaglist({ state, listId, type, inputTagValue, isTagInputFocus });
  }
  let selectedList = state.curationList.lists.find(list => {
    return (list.id === listId);
  });
  let categoryName = (tags || []).find(tag => {
    return (tag.id === state.ui.tag.selectedTagCategory);
  });
  categoryName = categoryName ? categoryName.name : 'All';
  appliedTags = getAppliedTags({ state, listId, type });
  return {
    listId,
    tags,
    type,
    tagSearchList,
    isSearchTagsToShow: tagSearchList.length > 0,
    isTagCategoryListToShow: state.ui.tag.showCategoryDropDown,
    inputTagValue,
    appliedTags,
    selectedCategoryName: categoryName,
    loaders: state.loaders || {},
    disabled: ownProps.disabled,
    listType
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    actions: bindActionCreators({
      ...actions
    }, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Tag);
