module.exports = {
  itemAnalytics: [
    {
      id: 'p1',
      type: 'stat',
      name: 'Total Units Sold',
      value: '105.2K',
      days: [{
        period: 30,
        value: '323'
      },
      {
        period: 90,
        value: '985'
      }]
    }, {
      id: 'p2',
      type: 'stat',
      name: 'Total Page Views',
      value: '102.3K',
      days: [{
        period: 30,
        value: '10K'
      },
      {
        period: 90,
        value: '90.4K'
      }]
    },
    {
      id: 'p3',
      type: 'price',
      name: 'Total Item Revenue',
      value: '125.5K',
      days: [{
        period: 30,
        value: '1.1K'
      },
      {
        period: 90,
        value: '1.8K'
      }]
    },
    {
      id: 'p4',
      type: 'price',
      name: 'Revenue per Page View',
      value: '122.67',
      days: [{
        period: 30,
        value: '121.85'
      },
      {
        period: 90,
        value: '123.15'
      }]
    },
    {
      id: 'p6',
      type: 'session',
      name: 'Average Session Duration',
      value: '258'
    },
  ]
};