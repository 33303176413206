import * as types from '../../../../../constants/ActionTypes';
import defaultProduct from '../../../../../assets/images/default-product.jpg';

const initialState = {
  meta: {
    tags: [],
    dimensions: []
  },
  item: {},
  allSelectedCuration: false,
  infoMessage: '',
  windowScroll: false
};

const updateImageSrc = ({ data, size = '400' }) => {
  let path = (data.info && Object.keys(data.info).length)
    ? data.info.imageUrl
    : defaultProduct;
  return path.replace(/<SIZE>/g, size);
};

export const isAllSelected = ({ curations }) => {
  const getUnSelectedCuration = curations.filter(curation => !curation.isSelected);
  if (getUnSelectedCuration && getUnSelectedCuration.length > 0) {
    return false;
  }
  return true;
};

export const getSelectedCurationCount = ({ state }) => {
  let selectedCurationCount = 0;
  if (state.maintenanceDetails.allSelectedCuration) {
    selectedCurationCount = state.maintenanceDetails.searchReport.totalCurations
                            ? state.maintenanceDetails.searchReport.totalCurations
                            : 0;
  } else {
    const selectedCuration = (state.maintenanceDetails.curations || [])
                              .filter(curation => curation.isSelected);
    selectedCurationCount = selectedCuration.length;
  }
  return selectedCurationCount;
};

export const serializeRemoveItem = ({ state, itemId, userAction }) => {
  const allCurations = state.maintenanceDetails.allSelectedCuration;
  let curationIds = [];
  let replaceItemId = '';
  if (userAction === 'replace') {
    replaceItemId = state.maintenanceDetails.similarItems.find(item => item.isSelected).itemId;
  }

  if (!allCurations) {
    curationIds = state.maintenanceDetails.curations.map((curations) => {
      if (curations.isSelected) {
        return curations.id;
      }
      return '';
    });

    curationIds = curationIds.filter(curationId => curationId);
  }

  return {
    itemId,
    curationIds,
    allCurations,
    userAction,
    replaceItemId
  };

};

export default function app(state = initialState, action) {
  switch (action.type) {
    case types.LOAD_METADATA:
      return {
        ...state,
        meta: action.meta
      };
    case types.LOAD_PRODUCT_INFO:
      return {
        ...state,
        item: {
          ...action.item
        },
        curations: [],
        allSelectedCuration: false
      };
    case types.LOAD_PRODUCT_CURATIONS:
      return {
        ...state,
        curations: action.curations.map(curation => {
          return {
            ...curation,
            isSelected: state.allSelectedCuration
          };
        }),
        searchReport: action.searchReport,
        status: action.status
      };
    case types.SET_SIMILAR_ITEMS_FOR_ITEM:
      return {
        ...state,
        similarItems: (action.results.items || action.results.skus).map(sku => {
          return {
            ...sku,
            $imageSrc: updateImageSrc({ data: sku }),
            isSelected: false
          };
        })
      };
    case types.TOGGLE_SIMILAR_ITEM_SELELCTION:
      return {
        ...state,
        similarItems: state.similarItems.map(item => {
          if (item.itemId === action.itemId) {
            return {
              ...item,
              isSelected: !action.isSelected
            };
          }
          return {
            ...item,
            isSelected: false
          };
        }),
      };
    case types.TOGGLE_CURATION:
      return {
        ...state,
        curations: state.curations.map(curation => {
          if (curation.id === action.id) {
            return {
              ...curation,
              isSelected: !action.isSelected
            };
          }
          return {
            ...curation,
            isSelected: curation.isSelected
          };
        })
      };
    case types.TOGGLE_SELECT_ALL_CURATIONS:
      return {
        ...state,
        allSelectedCuration: !action.allSelectedCuration,
        curations: state.curations.map(curation => {
          return {
            ...curation,
            isSelected: !action.allSelectedCuration
          };
        })
      };
    case types.TOGGLE_UN_SELECT_ALL_CURATIONS:
      return {
        ...state,
        allSelectedCuration: action.allSelectedCuration,
      };
    case types.LOAD_MERCHANDISING_ATTRIBUTES:
      return {
        ...state,
        meta: {
          ...state.meta,
          dimensions: state.meta.dimensions.map(dimension => {
            if (action.meta && action.meta.dimensions) {
              const actionDimension = action.meta.dimensions.find(d => d.id === dimension.id);

              if (actionDimension && dimension.id === actionDimension.id) {
                return {
                  ...dimension,
                  attributes: dimension.attributes.map(attr => {
                    const actionAttribute = actionDimension.attributes.find(a => a.id === attr.id);
                    if (actionAttribute && actionAttribute.id === attr.id) {
                      return {
                        ...attr,
                        weight: actionAttribute.weight
                      };
                    }
                    return attr;
                  })
                };
              }
            }
            return dimension;
          })
        }
      };
    case types.RESET_MERCHANDISING_ATTRIBUTES:
      return {
        ...state,
        meta: {
          ...state.meta,
          dimensions: action.dimensions
        },
        item: {},
        curations: [],
        allSelectedCuration: false,
        infoMessage: ''
      };
    case types.SET_PRODUCT_CURATION_INFO_MESSAGE:
      return {
        ...state,
        infoMessage: action.infoMessage
      };
    case types.SET_WINDOW_SCROLL_TOP:
      return {
        ...state,
        windowScroll: action.windowScroll
      };
    default:
      return state;
  }
}

export const getTagsById = ({ tagId, state = [] }) => {
  return state.app.meta.tags.find(i => i.id === tagId);
};

export const getDimensionsById = ({ dimensionId, state = [] }) => {
  return state.app.meta.dimensions.find(i => i.id === dimensionId);
};

export function isCurationAndItemSelected({ state = [] }) {
  const isCurationSelected = !!(state.maintenanceDetails.curations || []).
                                 find(curation => curation.isSelected);
  const isSimilarItemSelected = !!(state.maintenanceDetails.similarItems || []).
                                    find(item => item.isSelected);
  return isCurationSelected && isSimilarItemSelected;
}