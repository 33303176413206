import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import * as actions from './actions';
import { updateCurationMetaData } from '../ReviewList/actions';
import component from './component';
import { reOrderDimensions } from './reducer';

const mapStateToProps = (state, ownProps) => {
  const listType = /^\/curations/.test(ownProps.location.pathname)
    ? 'curation'
    : 'collection';
  const viewMode = ownProps.match.params.viewMode || 'list';
  const searchReport = state.curationList.searchReport || {};
  const pages = Math.ceil(searchReport.totalProducts / searchReport.pageSize);
  searchReport.$visiblePages = pages > 5 ? 5 : pages;
  let userAccess = false;
  if (state.app.user) {
    userAccess = state.app.user.isCurator || state.app.user.isAdmin;
  }
  const curationLists = state.curationList.lists.map(list => {
    const l = {
      ...list,
      meta: {
        ...list.meta,
        $dimensions: reOrderDimensions({ dimensions: list.meta.dimensions })
      }
    };
    l.$canEdit = false;
    if ((state.app.user.userId === l.createdBy && state.app.user.isCurator)
      || state.app.user.isAdmin) {
      l.$canEdit = true;
    }
    return l;
  });
  const selectedListCount = state.curationList.lists.filter(l => l._selected).length;
  const multiListSelected = state.ui.curationList.filterKeyword.match(/id:\d/g) !== null;
  const selectedCurationCount = state.ui.curationList.filterKeyword.match(/id:\d{1,6},\d{1,6}/g)
    !== null;
  const pinterestFilter = state.ui.pinterestFilter || {};
  const defaultCurationList = state.ui.defaultCurationList || false;
  return {
    searchReport,
    userAccess,
    keyword: state.ui.curationList.filterKeyword,
    curationLists,
    sortName: ownProps.match.params.sortBy,
    sortType: ownProps.match.params.sortOrder,
    loaders: state.app.loaders,
    selectedListCount,
    errors: state.curationList.errorModel,
    listType,
    viewMode,
    meta: state.app.meta,
    multiListSelected,
    selectedCurationCount,
    pinterestFilter,
    defaultCurationList
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const viewMode = ownProps.match.params.viewMode || 'list';
  let listType = /^\/curations/.test(ownProps.location.pathname)
    ? 'curation'
    : 'collection';
  let startIndex = (ownProps.match.params.page || 0) * 24;
  let k = ownProps.match.params.keyword || '';
  const page = ownProps.match.params.page || '0';
  let sortBy = ownProps.match.params.sortBy;
  let sortOrder = ownProps.match.params.sortOrder;
  return {
    actions: bindActionCreators({
      ...actions,
      setCurationFilterKeyword: actions.setCurationFilterKeyword,
      setUIListView: ({ type }) => {
        return () => {
          const parts = [
            listType + 's',
            type || (viewMode === 'grid' ? 'list' : 'grid'),
            page
          ];
          if (ownProps.match.params.keyword) {
            parts.push(ownProps.match.params.keyword);
          }
          let sort = '';
          if (ownProps.match.params.sortBy) {
            sort = ownProps.match.params.sortBy + '/' + ownProps.match.params.sortOrder;
            parts.push(sort);
          }
          ownProps.history.push('/' + parts.join('/'));
        };
      },
      toggleListSelection: actions.toggleListSelection,
      updateSelectedListsMetaData: actions.updateSelectedListsMetaData,
      publishLists: actions.publishLists,
      unpublishLists: actions.unpublishLists,
      toggleListNameEditMode: actions.toggleListNameEditMode,
      updateSelectedListName: actions.updateSelectedListName,
      updateCurationMetaData,
      toggleAttributeAddRemove: actions.toggleAttributeAddRemove,
      addRemoveCurationIdToSearch: actions.addRemoveCurationIdToSearch,
      isCheckedForMultiEdit: actions.isCheckedForMultiEdit,
      filterListByPinterest: actions.filterListByPinterest,
      filterCurations: ({ keyword, type, view = viewMode }) => {
        return () => {
          dispatch(actions.setCurationFilterKeyword({ keyword }));
          ownProps.history.push(`/${type}s/${view}/0/${encodeURIComponent(keyword)}`);
        };
      },
      goToMultiListEditView: ({ type, keyword }) => {
        return () => {
          ownProps.history.push(`/${type}s/grid/0/${encodeURIComponent(keyword)}`);
        };
      },
      sortList: ({ sortName, sortType, listType }) => {
        return (dis) => {
          const keyword = ownProps.match.params.keyword;
          let path = [
            listType + 's',
            viewMode,
            '0'
          ];
          if (keyword) {
            path.push(keyword);
          }
          path.push(sortName);
          path.push(sortType);
          ownProps.history.push('/' + path.join('/'));
        };
      },
      handlePageChanged: (newPage) => {
        return (dis, getState) => {
          const state = getState();
          let sort = '';
          if (ownProps.match.params.sortBy) {
            sort = '/' + ownProps.match.params.sortBy + '/' + ownProps.match.params.sortOrder;
          }
          if (ownProps.match.params.keyword) {
            ownProps.history.push(`/${listType}s/${viewMode}/${Math.ceil(newPage)
            }/${ownProps.match.params.keyword}${sort}`);
          } else {
            ownProps.history.push(`/${listType}s/${viewMode}/${Math.ceil(newPage)}${sort}`);
          }
        };
      },
      createNewListAndRouteToIt: ({ type = 'curation' } = {}) => {
        return () => {
          dispatch(actions.resetUISettings());
          let resp = dispatch(actions.createNewListAndRouteToIt({ type }));
          ownProps.history.push(`/${type}/curate/${resp.id}`);
        };
      },
      navigateToListDetailsPage: ({ listId, type = 'curation' }) => {
        return () => {
          dispatch(actions.resetUISettings());
          // Backend sends L :(
          ownProps.history.push(`/${type}/review/${listId}`);
        };
      },
    }, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(component));
