import { ENV } from '../../../../../../../constants/environment';

import * as api from '../../../../../../../util/api';
import * as types from '../../../../../../../constants/ActionTypes';

export function openPipForItem({ item }) {
  return () => {
    window.open('https://www.homedepot.com' + item.productUrl, '_blank');
  };
}

export function openCurationForItem({ item }) {
  return (dispatch) => {
    let prefix = (ENV === 'local') ? 'index.html' : '';
    dispatch({ type: types.SET_ACTIVE_MENU_TAB, activeTab: 'curations' });
    window.location = `#/curation/review/${item.itemId || item.id}`;
  };
}

export function fetchCurtationForList({ listId, curationId, listType }) {
  return (dispatch, getState) => {
    const state = getState();
    const meta = state.app.meta;
    return api.getListDetailsById({ listId: curationId, type: listType })
      .then(response => {
        dispatch({
          type: types.SET_LIST_DATA_FOR_CURATION,
          listId,
          response,
          meta,
          itemId: curationId
        });
        return true;
      });
  };

}
