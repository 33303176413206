import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InlineMessage from '../../../modules/InlineMessage/component';
import ReviewItemImage from '../modules/ReviewItemImage';

class ReviewItemImageWrapper extends Component {
  render() {
    const {
      errors,
      UPLOAD_IMAGE_ERR,
      list
    } = this.props;
    return (
      <div className="review-item-image-wrapper">
            <div className="review-item-box-title">
              <span className="review-item-box-title__text">Featured Image</span>
            </div>
            {errors.length > 0 &&
              <div className="grid flush">
                {errors.filter(error => {
                  return error.errorCode === UPLOAD_IMAGE_ERR;
                }).map((err, i) => {
                  return (<InlineMessage type="danger" key={i}
                    message={err.description}
                  />);
                })}
              </div>
            }
            <ReviewItemImage list={list} />
          </div>
    );
  }
}

ReviewItemImageWrapper.propTypes = {
  errors: PropTypes.array,
  UPLOAD_IMAGE_ERR: PropTypes.number,
  list: PropTypes.object
};

export default ReviewItemImageWrapper;