import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from './actions';
import { hydrateItems } from './modules/Item/reducer';
import component from './component';
import * as sliderActions from '../../modules/Slider/actions';
import { getDNDContext } from '../../../../util';
import { resetUISettings } from '../CurationList/actions';

const mapStateToProps = (state, ownProps) => {
  let listId = parseInt(ownProps.match.params.id, 10);
  const list = state.curationList.lists.find(l => l.id === listId) || {};
  let results = state.item && state.item.results
    ? state.item.results
    : { skus: [], curations: [] };
  let searchReport;
  if (state.item.results && state.item.results.searchReport) {
    searchReport = state.item.results.searchReport;
    const pages = Math.ceil(searchReport.totalProducts / searchReport.pageSize);
    searchReport.$visiblePages = pages > 4 ? 4 : pages;
  }
  const listType = ownProps.match.params.listType || 'curation';
  const items = listType === 'collection'
    ? (list.curations || [])
    : (list.skus || []);
  return {
    // remove result items from the curation
    results: hydrateItems({
      results,
      items: list.items,
      listType
    }),
    list,
    listType: ownProps.match.params.listType,
    itemView: state.item.itemView,
    actions,
    searchReport,
    loaders: state.app.loaders,
    ui: state.ui
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { id, listType } = ownProps.match.params;
  return {
    generateMultiItemPreview: actions.generateMultiItemPreview,
    actions: bindActionCreators({
      ...actions,
      resetUISettings,
      ...sliderActions,
      handlePageChanged: (newPage) => {
        return () => {
          dispatch(actions.handlePageChanged({ newPage, id: parseInt(id, 10), listType }));
        };
      },
    }, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(
  getDNDContext()(component)// eslint-disable-line new-cap
);
