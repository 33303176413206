import group from '../../../../../../assets/images/group.png';
export default {
  curation: {
    searchReport: {
      totalProducts: 20,
      pageSize: 10,
      currentPage: 0,
      resultType: 'sliders'
    },
    items: [
      {
        itemId: '0000005',
        imageSrc: 'http://www.homedepot.com/hdus/en_US/DTCCOMNEW/fetch/collections/staticImages/patio_collection_Alveranda/17PATIO-207189496-ALVERANDA-SILO.jpg',
        title: 'product title',
        modelNumber: '1232',
        price: '12.20',
        type: 'item'
      },
      {
        itemId: '0000003',
        imageSrc: 'http://www.homedepot.com/hdus/en_US/DTCCOMNEW/fetch/collections/staticImages/patio_collection_Alveranda/17PATIO-207189496-ALVERANDA-SILO.jpg',
        title: 'product title',
        modelNumber: '1232',
        price: '12.20',
        type: 'item'
      },
      {
        itemId: '0000004',
        imageSrc: 'http://www.homedepot.com/hdus/en_US/DTCCOMNEW/fetch/collections/staticImages/patio_collection_Alveranda/17PATIO-207189496-ALVERANDA-SILO.jpg',
        title: 'product title',
        modelNumber: '1232',
        price: '12.20',
        type: 'item'
      }
    ]
  },
  collection: {
    searchReport: {
      totalProducts: 20,
      pageSize: 10,
      currentPage: 0,
      resultType: 'sliders'
    },
    items: [
      {
        id: '0000005',
        imageSrc: group,
        title: 'some curation',
        createdAt: 1509553689042,
        type: 'curation',
        items: []
      },
      {
        itemId: '0000003',
        imageSrc: group,
        title: 'product title',
        createdAt: 1509553689042,
        type: 'curation',
        items: []
      },
      {
        itemId: '0000004',
        imageSrc: group,
        title: 'product title',
        createdAt: 1509553689042,
        type: 'curation',
        items: []
      }
    ]
  }
};
