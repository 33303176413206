import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './header.scss';
import { Link } from 'react-router-dom';
import logo from '../../../../assets/images/logo.svg';
import layergroupcolored from './color-layered-group.png';
import layergroupsolid from './layer-group-solid.png';
import usersolid from './user-solid-png.png';
import products from './products-png.png';
import collectionsactive from './collections_active-icon.png';
import curationsactive from './Curation-active_state.png';
import productsactive from './products_icon-active-state.png';
import logoutactive from './user_active-state.png';

class HeaderComponent extends Component {

  render() {
    const {
      activeTab,
      navigteToList,
      userId,
      userName,
      logout,
      setActiveMenuTab,
      location
    } = this.props;
    return (
      <header className="col__12-12 app-header">
      <Link
        to="/curations"
        onClick={e => {
          setActiveMenuTab({ type: 'curations' });
        }}
      >
        <img role="presentation" src={logo} />
      </Link>
      <h1 className="app-header__title">
        <Link
          to="/curations"
          onClick={e => {
            setActiveMenuTab({ type: 'curations' });
          }}
        >
          DaVinci
        </Link>
      </h1>
      <ul className="app-header__navigation">
        {console.log(activeTab)}
        <li className={activeTab === 'curations' ? 'navigation__item--active-curations' : ''}>
          <a
            href="#"
            onClick={e => {
              e.preventDefault();
              navigteToList({ type: 'curations' });
            }}
          >
            <img alt="" src={activeTab === 'curations' ? curationsactive :
              layergroupcolored}
              style={{
                display: 'block',
                margin: '0 auto'
              }}
            />
            <span className="app-header__menu">Curations</span>
          </a>
        </li>
        <li className={activeTab === 'collections' ? 'navigation__item--active-collections' : ''}>
          <a
            href="#"
            onClick={e => {
              e.preventDefault();
              navigteToList({ type: 'collections' });
            }}
          >
            <img alt="" src={activeTab === 'collections' ?
              collectionsactive : layergroupsolid}
              style={{
                display: 'block',
                margin: '0 auto'
              }}
            />
            <span className="app-header__menu">Collections</span>
          </a>
        </li>

        <li className={activeTab === 'products' ? 'navigation__item--active-products' : ''}>
          <a
            href="#"
            onClick={e => {
              e.preventDefault();
              navigteToList({ type: 'products' });
            }}
          >
              <img alt="" src={activeTab === 'products' ?
              productsactive : products}
                style={{
                  display: 'block',
                  margin: '0 auto'
                }}
              />
            <span className="app-header__menu">Products</span>
          </a>
        </li>
        <li>
          {activeTab &&
            <a
              href="#"
              className="logouttext_orange"
              onClick={e => {
                e.preventDefault();
                logout();
              }}
            >
            <img alt="" className="logoutimage"
              src={activeTab === 'logout' ?
              logoutactive : usersolid}
              style={{
                display: 'block',
                margin: '0 auto'
              }}
            />
            Logout ({userName.split(' ')[0] ? userName.split(' ')[0] : ''})
          </a>
          }
        </li>
      </ul>
    </header>);
  }
}

HeaderComponent.propTypes = {
  activeTab: PropTypes.string,
  logout: PropTypes.func,
  userId: PropTypes.string,
  userName: PropTypes.string,
  setActiveMenuTab: PropTypes.func,
  navigteToList: PropTypes.func,
  location: PropTypes.object
};

export default HeaderComponent;
