import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from './HeaderActions';
import Header from './HeaderComponent';
import { withRouter } from 'react-router-dom';
import { logoutUser } from '../../App/AppActions';
import {
  setCurationFilterKeyword,
  toggleDefaultCurationList
} from '../../App/CurationList/actions';
import { SET_ACTIVE_MENU_TAB } from '../../../../constants/ActionTypes';

const getTabFromRoute = (route) => {
  if (/collections/.test(route)) return 'collections';
  if (/collection/.test(route)) return 'collections';
  if (/curation/.test(route)) return 'curations';
  if (/curations/.test(route)) return 'curations';
  if (/products/.test(route)) return 'products';
  if (/product/.test(route)) return 'products';
  return null;
};

const mapStateToProps = (state, ownProps) => {
  const route = ownProps.location
    ? ownProps.location.pathname
    : window.location.hash || '';
  return {
    activeTab: getTabFromRoute(route),
    userId: state.app.user.userId,
    userName: state.app.user.userName || ''
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    logout: () => {
      dispatch(logoutUser());
      // @todo loader
    },
    setActiveMenuTab: ({ type = 'curations' }) => {
      dispatch(setCurationFilterKeyword({ keyword: '' }));
      dispatch({ type: SET_ACTIVE_MENU_TAB, activeTab: type });
    },
    navigteToList: ({ type = 'curations' }) => {
      dispatch(toggleDefaultCurationList({ defaultCurationList: true }));
      dispatch(setCurationFilterKeyword({ keyword: '' }));
      ownProps.history.push(`/${type}`);
    },
    actions: bindActionCreators({
      ...actions
    }, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
