export default {

  "dimensions": [
    {
      "id": 100,
      "position": 1,
      "type": "dimension",
      "name": "Style",
      "attributes": [
        {
          "id": 100,
          "name": "Art Deco",
          "value": 0.12123
        },
        {
          "id": 200,
          "name": "Glam",
          "value": 0.223
        },
        {
          "id": 300,
          "name": "Modern",
          "value": 0.12123
        },
        {
          "id": 400,
          "name": "Mid-Century Modern",
          "value": 0.12123
        },
        {
          "id": 500,
          "name": "Cottage",
          "value": 0.12123
        },
        {
          "id": 600,
          "name": "Coastal",
          "value": 0.12123
        },
        {
          "id": 700,
          "name": "Farmhouse",
          "value": 0.12123
        },
        {
          "id": 800,
          "name": "Rustic",
          "value": 0.12123
        },
        {
          "id": 900,
          "name": "Mission",
          "value": 0.12123
        },
        {
          "id": 1000,
          "name": "Classic",
          "value": 0.12123
        },
        {
          "id": 1100,
          "name": "Industrial",
          "value": 0.12123
        },
        {
          "id": 1200,
          "name": "Bohemian",
          "value": 0.12123
        },
        {
          "id": 1300,
          "name": "Southwestern",
          "value": 0.12123
        },
        {
          "id": 1500,
          "name": "Southwest",
          "value": 0.12123
        },
        {
          "id": 1600,
          "name": "Vintage",
          "value": 0.12123
        }
      ]
    },
    {
      "id": 200,
      "position": 2,
      "type": "dimension",
      "name": "Room",
      "attributes": [
        {
          "id": 100,
          "name": "Kitchen",
          "value": 0.12123
        },
        {
          "id": 200,
          "name": "Living Room",
          "value": 0.12123
        },
        {
          "id": 300,
          "name": "Home Office",
          "value": 0.12123
        },
        {
          "id": 400,
          "name": "Bedroom",
          "value": 0.12123
        },
        {
          "id": 500,
          "name": "Bathroom",
          "value": 0.12123
        },
        {
          "id": 600,
          "name": "Entryway",
          "value": 0.12123
        },
        {
          "id": 700,
          "name": "Outdoor",
          "value": 0.12123
        },
        {
          "id": 800,
          "name": "Laundry Room",
          "value": 0.12123
        },
        {
          "id": 900,
          "name": "Kids Room",
          "value": 0.12123
        },
        {
          "id": 1000,
          "name": "Dining Room",
          "value": 0.12123
        }
      ]
    }
  ],
  "refinements": [],
  "tags": [
    {
      "id": 500,
      "position": 5,
      "type": "tag",
      "name": "Setting",
      "attributes": [
        {
          "id": 100,
          "name": "Loft",
          "selected": false
        },
        {
          "id": 200,
          "name": "Flat",
          "selected": false
        },
        {
          "id": 300,
          "name": "Open-Concept",
          "selected": false
        },
        {
          "id": 400,
          "name": "Penthouse",
          "selected": false
        },
        {
          "id": 500,
          "name": "Apartment",
          "selected": false
        },
        {
          "id": 600,
          "name": "Studio",
          "selected": false
        },
        {
          "id": 700,
          "name": "Basement",
          "selected": false
        },
        {
          "id": 800,
          "name": "Adobe",
          "selected": false
        },
        {
          "id": 900,
          "name": "Factory",
          "selected": false
        },
        {
          "id": 1000,
          "name": "Warehouse",
          "selected": false
        },
        {
          "id": 1100,
          "name": "Cabin",
          "selected": false
        },
        {
          "id": 1200,
          "name": "Bungalow",
          "selected": false
        },
        {
          "id": 1300,
          "name": "Barn",
          "selected": false
        },
        {
          "id": 1400,
          "name": "Kitchen",
          "selected": false
        },
        {
          "id": 1500,
          "name": "Dining Room",
          "selected": false
        },
        {
          "id": 1600,
          "name": "Bar",
          "selected": false
        },
        {
          "id": 1700,
          "name": "Library",
          "selected": false
        },
        {
          "id": 1800,
          "name": "Lounge",
          "selected": false
        },
        {
          "id": 1900,
          "name": "Den",
          "selected": false
        },
        {
          "id": 2000,
          "name": "Ranch",
          "selected": false
        },
        {
          "id": 2100,
          "name": "Lodge",
          "selected": false
        },
        {
          "id": 2200,
          "name": "Workshop",
          "selected": false
        },
        {
          "id": 2300,
          "name": "Villa",
          "selected": false
        },
        {
          "id": 2400,
          "name": "Study",
          "selected": false
        },
        {
          "id": 2500,
          "name": "Laundry Room",
          "selected": false
        },
        {
          "id": 2600,
          "name": "Apothecary",
          "selected": false
        },
        {
          "id": 2700,
          "name": "Mudroom",
          "selected": false
        },
        {
          "id": 2800,
          "name": "A-Frame",
          "selected": false
        },
        {
          "id": 2900,
          "name": "Atrium",
          "selected": false
        },
        {
          "id": 3000,
          "name": "Shed",
          "selected": false
        },
        {
          "id": 3100,
          "name": "Mancave",
          "selected": false
        },
        {
          "id": 3200,
          "name": "Garage",
          "selected": false
        },
        {
          "id": 3300,
          "name": "Camper/Airstream",
          "selected": false
        },
        {
          "id": 3400,
          "name": "Tiny House",
          "selected": false
        },
        {
          "id": 3500,
          "name": "Summer Home",
          "selected": false
        },
        {
          "id": 3600,
          "name": "Family Room",
          "selected": false
        },
        {
          "id": 3700,
          "name": "Playroom",
          "selected": false
        },
        {
          "id": 3800,
          "name": "Sunroom",
          "selected": false
        },
        {
          "id": 3900,
          "name": "Atelier",
          "selected": false
        },
        {
          "id": 4000,
          "name": "Workspace",
          "selected": false
        },
        {
          "id": 4100,
          "name": "Cafe",
          "selected": false
        },
        {
          "id": 4200,
          "name": "Carriage House",
          "selected": false
        },
        {
          "id": 4300,
          "name": "Diner",
          "selected": false
        },
        {
          "id": 4400,
          "name": "Dining",
          "selected": false
        },
        {
          "id": 4500,
          "name": "Speakeasy",
          "selected": false
        },
        {
          "id": 4600,
          "name": "Embellished",
          "selected": false
        },
        {
          "id": 4700,
          "name": "Kitchen & Dining Room",
          "selected": false
        },
        {
          "id": 4800,
          "name": "Urban",
          "selected": false
        }
      ]
    },
    {
      "id": 600,
      "position": 6,
      "type": "tag",
      "name": "Environment",
      "attributes": [
        {
          "id": 100,
          "name": "Beach",
          "selected": false
        },
        {
          "id": 200,
          "name": "Sea Cliff",
          "selected": false
        },
        {
          "id": 300,
          "name": "Forest",
          "selected": false
        },
        {
          "id": 400,
          "name": "Mountain",
          "selected": false
        },
        {
          "id": 500,
          "name": "Lake",
          "selected": false
        },
        {
          "id": 600,
          "name": "River",
          "selected": false
        },
        {
          "id": 700,
          "name": "Desert",
          "selected": false
        },
        {
          "id": 800,
          "name": "Urban",
          "selected": false
        },
        {
          "id": 900,
          "name": "Rural",
          "selected": false
        },
        {
          "id": 1000,
          "name": "Suburban",
          "selected": false
        },
        {
          "id": 1100,
          "name": "Outdoors",
          "selected": false
        },
        {
          "id": 1200,
          "name": "New York",
          "selected": false
        }
      ]
    },
    {
      "id": 700,
      "position": 7,
      "type": "tag",
      "name": "Locations",
      "attributes": [
        {
          "id": 100,
          "name": "France",
          "selected": false
        },
        {
          "id": 200,
          "name": "Paris",
          "selected": false
        },
        {
          "id": 300,
          "name": "London",
          "selected": false
        },
        {
          "id": 400,
          "name": "Italy",
          "selected": false
        },
        {
          "id": 500,
          "name": "Spain",
          "selected": false
        },
        {
          "id": 600,
          "name": "Mexico",
          "selected": false
        },
        {
          "id": 700,
          "name": "Southwest",
          "selected": false
        },
        {
          "id": 800,
          "name": "East Coast",
          "selected": false
        },
        {
          "id": 900,
          "name": "West Coast",
          "selected": false
        },
        {
          "id": 1000,
          "name": "Cape Cod",
          "selected": false
        },
        {
          "id": 1100,
          "name": "New York",
          "selected": false
        },
        {
          "id": 1200,
          "name": "Brooklyn",
          "selected": false
        },
        {
          "id": 1300,
          "name": "Chicago",
          "selected": false
        },
        {
          "id": 1400,
          "name": "Hollywood",
          "selected": false
        },
        {
          "id": 1500,
          "name": "Morocco",
          "selected": false
        },
        {
          "id": 1600,
          "name": "India",
          "selected": false
        },
        {
          "id": 1700,
          "name": "Greece",
          "selected": false
        },
        {
          "id": 1800,
          "name": "Asia",
          "selected": false
        },
        {
          "id": 1900,
          "name": "Africa",
          "selected": false
        },
        {
          "id": 2000,
          "name": "United States",
          "selected": false
        },
        {
          "id": 2100,
          "name": "South America",
          "selected": false
        },
        {
          "id": 2200,
          "name": "Palm Springs",
          "selected": false
        },
        {
          "id": 2300,
          "name": "The South (US)",
          "selected": false
        }
      ]
    },
    {
      "id": 800,
      "position": 8,
      "type": "tag",
      "name": "Design Features",
      "attributes": [
        {
          "id": 100,
          "name": "Gears",
          "selected": false
        },
        {
          "id": 200,
          "name": "Pulleys",
          "selected": false
        },
        {
          "id": 300,
          "name": "Wheels",
          "selected": false
        },
        {
          "id": 400,
          "name": "Edison bulbs",
          "selected": false
        },
        {
          "id": 500,
          "name": "Hairpin",
          "selected": false
        },
        {
          "id": 600,
          "name": "Live Edge",
          "selected": false
        },
        {
          "id": 700,
          "name": "Painted",
          "selected": false
        },
        {
          "id": 800,
          "name": "Carved",
          "selected": false
        },
        {
          "id": 900,
          "name": "Embellished",
          "selected": false
        },
        {
          "id": 1000,
          "name": "Embroidered",
          "selected": false
        },
        {
          "id": 1100,
          "name": "Printed",
          "selected": false
        },
        {
          "id": 1200,
          "name": "Tufted",
          "selected": false
        },
        {
          "id": 1300,
          "name": "Woven",
          "selected": false
        },
        {
          "id": 1400,
          "name": "Macrame",
          "selected": false
        },
        {
          "id": 1500,
          "name": "High-low",
          "selected": false
        },
        {
          "id": 1600,
          "name": "Shag",
          "selected": false
        },
        {
          "id": 1700,
          "name": "Beaded",
          "selected": false
        },
        {
          "id": 1800,
          "name": "Bicycles",
          "selected": false
        },
        {
          "id": 1900,
          "name": "Cactus",
          "selected": false
        },
        {
          "id": 2000,
          "name": "Caging",
          "selected": false
        },
        {
          "id": 2100,
          "name": "Tapered",
          "selected": false
        },
        {
          "id": 2200,
          "name": "Tassel",
          "selected": false
        }
      ]
    },
    {
      "id": 900,
      "position": 9,
      "type": "tag",
      "name": "Symbolic Features",
      "attributes": [
        {
          "id": 100,
          "name": "Buddha",
          "selected": false
        },
        {
          "id": 200,
          "name": "Hamsa",
          "selected": false
        },
        {
          "id": 300,
          "name": "Lotus",
          "selected": false
        },
        {
          "id": 400,
          "name": "Skulls",
          "selected": false
        },
        {
          "id": 500,
          "name": "Feathers",
          "selected": false
        },
        {
          "id": 600,
          "name": "Arrows",
          "selected": false
        },
        {
          "id": 700,
          "name": "Sunburst",
          "selected": false
        },
        {
          "id": 800,
          "name": "Sputnik",
          "selected": false
        },
        {
          "id": 900,
          "name": "Fleur de Lis",
          "selected": false
        },
        {
          "id": 1000,
          "name": "Astronomy",
          "selected": false
        },
        {
          "id": 1100,
          "name": "Animals",
          "selected": false
        },
        {
          "id": 1200,
          "name": "Animals_Barnyard",
          "selected": false
        },
        {
          "id": 1300,
          "name": "Animals_Forest",
          "selected": false
        },
        {
          "id": 1400,
          "name": "Animals_Marine",
          "selected": false
        },
        {
          "id": 1500,
          "name": "Anchor",
          "selected": false
        },
        {
          "id": 1600,
          "name": "Animals_Desert",
          "selected": false
        },
        {
          "id": 1700,
          "name": "Bikes",
          "selected": false
        },
        {
          "id": 1800,
          "name": "Bones",
          "selected": false
        },
        {
          "id": 1900,
          "name": "Bridges",
          "selected": false
        },
        {
          "id": 2000,
          "name": "Cactus",
          "selected": false
        },
        {
          "id": 2100,
          "name": "Cameras/Photography",
          "selected": false
        },
        {
          "id": 2200,
          "name": "Compass",
          "selected": false
        },
        {
          "id": 2300,
          "name": "Palm",
          "selected": false
        },
        {
          "id": 2400,
          "name": "Pineapple",
          "selected": false
        },
        {
          "id": 2500,
          "name": "Porthole",
          "selected": false
        }
      ]
    },
    {
      "id": 1000,
      "position": 10,
      "type": "tag",
      "name": "Marketing Features",
      "attributes": [
        {
          "id": 200,
          "name": "Modular",
          "selected": false
        },
        {
          "id": 300,
          "name": "Multi-Functional",
          "selected": false
        },
        {
          "id": 400,
          "name": "Performance Fabric",
          "selected": false
        },
        {
          "id": 500,
          "name": "Ultimate Comfort",
          "selected": false
        },
        {
          "id": 600,
          "name": "Customizable",
          "selected": false
        },
        {
          "id": 700,
          "name": "Heirloom Quality",
          "selected": false
        },
        {
          "id": 800,
          "name": "Fair Trade",
          "selected": false
        },
        {
          "id": 900,
          "name": "Eco-Friendly",
          "selected": false
        },
        {
          "id": 1000,
          "name": "Organic",
          "selected": false
        },
        {
          "id": 1100,
          "name": "Non-Toxic",
          "selected": false
        },
        {
          "id": 1200,
          "name": "FSC Certified Wood",
          "selected": false
        },
        {
          "id": 1300,
          "name": "Handmade",
          "selected": false
        },
        {
          "id": 1400,
          "name": "Sustainable",
          "selected": false
        }
      ]
    },
    {
      "id": 1800,
      "position": 8,
      "type": "tag",
      "name": "Intent/Purpose",
      "attributes": [
        {
          "id": 100,
          "name": "Small Space",
          "selected": false
        },
        {
          "id": 200,
          "name": "Organize/Declutter",
          "selected": false
        },
        {
          "id": 300,
          "name": "Entertain",
          "selected": false
        },
        {
          "id": 400,
          "name": "Pet-Friendly",
          "selected": false
        },
        {
          "id": 500,
          "name": "Kid-Friendly",
          "selected": false
        },
        {
          "id": 600,
          "name": "Zen/Peaceful",
          "selected": false
        },
        {
          "id": 700,
          "name": "Cozy",
          "selected": false
        }
      ]
    },
    {
      "id": 1900,
      "position": 9,
      "type": "tag",
      "name": "Other",
      "attributes": [
        {
          "id": 100,
          "name": "Rugged",
          "selected": false
        },
        {
          "id": 200,
          "name": "Primitive",
          "selected": false
        },
        {
          "id": 300,
          "name": "Chic",
          "selected": false
        },
        {
          "id": 400,
          "name": "Plush",
          "selected": false
        },
        {
          "id": 500,
          "name": "Mad Men",
          "selected": false
        },
        {
          "id": 600,
          "name": "Great Gatsby",
          "selected": false
        },
        {
          "id": 700,
          "name": "Frank Lloyd Wright",
          "selected": false
        },
        {
          "id": 800,
          "name": "Sports",
          "selected": false
        },
        {
          "id": 900,
          "name": "Kids & Teens",
          "selected": false
        },
        {
          "id": 1000,
          "name": "Movies & TV",
          "selected": false
        },
        {
          "id": 1100,
          "name": "Collected",
          "selected": false
        },
        {
          "id": 1200,
          "name": "Music",
          "selected": false
        },
        {
          "id": 1300,
          "name": "Travel",
          "selected": false
        }
      ]
    }
  ]
}
