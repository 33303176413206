import * as types from '../../../../../../constants/ActionTypes';
import * as api from '../../../../../../util/api';

export const getUserClassificationByDimensions = ({ dimensionId, attributeId, startIndex = 0 }) => {
  return (dispatch) => {
    dispatch({
      type: types.INITIATE_LOADER,
      loader: 'load-dimension-classification-details'
    });

    return api
      .getUserClassificationByDimensions({ dimensionId, attributeId, startIndex })
      .then(response => {

        const searchReport = {
          pageSize: response.searchReport.pageSize,
          startIndex: response.searchReport.startIndex,
          totalUsers: response.searchReport.totalUsers,
          $visiblePages: 5
        };

        dispatch({
          type: types.SET_DIMENSION_CLASSIFICATION_BY_USER,
          users: response && response.users && response.users.length > 0
            ? response.users
            : [],
          searchReport
        });

        dispatch({ type: types.REMOVE_LOADER, loader: 'load-dimension-classification-details' });
      })
      .catch(e => {
        console.error(e);
        dispatch({ type: types.REMOVE_LOADER, loader: 'load-dimension-classification-details' });
      });
  };

};