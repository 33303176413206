import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import component from './GoogleAnalytics.component';
import { withRouter } from 'react-router-dom';
import * as actions from './googleAnalytics.actions';

const mapStateToProps = (state, ownProps) => {
  return {
    children: ownProps.children
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    actions: {
      ...actions
    }
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(component)
);
