import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import * as actions from './ProductDetails.actions';
import ProductDetailsComponent from './ProductDetails.component';


const mapStateToProps = (state, ownProps) => {
  const itemId = ownProps.match.params.id;
  const item = state.maintenanceDetails.item;
  const curations = state.maintenanceDetails.curations;
  const allSelectedCuration = state.maintenanceDetails.allSelectedCuration;
  const searchReport = state.maintenanceDetails.searchReport;
  const infoMessage = state.maintenanceDetails.infoMessage || '';
  const windowScroll = state.maintenanceDetails.windowScroll || false;
  const isAdminUser = state.app.user.isAdmin || false;
  return {
    loaders: state.app.loaders,
    itemId,
    item,
    curations,
    allSelectedCuration,
    searchReport,
    infoMessage,
    windowScroll,
    isAdminUser
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    actions: bindActionCreators({
      ...actions,
      handlePageChanged: (newPage) => {
        return () => {
          const itemId = ownProps.match.params.id;
          dispatch(actions.handlePageChanged({ itemId, newPage }));
        };
      }
    }, dispatch)
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProductDetailsComponent)
);