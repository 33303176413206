import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from './actions';
import { selectItem } from '../actions';
import * as api from '../../../../../../../util/api';
import { removeItemFromSet } from '../../../../CurateList/actions';
import defaultProduct from '../../../../../../../assets/images/default-product.jpg';
import { sliceItemsForCollection } from '../../../../CurationList/reducer';

import component from './component';
function mapStateToProps(state, ownProps) {
  const list = ownProps.list;
  const item = ownProps.item;
  const size = ownProps.size || 'large';
  const NoOfCoulmns = state.item.itemView;
  let itemSize = 125;
  if (NoOfCoulmns === 6) {
    itemSize = 75;
  }

  const curationListViewType = state.ui.curationList.viewType;
  const listIconSize = (curationListViewType === 'curation' && ownProps.type === 'set')
    ? 50 : 75;
  const defaults = {
    width: size === 'small'
      ? listIconSize : itemSize,
    height: size === 'small'
      ? listIconSize : itemSize
  };

  const style = {
    ...defaults,
    float: (size === 'small' && curationListViewType === 'curation') ? 'left' : 'none'
  };

  if (item.type === 'product') {
    style.backgroundImage = `url(${item.$imageSrc || defaultProduct})`;
  }


  return {
    list,
    ui: state.ui,
    item,
    index: ownProps.index,
    style: { container: style, text: size === 'large', size },
    NoOfCoulmns,
    loaders: state.app.loaders || {}
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const list = ownProps.list;
  return {
    actions: bindActionCreators({
      ...actions,
      selectItem,
      removeItemFromSet: ({ item, listId, section, type }) => {
        return () => {
          const answer = confirm(`Are you sure you want to delete the ${type === 'curation' ? 'item' : 'curation'}?`); // eslint-disable-line
          if (answer) {
            dispatch(removeItemFromSet({ item, listId, section, type }));
          }
        };
      }
    }, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(component);
