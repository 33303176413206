import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './search.scss';

class SearchFormComponent extends Component {
  render() {
    const {
      loaders,
      keyword,
      actions,
      listType,
      list
    } = this.props;
    return (
      <div className="col__12-12 searchForm__container" >
      <form className="searchForm" onSubmit={(e) => {
        e.preventDefault();
        const k = e.target.elements.keyword.value;
        if (listType === 'collection') {
          actions.submitSearchForCuration({ keyword: k, id: list.id });
        } else {
          actions.submitSearch({ keyword: k, id: list.id });
        }
      }}
      >
        <div className="grid">
        <div className="col__2-12"></div>
          <div className="col__8-12">
            <div className="searchForm__textWrapper input-group--inline">
              <input
                type="text"
                id="keyword"
                name="keyword"
                className="form-input__field input-group--inline__input"
                placeholder="Search"
                value={keyword}
                onChange={(e) => {
                  const k = e.target.value;
                  actions.setSearchKeyword({ keyword: k });
                }}
              />
              <button className="bttn input-group--inline__bttn">
                <span className="mw-icon-search bttn__content"></span>
              </button>
            </div>
          </div>
          <div className="col__2-12"></div>
        </div>
      </form>
    </div>);
  }
}

SearchFormComponent.propTypes = {
  actions: PropTypes.shape({
    submitSearch: PropTypes.func.isRequired
  }),
  listType: PropTypes.string,
  loaders: PropTypes.object,
  keyword: PropTypes.string,
  results: PropTypes.array,
  list: PropTypes.object
};

export default SearchFormComponent;
