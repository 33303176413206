
import * as types from '../../../../../../constants/ActionTypes';
import { fetchItemList } from '../../../CurateList/actions';
import * as api from '../../../../../../util/api';
import { GCS_IMAGE_BUCKET_LIFESTYLE } from '../../../../../../constants/environment';


export function uploadImage({ listId, picture, index = 0 }) {
  return (dispatch, getState) => {
    dispatch({ type: types.GENERIC_ERROR_HANDLER_CLEAR });
    if (picture.files[0].size > 10485760) {
      const error = {
        errorCode: 400,
        description: 'File Size is too Large. Please select Correct File!!!'
      };
      dispatch({ type: types.ADD_CURATION_ERROR, response: [error] });
    } else {
      dispatch({ type: types.INITIATE_LOADER, loader: 'image-upload' });
      api.uploadImage({ file: picture.files[0] })
      .then(resp => {
        // save the id to the curaiton
        // @todo
        dispatch({ type: types.REMOVE_LOADER, loader: 'image-upload' });
        if (resp && resp.errorCode) {
          dispatch({ type: types.ADD_CURATION_ERROR, response: [resp] });
        } else if (resp && resp.status && resp.status === 200) {
          const guid = resp.guid;
          dispatch({
            type: types.UPDATE_LIST_IMAGE,
            listId,
            image: resp.image,
            index,
            insert: true
          });
          const state = getState();
          const list = state.curationList.lists.find(li => li.id === listId);
          api.updateListMeta({ list, type: list.type })
            .then(response => {
              if (response.errorCode) {
                dispatch({ type: types.ADD_CURATION_ERROR, response: [response] });
              } else {
                dispatch({
                  type: types.SET_LIST_DATA_WITHOUT_ITEMS,
                  id: list.id,
                  response,
                  meta: state.app.meta
                });
              }
            });
        }

      }).catch(e => {
        console.log(e);
        dispatch({ type: types.REMOVE_LOADER, loader: 'image-upload' });
      });
    }
    // let reader = new FileReader();
    // reader.onload = function () {
    //   let dataURL = reader.result;
    //   const can = document.createElement("canvas");

    //   const ctx = can.getContext("2d");
    //   const i = new Image();
    //   i.onload = () => {
    //     const w = i.width;
    //     const h = i.height;
    //     const r = (h / w);
    //     const h2 = r * 300;
    //     can.height = h2;
    //     can.width = 300;
    //     ctx.drawImage(i, 0, 0, 300, h2);

    //     dispatch({ type: types.REVIEW_PREVIEW_IMAGE, listId, picture: can.toDataURL() });
    //   };

    //   i.src = dataURL;
    // };
    // reader.readAsDataURL(picture.files[0]);
    // dispatch(fetchItemList({ id: listId }));
  };
}

export function updateImage({ listId, value, index = 0 }) {
  return (dispatch, getState) => {
    const i = new Image();
    const src = GCS_IMAGE_BUCKET_LIFESTYLE + value;
    let image = {
      guid: value,
      position: index
    };
    i.onload = () => {
      let img = {
        ...image,
        height: i.naturalHeight,
        width: i.naturalWidth
      };
      dispatch({ type: types.UPDATE_LIST_IMAGE, listId, image: img, index });
      dispatch({ type: types.GENERIC_ERROR_HANDLER_CLEAR });
      const state = getState();
      const list = state.curationList.lists.find(li => li.id === listId);
      api.updateListMeta({ list, type: list.type })
      .then(response => {
        if (response.errorCode) {
          dispatch({ type: types.ADD_CURATION_ERROR, response: [response] });
        } else {
          dispatch({ type: types.SET_LIST_DATA_WITHOUT_ITEMS, id: list.id,
            response, meta: state.app.meta });
        }
      });
    };
    i.onerror = () => {
      console.log('err');
      const response = {
        errorCode: 400,
        description: 'Invalid data for guid/position/height/width in the images'
      };
      dispatch({ type: types.ADD_CURATION_ERROR, response: [response] });
    };
    i.src = src;
    dispatch({ type: types.UPDATE_LIST_IMAGE, listId, image, index });
  };
}

export function addEmptyImage({ listId }) {
  return { type: types.ADD_EMPTY_IMAGE, listId };
}

export function saveImage({ listId }) {
  return (dispatch, getState) => {
    const state = getState();
    const list = state.curationList.lists.find(li => li.id === listId);
    dispatch({ type: types.GENERIC_ERROR_HANDLER_CLEAR });
    api.updateListMeta({ list })
    .then(response => {
      if (response.errorCode) {
        dispatch({ type: types.ADD_CURATION_ERROR, response: [response] });
      } else {
        dispatch({ type: types.SET_LIST_DATA_WITHOUT_ITEMS, id: list.id,
          response, meta: state.app.meta });
      }
    });
  };
}

export function updateImagePosition({ listId, currentPosition, newPosition }) {
  return (dispatch, getState) => {
    dispatch({ type: types.UPDATE_IMAGE_POSITION, listId, currentPosition, newPosition });
    const state = getState();
    const list = state.curationList.lists.find(li => li.id === listId);
    api.updateListMeta({ list })
      .then(response => {
        if (response.errorCode) {
          dispatch({ type: types.ADD_CURATION_ERROR, response: [response] });
        } else {
          dispatch({ type: types.SET_LIST_DATA_WITHOUT_ITEMS, id: list.id,
            response, meta: state.app.meta });
        }
      });
  };
}

export function removeImage({ listId, index, image }) {
  return (dispatch, getState) => {
    dispatch({ type: types.UPDATE_LIST_IMAGE, listId, image, index, remove: true });
    // dispatch({ type: types.REVIEW_PREVIEW_IMAGE, listId, picture: '' });
    const state = getState();
    const list = state.curationList.lists.find(li => li.id === listId);
    dispatch({ type: types.GENERIC_ERROR_HANDLER_CLEAR });
    api.updateListMeta({ list })
      .then(response => {
        if (response.errorCode) {
          dispatch({ type: types.ADD_CURATION_ERROR, response: [response] });
        } else {
          dispatch({ type: types.SET_LIST_DATA_WITHOUT_ITEMS, id: list.id,
            response, meta: state.app.meta });
        }
      });
  };
}
