import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { formatDate } from '../../../../../util';

class ReviewListDetails extends Component {
  render() {
    const {
      list,
      text
    } = this.props;
    return (
      <div>
        <div className="reviewList-details">
          {text === text.toUpperCase() &&
              <div className="text-caps">{list.type} {text}: </div>}
            <div>{text}: </div>
            <div className="reviewList-details__value">
              {text === 'Status' && list && list.status && list.status !== '' &&
                <span>{list.status}</span>
              }
              {(text === 'Curation ID' || text === 'Collection ID') && list && list.id &&
                list.id !== '' &&
              typeof parseInt(list.id, 10) === 'number' &&
                <span>{list.id}</span>
              }
              {text === 'Curator' && list && list.createdBy &&
                <span>{list.createdBy}</span>
              }
              {text === 'Date created' && list && list.createdAt &&
                <span>{formatDate(new Date(list.createdAt), 'MM/dd/yyyy')}</span>
              }
            </div>
        </div>
      </div>
    );
  }
}

ReviewListDetails.propTypes = {
  list: PropTypes.object,
  text: PropTypes.string
};

export default ReviewListDetails;