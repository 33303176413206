import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Accordion extends Component {
  render() {
    const {
      id,
      title,
      children,
      className = '',
      subtitle
    } = this.props;
    return (
      <div className={'accordion__wrapper ' + className}>
        <div className="accordion__item">
          <input
            className="accordion__input"
            id={`ac-${id}`}
            name={`accordion-${id}`}
            type="checkbox"
          />
          <label className="accordion__label" htmlFor={`ac-${id}`}>
            <div className="accordion__trigger"><div className="accordion__plus"></div></div>
            <div className="slider-text-wrapper accordion__text-wrapper">
              <div className="slider-text-wrapper__title">{title}</div>
              <div className="slider-text-wrapper__subtitle">{subtitle}</div>
            </div>
          </label>
          <div className="accordion__content-wrapper">
            <div className="accordion__content">
              {children}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Accordion.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.string,
  subtitle: PropTypes.string
};


export default Accordion;
