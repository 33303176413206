import React, { Component } from 'react';
import PropTypes from 'prop-types';
import HeaderContainer from '../../../../modules/Header';
import '../style.scss';
import { Link } from 'react-router-dom';
import Pager from 'react-pager';

class DimensionClassificationsComponent extends Component {


  componentDidMount() {
    const { actions, dimensionId, attributeId } = this.props;
    actions.getUserClassificationByDimensions({ dimensionId, attributeId });
    document.title = 'Dimension Classifications';
  }

  componentDidUpdate(previousProps) {
    const { actions, dimensionId, attributeId, match, location } = this.props;
    const startIndex = (match.params.page || 0) * 24;
    if (location.pathname !== previousProps.location.pathname) {
      actions.getUserClassificationByDimensions({ dimensionId, attributeId, startIndex });
    }
  }

  render() {
    const {
      loaders,
      actions,
      users,
      searchReport
    } = this.props;


    const getAttributeName = ({ userDetails }) => {
      let attributeName = '';
      if (userDetails && userDetails.length > 0) {
        attributeName = userDetails[0].attributeName;
      }
      return attributeName;
    };

    const getDimensionName = ({ userDetails }) => {
      let dimensionName = '';
      if (userDetails && userDetails.length > 0) {
        dimensionName = userDetails[0].dimensionName;
      }
      return dimensionName;
    };


    return (
      <div className="grid curationizer-main">
        <HeaderContainer />
        <div className="grid dimensionClassifications">
          <div className="col__12-12 dimension-classifications-heading">
            User Classification By {getDimensionName({ userDetails: users })} Dimension
            and {getAttributeName({ userDetails: users })} attribute
          </div>
          <div className="col__12-12 dimension-classifications-header">
            <div className="dimension-classifications-fields">
              User Id
            </div>

            <div className="dimension-classifications-fields">
              Dimension Name
            </div>

            <div className="dimension-classifications-fields">
              Attribute Name
            </div>

            <div className="dimension-classifications-fields">
              Attribute Weight %
            </div>
          </div>
          {loaders['load-dimension-classification-details'] &&
          <div className="col__12-12 curationList-loader">
            <div className="pre-loader">
              <div className="segment-spinner" />
            </div>
          </div>}
          {users && users.length > 0
          && !loaders['load-dimension-classification-details']
          && <div className="col__12-12 dimension-classifications-header">
            <div className="curationListBody grid">
              <div className="col__12-12">
                {users.map((user, index) => {
                  return (
                    <div className={`grid ${index % 2 === 0 ?
                      'curationList__body' : ''}`}
                      key={user.userId}
                    >


                      <div className="dimension-classification-cells">
                        <Link to={{
                          pathname: `/userClassifications/details/${user.userId}`
                        }}
                        >
                          {user.userId} </Link>
                       </div>
                      <div className="dimension-classification-cells dimension-name-cell">
                        <span className="dimension-classification-name">{user.dimensionName}</span>

                      </div>
                      <div className="dimension-classification-cells">
                        {user.attributeName}
                      </div>
                      <div className="dimension-classification-cells">
                        {user.attributeWeight}
                      </div>
                    </div>
                  );
                })}
                {!loaders['load-dimension-classification-details']
                && <div className="col__12-12">
                  <div className="pagination-wrapper">
                    <Pager
                      total={searchReport.totalUsers / searchReport.pageSize}
                      current={Math.floor(searchReport.startIndex / searchReport.pageSize)}
                      visiblePages={searchReport.$visiblePages}
                      titles={{ first: '<<', last: '>>' }}
                      className="hd-pagination__wrapper"
                      onPageChanged={actions.handlePageChanged}
                    />
                  </div>
                </div>}
              </div>
            </div>
          </div>}
        </div>
      </div>
    );
  }
}

DimensionClassificationsComponent.propTypes = {
  loaders: PropTypes.object,
  dimensionId: PropTypes.string,
  actions: PropTypes.object,
  attributeId: PropTypes.string,
  users: PropTypes.array,
  searchReport: PropTypes.object,
  match: PropTypes.object,
  location: PropTypes.object,
};

export default DimensionClassificationsComponent;
