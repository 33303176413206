export const SET_SLIDER_VALUE = 'SET_SLIDER_VALUE';
export const SET_SLIDER_ATTRIBUTE_VALUE = 'SET_SLIDER_ATTRIBUTE_VALUE';
export const TOGGLE_SLIDER_LOCK = 'TOGGLE_SLIDER_LOCK';

export const CREATE_CURATION_LIST_FROM_TEMPLATE = 'CREATE_CURATION_LIST_FROM_TEMPLATE';
export const REFRESH_CURATION_LISTS = 'REFRESH_CURATION_LISTS';
export const REFRESH_COLLECTION_LISTS = 'REFRESH_COLLECTION_LISTS';

export const SET_META_DATA = 'SET_META_DATA';
export const UPDATE_CURATION_METADATA = 'UPDATE_CURATION_METADATA';

export const ADD_ITEM_TO_SET = 'ADD_ITEM_TO_SET';
export const ADD_ITEMS_TO_SET = 'ADD_ITEMS_TO_SET';
export const REPLACE_ITEMS_IN_SET = 'REPLACE_ITEMS_IN_SET';
export const REMOVE_ITEM_FROM_SET = 'REMOVE_ITEM_FROM_SET';
export const REMOVE_ITEMS_FROM_SET = 'REMOVE_ITEMS_FROM_SET';
export const UPDATE_ITEM_POSITION = 'UPDATE_ITEM_POSITION';

export const SELECT_CURATION_ITEM = 'SELECT_CURATION_ITEM';

export const SELECT_REVIEW_ITEM = 'SELECT_REVIEW_ITEM';
export const SET_RESULTS_FOR_ITEM = 'SET_RESULTS_FOR_ITEM';
export const REMOVE_RESULTS_ITEM = 'REMOVE_RESULTS_ITEM';
export const FILTER_RESULTS = 'FILTER_RESULTS';
export const UPDATE_LIST_IMAGE = "UPDATE_LIST_IMAGE";
export const ADD_EMPTY_IMAGE = 'ADD_EMPTY_IMAGE';
export const UPDATE_IMAGE_POSITION = 'UPDATE_IMAGE_POSITION';
export const UPDATE_DIMENSION_WEIGHTS = 'UPDATE_DIMENSION_WEIGHTS';

export const SET_LIST_DATA_FOR_CURATION = 'SET_LIST_DATA_FOR_CURATION';
export const COPY_CURATION_TO_COLLECTION = 'COPY_CURATION_TO_COLLECTION';

export const SET_ITEM_SELECTED_PAGINATION_VALUE = 'SET_ITEM_SELECTED_PAGINATION_VALUE';
export const SET_CURATION_SELECTED_PAGINATION_VALUE = 'SET_CURATION_SELECTED_PAGINATION_VALUE';
export const UPDATE_ITEMS_DATA = 'UPDATE_ITEMS_DATA';
export const SET_LIST_DATA = 'SET_LIST_DATA';
export const SET_LIST_DATA_WITHOUT_ITEMS = 'SET_LIST_DATA_WITHOUT_ITEMS';
export const SET_LIST_DATA_WITHOUT_META = 'SET_LIST_DATA_WITHOUT_META';
export const DELETE_CURATION = 'DELETE_CURATION';
export const UPDATE_CURATION_DATA = 'UPDATE_CURATION_DATA';
export const UPDATE_CURATION_METADATA_WITH_CLASSIFICATION_DATA =
  'UPDATE_CURATION_METADATA_WITH_CLASSIFICATION_DATA';
export const UPDATE_VERSIONS_FOR_LIST = 'UPDATE_VERSIONS_FOR_LIST';
export const LOAD_VERSION_FOR_LIST = 'LOAD_VERSION_FOR_LIST';
export const TOGGLE_VERSION_ROW = 'TOGGLE_VERSION_ROW';
export const CLOSE_ALL_VERSION_ROWS = 'CLOSE_ALL_VERSION_ROWS';
export const LOAD_DIFF_FOR_VERSION = 'LOAD_DIFF_FOR_VERSION';

// Tag Component related Constants.
export const SET_CURATION_TAGS_CATEGORY = 'SET_CURATION_TAGS_CATEGORY';
export const SET_CURATION_SEARCH_TAGS_VALUE = 'SET_CURATION_SEARCH_TAGS_VALUE';
export const UPDATE_MULTI_LISTS_TAGS_VALUE = 'UPDATE_MULTI_LISTS_TAGS_VALUE';
export const UPDATE_APP_TAGS_METADATA = 'UPDATE_APP_TAGS_METADATA';
export const SET_CURATION_INPUT_TAG_VALUE = 'SET_CURATION_INPUT_TAG_VALUE';
export const SET_CURATION_SORT = 'SET_CURATION_SORT';
export const UPDATE_CURATION_STATUS = 'UPDATE_CURATION_STATUS';
export const SET_PRODUCT_KEYWORD_FILTER = 'SET_PRODUCT_KEYWORD_FILTER';
export const SET_ITEM_KEYWORD_FILTER = 'SET_ITEM_KEYWORD_FILTER';
export const REMOVE_CURATION_SEARCH_TAGS_VALUE = 'REMOVE_CURATION_SEARCH_TAGS_VALUE';
export const SHOW_HIDE_TAG_CATEGORY_DROPDOWN = 'SHOW_HIDE_TAG_CATEGORY_DROPDOWN';
export const SET_IS_TAG_INPUT_FIELD_FOCUS_IN = 'SET_IS_TAG_INPUT_FIELD_FOCUS_IN';


//

export const TOGGLE_ITEM_RESULT_VIEW = 'TOGGLE_ITEM_RESULT_VIEW';
export const TOGGLE_RESULTS_ITEM_SELECTION = 'TOGGLE_RESULTS_ITEM_SELECTION';
export const TOGGLE_SELECTED_RESULTS_ITEM_SELECTION = 'TOGGLE_SELECTED_RESULTS_ITEM_SELECTION';

export const CHANGE_CURATION_LIST_VIEW = 'CHANGE_CURATION_LIST_VIEW';

export const SET_CURATION_KEYWORD_FILTER = 'SET_CURATION_KEYWORD_FILTER';
export const UPDATE_CURATION_NAME = 'UPDATE_CURATION_NAME';
export const UPDATE_INTERNAL_NAME = 'UPDATE_INTERNAL_NAME';
export const UPDATE_CURATION_DESCRIPTION = 'UPDATE_CURATION_DESCRIPTION';
export const TOGGLE_NAME_EDIT_MODE = 'TOGGLE_NAME_EDIT_MODE';
export const TOGGLE_COLLECTION_SUBTYPE_EDIT_MODE = 'TOGGLE_COLLECTION_SUBTYPE_EDIT_MODE';
export const TOGGLE_PINTEREST_PUBLISHED = 'TOGGLE_PINTEREST_PUBLISHED';
export const TOGGLE_HIDE_CLP_PUBLISHED = 'TOGGLE_HIDE_CLP_PUBLISHED';
export const SAVE_CURATION_NAME = 'SAVE_CURATION_NAME';
export const SAVE_INTERNAL_NAME = 'SAVE_INTERNAL_NAME';
export const UPDATE_COLLECTION_SUBTYPE = 'UPDATE_COLLECTION_SUBTYPE';
export const TOGGLE_DESCRIPTION_EDIT_MODE = 'TOGGLE_DESCRIPTION_EDIT_MODE';

export const RESET_UI_SETTINGS = 'RESET_UI_SETTINGS';
export const SET_UI_LIST_VIEW = 'SET_UI_LIST_VIEW';
export const SET_DISCOVER_ITEM_BY = 'SET_DISCOVER_ITEM_BY';

export const SET_SORT_TYPE = 'SET_SORT_TYPE';
export const RESET_SORT_TYPE = 'RESET_SORT_TYPE';
export const TOGGLE_CURATELIST_EDIT_MODE = 'TOGGLE_CURATELIST_EDIT_MODE';
export const TOGGLE_CURATELIST_BULK_MODE = 'TOGGLE_CURATELIST_BULK_MODE';

export const SET_LIST_ANALYTICS = 'SET_LIST_ANALYTICS';
export const SET_ITEM_ANALYTICS = 'SET_ITEM_ANALYTICS';

export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';

export const SET_REFINEMENT_ATTRIBUTE_VALUE = 'SET_REFINEMENT_ATTRIBUTE_VALUE';
export const SET_ALL_ATTRIBUTE_VALUES_OF_REFINEMENT = 'SET_ALL_ATTRIBUTE_VALUES_OF_REFINEMENT';
export const ADD_LIST = 'ADD_LIST';

export const SET_REVIEWLIST_COLUMN_VIEW = 'SET_REVIEWLIST_COLUMN_VIEW';
export const REVIEW_PREVIEW_IMAGE = 'REVIEW_PREVIEW_IMAGE';

export const UPDATE_LIST_DETAILS = 'UPDATE_LIST_DETAILS';
export const TOGGLE_LIST_SELECTION = 'TOGGLE_LIST_SELECTION';
export const CLEAR_APP_META_ATTRIBUTES_SELECTION = 'CLEAR_APP_META_ATTRIBUTES_SELECTION';
export const CLEAR_MULTI_LIST_ATTRIBUTE_EDIT_MODE = 'CLEAR_MULTI_LIST_ATTRIBUTE_EDIT_MODE';
export const UPDATE_SELECTED_LISTS_METADATA = 'UPDATE_SELECTED_LISTS_METADATA';
export const UPDATE_APP_METADATA = 'UPDATE_APP_METADATA';
export const TOGGLE_LIST_NAME_EDIT_MODE = 'TOGGLE_LIST_NAME_EDIT_MODE';
export const SAVE_SELECTED_LIST_NAME = 'SAVE_SELECTED_LIST_NAME';
export const TOGGLE_MULTI_LIST_ATTRIBUTE_EDIT_MODE = 'TOGGLE_MULTI_LIST_ATTRIBUTE_EDIT_MODE';
export const SET_LIST_DATA_WITH_NAME_EDIT = 'SET_LIST_DATA_WITH_NAME_EDIT';
export const SET_MULTI_LIST_DATA = 'SET_MULTI_LIST_DATA';
export const UPDATE_MULTI_LIST_STATUS = 'UPDATE_MULTI_LIST_STATUS';
export const SET_SEARCH_KEYWORD = 'SET_SEARCH_KEYWORD';

export const SET_USERNAME = 'SET_USERNAME';

export const INITIATE_LOADER = 'INITIATE_LOADER';
export const REMOVE_LOADER = 'REMOVE_LOADER';

export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';
export const CLEAR_ERROR_MESSAGE = 'CLEAR_ERROR_MESSAGE';

export const SET_ACTIVE_MENU_TAB = 'SET_ACTIVE_MENU_TAB';
export const ADD_CURATION_ERROR = 'ADD_CURATION_ERROR';
export const GENERIC_ERROR_HANDLER_CLEAR = 'GENERIC_ERROR_HANDLER_CLEAR';

export const SET_REVIEW_LIST_STATUS = 'SET_REVIEW_LIST_STATUS';

export const SET_PINTEREST_STATUS = 'SET_PINTEREST_STATUS';
export const RESET_PINTEREST_STATUS = 'RESET_PINTEREST_STATUS';

export const HOTSPOT_SET_DRAG = 'HOT_SPOT_SET_DRAG';
export const HOTSPOT_SET_CANVAS_COORDS = 'HOTSPOT_SET_CANVAS_COORDS';
export const HOTSPOT_RESET_DRAG_COORDS = 'HOTSPOT_RESET_DRAG_COORDS';
export const HOTSPOT_SET_NEW_HOTSPOT = 'HOTSPOT_SET_NEW_HOTSPOT';
export const HOTSPOT_CLEAR = 'HOTSPOT_CLEAR';
export const HOTSPOT_APPLY_TO_IMAGE = 'HOTSPOT_APPLY_TO_IMAGE';

export const HOTSPOT_SET_REPOSITION = 'HOTSPOT_SET_REPOSITION';
export const HOTSPOT_SET_ITEM = 'HOTSPOT_SET_ITEM';
export const HOTSPOT_TOGGLE_VISIBILITY = 'HOTSPOT_TOGGLE_VISIBILITY';
export const HOTSPOT_LOAD_ITEMS = 'HOTSPOT_LOAD_ITEMS';
export const HOTSPOT_SET_REPOSITION_COORDS = 'HOTSPOT_SET_REPOSITION_COORDS';
export const HOTSPOT_SET_RESIZE = 'HOTSPOT_SET_RESIZE';
export const HOTSPOT_SET_ACTIVE = 'HOTSPOT_SET_ACTIVE';
export const DELETE_HOTSPOT = 'DELETE_HOTSPOT';
export const CLEAR_SPOT_IN_HOTSPOT = 'CLEAR_SPOT_IN_HOTSPOT';
export const CREATE_DOT_ON_HOTSPOT = 'CREATE_DOT_ON_HOTSPOT';
export const SET_CURATION_STATUS = 'SET_CURATION_STATUS';
export const HOTSPOT_GRID_VIEW_STATUS = 'HOTSPOT_GRID_VIEW_STATUS';
export const HOTSPOT_UPDATED_ITEM = 'HOTSPOT_UPDATED_ITEM';
export const HOTSPOT_TOGGLE_PRIORITY = 'HOTSPOT_TOGGLE_PRIORITY';
export const HOTSPOT_UPDATED_ITEM_ON_PRIORITY = 'HOTSPOT_UPDATED_ITEM_ON_PRIORITY';
export const HOTSPOT_TOGGLE_EXACT_MATCH_AND_REPLACEMENT =
  'HOTSPOT_TOGGLE_EXACT_MATCH_AND_REPLACEMENT';
export const HOTSPOT_TOGGLE_PINTEREST_PREVIEW = 'HOTSPOT_TOGGLE_PINTEREST_PREVIEW';
export const HOTSPOT_SAVE_TEMPLATE = 'HOTSPOT_SAVE_TEMPLATE';
export const HOTSPOT_APPLY_TEMPLATE = 'HOTSPOT_APPLY_TEMPLATE';
export const HOTSPOT_CLEAR_TEMPLATES = 'HOTSPOT_CLEAR_TEMPLATES';
export const SET_HOTSPOT_PINTEREST_PREVIEW_DETAILS = 'SET_HOTSPOT_PINTEREST_PREVIEW_DETAILS';
export const SET_DEFAULT_CURATION_LIST = 'SET_DEFAULT_CURATION_LIST';
export const SET_PREVIOUS_HOTSPOT = 'SET_PREVIOUS_HOTSPOT';
export const UPDATE_HISTORY_ITEMS_ON_MOVE = 'UPDATE_HISTORY_ITEMS_ON_MOVE';
export const UPDATE_HISTORY_ON_SET_ITEMS = 'UPDATE_HISTORY_ON_SET_ITEMS';
export const SET_DEFAULT_GRID_VIEW_PINTEREST_PREVIEW = 'SET_DEFAULT_GRID_VIEW_PINTEREST_PREVIEW';

// Maintenance Items
export const LOAD_MAINTENANCE_ITEMS = 'LOAD_MAINTENANCE_ITEMS';
export const LOAD_MERCHANDISING_ATTRIBUTES = 'LOAD_MERCHANDISING_ATTRIBUTES';
export const RESET_MERCHANDISING_ATTRIBUTES = 'RESET_MERCHANDISING_ATTRIBUTES';
export const TOGGLE_MAINTENANCE_SIMILAR_ITEMS = 'TOGGLE_MAINTENANCE_SIMILAR_ITEMS';
export const LOAD_PRODUCT_INFO = 'LOAD_PRODUCT_INFO';
export const SET_SIMILAR_ITEMS_FOR_ITEM = 'SET_SIMILAR_ITEMS_FOR_ITEM';
export const TOGGLE_SIMILAR_ITEM_SELELCTION = 'TOGGLE_SIMILAR_ITEM_SELELCTION';
export const TOGGLE_CURATION = 'TOGGLE_CURATION';
export const RESET_SIMILAR_ITEMS_SLIDER = 'RESET_SIMILAR_ITEMS_SLIDER';
export const TOGGLE_SELECT_ALL_CURATIONS = 'TOGGLE_SELECT_ALL_CURATIONS';
export const LOAD_PRODUCT_CURATIONS = 'LOAD_PRODUCT_CURATIONS';
export const TOGGLE_UN_SELECT_ALL_CURATIONS = 'TOGGLE_UN_SELECT_ALL_CURATIONS';
export const SET_PRODUCT_CURATION_INFO_MESSAGE = 'SET_PRODUCT_CURATION_INFO_MESSAGE';
export const SET_WINDOW_SCROLL_TOP = 'SET_WINDOW_SCROLL_TOP';

// Vision Items
export const RESET_VISION_UI_SETTINGS = 'RESET_VISION_UI_SETTINGS';
export const LOAD_HOTSPOT_IMAGES = 'LOAD_HOTSPOT_IMAGES';
export const RESET_HOTSPOT_IMAGES = 'RESET_HOTSPOT_IMAGES';
export const SET_VISION_IMAGE_ATTRIBUTES = 'SET_VISION_IMAGE_ATTRIBUTES';
export const REMOVE_VISION_IMAGE = 'REMOVE_VISION_IMAGE';
export const SET_UNSET_ITEM_BY_ITEM = 'SET_UNSET_ITEM_BY_ITEM';
export const SET_ITEM_ID_FOR_ITEM_BY_ITEM = 'SET_ITEM_ID_FOR_ITEM_BY_ITEM';
export const UN_SET_ITEM_ID_FOR_ITEM_BY_ITEM = 'UN_SET_ITEM_ID_FOR_ITEM_BY_ITEM';
export const SET_VISION_ITEM_ACTIVE = 'SET_VISION_ITEM_ACTIVE';

// Collections List
export const LIST_COLLECTIONS_FROM_CURATION = 'LIST_COLLECTIONS_FROM_CURATION';

// Classifications
export const SET_CLASSIFICATIONS = 'SET_CLASSIFICATIONS';
export const SET_USER_CLASSIFICATION_WEIGHT = 'SET_USER_CLASSIFICATION_WEIGHT';
export const SET_SKU_CLASSIFICATION_WEIGHT = 'SET_SKU_CLASSIFICATION_WEIGHT';
export const SET_SKU_CLASSIFICATION_ERROR_MSG = 'SET_SKU_CLASSIFICATION_ERROR_MSG';
export const SET_CLASSIFICATION_INFO_MSG = 'SET_CLASSIFICATION_INFO_MSG';
export const SET_USER_CLASSIFICATION_DETAILS = 'SET_USER_CLASSIFICATION_DETAILS';
export const RESET_USER_CLASSIFICATION_DETAILS = 'RESET_USER_CLASSIFICATION_DETAILS';
export const SET_DIMENSION_CLASSIFICATION_BY_USER = 'SET_DIMENSION_CLASSIFICATION_BY_USER';
export const SET_USER_CLASSIFICATION_DETAILS_BY_ID = 'SET_USER_CLASSIFICATION_DETAILS_BY_ID';
