import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import * as actions from './SimilarItems.actions';
import { isCurationAndItemSelected } from '../../MaintenanceDetails.reducer';
import component from './SimilarItems.component';

const mapStateToProps = (state, ownProps) => {
  const curationAndItemSelected = isCurationAndItemSelected({ state });
  return {
    loaders: state.app.loaders,
    showSimilarItems: state.ui.showMaintenanceSimilarItems,
    similarItems: state.maintenanceDetails.similarItems,
    curationAndItemSelected
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    actions: bindActionCreators({
      ...actions,
    }, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(component));
