import * as types from '../../../../../constants/ActionTypes';

const initialState = {
  items: [],
  errorModel: []
};

export default function app(state = initialState, action) {
  switch (action.type) {
    case types.GENERIC_ERROR_HANDLER_CLEAR:
      return {
        ...state,
        errorModel: []
      };
    case types.LOAD_MAINTENANCE_ITEMS:
      return {
        ...state,
        items: action.items,
        searchReport: action.searchReport
      };
    case types.SET_ITEM_KEYWORD_FILTER:
      return {
        ...state,
        items: state.items,
        keyword: action.keyword
      };
    default:
      return state;
  }
}

export const getListById = ({ listId, state = [] }) => {
  return state.lists.find(i => i.id === listId);
};
