import * as types from '../../../../../../constants/ActionTypes';
import * as api from '../../../../../../util/api';
import { loadExcelDependency } from '../../../../../../util';
import { updateImageSrc } from '../Item/reducer';
// import XLSX from 'xlsx';
import { toggleCurateListBulkMode } from '../Items/actions';

export const handleFileSelect = (evt, { listId }, rABS = true) => {
  return (dispatch) => {
    let files;
    if (Array.isArray(evt)) {
      files = evt;
    } else {
      if (evt) {
        evt.stopPropagation();
        evt.preventDefault();
        files = evt.target.files;
      }
    }

    loadExcelDependency().then(XLSX => {
      dispatch({ type: types.CLEAR_ERROR_MESSAGE });

      const uniqueIds = {};
      let file = files[0];
      let reader = new FileReader();
      reader.onload = function (e) {
        let data = e.target.result;
        if (!rABS) data = new Uint8Array(data);
        let workbook = XLSX.read(data, { type: rABS ? 'binary' : 'array' });
        let firstWorksheet = workbook.Sheets[workbook.SheetNames[0]];
        let newSheet = XLSX.utils.sheet_to_json(firstWorksheet, { header: 1 });
        let spreadSheet = newSheet.reduce((acc, current) => {
          if (current.length > 1) {
            current.forEach(row => {
              let splitCurrent = row.split(',');
              splitCurrent.forEach(osmid => {
                osmid = osmid.replace(/\D/g, '');
                acc.push(osmid);
              });
            });
          } else {
            if (current.length === 0) {
              return acc;
            } else if (current[0].includes(',')) {
              let splitCurrent = current[0].split(',');
              splitCurrent.forEach(osmid => {
                osmid = osmid.replace(/\D/g, '');
                acc.push(osmid);
              });
            } else {
              current = current[0].replace(/\D/g, '');
              if (current.length > 0) {
                acc.push(current);
              }
            }
          }
          return acc;
        }, []);
        const ids = spreadSheet.map(id => {
          return id.trim();
        })
        .filter(String)
        .filter((itemId) => {
          if (!uniqueIds[itemId]) {
            uniqueIds[itemId] = true;
            return true;
          }
          return false;
        })
        .filter((itemId) => {
          return /^\d+$/.test(itemId);
        })
        .filter((itemId) => {
          return (itemId + '').length >= 7;
        });
        dispatch({ type: types.INITIATE_LOADER, loader: 'itemResult' });
        api.getItemsByIds({ ids })
          .then(skus => {
            const items = skus.map(item => {
              return {
                ...item,
                $imageSrc: updateImageSrc({ data: item }),
              };
            });
            let search = { source: 'solr', skus: items };
            search.searchReport = {
              keyword: '',
              searchInterface: 'xlsx',
              sortBy: 'bestmatch',
              sortOrder: 'none',
              startIndex: 0,
              pageSize: items.length,
              totalProducts: items.length
            };
            dispatch({ type: types.REMOVE_LOADER, loader: 'itemResult' });
            dispatch({ type: types.SET_ITEM_SELECTED_PAGINATION_VALUE, value: 1 });
            dispatch({ type: types.UPDATE_ITEMS_DATA, value: search, resultType: 'upload' });
          })
          .catch(err => {
            dispatch({ type: types.SET_ERROR_MESSAGE, message: 'One or more' +
            ' of the OSMIDS in your excel sheet cannot be found' });
            dispatch({ type: types.REMOVE_LOADER, loader: 'itemResult' });
            console.log(err);
          });
      };
      if (rABS) reader.readAsBinaryString(file);
      else reader.readAsArrayBuffer(file);
    });
  };
};

export const showOmsidImportTextBox = () => {
  return (dispatch, getState) => {
    const state = getState();
    if (!state.ui.curateList.bulkMode) {
      dispatch(toggleCurateListBulkMode());
    }
  };
};
