import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { GCS_IMAGE_BUCKET_LIFESTYLE } from '../../../../../../constants/environment';
import './style.scss';
import { DragSource, DropTarget } from 'react-dnd';
import classNames from 'classnames';
import { deserializeHotspotItems,
         getHotspotImage
       } from '../../../../../overlay/Hotspots/CurationHotSpotReducer';
import domtoimage from 'dom-to-image';
import hotspotLogo from '../../../../../../assets/images/hotspot-arrow.svg';

export function getBoundingBoxesForImage({ list = {}, image, canvas }) {
  const imageBoundBoxItems = getHotspotImage({ list, imageGuid: image.guid });
  return deserializeHotspotItems({ items: imageBoundBoxItems, canvas });
}

class DraggableImageComponent extends Component {

  render() {
    const { isDragging, connectDragSource, actions, list, image, index } = this.props;
    const canvas = {
      width: 235,
      height: 400
    };
    if (this.el) {
      canvas.height = this.el.height;
      canvas.width = this.el.width;
    }
    const boxes = getBoundingBoxesForImage({ list, image, canvas });
    return (connectDragSource(
      <form
        className={classNames({
          'image--isDragging': isDragging
        })}
        key={index}
      >
      <div className="vision_preview--wrapper">
        <div className="vision_preview--container">
          <div>

            {list.$canEdit && <div className="deleteImage"
              onClick={ (e) => {
                e.preventDefault();
                actions.removeImage({ listId: list.id, index, image });
              }}
            >X</div>}
            {image.guid && <div>
              {boxes && !!boxes.length && <svg className="vision_preview__hotspots"
                onDoubleClick={(e) => {
                  const url = `/davinci/overlay/index.html#/hotspot/${list.id}/${image.guid}`;
                  window.THD_OVERLAYS.hotspots.open(url);
                }}
              >
                {boxes
                .filter(box => box.dotCoord && box.dotCoord.x && box.dotCoord.y)
                .map((box, i) => {
                  return (
                    <g
                      key={i}
                      transform={`translate(${box.coords.x},${box.coords.y})`}
                      x={box.coords.x}
                      y={box.coords.y}
                    >

                      <image
                        href={hotspotLogo}
                        x={box.dotCoord.x}
                        y={box.dotCoord.y}
                        className="hotspot-logo"
                      />
                  </g>);
                })}
              </svg>}
              <img
                src={GCS_IMAGE_BUCKET_LIFESTYLE + image.guid}
                className="vision__preview"
                alt="preview"
                width="300"
                ref={(el) => {
                  if (el) this.el = el;
                }}
                onDoubleClick={(e) => {
                  const url = `/davinci/overlay/index.html#/hotspot/${list.id}/${image.guid}`;
                  window.THD_OVERLAYS.hotspots.open(url);
                }}
              />
            </div>}
          </div>
        </div>
      </div>
        <input
          type="text"
          className="form-input__field preview-image_id"
          placeholder="ID"
          autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false"
          readOnly={!list.$canEdit}
          defaultValue={image.guid || ''}
          onBlur={(e) => {
            if (list.$canEdit) {
              const value = e.target.value;
              let updateImagePromise = new Promise(() => {
                actions.updateImage({ listId: list.id, value, index });
              });
              updateImagePromise.then(() => {
                actions.saveImage({ listId: list.id, index });
              });
            }
          }}
        />
    </form>)
    );
  }
}

const src = {
  beginDrag(props, dnd, component) {
    if (component.el) {
      component.props.connectDragPreview(component.el);
    }

    return {
      ...props
    };
  }
};

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
    connectDragPreview: connect.dragPreview()
  };
}

DraggableImageComponent.propTypes = {
  isDragging: PropTypes.bool,
  connectDragSource: PropTypes.func.isRequired,
  actions: PropTypes.object,
  list: PropTypes.object,
  image: PropTypes.object,
  index: PropTypes.number
};


const DraggableImage = DragSource("IMAGE_DND", src, collect)(DraggableImageComponent);// eslint-disable-line


const droppableSec = {
  canDrop(props, monitor) {
    const item = monitor.getItem();
    // cant drop on same item
    // return item.props.product.itemId !== props.product.itemId;
    // dont allow dropping of item thats already in item set
    if (!props.list.$canEdit) {
      return false;
    }
    return true;
  },

  drop(props, monitor) {
    const item = monitor.getItem();
    props.actions
      .updateImagePosition({
        listId: item.list.id, currentPosition: item.index, newPosition: props.index
      });
  }
};


function droppableCollect(connect, monitor) {
  const info = {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop()
  };
  return info;
}


class DroppableImageSrc extends Component {// eslint-disable-line
  render() {
    const { connectDropTarget, isOver, canDrop, children } = this.props;
    const className = ['list-images'];
    if (isOver && canDrop) {
      className.push('image--droppable');
    }
    return connectDropTarget(
      <div className={className.join(' ')}>
        {children}
      </div>
    );
  }
}

DroppableImageSrc.propTypes = {
  isOver: PropTypes.bool,
  connectDropTarget: PropTypes.func,
  movePiece: PropTypes.func,
  canDrop: PropTypes.bool,
  children: PropTypes.object
};


const DroppableImage = DropTarget("IMAGE_DND", droppableSec, droppableCollect)(DroppableImageSrc);// eslint-disable-line

const ReviewItemImage = ({ list, imageSrc, ui, actions, loaders }) => (
    <div>
        {list.$canEdit && <div className="file__container--wrapper">
         {loaders['image-upload'] &&
            <div className="pre-loader">
              <div className="segment-spinner"></div>
            </div>
          }
            <div className={list.$canUploadMoreImages
              ? 'file_upload--container'
              : 'hide_uplod--wrapper'
            }
              onClick={(e) => {
                e.preventDefault();
                e.currentTarget.nextSibling.click();
              }}
            >
              {/* <div className="upload-icon"></div> */}
              <a className="file_upload--label">Set featured image</a>
            </div>
            <input type="file" name="visionUpload" accept="accept=image/*"
              className="file__container--wrapper-input"
              onChange={(e) => {
                const picture = e.target;
                actions.uploadImage({ picture, listId: list.id, index: list.images.length });
                e.target.value = null;// eslint-disable-line
              }}
            />
            {/* list.$canUploadMoreImages &&
              <div className="file_upload--label">Or</div>
            }
            {list.$canUploadMoreImages && <a
              className="file_upload--label"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                actions.addEmptyImage({ listId: list.id });
              }}
            >Add image guid</a>*/}

        </div> }
          <div>
            {list.images && list.images.map((image, index) => {
              return (
                <DroppableImage
                  key={index}
                  list={list}
                  actions={actions}
                  image={image}
                  index={index}
                >
                  <DraggableImage list={list} actions={actions} image={image} index={index} />
                </DroppableImage>
              );
            })}

          </div>
  </div>
);

ReviewItemImage.propTypes = {
  list: PropTypes.object,
  ui: PropTypes.object,
  imageSrc: PropTypes.string,
  actions: PropTypes.object,
  loaders: PropTypes.object
};

// export default DragSource(ItemTypes.CARD, cardSource, collect)(Card);
export default ReviewItemImage;
