import * as types from '../../../../constants/ActionTypes';
import * as api from '../../../../util/api';

export const getClassifications = ({}) => {
  return (dispatch) => {
    dispatch({
      type: types.INITIATE_LOADER,
      loader: 'load-classification-details'
    });
    return api
      .getClassificationDetails()
      .then(response => {
        dispatch({ type: types.SET_CLASSIFICATIONS,
          sku: response.SKU ? response.SKU : [],
          user: response.User ? response.User : [] });
        dispatch({ type: types.REMOVE_LOADER, loader: 'load-classification-details' });
      })
      .catch(e => {
        console.error(e);
        dispatch({ type: types.REMOVE_LOADER, loader: 'load-classification-details' });
      });
  };

};

export const setClassificationWeight = ({ id, value, type }) => {
  return (dispatch, getState) => {
    const state = getState();
    if (type === 'user') {
      dispatch({
        type: types.SET_USER_CLASSIFICATION_WEIGHT,
        id,
        weight: value
      });
    } else {
      dispatch({
        type: types.SET_SKU_CLASSIFICATION_WEIGHT,
        id,
        weight: value
      });
    }
  };
};

export const applyClassificationChanges = () => {
  return (dispatch, getState) => {
    const state = getState();

    dispatch({
      type: types.INITIATE_LOADER,
      loader: 'submit-classification-changes'
    });


    const userClassifications = state.classification.userClassificationChanged
                                && state.classification.userClassificationChanged.length > 0
                                ? state.classification.userClassificationChanged
                                : state.classification.userClassification;

    const skuClassifications = state.classification.skuClassificationChanged
                               && state.classification.skuClassificationChanged.length > 0
                                ? state.classification.skuClassificationChanged
                                : state.classification.skuClassification;

    const userClassificationWeights = userClassifications.reduce((total, userClassification) => {
      return total + userClassification.weight;
    }, 0);

    const skuClassificationWeights = skuClassifications.reduce((total, skuClassification) => {
      return total + skuClassification.weight;
    }, 0);

    if (skuClassificationWeights !== 100 && userClassificationWeights !== 100) {
      const errorMessage = 'User Classification is not 100% and Sku Classification is not 100%. ' +
                            'Actual user classification weight is ' + userClassificationWeights +
                            '%, actual sku classification weight is ' + skuClassificationWeights +
                            '%. Expected user classification weight is 100% and ' +
                            'expected sku classification weight is 100%.';
      const infoMessage = '';

      dispatch({
        type: types.SET_SKU_CLASSIFICATION_ERROR_MSG,
        errorMessage
      });

      dispatch({ type: types.SET_CLASSIFICATION_INFO_MSG, infoMessage });

    } else if (userClassificationWeights !== 100) {
      const errorMessage = 'User Classification is not 100%. ' +
                            'Actual user classification weight is ' +
                            userClassificationWeights +
                            '%. Expected user classification weight is 100%.';

      const infoMessage = '';

      dispatch({
        type: types.SET_SKU_CLASSIFICATION_ERROR_MSG,
        errorMessage
      });

      dispatch({ type: types.SET_CLASSIFICATION_INFO_MSG, infoMessage });

    } else if (skuClassificationWeights !== 100) {
      const errorMessage = 'Sku Classification is not 100%. Actual sku classification weight is ' +
                            skuClassificationWeights +
                            '%. Expected sku classification weight is 100%.';
      const infoMessage = '';

      dispatch({
        type: types.SET_SKU_CLASSIFICATION_ERROR_MSG,
        errorMessage
      });

      dispatch({ type: types.SET_CLASSIFICATION_INFO_MSG, infoMessage });
    } else {
      const errorMessage = '';

      dispatch({
        type: types.SET_SKU_CLASSIFICATION_ERROR_MSG,
        errorMessage
      });

      api.updateClassificationDetails(
        {
          user: userClassifications,
          sku: skuClassifications
        })
        .then(response => {

          const infoMessage = 'Classifications updated successfully.';

          dispatch({ type: types.SET_CLASSIFICATIONS,
            sku: response.SKU ? response.SKU : [],
            user: response.User ? response.User : [] });

          dispatch({ type: types.SET_CLASSIFICATION_INFO_MSG, infoMessage });
        })
        .catch(e => {
          console.log(e);
          dispatch({
            type: types.REMOVE_LOADER,
            loader: 'submit-classification-changes'
          });
        });
    }
    dispatch({
      type: types.REMOVE_LOADER,
      loader: 'submit-classification-changes'
    });
    window.scrollTo(0, 0);
  };
};

